import { routes } from 'config/routes'
import { TableColumnProps, Modal, Drawer, message, DatePicker, Input, Select, Space } from 'antd';
import { useTranslation } from 'react-i18next'
import PageHeader from 'components/page-header'
import { Button, CardBody, CardHeader, Card, Chip, Switch, ModalBody, ModalHeader, ModalContent, ModalFooter } from '@nextui-org/react';
import { useNavigate } from 'react-router-dom';
import useFetch from 'hooks/use-fetch'
import { useEffect, useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion';

import moment from 'moment'
import {
   OFFER_MANAGEMENT_LIST,
   OFFER_MANAGEMENT_LIST_URL,
   OFFER_MANAGEMENT_SHOW,
   OFFER_MANAGEMENT_SHOW_URL,
   OFFER_MANAGEMENT_STORE
} from 'api/offer-management'
import SearchInput from 'components/Input'
import TableComponents from 'components/Table'
import { IconEdit, IconFileInvoice, IconPdf, IconTruck, IconUser, IconX, IconCalendar, IconPackage, IconBuilding, IconTools, IconClock, IconCheck, IconInfoCircle, IconCalculator, IconChartBar } from '@tabler/icons-react';
import { useDispatch, useSelector } from 'react-redux'
import { setContactId, setOfferNumber, setProduct, setTermTime } from 'features/user/offer'
import { RootState } from 'store'
import { setLoading } from 'features/user/app'
import Preview from '../offers-management/Preview'
import { getOrderStatusWithClass, getOrderWithAnimation, orderStatus, orderStatusColor, orderStatusText } from 'utils/get-order-status'
import cn from 'utils/class-names'
import Lottie from 'react-lottie'
import { MoneyFormat2, MoneyFormatTR } from 'hooks/currency'
import { Popover, PopoverTrigger, PopoverContent } from '@nextui-org/react';
import OrderStatusModalButton from './OrderStatusModalButton';
import { SearchOutlined } from '@ant-design/icons';
import type { Locale } from 'antd/es/locale';
import tr_TR from 'antd/es/date-picker/locale/tr_TR';
import type { Dayjs } from 'dayjs';
import type { EventValue } from 'rc-picker/lib/interface';
import { USER_SHOW } from 'api/user';
import { setTotalOrders, setTotalRevenue, setTotalProfit, setConversionRate } from 'features/user/user';
import axios from 'axios';

type StatusType = 'ORDER_RECEIVED' | 'ORDER_PREPARING' | 'PRODUCTION_STAGE' | 'SHIPPING' | 'DELIVERED' | 'CANCELLED';

const statusConfig: Record<StatusType, {
   icon: string;
   label: string;
   className: string;
   dotColor: string;
   description: string;
   gradient: string;
}> = {
   ORDER_RECEIVED: {
      icon: '📦',
      label: 'Sipariş Alındı',
      className: 'bg-[#EEF6FF] text-[#1D4ED8] border border-[#93C5FD]',
      dotColor: 'bg-[#3B82F6]',
      description: 'Sipariş sisteme kaydedildi',
      gradient: 'from-[#DBEAFE] via-[#EEF6FF] to-[#DBEAFE]'
   },
   ORDER_PREPARING: {
      icon: '🔧',
      label: 'Hazırlanıyor',
      className: 'bg-[#FEF3C7] text-[#B45309] border border-[#FCD34D]',
      dotColor: 'bg-[#F59E0B]',
      description: 'Ürünler hazırlanma aşamasında',
      gradient: 'from-[#FEF3C7] via-[#FDE68A] to-[#FEF3C7]'
   },
   PRODUCTION_STAGE: {
      icon: '🏭',
      label: 'Üretimde',
      className: 'bg-[#EDE9FE] text-[#5B21B6] border border-[#A78BFA]',
      dotColor: 'bg-[#7C3AED]',
      description: 'Üretim sürecinde',
      gradient: 'from-[#EDE9FE] via-[#DDD6FE] to-[#EDE9FE]'
   },
   SHIPPING: {
      icon: '🚚',
      label: 'Kargoda',
      className: 'bg-[#FFEDD5] text-[#C2410C] border border-[#FB923C]',
      dotColor: 'bg-[#F97316]',
      description: 'Teslimat için yola çıktı',
      gradient: 'from-[#FFEDD5] via-[#FED7AA] to-[#FFEDD5]'
   },
   DELIVERED: {
      icon: '✅',
      label: 'Teslim Edildi',
      className: 'bg-[#DCFCE7] text-[#15803D] border border-[#4ADE80]',
      dotColor: 'bg-[#22C55E]',
      description: 'Müşteriye teslim edildi',
      gradient: 'from-[#DCFCE7] via-[#BBF7D0] to-[#DCFCE7]'
   },
   CANCELLED: {
      icon: '❌',
      label: 'İptal Edildi',
      className: 'bg-[#FEE2E2] text-[#B91C1C] border border-[#FCA5A5]',
      dotColor: 'bg-[#EF4444]',
      description: 'Sipariş iptal edildi',
      gradient: 'from-[#FEE2E2] via-[#FECACA] to-[#FEE2E2]'
   }
};

type QueryObject = {
   customer_name?: string;
   company_name?: string;
   order_status?: string;
   start_date?: string;
   end_date?: string;
   user_ids?: number[];
};

// Hesaplama fonksiyonları
const calculateProductTotal = (products: any[]) => {
   return products?.reduce((total: number, product: any) => {
      const productJson = JSON.parse(product.product_json);
      return total + (product.product_qty * parseFloat(productJson.price));
   }, 0) || 0;
};

const calculateServiceTotal = (products: any[]) => {
   return products?.reduce((total: number, product: any) => {
      return total + product.services?.reduce((serviceTotal: number, service: any) => {
         return serviceTotal + (parseFloat(service.price) * product.product_qty);
      }, 0) || 0;
   }, 0) || 0;
};

const calculateMarkupTotal = (products: any[]) => {
   return products?.reduce((total: number, product: any) => {
      // Ürün kar hesabı
      const productJson = JSON.parse(product.product_json);
      const baseAmount = product.product_qty * parseFloat(productJson.price);
      const productMarkup = product.product_markup_type === 1
         ? baseAmount * (parseFloat(product.product_markup) / 100)
         : parseFloat(product.product_markup);

      // Hizmet kar hesabı
      const serviceMarkup = product.services?.reduce((serviceTotal: number, service: any) => {
         const serviceAmount = parseFloat(service.price) * product.product_qty;
         return serviceTotal + (service.service_markup_type === 1
            ? serviceAmount * (parseFloat(service.service_markup) / 100)
            : parseFloat(service.service_markup));
      }, 0) || 0;

      return total + productMarkup + serviceMarkup;
   }, 0) || 0;
};

const calculateOrderTotal = (order: any) => {
   if (!order?.products) return 0;

   return order.products.reduce((total: number, product: any) => {
      const unitPrice = calculateUnitPrice(product);
      const quantity = parseInt(product.product_qty || '0');
      return total + (unitPrice * quantity);
   }, 0);
};

const calculateUnitPrice = (product: any): number => {
   const productJson = JSON.parse(product.product_json);
   let basePrice = parseFloat(productJson.price || '0');

   const productMarkup = product.product_markup_type === 1
      ? basePrice * (parseFloat(product.product_markup || '0') / 100)
      : parseFloat(product.product_markup || '0');

   let unitPrice = basePrice + productMarkup;

   product.services?.forEach((service: any) => {
      if (service.included_in_total) {
         const servicePrice = parseFloat(service.price || '0');
         const serviceMarkup = service.service_markup_type === 1
            ? servicePrice * (parseFloat(service.service_markup || '0') / 100)
            : parseFloat(service.service_markup || '0');
         unitPrice += servicePrice + serviceMarkup;
      }
   });

   return unitPrice;
};

const calculateTotalCost = (offer: any): number => {
   if (!offer?.products) return 0;

   return offer.products.reduce((total: number, product: any) => {
      const productJson = JSON.parse(product.product_json);
      const basePrice = parseFloat(productJson.price || '0');
      const quantity = parseInt(product.product_qty || '0');

      // Ürün maliyeti
      let cost = basePrice * quantity;

      // Hizmet maliyetleri
      product.services?.forEach((service: any) => {
         if (service.price && service.included_in_total) {
            cost += parseFloat(service.price) * quantity;
         }
      });

      return total + cost;
   }, 0);
};

const calculateTotalProfit = (offer: any): number => {
   if (!offer?.products) return 0;

   const totalRevenue = calculateOrderTotal(offer);
   const totalCost = calculateTotalCost(offer);

   return totalRevenue - totalCost;
};

const calculateProfitMargin = (offer: any): string => {
   if (!offer?.products) return '0';

   const totalRevenue = calculateOrderTotal(offer);
   const totalProfit = calculateTotalProfit(offer);

   if (totalRevenue === 0) return '0';

   return ((totalProfit / totalRevenue) * 100).toFixed(2);
};

const calculateFinancials = (products: any[]) => {
   const totals = products?.reduce((sum, product) => {
      try {
         const productJson = typeof product.product_json === 'string'
            ? JSON.parse(product.product_json)
            : product.product_json;

         const quantity = parseInt(product.product_qty || 0);
         const basePrice = parseFloat(productJson.price || 0);

         // 1. Ürün komisyonu hesaplama
         let productMarkup = 0;
         if (product.product_markup_type === '2') {
            productMarkup = parseFloat(product.product_markup || 0);
         } else {
            productMarkup = basePrice * (parseFloat(product.product_markup || 0) / 100);
         }

         // 2. Hizmet maliyetleri ve komisyonları
         const serviceTotals = (product.services || []).reduce((serviceSum: any, service: any) => {
            if (service.included_in_total === 1) {
               const servicePrice = parseFloat(service.price || 0);
               let serviceMarkup = 0;

               if (service.service_markup_type === '2') {
                  serviceMarkup = parseFloat(service.service_markup || 0);
               } else {
                  serviceMarkup = servicePrice * (parseFloat(service.service_markup || 0) / 100);
               }

               return {
                  price: serviceSum.price + servicePrice,
                  markup: serviceSum.markup + serviceMarkup
               };
            }
            return serviceSum;
         }, { price: 0, markup: 0 });

         // 3. Toplam hesaplamalar
         const totalBasePrice = (basePrice + serviceTotals.price) * quantity; // Komisyonsuz toplam
         const totalMarkup = (productMarkup + serviceTotals.markup) * quantity; // Toplam komisyon

         return {
            revenue: sum.revenue + totalBasePrice,
            profit: sum.profit + totalMarkup,
            quantity: sum.quantity + quantity
         };

      } catch (error) {
         console.error('Finansal hesaplama hatası:', error);
         return sum;
      }
   }, { revenue: 0, profit: 0, quantity: 0 });

   return {
      revenue: totals?.revenue || 0,
      profit: totals?.profit || 0,
      quantity: totals?.quantity || 0,
      profitMargin: totals?.revenue ? ((totals.profit / totals.revenue) * 100).toFixed(2) : '0'
   };
};

interface OrderDetailsDrawerProps {
   open: boolean;
   onClose: () => void;
   order: any;
   onFilter: () => void;
}

const OrderDetailsDrawer = ({ open, onClose, order, onFilter }: OrderDetailsDrawerProps): JSX.Element => {
   const orderStatuses = [
      { status: 'ORDER_RECEIVED', label: 'Sipariş Alındı', icon: '📦', className: 'bg-[#EEF6FF] text-[#1D4ED8] border border-[#93C5FD]', dotColor: 'bg-[#3B82F6]', description: 'Sipariş sisteme kaydedildi', gradient: 'from-[#DBEAFE] via-[#EEF6FF] to-[#DBEAFE]' },
      { status: 'ORDER_PREPARING', label: 'Hazırlanıyor', icon: '🔧', className: 'bg-[#FEF3C7] text-[#B45309] border border-[#FCD34D]', dotColor: 'bg-[#F59E0B]', description: 'Ürünler hazırlanma aşamasında', gradient: 'from-[#FEF3C7] via-[#FDE68A] to-[#FEF3C7]' },
      { status: 'PRODUCTION_STAGE', label: 'Üretimde', icon: '🏭', className: 'bg-[#EDE9FE] text-[#5B21B6] border border-[#A78BFA]', dotColor: 'bg-[#7C3AED]', description: 'Üretim sürecinde', gradient: 'from-[#EDE9FE] via-[#DDD6FE] to-[#EDE9FE]' },
      { status: 'SHIPPING', label: 'Kargoda', icon: '🚚', className: 'bg-[#FFEDD5] text-[#C2410C] border border-[#FB923C]', dotColor: 'bg-[#F97316]', description: 'Teslimat için yola çıktı', gradient: 'from-[#FFEDD5] via-[#FED7AA] to-[#FFEDD5]' },
      { status: 'DELIVERED', label: 'Teslim Edildi', icon: '✅', className: 'bg-[#DCFCE7] text-[#15803D] border border-[#4ADE80]', dotColor: 'bg-[#22C55E]', description: 'Müşteriye teslim edildi', gradient: 'from-[#DCFCE7] via-[#BBF7D0] to-[#DCFCE7]' },
      { status: 'CANCELLED', label: 'İptal Edildi', icon: '❌', className: 'bg-[#FEE2E2] text-[#B91C1C] border border-[#FCA5A5]', dotColor: 'bg-[#EF4444]', description: 'Sipariş iptal edildi', gradient: 'from-[#FEE2E2] via-[#FECACA] to-[#FEE2E2]' }
   ];

   const currentStatusIndex = orderStatuses.findIndex(s => s.status === order?.status);

   const [selectedProduct, setSelectedProduct] = useState<any>(null);
   const [deliveryModal, setDeliveryModal] = useState(false);
   const [deliveryDate, setDeliveryDate] = useState<any>(null);
   const [isDeliveryConfirmed, setIsDeliveryConfirmed] = useState(false);
   const [isUpdating, setIsUpdating] = useState(false);

   const handleSwitchChange = (checked: boolean, product: any) => {
      if (checked) {
         setSelectedProduct(product);
         setDeliveryModal(true);
      }
   };

   const handleDeliveryConfirm = async () => {
      if (!deliveryDate) {
         message.error('Lütfen teslim tarihini seçin');
         return;
      }

      setIsUpdating(true);
      try {
         const response = await axios.post('offers/updateDelivered', {
            offer_id: order?.offer?.id,
            products: [{
               id: selectedProduct.id,
               is_delivered: 'true',
               delivered_date: moment(deliveryDate).format('YYYY-MM-DD')
            }]
         });

         if (response.data.status) {
            message.success('Teslim bilgisi kaydedildi');
            setDeliveryModal(false);
            setSelectedProduct(null);
            setDeliveryDate(null);
            onClose();
            onFilter();
         }
      } catch (error) {
         message.error('Bir hata oluştu');
         console.error(error);
      } finally {
         setIsUpdating(false);
      }
   };

   return (
      <Drawer open={open} onClose={onClose} width="100%">
         <div className="min-h-screen bg-gray-50/50">
            {/* Header ve Status Bar */}
            <div className="bg-white border-b sticky top-0 z-10">
               <div className="container mx-auto px-6">
                  <div className="h-16 flex items-center justify-between">
                     <div className="flex items-center gap-4">
                        <span className="px-3 py-1 bg-primary/10 text-primary rounded-full text-sm font-medium">
                           #{order?.offer?.no}
                        </span>
                        <div className="flex items-center gap-2 text-sm text-gray-500">
                           <IconCalendar size={16} />
                           {moment(order?.offer?.created_at).format('DD MMMM YYYY')}
                        </div>
                     </div>
                     <Button isIconOnly color="danger" variant="light" onClick={onClose}>
                        <IconX />
                     </Button>
                  </div>

                  {/* Status Steps */}
                  <div className="py-6 border-t">
                     <div className="flex justify-between relative">
                        {orderStatuses.map((status, index) => {
                           const isCurrentStatus = order?.offer?.offer_status === status.status;
                           const isPastStatus = index <= currentStatusIndex;

                           return (
                              <div key={status.status} className="flex flex-col items-center relative z-10">
                                 {/* Status Circle */}
                                 <div className={cn(
                                    "w-14 h-14 rounded-full flex items-center justify-center transition-all duration-300",
                                    isCurrentStatus && "ring-4 ring-offset-2",
                                    isPastStatus ? status.className : "bg-gray-50 text-gray-300 border border-gray-200",
                                 )}>
                                    <span className="text-2xl">{status.icon}</span>
                                 </div>

                                 {/* Status Label */}
                                 <div className={cn(
                                    "mt-3 text-center transition-all duration-300",
                                    isCurrentStatus ? "scale-105" : "scale-100"
                                 )}>
                                    <p className={cn(
                                       "font-medium whitespace-nowrap",
                                       isPastStatus ? status.className.split(' ')[1] : "text-gray-400"
                                    )}>
                                       {status.label}
                                    </p>
                                    <p className={cn(
                                       "text-[11px] mt-1",
                                       isCurrentStatus ? status.className.split(' ')[1] : "text-gray-400"
                                    )}>
                                       {status.description}
                                    </p>
                                 </div>

                                 {/* Connection Line */}
                                 {index < orderStatuses.length - 1 && (
                                    <div className="absolute top-7 left-[56px] h-[2px] w-[calc(200%-28px)]">
                                       <div className={cn(
                                          "h-full transition-all duration-500",
                                          isPastStatus ? `bg-gradient-to-r ${status.gradient}` : "bg-gray-100"
                                       )} />
                                    </div>
                                 )}

                                 {/* Current Status Indicator */}
                                 {isCurrentStatus && (
                                    <div className="absolute -top-8 left-1/2 -translate-x-1/2 whitespace-nowrap">
                                       <div className={cn(
                                          "px-3 py-1 rounded-full text-xs font-medium animate-pulse",
                                          status.className
                                       )}>
                                          Mevcut Durum
                                       </div>
                                    </div>
                                 )}
                              </div>
                           );
                        })}
                     </div>
                  </div>
               </div>
            </div>

            {/* Content */}
            <div className="container mx-auto px-6 py-8">
               <div className="grid grid-cols-12 gap-6">
                  {/* Sol Kolon - Sipariş İçeriği */}
                  <div className="col-span-8">
                     <div className="bg-white rounded-xl shadow-sm">
                        <div className="p-6 border-b">
                           <h3 className="font-semibold">Sipariş İçeriği ve Kar Analizi</h3>
                        </div>
                        <div className="divide-y">
                           {order?.offer?.products?.map((product: any, index: number) => {
                              const productJson = JSON.parse(product.product_json);
                              const basePrice = parseFloat(productJson.price || '0');
                              const quantity = product.product_qty;
                              const productMarkup = product.product_markup_type === 1
                                 ? basePrice * (parseFloat(product.product_markup || '0') / 100)
                                 : parseFloat(product.product_markup || '0');
                              const unitPrice = calculateUnitPrice(product);
                              const total = unitPrice * quantity;
                              const profitMargin = ((unitPrice - basePrice) / unitPrice * 100).toFixed(2);

                              return (
                                 <div key={index} className="p-6">
                                    <div className="flex items-start gap-6">
                                       {/* Ürün Görseli */}
                                       <div className="w-20 h-20 bg-gray-100 rounded-lg flex items-center justify-center overflow-hidden">
                                          {productJson.image ? (
                                             <img
                                                src={productJson.image}
                                                alt={productJson.name}
                                                className="w-full h-full object-cover"
                                                onError={(e) => {
                                                   e.currentTarget.onerror = null;
                                                   e.currentTarget.src = 'https://placehold.co/80x80/png';
                                                }}
                                             />
                                          ) : (
                                             <IconPackage className="w-8 h-8 text-gray-400" />
                                          )}
                                       </div>

                                       {/* Ürün Detayları */}
                                       <div className="flex-1">
                                          <div className="flex justify-between items-start mb-4">
                                             <div>
                                                <h4 className="font-medium">{productJson.name}</h4>
                                                <div className="mt-1 flex items-center gap-3 text-sm">
                                                   <span className="text-gray-500">
                                                      Maliyet: {MoneyFormatTR(basePrice)} {product.currency || 'TL'}
                                                   </span>
                                                   <span className="text-gray-500">
                                                      Kar: {MoneyFormatTR(productMarkup)} {product.currency || 'TL'}
                                                   </span>
                                                   <span className={cn(
                                                      "px-2 py-0.5 rounded text-xs font-medium",
                                                      parseFloat(profitMargin) > 20
                                                         ? "bg-green-100 text-green-700"
                                                         : "bg-yellow-100 text-yellow-700"
                                                   )}>
                                                      Kar Marjı: %{profitMargin}
                                                   </span>
                                                </div>
                                             </div>
                                             <div className="text-right">
                                                <div className="font-medium text-primary">
                                                   {MoneyFormatTR(total)} {product.currency || 'TL'}
                                                </div>
                                                <div className="text-sm text-gray-500">
                                                   {quantity} x {MoneyFormatTR(unitPrice)} {product.currency || 'TL'}
                                                </div>
                                             </div>
                                          </div>

                                          {/* Hizmetler */}
                                          {product.services?.length > 0 && (
                                             <div className="mt-3 space-y-2">
                                                {product.services.map((service: any, idx: number) => {
                                                   const serviceBasePrice = parseFloat(service.price || '0');
                                                   const serviceMarkup = service.service_markup_type === 1
                                                      ? serviceBasePrice * (parseFloat(service.service_markup || '0') / 100)
                                                      : parseFloat(service.service_markup || '0');
                                                   const serviceProfit = ((serviceMarkup / serviceBasePrice) * 100).toFixed(2);

                                                   return (
                                                      <div key={idx} className="flex items-center justify-between bg-gray-50 rounded px-3 py-2">
                                                         <div className="flex items-center gap-2">
                                                            <IconTools size={16} className="text-gray-400" />
                                                            <span className="text-sm">{service.name}</span>
                                                            {service.price > 0 && (
                                                               <div className="flex items-center gap-2 ml-3 text-xs text-gray-500">
                                                                  <span>Maliyet: {MoneyFormatTR(serviceBasePrice)} {product.currency || 'TL'}</span>
                                                                  <span>Kar: {MoneyFormatTR(serviceMarkup)} {product.currency || 'TL'}</span>
                                                                  <span className="text-green-600">(%{serviceProfit} kar)</span>
                                                               </div>
                                                            )}
                                                         </div>
                                                         <div className="flex items-center gap-3">
                                                            {service.price > 0 && !service.included_in_total && (
                                                               <span className="text-sm">
                                                                  {MoneyFormatTR(service.price)} {product.currency || 'TL'}
                                                               </span>
                                                            )}
                                                            <span className={cn(
                                                               "text-xs px-2 py-0.5 rounded whitespace-nowrap",
                                                               service.included_in_total
                                                                  ? "bg-green-100 text-green-700"
                                                                  : "bg-red-50 text-red-700"
                                                            )}>
                                                               {service.included_in_total ? "Birim Fiyata Dahil" : "Birim Fiyata Dahil Değil"}
                                                            </span>
                                                         </div>
                                                      </div>
                                                   );
                                                })}
                                             </div>
                                          )}

                                          {/* Teslim Durumu - Buraya ekliyoruz */}
                                          <div className="mt-4 pt-4 border-t border-gray-100">
                                             <div className="flex items-center justify-between">
                                                <span className={cn(
                                                   "text-sm",
                                                   product.is_delivered ? "text-green-600" : "text-gray-500"
                                                )}>
                                                   {product.is_delivered ? (
                                                      <div className="flex items-center gap-2">
                                                         <span>Teslim Edildi</span>
                                                         <span className="text-gray-400">
                                                            ({moment(product.delivered_date).format('DD.MM.YYYY')})
                                                         </span>
                                                      </div>
                                                   ) : "Teslim Edilmedi"}
                                                </span>

                                                <Button
                                                   size="sm"
                                                   color="primary"
                                                   variant="flat"
                                                   startContent={product.is_delivered ? <IconEdit size={16} /> : <IconCheck size={16} />}
                                                   onClick={() => {
                                                      setSelectedProduct(product);
                                                      setDeliveryModal(true);
                                                   }}
                                                >
                                                   {product.is_delivered ? 'Teslim Tarihini Güncelle' : 'Teslim Edildi Olarak İşaretle'}
                                                </Button>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              );
                           })}
                        </div>
                     </div>
                  </div>

                  {/* Sağ Kolon */}
                  <div className="col-span-4 space-y-6">
                     {/* ... mevcut müşteri kartı ... */}

                     {/* Kar Analizi Kartı */}
                     <div className="bg-white rounded-xl shadow-sm p-6">
                        <h3 className="font-semibold mb-4">Kar Analizi</h3>
                        {order?.offer?.products && (
                           <div className="space-y-3">
                              {(() => {
                                 const financials = calculateFinancials(order.offer.products);
                                 return (
                                    <>
                                       <div className="flex justify-between text-sm">
                                          <span className="text-gray-500">Toplam Tutar</span>
                                          <span>{MoneyFormatTR(financials.revenue)} TL</span>
                                       </div>
                                       <div className="flex justify-between text-sm">
                                          <span className="text-gray-500">Toplam Kar</span>
                                          <span className="text-green-600">{MoneyFormatTR(financials.profit)} TL</span>
                                       </div>
                                       <div className="flex justify-between text-sm">
                                          <span className="text-gray-500">Kar Marjı</span>
                                          <span className="text-green-600">%{financials.profitMargin}</span>
                                       </div>
                                       <div className="flex justify-between text-sm">
                                          <span className="text-gray-500">Toplam Ürün Adedi</span>
                                          <span>{financials.quantity}</span>
                                       </div>
                                    </>
                                 );
                              })()}
                           </div>
                        )}
                     </div>

                     {/* ... mevcut toplam kartı ... */}
                  </div>
               </div>
            </div>
         </div>

         {/* Teslim Tarihi Modalı */}
         <Modal
            open={deliveryModal}
            onCancel={() => {
               setDeliveryModal(false);
               setDeliveryDate(null);
            }}
            title="Teslim Tarihi Seçin"
            okText="Kaydet"
            cancelText="İptal"
            confirmLoading={isUpdating}
            onOk={handleDeliveryConfirm}
         >
            <div className="space-y-4">
               <div>
                  <label className="text-sm text-gray-600 mb-1.5 block">
                     Teslim Tarihi
                  </label>
                  <DatePicker
                     className="w-full"
                     format="DD.MM.YYYY"
                     value={deliveryDate}
                     onChange={(date) => setDeliveryDate(date)}
                  />
               </div>
            </div>
         </Modal>
      </Drawer>
   );
};

export default function OrdersPage({ user_id, customer_id }: { user_id?: number, customer_id?: number }) {
   const { t } = useTranslation()
   const pageHeader = {
      title: 'Siparişler',
      breadcrumb: [
         {
            href: routes.dashboard,
            name: t('aside.dashboard')
         },
         {
            href: routes.dashboard,
            name: 'Siparişler'
         }
      ]
   }

   const app = useSelector((state: RootState) => state.app)
   const [page, setPage] = useState<number>(1)
   const [search, setSearch] = useState<string>('')
   const navigate = useNavigate()
   const dispatch = useDispatch()
   const [offerManagementQuery, { data, loading }] = useFetch<any>()
   const [getOfferLastIdQuery, { data: lastIdData, loading: lastIdLoading }] = useFetch<any>()
   const [getOffer, { data: offerData, loading: offerLoading }] = useFetch<any>()
   const [getOffersFromOfferGroupQuery, { data: offersFromOfferGroupData, loading: offersFromOfferGroupLoading }] =
      useFetch<any>()
   const [convertOfferToOrderMutation, { data: convertOfferToOrderData, loading: convertOfferToOrderLoading }] =
      useFetch<any>()
   const [updateOrderStatusMutation, { data: updateOrderStatusData, loading: updateStatusLoading, error: updateStatusError }] = useFetch<any>()
   const [usersQuery, { data: usersData, loading: usersLoading }] = useFetch<any>()

   const [selectedOrder, setSelectedOrder] = useState<any>(null)
   const [statusUpdateModal, setStatusUpdateModal] = useState(false)
   const [offerListModal, setOfferListModal] = useState<boolean>(false);
   const [previewItem, setPreviewItem] = useState<any>(null);
   const [previewDrawer, setPreviewDrawer] = useState<boolean>(false);
   const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);

   const [filters, setFilters] = useState({
      customerName: '',
      companyName: '',
      orderStatus: undefined,
      dateRange: [null, null] as [EventValue<Dayjs>, EventValue<Dayjs>],
      personnel: []
   });

   const handlePreview = (record: any) => {
      setPreviewItem(record)
      setPreviewDrawer(true)
   }

   const onFilter = () => {
      const queryObject: any = {}
      if (filters.customerName) queryObject.customer_name = filters.customerName
      if (filters.companyName) queryObject.company_name = filters.companyName
      if (filters.orderStatus) queryObject.order_status = filters.orderStatus
      if (filters.dateRange?.[0]) queryObject.start_date = filters.dateRange[0].format('YYYY-MM-DD')
      if (filters.dateRange?.[1]) queryObject.end_date = filters.dateRange[1].format('YYYY-MM-DD')
      if (filters.personnel?.length) queryObject.user_ids = filters.personnel
      if (customer_id) queryObject.customer_id = customer_id

      const queryString = Object.entries(queryObject)
         .map(([key, value]) => {
            if (key === 'user_ids' && Array.isArray(value)) {
               return `${key}=${value.join(',')}`
            }
            return `${key}=${value}`
         })
         .join('&');


      if (user_id) {
         queryObject.user_ids = [user_id]
      }


      //@ts-ignore
      offerManagementQuery(OFFER_MANAGEMENT_LIST, {
         endPoint: OFFER_MANAGEMENT_LIST_URL(page, search) + '&is_ordered=1&' + queryString
      })
   }

   useEffect(() => {
      onFilter()
   }, [page])

   useEffect(() => {
      if (data) {
         dispatch(setTotalOrders(data?.data?.total ?? 0))
         dispatch(setTotalProfit(data?.total_profit ?? 0))
         dispatch(setTotalRevenue(data?.total_revenue ?? 0))
         dispatch(setConversionRate(data?.conversion_rate ?? 0))
      }
   }, [data])

   useEffect(() => {
      if (updateOrderStatusData) {
         message.success('Sipariş durumu güncellendi')
         setStatusUpdateModal(false)
         onFilter()
      }
   }, [updateOrderStatusData])

   useEffect(() => {
      if (updateStatusLoading) {
         setIsUpdatingStatus(true)
      }
   }, [updateStatusLoading])

   useEffect(() => {
      if (updateStatusError) {
         setIsUpdatingStatus(false)
      }
   }, [updateStatusError])

   useEffect(() => {
      //@ts-ignore
      usersQuery(USER_SHOW, {
         endPoint: '/users-all'
      })
   }, [])

   const columns: TableColumnProps<any>[] = [
      {
         title: 'Sipariş No',
         key: 'no',
         render: (text: any, record: any) => (
            <div className="flex items-center gap-2 py-3">
               <div className="w-10 h-10 rounded-full bg-primary/10 flex items-center justify-center">
                  <span className="text-primary font-medium">#{record.offer_group_no}</span>
               </div>
               <div className="flex flex-col">
                  <span className="text-xs text-gray-500">Sipariş ID</span>
                  <span className="font-medium">{record.offer_group_id}</span>
               </div>
            </div>
         )
      },
      {
         title: 'Müşteri',
         key: 'customer',
         render: (text: any, record: any) => (
            <div
               className="flex items-center gap-3 cursor-pointer hover:bg-blue-50 p-3 rounded-lg transition-all group border border-transparent hover:border-blue-200 hover:shadow-sm"
               onClick={() => navigate(`/dashboard/customer-management/profile/${record.offer.contact.customer_id}`)}
            >
               <div className="w-10 h-10 rounded-full bg-gray-100 flex items-center justify-center group-hover:bg-blue-100 group-hover:ring-2 group-hover:ring-blue-200 transition-all">
                  <span className="text-gray-600 font-medium group-hover:text-blue-600">
                     {record?.offer?.contact?.customer?.company_name?.charAt(0)}
                  </span>
               </div>
               <div className="flex flex-col">
                  <span className="font-medium group-hover:text-blue-600 group-hover:underline">
                     {record?.offer?.contact?.customer?.company_name}
                  </span>
                  <span className="text-sm text-gray-500 group-hover:text-blue-500">
                     {record?.offer?.contact?.name}
                  </span>
               </div>
            </div>
         )
      },
      {
         title: 'Durum',
         key: 'status',
         render: (text: any, record: any) => {
            return (
               <div className={cn(
                  "group relative flex items-center gap-3 px-4 py-2.5 rounded-xl",
                  "transition-all duration-300 cursor-pointer",
                  "hover:shadow-lg hover:scale-[1.02]",
                  "before:absolute before:inset-0 before:rounded-xl before:opacity-0",
                  "before:transition-opacity hover:before:opacity-100",
                  statusConfig[record.offer.offer_status as StatusType]?.className
               )}>
                  {/* Animasyonlu arka plan */}
                  <div className={cn(
                     "absolute inset-0 rounded-xl opacity-20",
                     "bg-gradient-to-r animate-gradient",
                     statusConfig[record.offer.offer_status as StatusType]?.gradient
                  )} />

                  {/* İkon ve pulsing dot */}
                  <div className="relative">
                     <span className="text-2xl">{statusConfig[record.offer.offer_status as StatusType]?.icon}</span>
                     <span className={cn(
                        "absolute -top-1 -right-1 w-2.5 h-2.5 rounded-full animate-pulse",
                        statusConfig[record.offer.offer_status as StatusType]?.dotColor
                     )} />
                  </div>

                  {/* İçerik */}
                  <div className="flex flex-col z-10">
                     <span className="font-medium">
                        {statusConfig[record.offer.offer_status as StatusType]?.label}
                     </span>
                     <span className="text-xs opacity-60 group-hover:opacity-100 transition-opacity">
                        {statusConfig[record.offer.offer_status as StatusType]?.description}
                     </span>
                  </div>

                  {/* Zaman tooltip */}
                  <div className={cn(
                     "absolute -top-10 left-1/2 -translate-x-1/2",
                     "bg-white/90 backdrop-blur-sm px-3 py-1.5 rounded-lg shadow-lg",
                     "text-xs whitespace-nowrap opacity-0 group-hover:opacity-100",
                     "transition-all duration-300 transform -translate-y-2 group-hover:translate-y-0",
                     statusConfig[record.offer.offer_status as StatusType]?.className
                  )}>
                     <span className="font-medium">Son güncelleme:</span> {moment(record.offer.updated_at).format('DD MMM YYYY HH:mm')}
                  </div>
               </div>
            );
         }
      },
      {
         title: 'Personel',
         key: 'personnel',
         render: (text: any, record: any) => (
            <Chip
               onClick={() => navigate(`/dashboard/users/${record.offer.user.id}/view`)}
               color="primary"
               startContent={<IconUser />}
               variant="flat"
            >
               {record.offer.user.name}
            </Chip>
         )
      },
      {
         title: 'Sipariş Tarihi',
         key: 'date',
         render: (text: any, record: any) => (
            <div className="flex flex-col">
               <span className="font-medium">
                  {moment(record.offer_group.ordered_at).format('DD MMM')}
               </span>
               <span className="text-sm text-gray-500">
                  {moment(record.offer_group.ordered_at).format('YYYY')}
               </span>
            </div>
         )
      },
      {
         title: 'Toplam',
         key: 'total',
         render: (text: any, record: any) => (
            <div className="w-[280px]">
               <FinancialSummary offer={record.offer} />
            </div>
         )
      },
      {
         title: 'İşlemler',
         key: 'actions',
         render: (text: any, record: any) => (
            <div className="flex items-center justify-end gap-2">
               <Button
                  size="sm"
                  variant="flat"
                  color="primary"
                  onClick={() => {
                     setSelectedOrder(record)
                     setPreviewDrawer(true)
                  }}
                  className="rounded-full"
               >
                  Detay
               </Button>
               <Button
                  size="sm"
                  variant="light"
                  color="secondary"
                  onClick={() => handleGetOffersFromOfferGroup(record.offer_group_id)}
                  className="rounded-full"
               >
                  Düzenle
               </Button>

               <Button
                  size="sm"
                  variant="light"
                  color="secondary"
                  onClick={() => {
                     setSelectedOrder(record)
                     setStatusUpdateModal(true)

                  }}
                  className="rounded-full"
               >
                  Durum Güncelle
               </Button>
            </div>
         )
      },

   ]

   useEffect(() => {
      //@ts-ignore
      offerManagementQuery(OFFER_MANAGEMENT_LIST, {
         endPoint: OFFER_MANAGEMENT_LIST_URL(page, '') + '&offerType=1&is_ordered=1'
      })
   }, [page])

   const handleGetlastId = () => {
      //@ts-ignore
      getOfferLastIdQuery(OFFER_MANAGEMENT_LIST, {
         endPoint: '/offers/getLastOfferId'
      })
   }

   const handleGetOffer = (id: number) => {
      //@ts-ignore
      getOffer(OFFER_MANAGEMENT_SHOW, {
         endPoint: OFFER_MANAGEMENT_SHOW_URL(id)
      })
   }

   const handleGetOffersFromOfferGroup = (id: number) => {
      //@ts-ignore
      getOffersFromOfferGroupQuery(OFFER_MANAGEMENT_LIST, {
         endPoint: '/offers/' + id + '/edit'
      })
   }

   useEffect(() => {
      if (lastIdData) {
         dispatch(setOfferNumber(lastIdData.data))
         navigate(routes.offerManagement.add)
      }
   }, [lastIdData])

   useEffect(() => {
      dispatch(
         setLoading(
            (offerLoading as boolean) ||
            (offersFromOfferGroupLoading as boolean) ||
            (convertOfferToOrderLoading as boolean)
         )
      )
   }, [offerLoading, offersFromOfferGroupLoading, convertOfferToOrderLoading])

   useEffect(() => {
      if (offerData) {
         const { data } = offerData
         dispatch(setOfferNumber(data?.no))
         dispatch(setContactId(data?.customer_contact_id))
         dispatch(setTermTime(data?.term_time))

         const products = data?.products.map((product: any, index: number) => {
            const description = JSON.parse(product.product_json)
            return {
               id: product.product_id,
               name: description.name,
               price: description.price,
               stock: product.product_qty,
               product_qty: description.stock,
               delivery_date: product.delivery_date,
               commission: product.product_markup,
               commission_type: product.product_markup_type,
               description: product.product_description,
               image: product.image,
               currency: product.currency,
               services: product.services.map((service: any) => {
                  return {
                     ...service.service,
                     name: service.name,
                     price: service.price,
                     commission: service.service_markup,
                     commission_type: service.service_markup_type,
                     description: service.service_description,
                     included_in_total: service.included_in_total
                  }
               })
            }
         })
         // console.log(products)
         dispatch(setProduct(products))
         navigate(routes.offerManagement.view(data?.offer_group_id))
      }
   }, [offerData])

   useEffect(() => {
      if (offersFromOfferGroupData) {
         setOfferListModal(true)
      }
   }, [offersFromOfferGroupData])

   useEffect(() => {
      if (convertOfferToOrderData) {
         message.success('Siparişe dönüştürüldü!')
         setOfferListModal(false)
         onFilter()
      }
   }, [convertOfferToOrderData])

   const handleUpdateOrderStatus = (status: string, offerId: number) => {
      setIsUpdatingStatus(true);
      //@ts-ignore
      updateOrderStatusMutation(OFFER_MANAGEMENT_STORE, {
         endPoint: `/offers/handleUpdateOrderStatus`,
         body: {
            status,
            offer_id: offerId
         }
      });
   }

   const handleFilterChange = (key: string, value: any) => {
      setFilters(prev => ({
         ...prev,
         [key]: value
      }));
   };

   const clearFilters = () => {
      setFilters({
         customerName: '',
         companyName: '',
         orderStatus: undefined,
         dateRange: [null, null] as [EventValue<Dayjs>, EventValue<Dayjs>],
         personnel: []
      });
   };

   const FinancialSummary = ({ offer }: { offer: any }) => {
      const total = parseFloat(offer?.total || '0');
      const profit = parseFloat(offer?.profit || '0');

      // Kar oranını product_markup'tan hesapla
      const profitMargin = offer?.products?.reduce((total: number, product: any) => {
         const markup = parseFloat(product.product_markup || '0');
         // Eğer markup type 2 ise sabit tutardır, yüzde hesaplanmalı
         if (product.product_markup_type === '2') {
            const productJson = JSON.parse(product.product_json);
            const basePrice = parseFloat(productJson.price || '0');
            return total + ((markup / basePrice) * 100);
         }
         // Markup type 1 ise direkt yüzde değeridir
         return total + markup;
      }, 0) || 0;

      return (
         <div className="space-y-3">
            <div className="flex items-center justify-between">
               <span className="text-sm text-gray-500">Toplam Tutar:</span>
               <Popover placement="left">
                  <PopoverTrigger>
                     <span className="font-semibold text-primary cursor-pointer hover:opacity-70 transition-opacity flex items-center gap-1">
                        {MoneyFormatTR(total)} {offer?.currency || '₺'}
                        <IconInfoCircle size={16} className="text-gray-400" />
                     </span>
                  </PopoverTrigger>
                  <PopoverContent className="w-96">
                     <div className="p-4">
                        <div className="flex items-center gap-2 mb-4">
                           <div className="p-2 rounded-lg bg-primary/10">
                              <IconCalculator size={20} className="text-primary" />
                           </div>
                           <div className="font-medium">Fiyat Analizi</div>
                        </div>

                        <div className="space-y-4">
                           {offer?.products?.map((product: any, index: number) => {
                              const productJson = JSON.parse(product.product_json);
                              const basePrice = parseFloat(productJson.price || '0');
                              const quantity = parseInt(product.product_qty || '0');
                              const totalServices = product.services?.reduce((sum: number, service: any) =>
                                 sum + (service.included_in_total ? parseFloat(service.price || '0') : 0), 0
                              ) || 0;

                              return (
                                 <div key={index} className="p-3 bg-gray-50 rounded-lg">
                                    <div className="flex items-start justify-between mb-2">
                                       <div className="flex items-center gap-2">
                                          <div className="w-8 h-8 rounded bg-white flex items-center justify-center">
                                             <IconPackage size={16} className="text-gray-500" />
                                          </div>
                                          <div className="font-medium">{productJson.name}</div>
                                       </div>
                                       <Chip color="primary" variant="flat" size="sm">
                                          {quantity} Adet
                                       </Chip>
                                    </div>

                                    <div className="space-y-2 text-sm">
                                       <div className="flex justify-between p-2 bg-white rounded">
                                          <span>Birim Fiyat</span>
                                          <span className="font-medium">{MoneyFormatTR(basePrice)} {offer?.currency}</span>
                                       </div>

                                       {product.services?.length > 0 && (
                                          <div className="p-2 bg-white rounded">
                                             <div className="flex justify-between mb-2">
                                                <span>Hizmetler</span>
                                                <span className="font-medium">{MoneyFormatTR(totalServices)} {offer?.currency}</span>
                                             </div>
                                             <div className="space-y-1 ml-3 text-xs">
                                                {product.services.map((service: any, sIndex: number) => (
                                                   <div key={sIndex} className="flex justify-between items-center">
                                                      <div className="flex items-center gap-1">
                                                         <span>{service.name}</span>
                                                         {service.included_in_total ? (
                                                            <Chip size="sm" variant="flat" color="success">Dahil</Chip>
                                                         ) : (
                                                            <Chip size="sm" variant="flat" color="warning">Hariç</Chip>
                                                         )}
                                                      </div>
                                                      <span>{MoneyFormatTR(service.price)} {offer?.currency}</span>
                                                   </div>
                                                ))}
                                             </div>
                                          </div>
                                       )}

                                       <div className="flex justify-between p-2 bg-primary/5 rounded font-medium">
                                          <span>Ürün Toplamı</span>
                                          <span>{MoneyFormatTR((basePrice + totalServices) * quantity)} {offer?.currency}</span>
                                       </div>
                                    </div>
                                 </div>
                              );
                           })}

                           <div className="p-3 bg-primary/5 rounded-lg">
                              <div className="grid grid-cols-3 gap-4">
                                 <div>
                                    <div className="text-sm text-gray-500">Toplam Tutar</div>
                                    <div className="font-medium text-primary">
                                       {MoneyFormatTR(total)} {offer?.currency}
                                    </div>
                                 </div>
                                 <div>
                                    <div className="text-sm text-gray-500">Toplam Ürün</div>
                                    <div className="font-medium">
                                       {offer?.products?.length || 0} Kalem
                                    </div>
                                 </div>
                                 <div>
                                    <div className="text-sm text-gray-500">Toplam Adet</div>
                                    <div className="font-medium">
                                       {offer?.products?.reduce((sum: number, p: any) => sum + parseInt(p.product_qty || '0'), 0)} Adet
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </PopoverContent>
               </Popover>
            </div>

            <div className="flex items-center justify-between">
               <span className="text-sm text-gray-500">Toplam Kar:</span>
               <Popover placement="left">
                  <PopoverTrigger>
                     <span className="font-semibold text-green-600 cursor-pointer hover:opacity-70 transition-opacity flex items-center gap-1">
                        {MoneyFormatTR(profit)} {offer?.currency || '₺'}
                        <IconInfoCircle size={16} className="text-gray-400" />
                     </span>
                  </PopoverTrigger>
                  <PopoverContent className="w-96">
                     <div className="p-4">
                        <div className="flex items-center gap-2 mb-4">
                           <div className="p-2 rounded-lg bg-green-50">
                              <IconChartBar size={20} className="text-green-600" />
                           </div>
                           <div className="font-medium">Kar Analizi</div>
                        </div>

                        <div className="space-y-4">
                           {offer?.products?.map((product: any, index: number) => {
                              const productJson = JSON.parse(product.product_json);
                              const basePrice = parseFloat(productJson.price || '0');
                              const quantity = parseInt(product.product_qty || '0');
                              const markup = parseFloat(product.product_markup || '0');

                              // Kar tutarı hesaplama
                              const markupAmount = product.product_markup_type === '2'
                                 ? markup * quantity  // Sabit tutar
                                 : basePrice * (markup / 100) * quantity;  // Yüzde bazlı

                              // Kar oranı hesaplama
                              const markupPercentage = product.product_markup_type === '2'
                                 ? ((markup / basePrice) * 100).toFixed(2)  // Sabit tutarın yüzdelik karşılığı
                                 : markup.toFixed(2);  // Direkt yüzde değeri

                              return (
                                 <div key={index} className="p-3 bg-gray-50 rounded-lg">
                                    <div className="flex items-center justify-between mb-2">
                                       <span className="font-medium">{productJson.name}</span>
                                       <Chip
                                          color={markupAmount > 0 ? "success" : "danger"}
                                          variant="flat"
                                       >
                                          {MoneyFormatTR(markupAmount)} {offer?.currency}
                                       </Chip>
                                    </div>

                                    <div className="grid grid-cols-2 gap-2 text-sm">
                                       <div className="p-2 bg-white rounded">
                                          <div className="text-gray-500">Kar Tipi</div>
                                          <div className="font-medium">
                                             {product.product_markup_type === '2' ? 'Sabit Tutar' : 'Yüzde Bazlı'}
                                          </div>
                                       </div>
                                       <div className="p-2 bg-white rounded">
                                          <div className="text-gray-500">Kar Oranı</div>
                                          <div className="font-medium">%{markupPercentage}</div>
                                       </div>
                                    </div>
                                 </div>
                              );
                           })}

                           <div className="p-3 bg-green-50 rounded-lg">
                              <div className="grid grid-cols-3 gap-4">
                                 <div>
                                    <div className="text-sm text-gray-500">Toplam Kar</div>
                                    <div className="font-medium text-green-600">
                                       {MoneyFormatTR(profit)} {offer?.currency}
                                    </div>
                                 </div>
                                 <div>
                                    <div className="text-sm text-gray-500">Ortalama Kar Oranı</div>
                                    <div className="font-medium text-green-600">
                                       %{(profitMargin / (offer?.products?.length || 1)).toFixed(2)}
                                    </div>
                                 </div>
                                 <div>
                                    <div className="text-sm text-gray-500">Toplam Satış</div>
                                    <div className="font-medium">{MoneyFormatTR(total)} {offer?.currency}</div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </PopoverContent>
               </Popover>
            </div>

            <div className="flex items-center justify-between">
               <span className="text-sm text-gray-500">Ortalama Kar Oranı:</span>
               <span className={`font-semibold ${profit > 0 ? 'text-green-600' : 'text-red-600'}`}>
                  %{(profitMargin / (offer?.products?.length || 1)).toFixed(2)}
               </span>
            </div>
         </div>
      );
   };

   const ProductDetails = ({ product }: { product: any }) => {
      // 1. Ürün birim fiyatı
      const productJson = JSON.parse(product.product_json);
      let basePrice = parseFloat(productJson.price || '0');

      // 2. Ürün kar hesabı
      const productMarkup = product.product_markup_type === 1
         ? basePrice * (parseFloat(product.product_markup || '0') / 100)
         : parseFloat(product.product_markup || '0');

      let unitPrice = basePrice + productMarkup;

      // 3. Dahil olan hizmetleri ve karlarını ekle
      product.services?.forEach((service: any) => {
         if (service.included_in_total) {
            // Hizmet fiyatı
            const servicePrice = parseFloat(service.price || '0');

            // Hizmet kar hesabı
            const serviceMarkup = service.service_markup_type === 1
               ? servicePrice * (parseFloat(service.service_markup || '0') / 100)
               : parseFloat(service.service_markup || '0');

            // Sadece included_in_total true ise birim fiyata ekle
            unitPrice += servicePrice + serviceMarkup;
         }
      });

      const currency = product.currency || 'TRY';
      const currencySymbol = currency === 'TRY' ? '₺' : currency === 'USD' ? '$' : '€';
      const quantity = product.stock || product.product_qty;

      return (
         <div>
            <div className="ozellik">
               Miktar: {quantity} Adet
            </div>
            <div className="ozellik">
               Birim Fiyat: {MoneyFormatTR(unitPrice)} {currencySymbol} + KDV
            </div>

            {product.services?.length > 0 && (
               <div className="urun-text mt-5">
                  <h5 className="baslik2">Hizmetler</h5>
                  {product.services.map((service: any, index: number) => (
                     <div key={index} className="mb-4 p-3 bg-gray-50 rounded-lg">
                        <div className="flex justify-between items-center">
                           <strong className="flex-1 mr-5">{service.name}</strong>
                           {service.price > 0 && (
                              <div className="flex items-center gap-2">
                                 {!service.included_in_total && (
                                    <span className="whitespace-nowrap">
                                       {MoneyFormatTR(service.price)} {currencySymbol} + KDV
                                    </span>
                                 )}
                                 <span className={cn(
                                    "px-2 py-1 rounded text-xs font-medium",
                                    service.included_in_total
                                       ? "bg-green-500 text-white"
                                       : "bg-red-500 text-white"
                                 )}>
                                    {service.included_in_total
                                       ? "Birim Fiyata Dahildir"
                                       : "Birim Fiyata Dahil Değildir"
                                    }
                                 </span>
                              </div>
                           )}
                        </div>
                        {service.service_description && (
                           <div className="mt-2 text-gray-600">
                              {service.service_description}
                           </div>
                        )}
                     </div>
                  ))}
               </div>
            )}
         </div>
      );
   };

   const [deliveryModal, setDeliveryModal] = useState(false);
   const [selectedProduct, setSelectedProduct] = useState<any>(null);
   const [deliveryDate, setDeliveryDate] = useState<any>(null);
   const [isDeliveryConfirmed, setIsDeliveryConfirmed] = useState(false);
   const [isUpdating, setIsUpdating] = useState(false);

   const handleDeliveryConfirm = async () => {
      if (!deliveryDate) {
         message.error('Lütfen teslim tarihini seçin');
         return;
      }

      setIsUpdating(true);
      try {
         const response = await axios.post('offers/updateDelivered', {
            offer_id: selectedOrder?.offer?.id,
            products: [{
               id: selectedProduct.id,
               is_delivered: 'true',
               delivered_date: moment(deliveryDate).format('YYYY-MM-DD')
            }]
         });

         if (response.data.status) {
            message.success('Teslim bilgisi kaydedildi');
            setDeliveryModal(false);
            setSelectedProduct(null);
            setDeliveryDate(null);

            onFilter();
         }
      } catch (error) {
         message.error('Bir hata oluştu');
         console.error(error);
      } finally {
         setIsUpdating(false);
      }
   };

   return (
      <>

         <Drawer
            width={window.innerWidth / 1.5}
            open={statusUpdateModal}
            onClose={() => setStatusUpdateModal(false)}
         >
            {selectedOrder && <OrderStatusModalButton
               offer={selectedOrder?.offer}
               offer_id={selectedOrder?.offer?.id} />}
         </Drawer>
         <OrderDetailsDrawer
            open={previewDrawer}
            onClose={() => setPreviewDrawer(false)}
            order={selectedOrder}
            onFilter={onFilter}
         />
         <Modal
            footer={null}
            title={
               <div className="flex items-center gap-3">
                  <IconPdf />
                  Teklifleri Görüntüle
               </div>
            }
            width={window.innerWidth / 1.5}
            onCancel={() => setOfferListModal(false)}
            open={offerListModal}
         >
            {offersFromOfferGroupData && (
               <div className="p-5">
                  {offersFromOfferGroupData?.data?.offers?.map((item: any, index: number) => (
                     <div
                        key={item.id}
                        className="pb-5 flex gap-2 items-center justify-between w-full cursor-pointer border-b border-gray-100"
                     >
                        <div className="flex flex-col space-y-2">
                           <span className="font-bold">
                              {offersFromOfferGroupData?.data?.no + '/' + (offersFromOfferGroupData?.data?.offers.length - index)}
                           </span>
                           <span>Revize Tarihi : {moment(item.updated_at).format('LL')}</span>
                           <span>
                              {item.contact.name} ({item.contact.customer.company_name})
                           </span>
                        </div>
                        <div className="gap-2 flex">
                           <Button
                              onClick={() => handlePreview(item)}
                              color="primary"
                              startContent={<IconPdf />}
                              variant="shadow"
                           >
                              Önizle
                           </Button>
                           <Button
                              onClick={() => handleGetOffer(item.id)}
                              color="secondary"
                              startContent={<IconEdit />}
                              variant="shadow"
                           >
                              Düzenle
                           </Button>
                           {offersFromOfferGroupData?.data?.is_ordered === 0 && (
                              <Button
                                 onClick={() => {
                                    Modal.confirm({
                                       title: 'Siparişe Dönüştür #' + offersFromOfferGroupData?.data?.no + '/' + item.id,
                                       content: 'Bu Teklif Siparişe dönüştürülüp stoktan düşecektir. Devam etmek istiyor musunuz?',
                                       okText: 'Onayla ve dönüştür',
                                       cancelText: 'İptal',
                                       onOk: () => {
                                          //@ts-ignore
                                          convertOfferToOrderMutation(OFFER_MANAGEMENT_STORE, {
                                             endPoint: '/offers/convert-order',
                                             body: {
                                                offer_id: item.id
                                             }
                                          });
                                       }
                                    });
                                 }}
                                 color="success"
                                 startContent={<IconFileInvoice />}
                                 variant="bordered"
                              >
                                 Siparişe Dönüştür
                              </Button>
                           )}
                        </div>
                     </div>
                  ))}
               </div>
            )}
         </Modal>
         {!user_id && <PageHeader title={pageHeader.title} breadcrumb={pageHeader.breadcrumb}></PageHeader>}

         <div className="@container">
            <Card title="Filter" className="mb-5">
               <CardHeader>
                  <h3 className="text-lg font-semibold">{t('common.filterTable')}</h3>
               </CardHeader>
               <CardBody>
                  <div className="mb-5 bg-white p-4 rounded-lg shadow-sm">
                     <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
                        {/* Müşteri Adı Filtresi */}
                        <div>
                           <label className="text-sm text-gray-600 mb-1 block">Müşteri Adı</label>
                           <Input
                              placeholder="Müşteri adı ara..."
                              prefix={<SearchOutlined className="text-gray-400" />}
                              className="hover:border-primary focus:border-primary"
                              value={filters.customerName}
                              onChange={(e) => handleFilterChange('customerName', e.target.value)}
                           />
                        </div>

                        {/* Firma Adı Filtresi */}
                        <div>
                           <label className="text-sm text-gray-600 mb-1 block">Firma Adı</label>
                           <Input
                              placeholder="Firma adı ara..."
                              prefix={<SearchOutlined className="text-gray-400" />}
                              className="hover:border-primary focus:border-primary"
                              value={filters.companyName}
                              onChange={(e) => handleFilterChange('companyName', e.target.value)}
                           />
                        </div>

                        {/* Sipariş Durumu Filtresi */}
                        <div>
                           <label className="text-sm text-gray-600 mb-1 block">Sipariş Durumu</label>
                           <Select
                              className="w-full"
                              placeholder="Durum seçin"
                              options={orderStatus.map((item) => ({
                                 value: item.key,
                                 label: (
                                    <div className="flex items-center gap-2">
                                       <span className={`inline-block w-2 h-2 rounded-full bg-${orderStatusColor[item.key]}-500`} />
                                       <span>{item.label}</span>
                                    </div>
                                 )
                              }))}

                              value={filters.orderStatus}
                              onChange={(value) => handleFilterChange('orderStatus', value)}
                           />
                        </div>

                        {/* Tarih Aralığı Filtresi */}
                        <div>
                           <label className="text-sm text-gray-600 mb-1 block">Sipariş Tarihi</label>
                           <DatePicker.RangePicker
                              className="w-full"
                              locale={tr_TR}
                              format="DD/MM/YYYY"
                              value={filters.dateRange}
                              onChange={(value) => handleFilterChange('dateRange', value)}
                           />
                        </div>

                        {/* Personel Filtresi */}
                        <div>
                           <label className="text-sm text-gray-600 mb-1 block">Personel</label>
                           <Select
                              className="w-full"
                              placeholder="Personel seçin"
                              mode="multiple"
                              allowClear
                              options={usersData?.result?.map((user: any) => ({
                                 value: user.id,
                                 label: user.name
                              }))}
                              filterOption={(input, option) =>
                                 (typeof option?.label === 'string') ?
                                    option.label.toLowerCase().includes(input.toLowerCase()) :
                                    false
                              }
                              value={filters.personnel}
                              onChange={(value) => handleFilterChange('personnel', value)}
                           />
                        </div>



                        {/* Filtreleri Temizle Butonu */}
                        <div className="flex items-end gap-2">
                           <button
                              className="bg-primary text-white px-4 py-2 rounded-md hover:bg-primary/90 transition-colors duration-200"
                              onClick={onFilter}
                           >
                              Filtrele
                           </button>
                           <button
                              className="bg-gray-100 text-gray-600 px-4 py-2 rounded-md hover:bg-gray-200 transition-colors duration-200"
                              onClick={clearFilters}
                           >
                              Filtreleri Temizle
                           </button>
                        </div>
                     </div>
                  </div>

                  {/* Mevcut grid içeriği */}
                  <div className="grid grid-cols-4 gap-2 items-center">
                     {/* ... existing content ... */}
                  </div>
               </CardBody>
            </Card>
            <Card title={'Siparişler'}>
               <TableComponents
                  columns={columns}
                  data={data?.data?.data || []}
                  pagination={{
                     total: data?.data?.total,
                     current: page,
                     pageSize: 10,
                     onChange: (page: any) => {
                        setPage(page)
                     }
                  }}
                  loading={loading}
               />
            </Card>
         </div>



         <AnimatePresence>
            {isUpdatingStatus && (
               <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="fixed inset-0 z-50 flex items-center justify-center"
               >
                  {/* Gradient Background */}
                  <motion.div
                     className="absolute inset-0 bg-gradient-to-br from-primary/20 to-secondary/20"
                     initial={{ opacity: 0 }}
                     animate={{ opacity: 1 }}
                     exit={{ opacity: 0 }}
                     style={{ backdropFilter: 'blur(8px)' }}
                  />

                  {/* Main Content */}
                  <motion.div
                     initial={{ scale: 0.8, opacity: 0 }}
                     animate={{ scale: 1, opacity: 1 }}
                     exit={{ scale: 0.8, opacity: 0 }}
                     className="relative z-10 flex flex-col items-center"
                  >
                     {/* Animated Circles */}
                     <div className="relative w-32 h-32">
                        <motion.div
                           animate={{
                              rotate: 360,
                              scale: [1, 1.2, 1],
                           }}
                           transition={{
                              rotate: { duration: 3, repeat: Infinity, ease: "linear" },
                              scale: { duration: 2, repeat: Infinity }
                           }}
                           className="absolute inset-0 rounded-full border-4 border-primary/30"
                        />
                        <motion.div
                           animate={{
                              rotate: -360,
                              scale: [1.2, 1, 1.2],
                           }}
                           transition={{
                              rotate: { duration: 3, repeat: Infinity, ease: "linear" },
                              scale: { duration: 2, repeat: Infinity, delay: 0.3 }
                           }}
                           className="absolute inset-2 rounded-full border-4 border-secondary/40"
                        />
                        <motion.div
                           animate={{
                              rotate: 360,
                              scale: [1, 1.1, 1],
                           }}
                           transition={{
                              rotate: { duration: 2, repeat: Infinity, ease: "linear" },
                              scale: { duration: 1.5, repeat: Infinity, delay: 0.5 }
                           }}
                           className="absolute inset-4 rounded-full border-4 border-primary/50"
                        />

                        {/* Center Icon */}
                        <motion.div
                           animate={{
                              scale: [1, 1.2, 1],
                              opacity: [0.5, 1, 0.5]
                           }}
                           transition={{
                              duration: 2,
                              repeat: Infinity,
                              ease: "easeInOut"
                           }}
                           className="absolute inset-0 flex items-center justify-center"
                        >
                           <div className="text-4xl">✨</div>
                        </motion.div>
                     </div>

                     {/* Text Content */}
                     <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.2 }}
                        className="mt-8 text-center"
                     >
                        <h2 className="text-2xl font-bold text-gray-800 mb-2">
                           Sipariş Durumu Güncelleniyor
                        </h2>
                        <motion.div
                           animate={{
                              opacity: [0.5, 1, 0.5]
                           }}
                           transition={{
                              duration: 2,
                              repeat: Infinity,
                              ease: "easeInOut"
                           }}
                           className="text-gray-600"
                        >
                           Lütfen bekleyiniz...
                        </motion.div>
                     </motion.div>

                     {/* Progress Dots */}
                     <div className="flex gap-2 mt-4">
                        {[...Array(3)].map((_, i) => (
                           <motion.div
                              key={i}
                              animate={{
                                 scale: [1, 1.5, 1],
                                 opacity: [0.3, 1, 0.3]
                              }}
                              transition={{
                                 duration: 1,
                                 repeat: Infinity,
                                 delay: i * 0.2
                              }}
                              className="w-2 h-2 rounded-full bg-primary"
                           />
                        ))}
                     </div>
                  </motion.div>
               </motion.div>
            )}
         </AnimatePresence>
      </>
   )
}

import { Card, Chip } from '@nextui-org/react'
import { IconSearch, IconRefresh, IconLayoutGrid, IconTable, IconInfoCircle } from '@tabler/icons-react'
import { Drawer, Empty, Form, Modal, Select, TreeSelect, message, TableColumnProps, Popover, Divider } from 'antd'
import { PRODUCT_STORE, PRODUCTS } from 'api/product'
import axios from 'axios'
import PageHeader from 'components/page-header'
import { routes } from 'config/routes'
import { addCart } from 'features/user/basket'
import useFetch from 'hooks/use-fetch'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Input, Loader } from 'rizzui'
import { RootState } from 'store'
import { apiUrl } from 'api/index'
import CreateProduct from './CreateProduct'
import ProductDetail, { prepareIlpenVariations, preparePozitronVariations } from 'utils/options';
import offer, { setProduct as addProductoffer } from 'features/user/offer'
import { isArray } from 'lodash'
import { useInView } from 'react-intersection-observer';
import ProductSkeleton from 'components/ProductSkeleton';
import { motion } from 'framer-motion';
import TableComponents from 'components/Table'

const safeNumber = (value: any): number => {
   if (typeof value === 'string') return parseFloat(value) || 0;
   return typeof value === 'number' ? value : 0;
};

const getCurrencySymbol = (currency: string): string => {
   switch (currency?.toUpperCase()) {
      case 'TRY': return '₺';
      case 'USD': return '$';
      case 'EUR': return '€';
      default: return '₺';
   }
};

function Products({ isFromOffer, handleClose, setOfferProduct, currentProducts }: any) {
   const offerProduct = useSelector((state: RootState) => state.offer.products)
   const carts = useSelector((state: RootState) => state.basket.carts)
   const [selectedVariant, setSelectedVariant] = React.useState<any>(null)
   const [variantList, setVariantList] = React.useState<any[]>([])
   const [stockRequestModal, setStockRequestModal] = React.useState(false)
   const [stockRequestValue, setStockRequestValue] = React.useState<any>(null)
   const [selectedProduct, setSelectedProduct] = React.useState<any>(null)
   const [iframeLink, setIframeLink] = React.useState<string | undefined>('')
   const [iframeOpen, setIframeOpen] = React.useState(false)
   const user = JSON.parse(localStorage.getItem('user') || '{}')
   const [searchQuery, { data, loading }] = useFetch<any>()
   const [updateStockMutation, { data: updateStockData, loading: updateStockLoading }] = useFetch<any>()

   const [categories, setCategories] = React.useState<any[]>([])
   const [suppliers, setSuppliers] = React.useState<any[]>([])

   const [products, setProducts] = React.useState<any[]>([])
   const [stockHistoryOpen, setStockHistoryOpen] = React.useState(false)

   const basket = useSelector((state: RootState) => state.basket.carts)
   const [histories, setHistories] = React.useState<any>([])
   const dispatch = useDispatch()
   const [selectedId, setSelectedId] = React.useState<string | undefined>(undefined)
   const [updateModal, setUpdateModal] = React.useState(false)

   const [updateForm] = Form.useForm()

   const [createProductForm] = Form.useForm()

   const [createProductDrawer, setCreateProductDrawer] = React.useState(false)

   const [page, setPage] = React.useState(1);
   const [hasMore, setHasMore] = React.useState(true);
   const [isLoading, setIsLoading] = React.useState(false);
   const { ref: loadMoreRef, inView } = useInView();

   const [viewType, setViewType] = useState<'grid' | 'table'>('grid')

   const pageHeader = {
      title: 'Ürünler & Stok Kontrolü',
      breadcrumb: [
         {
            href: routes.dashboard,
            name: 'Anasayfa'
         },
         {
            href: routes.products,
            name: 'Ürünler & Stok Kontrolü'
         }
      ]
   }

   const [form] = Form.useForm()

   const onSearch = (value: string) => {
      if (value.length > 3) {
         const url = 'https://test.gimel.com.tr/index.php?route=api/crm/searchProduct&search=' + value
         // @ts-ignore
         searchQuery(PRODUCTS, {
            endPoint: url
         })
      }
   }

   const fetchCategories = async (supplier?: string) => {
      const response = await fetch(apiUrl + '/api/category-list' + (supplier ? '?supplier=' + supplier : ''), {
         headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
         }
      })
      const data = await response.json()
      setCategories(data.data)
   }

   const fetchSuppliers = async (category?: string) => {
      const response = await fetch(apiUrl + '/api/supplier-list' + (category ? '?category=' + category : ''), {
         headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
         }
      })
      const data = await response.json()
      setSuppliers(data.data)
   }

   const handleFilter = async (values: any, currentPage = 1) => {
      setIsLoading(true);
      const object: any = {
         category_id: typeof values.category == 'undefined' ? '' : values.category,
         supplier: typeof values.supplier == 'undefined' ? '' : values.supplier,
         search: typeof values.query == 'undefined' ? '' : values.query,
         order: typeof values.order == 'undefined' ? '' : values.order,
         page: currentPage
      }

      const url = apiUrl + '/api/search-product?' + new URLSearchParams(object).toString().replace('undefined', '')

      try {
         const response = await fetch(url, {
            headers: {
               Authorization: 'Bearer ' + localStorage.getItem('token')
            }
         });

         const data = await response.json();

         if (currentPage === 1) {
            setProducts(data?.data.data);
         } else {
            setProducts(prev => [...prev, ...data?.data.data]);
         }

         setHasMore(data?.data.next_page_url !== null);
      } catch (error) {
         console.error('Error fetching products:', error);
      } finally {
         setIsLoading(false);
      }
   }

   React.useEffect(() => {
      if (inView && hasMore && !isLoading) {
         setPage(prev => {
            const nextPage = prev + 1;
            handleFilter(form.getFieldsValue(), nextPage);
            return nextPage;
         });
      }
   }, [inView, hasMore, isLoading]);

   const onFormSubmit = (values: any) => {
      setPage(1);
      handleFilter(values, 1);
   };

   useEffect(() => {
      if (products.length === 0) {
         handleFilter(form.getFieldsValue(), 1);
      }
   }, [form]);

   const handleAddBasket = async (product_id: string) => {
      const findProduct = products.find((item: any) => item.id == product_id)
      const findBasket = basket.find((item: any) => item.id == product_id)
      if (findBasket && !isFromOffer) {
         message.error('Ürün sepetinizde bulunmaktadır.')
      } else {
         if (!isFromOffer) {
            let urun = findProduct.urun_adi
            let adet = findProduct.stok_adedi
            if (variantList.length > 0) {
               if (selectedProduct.tedarikci == 'İlpen') {
                  urun = variantList.find((item: any) => item.id == selectedVariant)?.name
                  urun = findProduct.urun_adi + ' - ' + urun.split(' ')[0]

                  adet = variantList.find((item: any) => item.id == selectedVariant)?.name
                  adet = adet.split('Stok: ')[1]
               }
            }
            dispatch(
               addCart({
                  id: product_id,
                  name: urun,
                  model: findProduct.sku,
                  price: findProduct.get_supplier ? calcPriceDiscount(findProduct.satis_fiyati, findProduct.get_supplier?.discount) : findProduct.satis_fiyati,
                  stock: adet,
                  quantity: adet,
                  product_qty: adet,
                  delivery_date: '',
                  commission: 0,
                  commission_type: '1',
                  description: findProduct.aciklama,
                  services: [],
                  isReserve: false,
                  reserveEndDate: '',
                  supplier: findProduct.tedarikci,
                  image: findProduct.urun_resim_url,
                  currency: findProduct.para_birimi
               })
            )
         }

         if (isFromOffer) {
            let urun = findProduct.urun_adi
            let adet = findProduct.stok_adedi
            if (variantList.length > 0) {
               if (selectedProduct.tedarikci == 'İlpen') {
                  urun = variantList.find((item: any) => item.id == selectedVariant)?.name
                  urun = findProduct.urun_adi + ' - ' + urun.split(' ')[0]

                  adet = variantList.find((item: any) => item.id == selectedVariant)?.name
                  adet = adet.split('Stok: ')[1]
               } else if (selectedProduct.tedarikci == 'Pozitron') {
                  urun = variantList.find((item: any) => item.id == selectedVariant)?.name
                  urun = findProduct.urun_adi + ' - ' + urun.split(' ')[0]

                  adet = variantList.find((item: any) => item.id == selectedVariant)?.name
                  adet = adet.split('Stok Toplamı ')[1]
                  console.log('adet', adet)
                  console.log('selectedVariant', selectedVariant)
               }
            }

            let productArray = {
               id: product_id,
               name: urun,
               model: findProduct.sku,
               price: findProduct.get_supplier ? calcPriceDiscount(findProduct.satis_fiyati, findProduct.get_supplier?.discount) : findProduct.satis_fiyati,
               stock: adet,
               product_qty: adet,
               delivery_date: '',
               commission: 0,
               commission_type: '1',
               description: findProduct.aciklama,
               services: [],
               isReserve: false,
               reserveEndDate: '',
               supplier: findProduct.tedarikci,
               image: findProduct.urun_resim_url,
               currency: findProduct.para_birimi,
               converted_currency: findProduct.para_birimi,
            }

            dispatch(addProductoffer([...offerProduct, ...products]))
            console.log('offerProduct', offerProduct)
            setOfferProduct([...currentProducts, productArray])

            message.success('Ürün teklife eklendi.')
            setIframeOpen(false)
            handleClose(false)
            setVariantList([])
            setStockRequestValue(null)
            setStockRequestModal(false)
            setSelectedVariant(null)
         } else {
            message.success('Ürün sepetinize eklendi.')
         }
      }
   }

   React.useEffect(() => {
      fetchCategories()
      fetchSuppliers()
   }, [])

   const fetchHistory = async (product_id: string) => {
      const { data } = await axios.get('products/history/' + product_id)
      setHistories([])
      setHistories(data)

      setStockHistoryOpen(true)
   }

   const updateStock = (values: any) => {
      //@ts-ignore
      updateStockMutation(PRODUCT_STORE, {
         endPoint: 'update-product-stock',

         data: {
            product_id: selectedId,
            quantity: values.stock,
            stock_type: values.type
         }
      })
   }

   useEffect(() => {
      if (updateStockData) {
         setUpdateModal(false)
         message.success('Stok güncellendi.')
         //@ts-ignore
         handleFilter(form.getFieldsValue())
         updateForm.resetFields()
      }
   }, [updateStockData])

   const calcPriceDiscount = (price: number, discountRate: number) => {
      let newPrice = price - (price * discountRate) / 100
      return newPrice.toFixed(2)
   }

   const handleReset = () => {
      form.resetFields();
      setPage(1);
      handleFilter({}, 1);
      fetchCategories()
   };

   const columns: TableColumnProps<any>[] = [
      {
         title: 'Ürün',
         key: 'product',
         render: (_, record: any) => (
            <div className="flex items-center gap-3">
               <img
                  src={record.urun_resim_url}
                  alt={record.urun_adi}
                  className="w-12 h-12 rounded object-cover"
               />
               <div>
                  <div className="font-medium">{record.urun_adi}</div>
                  <div className="text-sm text-gray-500">SKU: {record.sku}</div>
               </div>
            </div>
         )
      },
      {
         title: 'Stok',
         key: 'stock',
         render: (_, record) => (
            <Chip color={record.stok_adedi > 0 ? "success" : "danger"} size="sm">
               {record.stok_adedi || 0} Adet
            </Chip>
         )
      },
      {
         title: 'Fiyat',
         key: 'price',
         render: (_, record) => (
            <div>
               <div>{safeNumber(record.satis_fiyati).toFixed(2)} {getCurrencySymbol(record.para_birimi)}</div>
               {record.tedarikci !== 'Gimel' && record.get_supplier?.discount > 0 && (
                  <div className="text-sm text-success">
                     İndirimli: {calcPriceDiscount(record.satis_fiyati, record.get_supplier?.discount)}
                  </div>
               )}
            </div>
         )
      },
      {
         title: 'Tedarikçi',
         key: 'supplier',
         render: (_, record) => (
            <Chip color="primary" size="sm">
               {record.tedarikci || 'Belirtilmemiş'}
            </Chip>
         )
      },
      {
         title: 'İşlemler',
         key: 'actions',
         render: (_, record) => (
            <div className="flex gap-2">
               <Button size="sm" color="primary" variant="flat"
                  onClick={() => {
                     setSelectedProduct(record)
                     setIframeOpen(true)
                  }}
               >
                  Detay
               </Button>
               <Button size="sm" color="danger" variant="flat"
                  onClick={() => fetchHistory(record.id)}
               >
                  Geçmiş
               </Button>
            </div>
         )
      }
   ]

   return (
      <div>
         <Modal footer={null} title="Seçenekler" open={stockRequestModal} onCancel={() => setStockRequestModal(false)}>
            {/* <Input
               value={stockRequestValue}
               label="Talep edilen stok adedi"
               onChange={e => setStockRequestValue(e.target.value)}
            /> */}
            <div>
               <Select
                  onChange={value => {
                     setSelectedVariant(value)
                  }}
                  className="w-full"
                  placeholder="Variant seçin"
               >
                  {variantList.map((item: any) => (
                     <Select.Option value={item.id}>{item.name}</Select.Option>
                  ))}
               </Select>
            </div>
            <Button
               className="mt-5"
               onClick={() => {
                  if (stockRequestValue) {
                     handleAddBasket(selectedProduct.id)
                  } else {
                     message.error('Lütfen talep edilen stok adedini giriniz.')
                  }
                  setStockRequestModal(false)
               }}
            >
               Sepete Ekle
            </Button>
         </Modal>
         <Drawer
            title="Ürün Ekle"
            closable
            open={createProductDrawer}
            onClose={() => setCreateProductDrawer(false)}
            placement="right"
            width={window.innerWidth > 768 ? '50%' : '100%'}
         >
            <Card style={{ padding: 10 }}>
               <CreateProduct
                  setCreateProductDrawer={setCreateProductDrawer}
                  isFromOffer={isFromOffer}
                  handleClose={handleClose}
                  setOfferProduct={setOfferProduct}
                  currentProducts={currentProducts}
               />
            </Card>
         </Drawer>
         <Drawer
            extra={
               <div className="flex items-center gap-2">
                  <Button onClick={() => setIframeOpen(false)} color="primary" variant="outline">
                     Kapat
                  </Button>
                  <Button
                     color="primary"
                     variant="outline"
                     onClick={() => {
                        console.log('selectedProduct', selectedProduct.tedarikci)
                        if (selectedProduct.tedarikci == 'İlpen') {
                           const getVariant = prepareIlpenVariations(
                              (JSON.parse(selectedProduct.json_verisi) as any) || { selectedProduct }
                           ) as any

                           const variant = getVariant[0]?.options?.map((item: any) => {
                              return {
                                 id: item.id,
                                 name: item.value
                              }
                           })

                           if (!Array.isArray(variant) || variant.length === 0) {
                              handleAddBasket(selectedProduct.id)
                           } else {
                              console.log(variant)
                              setVariantList(variant as any)
                              setStockRequestValue(selectedProduct.stok_adedi)
                              setStockRequestModal(true)
                              console.log(variant);
                           }

                           console.log('getVariant', getVariant)
                        } else if (selectedProduct.tedarikci == 'Doyuk') {
                           handleAddBasket(selectedProduct.id)
                        } else if (selectedProduct.tedarcikci == 'Akdeniz') {
                           handleAddBasket(selectedProduct.id)
                        } else if (selectedProduct.tedarikci == 'Pozitron') {
                           const getVariant = preparePozitronVariations(
                              (JSON.parse(selectedProduct.json_verisi) as any) || { selectedProduct }
                           ) as any

                           const variant = getVariant[0]?.options?.map((item: any) => {
                              return {
                                 id: item.id,
                                 name: item.value
                              }
                           })

                           if (!Array.isArray(variant) || variant.length === 0) {
                              handleAddBasket(selectedProduct.id)
                           } else {
                              setVariantList(variant as any)
                              setStockRequestValue(selectedProduct.stok_adedi)
                              setStockRequestModal(true)
                           }

                           //console.log('getVariant', getVariant)
                        } else {
                           handleAddBasket(selectedProduct.id)
                        }
                     }}
                  >
                     Sepete Ekle
                  </Button>
               </div>
            }
            placement="right"
            title="Ürün Detayı"
            closable
            width={window.innerWidth > 768 ? '50%' : '100%'}
            open={iframeOpen}
            onClose={() => setIframeOpen(false)}
         >
            {selectedProduct && selectedProduct && selectedProduct.urun_id && selectedProduct.json_verisi && (
               <ProductDetail
                  image={selectedProduct.urun_resim_url}
                  sku={selectedProduct.sku}
                  description={selectedProduct.aciklama}
                  product={(JSON.parse(selectedProduct.json_verisi) as any) || { selectedProduct }}
                  supplier={selectedProduct.get_supplier?.name ?? 'Gimel'}
               />
            )}
         </Drawer>
         <Drawer title="Stok Geçmişi" closable open={stockHistoryOpen} onClose={() => setStockHistoryOpen(false)}>
            {histories?.data?.length === 0 && (
               <div className="place-content-center">
                  <Empty description="Stok geçmişi bulunamadı." />
               </div>
            )}
            <ol className="relative border-s border-gray-200 light:border-gray-700">
               {histories?.data?.map((item: any) => (
                  <li className="mb-10 ms-4">
                     <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -start-1.5 border border-white light:border-gray-900 light:bg-gray-700" />
                     <time className="mb-1 text-sm font-normal leading-none text-gray-400 light:text-gray-500">
                        {moment(item.created_at).format('DD MMMM YYYY')}
                     </time>
                     <h3 className="text-lg font-semibold text-gray-900 light:text-white">
                        {item.user?.name} - {item.type == 'in' ? 'stok girişi yaptı' : 'Stok çıkışı yaptı'}
                     </h3>
                     <p className="mb-4 text-base font-normal text-gray-500 light:text-gray-400">
                        Önceki Stok : {item.before_stock} - Yeni Stok : {item.after_stock}
                     </p>
                     <p className="mb-4 text-base font-normal text-gray-500 light:text-gray-400">{item.description}</p>
                  </li>
               ))}
            </ol>
         </Drawer>
         <PageHeader title={pageHeader.title} breadcrumb={pageHeader.breadcrumb}>
            <Button color="primary" variant="solid" onClick={() => setCreateProductDrawer(true)}>
               Yeni Özel Üretim Ekle
            </Button>
         </PageHeader>
         <Card className="p-2" radius="none">
            <Form form={form} layout="vertical" onFinish={onFormSubmit}>
               <div className="grid lg:grid-cols-5 gap-2">
                  <div className="">
                     <Form.Item name="query" label="Ürün adı ile arama">
                        <Input
                           suffix={loading && <Loader size="lg" />}
                           placeholder="Ürün Ara"
                           prefix={<IconSearch />}
                        />
                     </Form.Item>
                  </div>
                  <div>
                     <Form.Item name="supplier" label="Tedarikçi ile arama">
                        <Select
                           showSearch
                           placeholder="Tedarikçi Seç"
                           optionFilterProp="children"
                           onChange={value => {
                              fetchCategories(value)
                              form.setFieldsValue({ category: undefined })
                           }}
                           filterOption={(input, option: any) =>
                              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                           }
                        >
                           <Select.Option value="undefined">Tüm Tedarikçiler</Select.Option>
                           {suppliers
                              ?.filter((supplier: any) => supplier.supplier !== '')
                              .map((supplier: any) => (
                                 <Select.Option value={supplier.supplier}>{supplier.supplier}</Select.Option>
                              ))}
                        </Select>
                     </Form.Item>
                  </div>
                  <div>
                     <Form.Item name="category" label="Kategori">
                        <TreeSelect
                           style={{ width: '100%' }}
                           value={form.getFieldValue('category')}
                           dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                           treeData={categories}
                           placeholder="Kategori"
                           treeDefaultExpandAll
                           onChange={value => form.setFieldsValue({ category: value })}
                           showSearch
                           filterTreeNode={(input, option: any) =>
                              option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                           }
                        />
                     </Form.Item>
                  </div>
                  <div>
                     <Form.Item name="order" label="Sıralama">
                        <Select
                           showSearch
                           placeholder="Sıralama"
                           optionFilterProp="children"
                           filterOption={(input, option: any) =>
                              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                           }
                        >
                           <Select.Option value="undefined">Sırala</Select.Option>
                           <Select.Option value="price_desc">Fiyat (En yüksekten en düşüğe)</Select.Option>
                           <Select.Option value="price_asc">Fiyat (En düşükten en yükseğe)</Select.Option>
                           <Select.Option value="quantity_asc">Adet (En azdan en çoğa)</Select.Option>
                           <Select.Option value="quantity_desc">Adet (En çoktan en aza)</Select.Option>
                        </Select>
                     </Form.Item>
                  </div>

                  <div className="flex gap-2 lg:mt-8">
                     <Button type="submit" color="primary" variant="solid">
                        Filtrele
                     </Button>
                     <Button
                        onClick={handleReset}
                        color="danger"
                        variant="flat"
                        className="flex items-center gap-2"
                     >
                        <IconRefresh size={18} />
                        Sıfırla
                     </Button>
                  </div>
               </div>
            </Form>

            <div className="flex justify-end mb-4 mt-4">
               <div className="flex items-center gap-2 bg-default-100 p-1 rounded-lg">
                  <Button
                     size="sm"
                     variant={viewType === 'grid' ? 'solid' : 'flat'}
                     onClick={() => setViewType('grid')}
                  >
                     Grid
                  </Button>
                  <Button
                     size="sm"
                     variant={viewType === 'table' ? 'solid' : 'flat'}
                     onClick={() => setViewType('table')}
                     prefix={<IconTable size={18} /> as any}
                  >
                     Tablo
                  </Button>
               </div>
            </div>

            {viewType === 'grid' ? (
               <div className="grid lg:grid-cols-4 gap-10 mt-2">
                  {products?.map((item: any, index: any) => (
                     <div className="bg-white border border-gray-300 rounded-lg p-5">
                        <div className="relative">
                           <div className="relative mx-auto aspect-[6/5] w-full overflow-hidden rounded-lg bg-gray-100">
                              <img
                                 src={item.urun_resim_url}
                                 alt="product"
                                 className="object-cover object-center w-full h-full transition-transform duration-200 transform hover:scale-105"
                              />
                           </div>
                        </div>

                        <div className="pt-4">
                           <a href="#" className="block">
                              <h6 className="text-lg font-semibold text-gray-800 transition-colors duration-150 hover:text-primary">
                                 {item.urun_adi}
                              </h6>
                           </a>

                           <p className="text-sm text-gray-600">
                              Fiyat: {safeNumber(item.satis_fiyati).toFixed(2)}{' '}
                              {getCurrencySymbol(item.para_birimi)} + KDV
                           </p>

                           {item.tedarikci !== 'Gimel' && item.get_supplier?.discount > 0 && (
                              <>
                                 <p className="text-sm text-gray-600 font-bold">
                                    İndirimli fiyat: {calcPriceDiscount(item.satis_fiyati, item.get_supplier?.discount)}{' '}
                                    {getCurrencySymbol(item.para_birimi)} + KDV
                                 </p>
                                 <p className="text-sm text-gray-600">
                                    Tedarikçi indirim oranı: {safeNumber(item.get_supplier?.discount).toFixed(2)}%
                                 </p>
                                 <p className="text-sm text-gray-600">
                                    Tedarikçi alış vadesi:{' '}
                                    {item.get_supplier?.purchase_term == 0 ? 'Peşin' : item.get_supplier?.purchase_term + ' Gün'}
                                 </p>
                              </>
                           )}

                           <p className="text-sm text-gray-600">
                              Stok Kodu: {item.sku}
                           </p>
                           <p className="text-sm text-gray-600">
                              Adet: {item.stok_adedi || 0}
                           </p>
                           <p className="text-sm text-gray-600">
                              Tedarikçi: {item.tedarikci || 'Belirtilmemiş'}
                           </p>

                           <div className="mt-3 flex gap-2">
                              <Button onClick={() => fetchHistory(item.id)} size="sm" color="danger" variant="outline">
                                 Stok Geçmişi
                              </Button>
                              <Button
                                 size="sm"
                                 color="primary"
                                 variant="outline"
                                 onClick={() => {
                                    setSelectedProduct(item)
                                    setIframeOpen(true)
                                 }}
                              >
                                 Ürün Detayı
                              </Button>
                              {item.offer_product?.is_reserve === 1 && item?.offer_product?.reserve_date && item?.offer_product?.offer?.user && (
                                 <Popover
                                    content={
                                       <div className="p-3 max-w-xs">
                                          <div className="space-y-2">
                                             <div className="flex items-center gap-2 text-warning">
                                                <IconInfoCircle size={16} />
                                                <span className="font-medium">Rezervasyon Bilgisi</span>
                                             </div>
                                             <Divider className="my-2" />
                                             <div className="space-y-1 text-sm">
                                                <p>
                                                   <span className="font-semibold">{item.offer_product.product_qty}</span> adet ürün,
                                                </p>
                                                <p>
                                                   <span className="font-semibold">{item.offer_product.offer.user.name}</span> tarafından
                                                </p>
                                                <p>
                                                   <span className="font-semibold">{moment(item.offer_product.reserve_date).format('DD.MM.YYYY')}</span> tarihine kadar rezerve edildi
                                                </p>
                                             </div>
                                          </div>
                                       </div>
                                    }
                                    trigger="hover"
                                    placement="right"
                                 >
                                    <Chip
                                       className="ml-2"
                                       color="warning"
                                       size="sm"
                                       variant="flat"
                                    >
                                       Rezerve Edildi
                                    </Chip>
                                 </Popover>
                              )}
                           </div>

                           {user?.role === 'admin' && item.supplier === 'gimel' && (
                              <Button
                                 className="mt-3 w-full"
                                 size="sm"
                                 color="primary"
                                 variant="outline"
                                 onClick={() => {
                                    setSelectedId(item.product_id)
                                    setUpdateModal(true)
                                 }}
                              >
                                 Stok Güncelle
                              </Button>
                           )}
                        </div>
                     </div>
                  ))}

                  {hasMore && (
                     <div ref={loadMoreRef} className="col-span-4 grid lg:grid-cols-4 gap-10">
                        {[1, 2, 3, 4].map((n) => (
                           <ProductSkeleton key={n} />
                        ))}
                     </div>
                  )}
               </div>
            ) : (
               <TableComponents
                  columns={columns}
                  data={products}
                  pagination={{
                     total: products?.length,
                     pageSize: 10,
                     showSizeChanger: true,
                     showTotal: (total: any) => `Toplam ${total} ürün`
                  }}
                  loading={loading}
               />
            )}
         </Card>
         <Modal
            footer={null}
            okButtonProps={{
               onClick: () => {
                  updateForm.submit()
               }
            }}
            title="Stok Güncelle"
            open={updateModal}
            onCancel={() => setUpdateModal(false)}
            onOk={() => setUpdateModal(false)}
         >
            <Form form={updateForm} layout="vertical" onFinish={updateStock}>
               <Form.Item label="Stok Miktarı" name="stock">
                  <Input />
               </Form.Item>
               <Form.Item label="Stok Tipi" name="type">
                  <Select>
                     <Select.Option value="in">Stok Girişi</Select.Option>
                     <Select.Option value="out">Stok Çıkışı</Select.Option>
                  </Select>
               </Form.Item>
               <Button isLoading={updateStockLoading} type="submit" color="primary" variant="solid">
                  Kaydet
               </Button>
            </Form>
         </Modal>
         <div className="grid lg:grid-cols-4 gap-10 mt-2">
            {products?.length === 0 && (
               <motion.div
                  className="col-span-4 min-h-[400px] flex flex-col items-center justify-center"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.3 }}
               >
                  <motion.div
                     className="bg-white p-8 rounded-xl shadow-sm w-full max-w-md text-center"
                     initial={{ scale: 0.9, y: 20 }}
                     animate={{ scale: 1, y: 0 }}
                     transition={{
                        type: "spring",
                        stiffness: 100,
                        damping: 15,
                        delay: 0.1
                     }}
                  >
                     <motion.div
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.2 }}
                     >
                        <Empty
                           image={Empty.PRESENTED_IMAGE_SIMPLE}
                           className="custom-empty mb-4"
                           description={false}
                        />

                        <h3 className="text-lg font-medium text-gray-800 mb-2">
                           Ürün Bulunamadı
                        </h3>

                        <p className="text-gray-500 mb-6">
                           Aradığınız kriterlere uygun ürün bulunmamaktadır.
                        </p>

                        <motion.div
                           whileHover={{ scale: 1.02 }}
                           whileTap={{ scale: 0.98 }}
                        >
                           <Button
                              onClick={handleReset}
                              color="primary"
                              variant="flat"
                              className="flex items-center gap-2 mx-auto"
                           >
                              <IconRefresh size={18} />
                              Filtreleri Sıfırla
                           </Button>
                        </motion.div>
                     </motion.div>
                  </motion.div>
               </motion.div>
            )}
         </div>
      </div>
   )
}

export default Products

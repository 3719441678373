import { IconMail, IconCalendar, IconUser, IconBuildingCommunity, IconX } from '@tabler/icons-react';
import moment from 'moment';
import { AiOutlineSafety } from "react-icons/ai";
import { Modal, Upload, message } from 'antd';
import { IconUpload, IconZoomIn, IconTrash, IconPhotoPlus } from '@tabler/icons-react';
import { useState } from 'react';
import { Button } from '@nextui-org/react';

const gradientColors = [
    { start: '#FF6B6B', end: '#FF8E8E' },
    { start: '#4ECDC4', end: '#6CE5DB' },
    { start: '#45B7D1', end: '#6BC6D9' },
    { start: '#96C93D', end: '#AAD85C' },
    { start: '#9B59B6', end: '#B07CC6' },
];

export default function CustomerHeader({ customer }: { customer: any }) {
    const [designModal, setDesignModal] = useState(false);
    const [selectedDesign, setSelectedDesign] = useState<string[]>([]);
    const [uploadVisible, setUploadVisible] = useState(false);

    // Tasarıma tıklama fonksiyonu
    const handleDesignClick = () => {
        const photoArray = customer?.data?.photos?.split(',').filter((photo: string) => photo.trim() !== '') || [];
        setSelectedDesign(photoArray);
        setDesignModal(true);
    };

    const getStatusInfo = (status: number) => {
        switch (status) {
            case 1:
                return {
                    text: "Aktif",
                    color: "text-green-600",
                    icon: <AiOutlineSafety size={30} className="text-green-600" />
                };
            case 0:
                return {
                    text: "Pasif",
                    color: "text-red-600",
                    icon: <IconX size={30} className="text-red-600" />
                };
            default:
                return {
                    text: "Belirsiz",
                    color: "text-gray-600",
                    icon: <AiOutlineSafety size={30} className="text-gray-600" />
                };
        }
    };

    return (
        <>
            <div className='flex gap-10 items-center justify-between'>
                <div className=''>
                    <div className='flex items-center gap-2'>
                        <div className='logo w-16 h-16 rounded-full bg-gray-100 flex items-center justify-center'>
                            {customer?.data?.logo ?
                                <img src={customer?.data?.logo} alt={customer?.data?.company_name} className='w-full h-full object-cover rounded-full' />
                                : <IconBuildingCommunity size={30} className='text-gray-500' />
                            }
                        </div>
                        <div className='flex flex-col'>
                            <span className='text-gray-500'>Firma Adı</span>
                            <span className='text-black text-2xl font-semibold'>
                                {customer?.data?.company_name}
                            </span>
                        </div>
                    </div>
                </div>
                <div className='flex items-center gap-10'>
                    {/* E-posta */}
                    <CustomerInfoItem
                        icon={<IconMail size={30} />}
                        label="E-posta"
                        value={customer?.data?.email}
                    />

                    {/* Kayıt Tarihi */}
                    <CustomerInfoItem
                        icon={<IconCalendar size={30} />}
                        label="Kayıt Tarihi"
                        value={moment(customer?.data?.created_at).format('DD.MM.YYYY')}
                    />

                    {/* Durum */}
                    <CustomerInfoItem
                        icon={getStatusInfo(customer?.data?.status)?.icon}
                        label="Durum"
                        value={getStatusInfo(customer?.data?.status)?.text}
                    />

                    {/* Yetkililer */}
                    <div className='flex items-center gap-2'>
                        <AvatarGroup contacts={customer?.data?.contacts} />
                        <div className='flex flex-col'>
                            <span className='text-gray-500'>Yetkililer</span>
                            <span className='text-black text-2xl font-semibold'>
                                {customer?.data?.contacts?.length || 0}
                            </span>
                        </div>
                    </div>

                    {/* Tasarımlar Butonu */}

                </div>
            </div>

            {/* Modal */}
            <Modal
                title={
                    <div className="flex items-center justify-between">
                        <span>Tasarımlar</span>
                        <Button
                            color="primary"
                            size="sm"
                            onClick={() => setUploadVisible(!uploadVisible)}
                            startContent={<IconUpload className="w-4 h-4" />}
                        >
                            Tasarım Yükle
                        </Button>
                    </div>
                }
                open={designModal}
                onCancel={() => {
                    setDesignModal(false);
                    setUploadVisible(false);
                }}
                footer={null}
                width={800}
            >
                {uploadVisible && (
                    <div className="mb-6 border rounded-lg p-4">
                        <Upload.Dragger
                            multiple
                            action="/api/upload"
                            onChange={info => {
                                if (info.file.status === 'done') {
                                    message.success(`${info.file.name} başarıyla yüklendi`);
                                    setSelectedDesign(prev => [...prev, info.file.response.url]);
                                } else if (info.file.status === 'error') {
                                    message.error(`${info.file.name} yüklenemedi.`);
                                }
                            }}
                        >
                            <p className="ant-upload-drag-icon">
                                <IconUpload className="w-8 h-8 mx-auto text-gray-400" />
                            </p>
                            <p className="ant-upload-text">Tasarım yüklemek için t��klayın veya sürükleyin</p>
                        </Upload.Dragger>
                    </div>
                )}

                <div className="flex items-center gap-4">
                    {selectedDesign.map((photo, index) => (
                        <div key={index} className="relative group">
                            <img
                                src={photo}
                                alt={`Tasarım ${index + 1}`}
                                className="w-full h-full object-contain rounded-lg"
                            />
                            <div className="absolute inset-0 bg-black/50 opacity-0 group-hover:opacity-100 transition-opacity duration-200 rounded-lg flex items-center justify-center">
                                <div className="flex gap-2">
                                    <Button
                                        size="sm"
                                        color="primary"
                                        variant="flat"
                                        onClick={() => window.open(photo, '_blank')}
                                        startContent={<IconZoomIn className="w-4 h-4" />}
                                    >
                                        Büyüt
                                    </Button>
                                    <Button
                                        size="sm"
                                        color="danger"
                                        variant="flat"
                                        onClick={() => {
                                            setSelectedDesign(prev => prev.filter((_, i) => i !== index));
                                            message.success('Tasarım silindi');
                                        }}
                                        startContent={<IconTrash className="w-4 h-4" />}
                                    >
                                        Sil
                                    </Button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                {selectedDesign.length === 0 && !uploadVisible && (
                    <div className="text-center py-12 text-gray-500">
                        Henüz tasarım yüklenmemiş
                    </div>
                )}
            </Modal>
        </>
    );
}

function CustomerInfoItem({ icon, label, value }: { icon: React.ReactNode, label: string, value: string }) {
    return (
        <div className='flex items-center gap-2'>
            <div className='text-gray-500'>{icon}</div>
            <div className='flex flex-col'>
                <span className='text-gray-500'>{label}</span>
                <span className='text-black text-lg font-semibold'>{value}</span>
            </div>
        </div>
    );
}

function AvatarGroup({ contacts }: { contacts: any[] }) {
    return (
        <div className='relative flex items-center'>
            {contacts?.slice(0, 5).map((_, index) => (
                <div
                    key={index}
                    className={`w-10 h-10 rounded-full flex items-center justify-center border-2 border-white ${index === 0 ? '' : '-ml-5'}`}
                    style={{
                        background: `linear-gradient(135deg, ${gradientColors[index % gradientColors.length].start}, ${gradientColors[index % gradientColors.length].end})`
                    }}
                >
                    <IconUser size={20} className='text-white' />
                </div>
            ))}
        </div>
    );
} 
import React, { useEffect } from 'react'

import { Dropdown, DropdownTrigger, DropdownMenu, DropdownItem, Button, cn } from '@nextui-org/react'
import { useTranslation } from 'react-i18next'
import { IconAnalyze, IconEdit, IconEye, IconHistory, IconPdf, IconTrash, IconUserPlus } from '@tabler/icons-react'
import { Drawer, Form, Modal, Popconfirm, Select, notification, Empty } from 'antd'
import useFetch from 'hooks/use-fetch'
import { OFFER_MANAGEMENT_STORE } from 'api/offer-management'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setContactId, setOfferNumber, setOtherSuppliers, setProduct } from 'features/user/offer'
import { routes } from 'config/routes'
import moment from 'moment'
import axios from 'axios'
import { Tooltip } from '@nextui-org/react'

interface ActionButtonProps {
   deleteButtonClick: any
   editButtonClick: any
   viewButtonClick: any
   users?: any
   row?: any
   setHide: any
   isAssigned: boolean
   offer_id?: any
}

function ActionButton(props: ActionButtonProps) {
   const row = props.row
   const navigate = useNavigate()
   const dispatch = useDispatch()
   const user = JSON.parse(localStorage.getItem('user') || '{}')
   const iconClasses = 'text-xl text-default-500 pointer-events-none flex-shrink-0'
   const [modal, setModal] = React.useState(false)
   const [makeAnAppointmentMutation, { data, loading, error }] = useFetch<any>()
   const [createOfferMutation, { data: createOfferData, loading: createOfferLoading, error: createOfferError }] =
      useFetch<any>()
   const [stockHistoryOpen, setStockHistoryOpen] = React.useState(false)
   const [histories, setHistories] = React.useState<any>([])

   const [form] = Form.useForm()

   console.log(props.users)

   const { t } = useTranslation()

   const onFinish = (values: any) => {
      console.log(props)
      //@ts-ignore
      makeAnAppointmentMutation(OFFER_MANAGEMENT_STORE, {
         endPoint: 'offers/assign',
         body: {
            offer_id: props.row.id,
            user_id: values.user
         }
      })
   }

   const createOffer = () => {
      //@ts-ignore

      createOfferMutation(OFFER_MANAGEMENT_STORE, {
         endPoint: 'offers/convertRequestToOffer',
         body: {
            offer_id: row?.id
         }
      })
   }

   const fetchHistory = async () => {
      const { data } = await axios.get('offers/' + row.id + '/history')
      setHistories([])
      setHistories(data)

      setStockHistoryOpen(true)
   }

   useEffect(() => {
      if (createOfferData) {
         const { data } = createOfferData
         dispatch(setOfferNumber(data?.no))
         dispatch(setContactId(createOfferData?.customer_contact_id))
         dispatch(setOtherSuppliers([]))
         console.log(data.products)

         const products = data?.products.map((product: any, index: number) => {
            const description = JSON.parse(product.product_json)
            console.log(description)
            return {
               id: product.product_id,
               name: description.name,
               //price: description.price?.trim() ?? 0,
               price: description.price > 0 ? description.price : 0,
               stock: product.product_qty,
               product_qty: description.stock,
               converted_currency: 'TRY',
               delivery_date: product.delivery_date,
               commission: product.product_markup,
               commission_type: product.product_markup_type,
               description: product.product_description,
               supplier: description.supplier,
               currency: product.currency ?? 'TRY',
               image: description.image,
               services: product.services.map((service: any) => {
                  return {
                     ...service.service,
                     commission: service.service_markup,
                     commission_type: service.service_markup_type,
                     description: service.service_description,
                     included_in_total: service.included_in_total
                  }
               })
            }
         })
         console.log(products)
         dispatch(setProduct(products))
         navigate(routes.offerManagement.view(data?.offer_group_id))
      }
   }, [createOfferData])

   useEffect(() => {
      if (data) {
         setModal(false)
         notification.success({
            message: 'Başarılı',
            description: 'Atama başarılı bir şekilde yapıldı.'
         })
         props.setHide(props.row.id)
      }
   }, [data])


   return (
      <div className="flex items-center gap-2">
         <Button
            style={{
               display: props.row?.isAssigned ? 'none' : 'block'
            }}
            variant="faded" color="primary" isLoading={createOfferLoading} onClick={() => createOffer()}>
            Teklife Dönüştür
         </Button>
         <Tooltip content="Atama Yap">
            <Button
               variant="light"
               style={{ display: props.isAssigned ? 'none' : 'block' }}
               isIconOnly
               className="text-default-500 hover:text-primary hover:bg-primary/10 transition-colors"
               onClick={() => setModal(true)}
            >
               <IconUserPlus className="w-5 h-5" />
            </Button>
         </Tooltip>

         <Tooltip content="Talep Durumları">
            <Button
               variant="light"
               isIconOnly
               className="text-default-500 hover:text-indigo-500 hover:bg-indigo-50 transition-colors"
               onClick={() => fetchHistory()}
            >
               <IconHistory className="w-5 h-5" />
            </Button>
         </Tooltip>

         <Popconfirm
            title="Talebi silmek istediğinize emin misiniz?"
            onConfirm={props.deleteButtonClick}
            okText="Evet"
            cancelText="Hayır"
         >
            <Tooltip content="Sil">
               <Button
                  variant="light"
                  isIconOnly
                  className="text-default-500 hover:text-danger hover:bg-danger/10 transition-colors"
               >
                  <IconTrash className="w-5 h-5" />
               </Button>
            </Tooltip>
         </Popconfirm>

         <Modal title="Atama Yap" open={modal} footer={null} closable onCancel={() => setModal(false)}>
            <Form form={form} layout="vertical" name="form_in_modal" onFinish={onFinish}>
               <Form.Item name="user" label="Kullanıcı" rules={[{ required: true, message: 'Kullanıcı seçiniz!' }]}>
                  <Select placeholder="Seçim">
                     {props.users?.result?.map((user: any) => (
                        <Select.Option key={user.id} value={user.id}>
                           {user.name}
                        </Select.Option>
                     ))}
                  </Select>
               </Form.Item>
               <div className="gap-2 flex">
                  <Button type="submit" color="primary" isLoading={loading} onClick={form.submit}>
                     Atama Yap
                  </Button>
                  <Button color="danger" onClick={() => setModal(false)}>
                     İptal
                  </Button>
               </div>
            </Form>
         </Modal>

         <Drawer
            title="Talep/Teklif Durumu"
            closable
            open={stockHistoryOpen}
            onClose={() => setStockHistoryOpen(false)}
         >
            {histories?.data?.length === 0 && (
               <div className="place-content-center">
                  <Empty description="Talep/Teklif geçmişi bulunamadı." />
               </div>
            )}
            <ol className="relative border-s border-gray-200 light:border-gray-700">
               {histories?.data?.map((item: any) => (
                  <li className="mb-10 ms-4">
                     <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -start-1.5 border border-white light:border-gray-900 light:bg-gray-700" />
                     <time className="mb-1 text-sm font-normal leading-none text-gray-400 light:text-gray-500">
                        {moment(item.created_at).format('DD MMMM YYYY')}
                     </time>
                     <h3 className="text-lg font-semibold text-gray-900 light:text-white">
                        {item.user?.name} - {item.action}
                     </h3>
                  </li>
               ))}
            </ol>
         </Drawer>
      </div>
   )
}

export default ActionButton

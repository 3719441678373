import React, { useState, useRef, useEffect } from 'react';
import { Modal, Pagination } from 'antd';
import { IconSearch, IconBuilding, IconX, IconUserCircle, IconUserSearch } from '@tabler/icons-react';
import { motion, AnimatePresence } from 'framer-motion';
import useFetch from 'hooks/use-fetch';
import { CUSTOMER_MANAGEMENT_LIST } from 'api/customer-management';

interface SelectCustomerModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSelect: (customer: any) => void;
}

export default function SelectCustomerModal({ isOpen, onClose, onSelect }: SelectCustomerModalProps) {
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(1);
    const searchRef = useRef<HTMLInputElement>(null);
    const [searchTimeout, setSearchTimeout] = useState<NodeJS.Timeout>();
    const listRef = useRef<HTMLDivElement>(null);
    const [hasMore, setHasMore] = useState(true);

    const [customersQuery, { data: customers, loading }] = useFetch<any>();

    // Scroll handler
    const handleScroll = () => {
        if (listRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = listRef.current;
            if (scrollTop + clientHeight >= scrollHeight - 10 && !loading && hasMore) {
                setPage(prev => prev + 1);
            }
        }
    };

    // Fetch customers
    const fetchCustomers = (searchQuery = '') => {
        //@ts-ignore
        customersQuery(CUSTOMER_MANAGEMENT_LIST, {
            endPoint: '/customer-with-contacts-select?page=' + page + '&search=' + searchQuery,
        })
    };

    useEffect(() => {
        if (isOpen) {
            fetchCustomers(search);
            searchRef.current?.focus();
        }
    }, [isOpen, page]);

    useEffect(() => {
        if (customers?.data?.length > 0) {
            setHasMore(page < customers?.meta?.last_page);
        }
    }, [customers]);

    // Debounced search
    const handleSearch = (value: string) => {
        setSearch(value);
        setPage(1);

        if (searchTimeout) {
            clearTimeout(searchTimeout);
        }

        const timeout = setTimeout(() => {
            fetchCustomers(value);
        }, 300);

        setSearchTimeout(timeout);
    };

    return (
        <Modal
            open={isOpen}
            onCancel={onClose}
            footer={null}
            width={640}
            title="Müşteri Seç"
            className="select-customer-modal"
        >
            <div className="space-y-6">
                {/* Search Input - Sticky */}
                <div className="sticky top-0 bg-white z-10 pb-2">
                    <div className="relative">
                        <input
                            ref={searchRef}
                            type="text"
                            value={search}
                            onChange={(e) => handleSearch(e.target.value)}
                            placeholder="Müşteri veya firma ara..."
                            className="
                                w-full px-4 py-2.5
                                bg-gray-50
                                border-0 
                                rounded-lg
                                text-sm text-gray-900
                                placeholder:text-gray-500
                                focus:outline-none focus:ring-2 focus:ring-blue-500/20
                            "
                        />
                        <div className="absolute right-3 top-1/2 -translate-y-1/2 flex items-center gap-2">
                            {search && (
                                <button
                                    onClick={() => handleSearch('')}
                                    className="p-1 hover:bg-gray-200/80 rounded-full transition-colors"
                                >
                                    <IconX size={14} className="text-gray-500" />
                                </button>
                            )}
                            <IconSearch size={16} className="text-gray-400" />
                        </div>
                    </div>
                </div>

                {/* Scrollable List Container */}
                <div
                    ref={listRef}
                    className="overflow-y-auto max-h-[400px] relative scroll-smooth"
                >
                    <div className="space-y-2 pb-14">
                        {customers?.data?.data?.map((company: any) => (
                            <motion.div
                                key={company.id}
                                initial={{ opacity: 0, y: 10 }}
                                animate={{ opacity: 1, y: 0 }}
                            >
                                {/* Company Header */}
                                <div className="flex items-center gap-3 px-2 py-1.5">
                                    <div className="w-6 h-6 rounded bg-gray-100 flex items-center justify-center">
                                        <IconBuilding size={14} className="text-gray-500" />
                                    </div>
                                    <span className="text-sm font-medium text-gray-700">
                                        {company.company_name}
                                    </span>
                                    <span className="text-xs text-gray-400">
                                        ({company.contacts?.length || 0})
                                    </span>
                                </div>

                                {/* Contacts */}
                                <div className="pl-8 space-y-0.5">
                                    {company.contacts?.map((contact: any) => (
                                        <motion.button
                                            key={contact.id}
                                            onClick={() => onSelect({
                                                id: contact.id,
                                                customer_id: company.id,
                                                customer_name: company.company_name,
                                                contact_name: contact.name,
                                            })}
                                            className="
                                                w-full px-3 py-2
                                                flex items-center gap-3
                                                rounded-lg
                                                text-left
                                                hover:bg-gray-50
                                                transition-colors
                                            "
                                        >
                                            <div className="w-8 h-8 rounded-full bg-gray-100 flex items-center justify-center">
                                                <span className="text-sm font-medium text-gray-700">
                                                    {contact.name.charAt(0)}
                                                </span>
                                            </div>
                                            <div>
                                                <div className="text-sm font-medium text-gray-900">
                                                    {contact.name}
                                                </div>
                                                {contact.email && (
                                                    <div className="text-xs text-gray-500">
                                                        {contact.email}
                                                    </div>
                                                )}
                                            </div>
                                        </motion.button>
                                    ))}
                                </div>
                            </motion.div>
                        ))}

                        {/* Loading Indicator */}
                        {loading && (
                            <div className="py-4 space-y-4">
                                {[1, 2, 3].map((i) => (
                                    <div key={i} className="animate-pulse">
                                        <div className="flex items-center gap-3 px-2">
                                            <div className="w-6 h-6 bg-gray-200 rounded" />
                                            <div className="h-4 w-32 bg-gray-200 rounded" />
                                        </div>
                                        <div className="pl-8 mt-2 space-y-2">
                                            {[1, 2].map((j) => (
                                                <div key={j} className="flex items-center gap-3">
                                                    <div className="w-8 h-8 bg-gray-200 rounded-full" />
                                                    <div>
                                                        <div className="h-4 w-24 bg-gray-200 rounded mb-1" />
                                                        <div className="h-3 w-32 bg-gray-200 rounded" />
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}

                        {/* Empty State */}
                        {!loading && customers?.data?.length === 0 && (
                            <div className="text-center py-12">
                                <IconUserSearch size={32} className="mx-auto text-gray-400 mb-2" />
                                <p className="text-sm text-gray-500">
                                    {search ? 'Aradığınız kriterlere uygun müşteri bulunamadı' : 'Henüz müşteri eklenmemiş'}
                                </p>
                            </div>
                        )}
                    </div>
                </div>

                {/* Fixed Pagination */}
                <div className="absolute bottom-0 left-0 right-0 bg-white border-t py-3 px-4">
                    <Pagination
                        current={customers?.data?.current_page}
                        total={customers?.data?.total}
                        pageSize={10}
                        onChange={(page) => setPage(page)}
                        className="flex justify-center"
                    />
                </div>
            </div>
        </Modal>
    );
} 
import { routes } from 'config/routes'
import { List, TableColumnProps, Modal, Drawer, message, DatePicker, Form, Input, Select } from 'antd'
import { useTranslation } from 'react-i18next'
import PageHeader from 'components/page-header'
import { Button, CardBody, CardHeader, Chip, Card, Checkbox, SelectItem } from '@nextui-org/react'
import { Link, useNavigate } from 'react-router-dom'
import useFetch from 'hooks/use-fetch'
import { useEffect, useState } from 'react'
import ActionButton from './ActionButton'
import moment from 'moment'
import {
   OFFER_MANAGEMENT_LIST,
   OFFER_MANAGEMENT_LIST_URL,
   OFFER_MANAGEMENT_SHOW,
   OFFER_MANAGEMENT_SHOW_URL,
   OFFER_MANAGEMENT_STORE
} from 'api/offer-management'
import SearchInput from 'components/Input'
import TableComponents from 'components/Table'
import { IconEdit, IconFileInvoice, IconFilePlus, IconPdf, IconEye, IconShoppingCart, IconFileOff, IconX, IconInfoCircle, IconChartBar, IconPackage } from '@tabler/icons-react'
import { useDispatch, useSelector } from 'react-redux'
import offer, { setCC, setContactId, setOfferNumber, setProduct, setSelfCreated, setTermTime } from 'features/user/offer'
import { RootState } from 'store'
import { setLoading } from 'features/user/app'
import Preview from './Preview'
import SuccessModal from 'components/Success'
import { MoneyFormat, MoneyFormatTR } from 'hooks/currency'
import { apiUrl } from 'api'
import { motion } from 'framer-motion'
import { IconChevronUp, IconChevronDown, IconSearch, IconFilter, IconBuilding, IconCurrencyLira, IconClock, IconTrendingUp, IconUser, IconCalendar, IconTruck, IconCalculator } from '@tabler/icons-react'
import { Disclosure } from '@headlessui/react'
import { AnimatePresence } from 'framer-motion'
import { Table, TableHeader, TableColumn, TableBody, TableRow, TableCell } from "@nextui-org/react"
import { useQuery } from '@tanstack/react-query'
import { USER_SHOW } from 'api/user'
import CustomSelect from 'components/ui/CustomSelect'
import { setTotalOffers } from 'features/user/user'
import { cn, Textarea } from 'rizzui'
import { Popover, PopoverTrigger, PopoverContent, Divider } from '@nextui-org/react'
import ReactQuill from 'react-quill'


interface Props {
   customer_id?: any
   user_id?: any
}

const { RangePicker } = DatePicker;

interface FilterValues {
   customerName: string;
   companyName: string;
   dateRange: [moment.Moment, moment.Moment] | null;
   minAmount: string;
   maxAmount: string;
   status: string;
   productName: string;
   assignedUserIds?: number;
}

const calculateProductTotal = (products: any[]) => {
   return products?.reduce((total: number, product: any) => {
      const productJson = JSON.parse(product.product_json);
      return total + (product.product_qty * parseFloat(productJson.price));
   }, 0) || 0;
};

const calculateServiceTotal = (products: any[]) => {
   return products?.reduce((total: number, product: any) => {
      return total + product.services?.reduce((serviceTotal: number, service: any) => {
         return serviceTotal + (parseFloat(service.price) * product.product_qty);
      }, 0) || 0;
   }, 0) || 0;
};

const calculateMarkupTotal = (products: any[]) => {
   return products?.reduce((total: number, product: any) => {
      // Ürün kar hesabı
      const productJson = JSON.parse(product.product_json);
      const baseAmount = product.product_qty * parseFloat(productJson.price);
      const productMarkup = product.product_markup_type === 1
         ? baseAmount * (parseFloat(product.product_markup) / 100)
         : parseFloat(product.product_markup);

      // Hizmet kar hesabı
      const serviceMarkup = product.services?.reduce((sTotal: number, service: any) => {
         const serviceAmount = parseFloat(service.price) * product.product_qty;
         return sTotal + (service.service_markup_type === 1
            ? serviceAmount * (parseFloat(service.service_markup) / 100)
            : parseFloat(service.service_markup));
      }, 0) || 0;

      return total + productMarkup + serviceMarkup;
   }, 0) || 0;
};

const FinancialSummary = ({ offer }: { offer: any }) => {
   const total = parseFloat(offer?.total || '0');
   const profit = parseFloat(offer?.profit || '0');

   // Kar oranını product_markup'tan hesapla
   const profitMargin = offer?.products?.reduce((total: number, product: any) => {
      const markup = parseFloat(product.product_markup || '0');
      // Eğer markup type 2 ise sabit tutardır, yüzde hesaplanmalı
      if (product.product_markup_type === '2') {
         const productJson = JSON.parse(product.product_json);
         const basePrice = parseFloat(productJson.price || '0');
         return total + ((markup / basePrice) * 100);
      }
      // Markup type 1 ise direkt yüzde değeridir
      return total + markup;
   }, 0) || 0;

   return (
      <div className="space-y-3">
         <div className="flex items-center justify-between">
            <span className="text-sm text-gray-500">Toplam Tutar:</span>
            <Popover placement="left">
               <PopoverTrigger>
                  <span className="font-semibold text-primary cursor-pointer hover:opacity-70 transition-opacity flex items-center gap-1">
                     {MoneyFormatTR(total)} {offer?.currency || '₺'}
                     <IconInfoCircle size={16} className="text-gray-400" />
                  </span>
               </PopoverTrigger>
               <PopoverContent className="w-96">
                  <div className="p-4">
                     <div className="flex items-center gap-2 mb-4">
                        <div className="p-2 rounded-lg bg-primary/10">
                           <IconCalculator size={20} className="text-primary" />
                        </div>
                        <div className="font-medium">Fiyat Analizi</div>
                     </div>

                     <div className="space-y-4">
                        {offer?.products?.map((product: any, index: number) => {
                           const productJson = JSON.parse(product.product_json);
                           const basePrice = parseFloat(productJson.price || '0');
                           const quantity = parseInt(product.product_qty || '0');
                           const totalServices = product.services?.reduce((sum: number, service: any) =>
                              sum + (service.included_in_total ? parseFloat(service.price || '0') : 0), 0
                           ) || 0;

                           return (
                              <div key={index} className="p-3 bg-gray-50 rounded-lg">
                                 <div className="flex items-start justify-between mb-2">
                                    <div className="flex items-center gap-2">
                                       <div className="w-8 h-8 rounded bg-white flex items-center justify-center">
                                          <IconPackage size={16} className="text-gray-500" />
                                       </div>
                                       <div className="font-medium">{productJson.name}</div>
                                    </div>
                                    <Chip color="primary" variant="flat" size="sm">
                                       {quantity} Adet
                                    </Chip>
                                 </div>

                                 <div className="space-y-2 text-sm">
                                    <div className="flex justify-between p-2 bg-white rounded">
                                       <span>Birim Fiyat</span>
                                       <span className="font-medium">{MoneyFormatTR(basePrice)} {offer?.currency}</span>
                                    </div>

                                    {product.services?.length > 0 && (
                                       <div className="p-2 bg-white rounded">
                                          <div className="flex justify-between mb-2">
                                             <span>Hizmetler</span>
                                             <span className="font-medium">{MoneyFormatTR(totalServices)} {offer?.currency}</span>
                                          </div>
                                          <div className="space-y-1 ml-3 text-xs">
                                             {product.services.map((service: any, sIndex: number) => (
                                                <div key={sIndex} className="flex justify-between items-center">
                                                   <div className="flex items-center gap-1">
                                                      <span>{service.name}</span>
                                                      {service.included_in_total ? (
                                                         <Chip size="sm" variant="flat" color="success">Dahil</Chip>
                                                      ) : (
                                                         <Chip size="sm" variant="flat" color="warning">Hariç</Chip>
                                                      )}
                                                   </div>
                                                   <span>{MoneyFormatTR(service.price)} {offer?.currency}</span>
                                                </div>
                                             ))}
                                          </div>
                                       </div>
                                    )}

                                    <div className="flex justify-between p-2 bg-primary/5 rounded font-medium">
                                       <span>Ürün Toplamı</span>
                                       <span>{MoneyFormatTR((basePrice + totalServices) * quantity)} {offer?.currency}</span>
                                    </div>
                                 </div>
                              </div>
                           );
                        })}

                        <div className="p-3 bg-primary/5 rounded-lg">
                           <div className="grid grid-cols-3 gap-4">
                              <div>
                                 <div className="text-sm text-gray-500">Toplam Tutar</div>
                                 <div className="font-medium text-primary">
                                    {MoneyFormatTR(total)} {offer?.currency}
                                 </div>
                              </div>
                              <div>
                                 <div className="text-sm text-gray-500">Toplam Ürün</div>
                                 <div className="font-medium">
                                    {offer?.products?.length || 0} Kalem
                                 </div>
                              </div>
                              <div>
                                 <div className="text-sm text-gray-500">Toplam Adet</div>
                                 <div className="font-medium">
                                    {offer?.products?.reduce((sum: number, p: any) => sum + parseInt(p.product_qty || '0'), 0)} Adet
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </PopoverContent>
            </Popover>
         </div>

         <div className="flex items-center justify-between">
            <span className="text-sm text-gray-500">Toplam Kar:</span>
            <Popover placement="left">
               <PopoverTrigger>
                  <span className="font-semibold text-green-600 cursor-pointer hover:opacity-70 transition-opacity flex items-center gap-1">
                     {MoneyFormatTR(profit)} {offer?.currency || '₺'}
                     <IconInfoCircle size={16} className="text-gray-400" />
                  </span>
               </PopoverTrigger>
               <PopoverContent className="w-96">
                  <div className="p-4">
                     <div className="flex items-center gap-2 mb-4">
                        <div className="p-2 rounded-lg bg-green-50">
                           <IconChartBar size={20} className="text-green-600" />
                        </div>
                        <div className="font-medium">Kar Analizi</div>
                     </div>

                     <div className="space-y-4">
                        {offer?.products?.map((product: any, index: number) => {
                           const productJson = JSON.parse(product.product_json);
                           const basePrice = parseFloat(productJson.price || '0');
                           const quantity = parseInt(product.product_qty || '0');
                           const markup = parseFloat(product.product_markup || '0');

                           // Kar tutarı hesaplama
                           const markupAmount = product.product_markup_type === '2'
                              ? markup * quantity  // Sabit tutar
                              : basePrice * (markup / 100) * quantity;  // Yüzde bazlı

                           // Kar oranı hesaplama
                           const markupPercentage = product.product_markup_type === '2'
                              ? ((markup / basePrice) * 100).toFixed(2)  // Sabit tutarın yüzdelik karşılığı
                              : markup.toFixed(2);  // Direkt yüzde değeri

                           return (
                              <div key={index} className="p-3 bg-gray-50 rounded-lg">
                                 <div className="flex items-center justify-between mb-2">
                                    <span className="font-medium">{productJson.name}</span>
                                    <Chip
                                       color={markupAmount > 0 ? "success" : "danger"}
                                       variant="flat"
                                       size="sm"
                                    >
                                       {MoneyFormatTR(markupAmount)} {offer?.currency}
                                    </Chip>
                                 </div>

                                 <div className="grid grid-cols-2 gap-2 text-sm">
                                    <div className="p-2 bg-white rounded">
                                       <div className="text-gray-500">Kar Tipi</div>
                                       <div className="font-medium">
                                          {product.product_markup_type === '2' ? 'Sabit Tutar' : 'Yüzde Bazlı'}
                                       </div>
                                    </div>
                                    <div className="p-2 bg-white rounded">
                                       <div className="text-gray-500">Kar Oranı</div>
                                       <div className="font-medium">%{markupPercentage}</div>
                                    </div>
                                 </div>
                              </div>
                           );
                        })}

                        <div className="p-3 bg-green-50 rounded-lg">
                           <div className="grid grid-cols-3 gap-4">
                              <div>
                                 <div className="text-sm text-gray-500">Toplam Kar</div>
                                 <div className="font-medium text-green-600">
                                    {MoneyFormatTR(profit)} {offer?.currency}
                                 </div>
                              </div>
                              <div>
                                 <div className="text-sm text-gray-500">Ortalama Kar Oranı</div>
                                 <div className="font-medium text-green-600">
                                    %{(profitMargin / (offer?.products?.length || 1)).toFixed(2)}
                                 </div>
                              </div>
                              <div>
                                 <div className="text-sm text-gray-500">Toplam Satış</div>
                                 <div className="font-medium">{MoneyFormatTR(total)} {offer?.currency}</div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </PopoverContent>
            </Popover>
         </div>

         <div className="flex items-center justify-between">
            <span className="text-sm text-gray-500">Ortalama Kar Oranı:</span>
            <span className={`font-semibold ${profit > 0 ? 'text-green-600' : 'text-red-600'}`}>
               %{(profitMargin / (offer?.products?.length || 1)).toFixed(2)}
            </span>
         </div>
      </div>
   );
};

const ProductDetails = ({ product }: { product: any }) => {
   // 1. Ürün birim fiyatı
   const productJson = JSON.parse(product.product_json);
   let basePrice = parseFloat(productJson.price || '0');

   // 2. Ürün kar hesabı
   const productMarkup = product.product_markup_type === 1
      ? basePrice * (parseFloat(product.product_markup || '0') / 100)
      : parseFloat(product.product_markup || '0');

   let unitPrice = basePrice + productMarkup;

   // 3. Dahil olan hizmetleri ve karlarını ekle
   product.services?.forEach((service: any) => {
      if (service.included_in_total) {
         // Hizmet fiyatı
         const servicePrice = parseFloat(service.price || '0');

         // Hizmet kar hesabı
         const serviceMarkup = service.service_markup_type === 1
            ? servicePrice * (parseFloat(service.service_markup || '0') / 100)
            : parseFloat(service.service_markup || '0');

         // Sadece included_in_total true ise birim fiyata ekle
         unitPrice += servicePrice + serviceMarkup;
      }
   });

   const currency = product.currency || 'TRY';
   const currencySymbol = currency === 'TRY' ? '₺' : currency === 'USD' ? '$' : '€';
   const quantity = product.stock || product.product_qty;

   return (
      <div>
         <div className="ozellik">
            Miktar: {quantity} Adet
         </div>
         <div className="ozellik">
            Birim Fiyat: {MoneyFormatTR(unitPrice)} {currencySymbol} + KDV
         </div>

         {product.services?.length > 0 && (
            <div className="urun-text mt-5">
               <h5 className="baslik2">Hizmetler</h5>
               {product.services.map((service: any, index: number) => (
                  <div key={index} className="mb-4 p-3 bg-gray-50 rounded-lg">
                     <div className="flex justify-between items-center">
                        <strong className="flex-1 mr-5">{service.name}</strong>
                        {service.price > 0 && (
                           <div className="flex items-center gap-2">
                              {!service.included_in_total && (
                                 <span className="whitespace-nowrap">
                                    {MoneyFormatTR(service.price)} {currencySymbol} + KDV
                                 </span>
                              )}
                              <span className={cn(
                                 "px-2 py-1 rounded text-xs font-medium",
                                 service.included_in_total == true
                                    ? "bg-green-500 text-white"
                                    : "bg-red-500 text-white"
                              )}>
                                 {service.included_in_total == true
                                    ? "Birim Fiyata Dahildir"
                                    : "Birim Fiyata Dahil Değildir"
                                 }
                              </span>
                           </div>
                        )}
                     </div>
                     {/* {service.service_description && (
                        <div className="mt-2 text-gray-600">
                           {service.service_description}
                        </div>
                     )} */}
                  </div>
               ))}
            </div>
         )}
      </div>
   );
};

const calculateUnitPrice = (product: any) => {
   try {
      // 1. Ürün birim fiyatı
      const productJson = JSON.parse(product.product_json);
      const basePrice = parseFloat(productJson.price || '0');

      // 2. Ürün kar hesabı
      const productMarkup = product.product_markup_type === 1
         ? basePrice * (parseFloat(product.product_markup || '0') / 100)  // Yüzdelik
         : parseFloat(product.product_markup || '0');                     // Sabit

      // Ürün birim fiyatı + kar
      let unitPrice = basePrice + productMarkup;

      console.log('Ürün Hesabı:', {
         basePrice,
         productMarkup,
         unitPrice
      });

      // 3. Dahil olan hizmetleri ekle
      if (Array.isArray(product.services)) {
         product.services.forEach((service: any) => {
            if (service.included_in_total === true) {
               // Hizmet birim fiyatı
               const servicePrice = parseFloat(service.price || '0');

               // Hizmet kar hesabı
               const serviceMarkup = service.service_markup_type === 1
                  ? servicePrice * (parseFloat(service.service_markup || '0') / 100)
                  : parseFloat(service.service_markup || '0');

               // Hizmet toplam tutarını birim fiyata ekle
               unitPrice += servicePrice + serviceMarkup;

               console.log('Dahil Edilen Hizmet:', {
                  name: service.name,
                  price: servicePrice,
                  markup: serviceMarkup,
                  total: servicePrice + serviceMarkup,
                  newUnitPrice: unitPrice
               });
            }
         });
      }

      console.log('Son Birim Fiyat:', unitPrice);
      return unitPrice;

   } catch (error) {
      console.error('Birim fiyat hesaplama hatası:', error);
      return 0;
   }
};



export default function OfferList(props: Props) {
   const { t } = useTranslation()
   const pageHeader = {
      title: 'Teklif Yönetimi',
      breadcrumb: [
         {
            href: routes.dashboard,
            name: t('aside.dashboard')
         },
         {
            href: routes.dashboard,
            name: 'Teklif Yönetimi'
         }
      ]
   }

   const [form] = Form.useForm();
   const carts = useSelector((state: RootState) => state.basket.carts)
   const app = useSelector((state: RootState) => state.app)
   const navigate = useNavigate()
   const dispatch = useDispatch()
   const [offerManagementQuery, { data, loading }] = useFetch<any>()
   const [getOfferLastIdQuery, { data: lastIdData, loading: lastIdLoading }] = useFetch<any>()
   const [getOffer, { data: offerData, loading: offerLoading }] = useFetch<any>()
   const [getOffersFromOfferGroupQuery, { data: offersFromOfferGroupData, loading: offersFromOfferGroupLoading }] =
      useFetch<any>()
   const [convertOfferToOrderMutation, { data: convertOfferToOrderData, loading: convertOfferToOrderLoading }] =
      useFetch<any>()

   const [previewOffer, { data: previewOfferData, loading: previewOfferLoading }] = useFetch<any>()

   const [page, setPage] = useState<number>(1)
   const [search, setSearch] = useState<string>('')
   const [offerType, setOfferType] = useState<any>(null)
   const [offerListModal, setOfferListModal] = useState<boolean>(false)
   const [previewItem, setPreviewItem] = useState<any>({})
   const [previewDrawer, setPreviewDrawer] = useState<boolean>(false)
   const [successModal, setSuccessModal] = useState<boolean>(false)

   const [dateRange, setDateRange] = useState<[moment.Moment, moment.Moment] | null>(null);

   const [filterValues, setFilterValues] = useState({
      customerName: '',
      companyName: '',
      dateRange: '',
      minAmount: '',
      maxAmount: '',
      status: '',
      productName: '',
      assignedUserId: undefined
   });

   const [users, { data: usersData, loading: usersLoading }] = useFetch<any>()
   const [mailModal, setMailModal] = useState(false)
   const [mailContent, setMailContent] = useState('')
   const [mailCc, setMailCc] = useState('')
   const [selectedOffer, setSelectedOffer] = useState<any>(null)
   const [sendMailMutation, { data: sendMailData, loading: sendMailLoading }] = useFetch<any>()

   const handleMailModal = (offer: any) => {
      setSelectedOffer(offer)
      setMailCc(offer.cc)
      setMailModal(true)
   }

   const handleSendMail = (id: number) => {
      //@ts-ignore
      sendMailMutation(OFFER_MANAGEMENT_STORE, {
         endPoint: '/offers/send-mail-to-customer',
         body: {
            id: id,
            cc: mailCc,
            customContent: mailContent,
            fromListBox: true
         }
      })
   }

   const onFilter = (values?: any) => {
      if (values) {
         setFilterValues({
            customerName: values.customerName || '',
            companyName: values.companyName || '',
            dateRange: values.dateRange || '',
            minAmount: values.minAmount || '',
            maxAmount: values.maxAmount || '',
            status: values.status || '',
            productName: values.productName || '',
            assignedUserId: values.assignedUserId
         });
      }

      setPage(1);

      const object: {
         page: number;
         [key: string]: any;
      } = {
         page: 1,
         search: search || '',
         customer_name: filterValues.customerName || values?.customerName || '',
         company_name: filterValues.companyName || values?.companyName || '',
         start_date: dateRange?.[0] ? moment(dateRange[0]).format('YYYY-MM-DD') : '',
         end_date: dateRange?.[1] ? moment(dateRange[1]).format('YYYY-MM-DD') : '',
         min_amount: filterValues.minAmount || values?.minAmount || '',
         max_amount: filterValues.maxAmount || values?.maxAmount || '',
         status: filterValues.status || values?.status || '',
         product_name: filterValues.productName || values?.productName || '',
         customer_id: props.customer_id ?? null,
         user_id: props.user_id ?? null
      };

      if (props.user_id) {
         object.user_ids = props.user_id
      }

      form.setFieldsValue(filterValues);

      Object.keys(object).forEach(key => {
         if (object[key] === '' || object[key] === null || object[key] === undefined) {
            delete object[key];
         }
      });

      const result = '?' + new URLSearchParams(object).toString();
      let url = '/offers' + result;

      //@ts-ignore
      offerManagementQuery(OFFER_MANAGEMENT_LIST, {
         endPoint: url
      });
   };

   useEffect(() => {
      onFilter();
   }, [page]);

   useEffect(() => {
      if (data) {
         dispatch(setTotalOffers(data?.data?.total ?? 0))
      }
   }, [data])

   useEffect(() => {
      //@ts-ignore
      users(USER_SHOW, {
         endPoint: '/users-all'
      })
   }, [])

   useEffect(() => {
      if (sendMailData) {
         setMailModal(false)
         message.success('Mail gönderildi')
         handleGetOffer(selectedOffer.id)

         // Teklif grubunu yeniden yükle
         if (selectedOffer.offer_group_id) {
            setOfferListModal(true)
            //@ts-ignore
            getOffersFromOfferGroupQuery(OFFER_MANAGEMENT_LIST, {
               endPoint: '/offers/' + selectedOffer.id + '/edit'
            })
         }
      }
   }, [sendMailData])



   useEffect(() => {
      //@ts-ignore
      onFilter()
   }, [page])

   const handleGetlastId = () => {
      //@ts-ignore
      getOfferLastIdQuery(OFFER_MANAGEMENT_LIST, {
         endPoint: '/offers/getLastOfferId'
      })
   }

   const handleGetOffer = (id: number) => {
      //@ts-ignore
      getOffer(OFFER_MANAGEMENT_SHOW, {
         endPoint: OFFER_MANAGEMENT_SHOW_URL(id)
      })
   }

   const handlePreviewOffer = (item: any) => {
      setPreviewItem(item)
      setPreviewDrawer(true)
   }

   const handleGetOffersFromOfferGroup = (id: number) => {
      //@ts-ignore
      getOffersFromOfferGroupQuery(OFFER_MANAGEMENT_LIST, {
         endPoint: '/offers/' + id + '/edit'
      })
   }

   useEffect(() => {
      if (lastIdData) {
         navigate(routes.offerManagement.add)
      }
   }, [lastIdData])

   useEffect(() => {
      dispatch(
         setLoading(
            (offerLoading as boolean) ||
            (offersFromOfferGroupLoading as boolean) ||
            (convertOfferToOrderLoading as boolean)
         )
      )
   }, [offerLoading, offersFromOfferGroupLoading, convertOfferToOrderLoading])

   useEffect(() => {
      if (offerData) {
         const { data } = offerData
         dispatch(setOfferNumber(data?.no))
         dispatch(setContactId(data?.customer_contact_id))
         dispatch(setTermTime(data?.term_time))
         dispatch(setCC(data?.cc))

         const products = data?.products.map((product: any, index: number) => {
            const description = JSON.parse(product.product_json)
            return {
               id: product.product_id,
               name: description.name,
               price: description.price,
               stock: product.product_qty,
               product_qty: description.stock,
               delivery_date: product.delivery_date,
               commission: product.product_markup,
               commission_type: product.product_markup_type,
               description: product.product_description,
               image: product?.image,
               currency: product?.currency,
               converted_currency: product?.converted_currency,

               services: product.services.map((service: any) => {
                  return {
                     ...service.service,
                     name: service.name,
                     price: service.price,
                     commission: service.service_markup,
                     commission_type: service.service_markup_type,
                     description: service.service_description,
                     included_in_total: service.included_in_total == 1 ? true : false
                  }
               })
            }
         })
         // console.log(products)
         dispatch(setProduct(products))
         navigate(routes.offerManagement.view(data?.offer_group_id))
      }
   }, [offerData])

   useEffect(() => {
      if (offersFromOfferGroupData) {
         setOfferListModal(true)
      }
   }, [offersFromOfferGroupData])

   useEffect(() => {
      if (convertOfferToOrderData) {
         message.success('Siparişe dönüştürüldü!')
         setOfferListModal(false)
         onFilter()
         setSuccessModal(true)
      }
   }, [convertOfferToOrderData])

   useEffect(() => {
      if (previewOfferData) {
         setPreviewDrawer(true)
      }
   }, [previewOfferData])

   const handleConvertToOrder = (offerId: number) => {
      Modal.confirm({
         title: 'Siparişe Dönüştür',
         content: 'Bu teklif siparişe dönüştürülecek. Devam etmek istiyor musunuz?',
         okText: 'Onayla',
         cancelText: 'İptal',
         onOk: () => {
            //@ts-ignore
            convertOfferToOrderMutation(OFFER_MANAGEMENT_STORE, {
               endPoint: '/offers/convert-order',
               body: {
                  offer_id: offerId
               }
            });
         }
      });
   };
   const handleFilter = (values: FilterValues) => {
      setPage(1);
      const filterParams: any = {
         page: 1,
         customer_name: values.customerName,
         company_name: values.companyName,
         start_date: values.dateRange?.[0]?.format('YYYY-MM-DD'),
         end_date: values.dateRange?.[1]?.format('YYYY-MM-DD'),
         min_amount: values.minAmount,
         max_amount: values.maxAmount,
         status: values.status,
         product_name: values.productName,
         customer_id: props.customer_id ?? null,
         //user_id: props.user_id ?? null
      };
      console.log(values)
      if (values.assignedUserIds) {
         filterParams.user_ids = values.assignedUserIds.toString();
      }

      if (props.user_id) {
         filterParams.user_ids = props.user_id
      }

      // Boş değerleri temizle
      Object.keys(filterParams).forEach(key =>
         (filterParams[key] === '' || filterParams[key] === null || filterParams[key] === undefined) && delete filterParams[key]
      );

      const queryString = new URLSearchParams(filterParams).toString();

      //@ts-ignore
      offerManagementQuery(OFFER_MANAGEMENT_LIST, {
         endPoint: `/offers?${queryString}`
      });
   };

   return (
      <>
         <Drawer
            placement="bottom"
            open={previewDrawer}
            height={window.innerHeight}
            onClose={() => {
               setPreviewDrawer(false)
            }}
         >
            <iframe src={apiUrl + '/api/saved-preview-offer?currency=' + previewItem.currency + '&offer_id=' + previewItem.id} width="100%" height="100%"></iframe>
         </Drawer>
         {/* <Drawer
            title="Teklif Önizleme"
            placement="bottom"
            height={window.innerHeight}
            onClose={() => setPreviewDrawer(false)}
            open={previewDrawer}
         >
            {previewItem && <Preview item={previewItem} />}
         </Drawer> */}
         <SuccessModal setOpen={setSuccessModal} open={successModal === true} />
         <Modal
            footer={null}
            title={
               <div className="flex items-center gap-3">
                  <IconPdf />
                  Teklifleri Görüntüle
               </div>
            }
            width={1200}
            onCancel={() => setOfferListModal(false)}
            open={offerListModal}
         >
            {offersFromOfferGroupData && (
               <div className="">
                  {offersFromOfferGroupData?.data?.offers
                     ?.filter((item: any) => item.is_offer)
                     .map((item: any, index: number) => {
                        return (
                           <div
                              key={item.id}
                              className="grid grid-cols-4 gap-2 items-center justify-between w-full cursor-pointer border-b border-gray-100"
                              style={{}}
                           >
                              <div className="flex flex-col space-y-2">
                                 <span className="font-bold">
                                    {offersFromOfferGroupData?.data?.no + '/' + (offersFromOfferGroupData?.data?.offers.length - index)}
                                 </span>
                                 <span>Revize Tarihi : {moment(item.updated_at).format('LLLL')}</span>
                                 <span>
                                    {item?.contact?.customer?.company_name} ({item?.contact?.name})
                                 </span>
                              </div>
                              <div
                                 className="gap-2 flex "
                                 style={{
                                    display: offersFromOfferGroupData?.data?.is_ordered === 0 ? 'flex' : 'none'
                                 }}
                              >
                                 <Button
                                    onClick={() => {
                                       handlePreviewOffer(item)
                                    }}
                                    color="primary"
                                    startContent={<IconPdf />}
                                    variant="shadow"
                                 >
                                    Önizle
                                 </Button>
                                 <Button
                                    onClick={() => {
                                       handleGetOffer(item.id)
                                    }}
                                    color="secondary"
                                    startContent={<IconEdit />}
                                    variant="shadow"
                                 >
                                    Düzenle
                                 </Button>
                                 {offersFromOfferGroupData?.data?.is_ordered === 0 && (
                                    <Button
                                       onClick={() => {
                                          Modal.confirm({
                                             title:
                                                'Siparişe Dönüştür #' +
                                                offersFromOfferGroupData?.data?.no +
                                                '/' +
                                                item.id,
                                             content:
                                                'Bu Teklif Siparişe dönüştürülüp stoktan düşecektir. Devam etmek istiyor musunuz?',
                                             okText: 'Onayla ve dönüştür',
                                             cancelText: 'İptal',
                                             onOk: () => {
                                                //@ts-ignore
                                                convertOfferToOrderMutation(OFFER_MANAGEMENT_STORE, {
                                                   endPoint: '/offers/convert-order',
                                                   body: {
                                                      offer_id: item.id
                                                   }
                                                })
                                             }
                                          })
                                       }}
                                       color="success"
                                       startContent={<IconFileInvoice />}
                                       variant="bordered"
                                    >
                                       Siparişe Dönüştür
                                    </Button>
                                 )}
                              </div>
                              <div>
                                 {item.is_ordered === 1 && (
                                    <Chip variant="bordered" color="success">
                                       Siparişe Dönüştürüldü
                                    </Chip>
                                 )}
                              </div>
                              <div className=''>
                                 {item.mail_send === 1 ? (
                                    <Chip variant="bordered" color="success">
                                       Mail Gönderildi
                                    </Chip>
                                 ) : (
                                    <div className='flex items-center gap-2'>
                                       <Chip variant="bordered" color="danger">
                                          Mail Gönderilmedi
                                       </Chip>
                                       <Button color="primary"
                                          onClick={() => handleMailModal(item)}
                                          variant="light" size="sm">
                                          Mail Gönder
                                       </Button>
                                    </div>
                                 )}
                              </div>
                           </div>
                        )
                     })}
               </div>
            )}
         </Modal>
         <div className={''} style={{ display: props.customer_id ? 'none' : 'block' }}>
            <PageHeader title={pageHeader.title} breadcrumb={pageHeader.breadcrumb}>
               <div className="mt-4 flex items-center gap-3 @lg:mt-0">
                  <Button
                     isLoading={lastIdLoading}
                     onClick={() => {
                        dispatch(setSelfCreated(true))

                        if (carts.length > 0) {
                           let productArray = carts.map((item: any) => {
                              return {
                                 id: item.id,
                                 name: item.name,
                                 model: item.model,
                                 price: item.price,
                                 stock: item.product_qty,
                                 product_qty: item.product_qty,
                                 delivery_date: '',
                                 commission: 0,
                                 commission_type: '1',
                                 description: item.description,
                                 services: [],
                                 isReserve: false,
                                 reserveEndDate: '',
                                 supplier: item.supplier,
                                 image: item.image,
                                 currency: item.currency
                              }
                           })

                           dispatch(setProduct(productArray as any))
                        }

                        navigate(routes.offerManagement.add)
                     }}
                     startContent={<IconFilePlus />}
                     color="primary"
                  >
                     Yeni Teklif Oluştur
                  </Button>
               </div>
            </PageHeader>
         </div>

         <div className="@container">
            <Card className="mb-4 overflow-hidden">
               <Form
                  form={form}
                  onFinish={handleFilter}
                  layout="vertical"
                  initialValues={{
                     customerName: filterValues.customerName,
                     companyName: filterValues.companyName,
                     dateRange: filterValues.dateRange,
                     minAmount: filterValues.minAmount,
                     maxAmount: filterValues.maxAmount,
                     productName: filterValues.productName,
                     assignedUserIds: filterValues.assignedUserId
                  }}
                  className="p-4"
               >
                  <Disclosure defaultOpen>
                     {({ open }) => (
                        <>
                           <Disclosure.Button className="flex w-full justify-between items-center mb-4">
                              <div className="flex items-center gap-2">
                                 <IconFilter className="w-5 h-5 text-primary" />
                                 <span className="font-medium text-gray-700">Filtreleme Seçenekleri</span>
                              </div>
                              <motion.div
                                 animate={{ rotate: open ? 180 : 0 }}
                                 transition={{ duration: 0.2 }}
                              >
                                 <IconChevronDown className="w-5 h-5 text-gray-500" />
                              </motion.div>
                           </Disclosure.Button>

                           <AnimatePresence>
                              {open && (
                                 <motion.div
                                    initial={{ height: 0, opacity: 0 }}
                                    animate={{ height: "auto", opacity: 1 }}
                                    exit={{ height: 0, opacity: 0 }}
                                    transition={{ duration: 0.2 }}
                                    className="overflow-hidden"
                                 >
                                    <div className="space-y-6">
                                       {/* Ana Filtreler */}
                                       <motion.div
                                          initial={{ y: 20, opacity: 0 }}
                                          animate={{ y: 0, opacity: 1 }}
                                          transition={{ delay: 0.1 }}
                                          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4"
                                       >
                                          {!props.customer_id && (
                                             <>

                                                <Form.Item name="companyName" className="mb-0">
                                                   <Input
                                                      prefix={<IconBuilding className="text-gray-400" />}
                                                      placeholder="Firma Adı"
                                                      className="rounded-lg hover:border-primary transition-colors"
                                                   />
                                                </Form.Item>
                                             </>
                                          )}

                                          <Form.Item name="customerName" className="mb-0">
                                             <Input
                                                prefix={<IconUser className="text-gray-400" />}
                                                placeholder="Müşteri Adı"
                                                className="rounded-lg hover:border-primary transition-colors"
                                             />
                                          </Form.Item>

                                          <Form.Item name="dateRange" className="mb-0">
                                             <RangePicker
                                                className="w-full rounded-lg hover:border-primary transition-colors"
                                                placeholder={['Başlangıç Tarihi', 'Bitiş Tarihi']}
                                             />
                                          </Form.Item>

                                          <Form.Item name="productName" className="mb-0">
                                             <Input
                                                prefix={<IconPackage className="text-gray-400" />}
                                                placeholder="Ürün Adı"
                                                className="rounded-lg hover:border-primary transition-colors"
                                             />
                                          </Form.Item>
                                       </motion.div>

                                       {/* Tutar Aralığı ve Butonlar */}


                                       <Form.Item
                                          label="Sorumlu Personel"
                                          name="assignedUserIds"
                                       >
                                          <Select
                                             mode="multiple"
                                             placeholder="Personel seçin"
                                             className="w-full"
                                             options={usersData?.result?.map((user: any) => ({
                                                value: user.id,
                                                label: user.name
                                             })) || []}
                                             onChange={(value: any) => {
                                                setFilterValues({
                                                   ...filterValues,
                                                   assignedUserId: value
                                                })
                                             }}
                                          />
                                       </Form.Item>
                                       <motion.div
                                          initial={{ y: 20, opacity: 0 }}
                                          animate={{ y: 0, opacity: 1 }}
                                          transition={{ delay: 0.2 }}
                                          className="flex flex-wrap items-end gap-4"
                                       >


                                          <motion.div
                                             className="flex gap-2"
                                             initial={{ y: 20, opacity: 0 }}
                                             animate={{ y: 0, opacity: 1 }}
                                             transition={{ delay: 0.3 }}
                                          >
                                             <Button
                                                type="button"
                                                variant="light"
                                                onClick={() => {
                                                   form.resetFields();
                                                   onFilter();
                                                }}
                                                className="group"
                                             >
                                                <motion.span
                                                   whileHover={{ scale: 1.02 }}
                                                   whileTap={{ scale: 0.98 }}
                                                >
                                                   Filtreleri Temizle
                                                </motion.span>
                                             </Button>
                                             <Button
                                                type="submit"
                                                color="primary"
                                                className="group"
                                                startContent={<IconFilter className="w-4 h-4" />}
                                             >
                                                <motion.span
                                                   whileHover={{ scale: 1.02 }}
                                                   whileTap={{ scale: 0.98 }}
                                                >
                                                   Filtrele
                                                </motion.span>
                                             </Button>
                                          </motion.div>
                                       </motion.div>
                                    </div>
                                 </motion.div>
                              )}
                           </AnimatePresence>
                        </>
                     )}
                  </Disclosure>
               </Form>
            </Card>
            <div className="">
               <Table
                  aria-label="Offers table"
                  classNames={{
                     base: "min-h-[400px]",
                     table: "min-w-[800px]",
                  }}
                  selectionMode="none"
               >
                  <TableHeader>
                     <TableColumn>TEKLİF DETAYI</TableColumn>
                     <TableColumn>FİNANSAL BİLGİLER</TableColumn>
                     <TableColumn>İŞLEMLER</TableColumn>
                     <TableColumn>NOT</TableColumn>
                  </TableHeader>
                  <TableBody>
                     {(data?.data?.data || []).map((offer: any) => (
                        <TableRow key={offer.id} className="hover:bg-gray-50/50 transition-colors">
                           <TableCell>
                              <div className="group cursor-pointer hover:bg-gray-50 p-3 rounded-xl transition-all duration-200">
                                 <div className="flex items-start gap-4">
                                    {/* Avatar Section with Animation */}
                                    <div className="relative">
                                       <div className="w-12 h-12 rounded-xl bg-gradient-to-br from-primary/10 to-primary/20 flex items-center justify-center transform group-hover:scale-105 transition-transform duration-200">
                                          <span className="text-lg font-semibold text-primary">
                                             {offer.offer.no?.charAt(0)}
                                          </span>
                                       </div>
                                       {/* Status Indicator */}
                                       <div className="absolute -bottom-1 -right-1 w-4 h-4 rounded-full border-2 border-white bg-green-500 shadow-sm" />
                                    </div>

                                    {/* Content Section */}
                                    <div className="flex-1 space-y-2">
                                       {/* Header */}
                                       <div className="flex items-center justify-between">
                                          <div className="flex items-center gap-2">
                                             <h3 className="text-lg font-semibold text-gray-900 group-hover:text-primary transition-colors">
                                                Teklif #{offer.offer.no}
                                             </h3>
                                             <div className="flex items-center gap-1 text-xs text-gray-500">
                                                <IconClock size={14} className="text-gray-400" />
                                                <span>{moment(offer?.created_at).fromNow()}</span>
                                             </div>
                                          </div>
                                       </div>

                                       {/* Customer Info */}
                                       <div className="space-y-1">
                                          <Button
                                             color="primary"
                                             variant="light"
                                             size="sm"
                                             onClick={(e) => {
                                                e.stopPropagation();
                                                navigate('/dashboard/customer-management/profile/' + offer.offer.contact.customer_id);
                                             }}
                                             className="group-hover:bg-primary/10 -ml-2 px-2"
                                             startContent={<IconUser size={16} />}
                                          >
                                             {offer.offer?.contact?.name}
                                          </Button>

                                          <div className="flex items-center gap-2">
                                             <IconBuilding size={14} className="text-gray-400" />
                                             <p className="text-sm text-gray-600">
                                                {offer.offer?.contact?.customer?.company_name}
                                             </p>
                                          </div>
                                       </div>

                                       {/* Timeline Info */}
                                       <div className="flex items-center gap-4 mt-2">
                                          <div className="flex items-center gap-2">
                                             <div className="w-2 h-2 rounded-full bg-primary animate-pulse" />
                                             <span className="text-xs text-gray-600">
                                                Son güncelleme: {moment(offer?.updated_at).fromNow()}
                                             </span>
                                          </div>
                                          <div className="h-4 w-px bg-gray-200" />
                                          <div className="flex items-center gap-2">
                                             <IconCalendar size={14} className="text-gray-400" />
                                             <span className="text-xs text-gray-600">
                                                {moment(offer?.created_at).format('LL')}
                                             </span>
                                          </div>
                                       </div>

                                       {/* Hover Indicator */}
                                       <div className="h-0.5 w-0 bg-primary transition-all duration-300 group-hover:w-full" />
                                    </div>
                                 </div>
                              </div>
                           </TableCell>
                           <TableCell>
                              <FinancialSummary offer={offer.offer} />
                           </TableCell>
                           <TableCell>
                              <div className="space-y-4">
                                 {/* Buttons in horizontal layout */}
                                 <div className="flex gap-2">
                                    <Button
                                       size="sm"
                                       color="primary"
                                       variant="flat"
                                       onClick={() => handlePreviewOffer(offer.offer)}
                                       startContent={<IconEye size={16} />}
                                    >
                                       Önizle
                                    </Button>
                                    <Button
                                       size="sm"
                                       color="secondary"
                                       variant="flat"
                                       onClick={() => handleGetOffersFromOfferGroup(offer.offer_group.id)}
                                       startContent={<IconEdit size={16} />}
                                    >
                                       Düzenle
                                    </Button>
                                    {!offer.offer.is_ordered && (
                                       <Button
                                          size="sm"
                                          color="success"
                                          variant="flat"
                                          onClick={() => handleConvertToOrder(offer.offer.id)}
                                          startContent={<IconShoppingCart size={16} />}
                                       >
                                          Siparişe Dönüştür
                                       </Button>
                                    )}
                                 </div>

                                 {/* Info section below buttons */}
                                 <div className="p-2 bg-gray-50 rounded-lg">
                                    <div className="text-xs text-gray-500 space-y-1">
                                       <div className="flex items-center gap-1">
                                          <IconClock size={12} />
                                          <span>Son güncelleme: {moment(offer.updated_at).fromNow()}</span>
                                       </div>
                                       <div className="flex items-center gap-1">
                                          <IconUser size={12} />
                                          <span>Oluşturan: {offer?.offer?.user?.name || 'Sistem'}</span>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </TableCell>
                           <TableCell>
                              <div className="text-sm text-gray-500">
                                 {offer?.offer?.note}
                              </div>
                           </TableCell>
                        </TableRow>
                     ))}
                  </TableBody>
               </Table>

               {/* Empty State */}
               {data?.data?.data?.length === 0 && (
                  <div className="bg-white rounded-xl shadow-sm p-8 text-center">
                     <div className="w-16 h-16 mx-auto mb-4 rounded-full bg-gray-50 flex items-center justify-center">
                        <IconFileOff className="w-8 h-8 text-gray-400" />
                     </div>
                     <h3 className="text-lg font-semibold text-gray-800 mb-2">
                        Henüz Teklif Bulunmuyor
                     </h3>
                     <p className="text-gray-500 mb-6">
                        Yeni bir teklif oluşturarak müşterilerinize hizmet vermeye başlayın.
                     </p>
                     <Button
                        color="primary"
                        startContent={<IconFilePlus />}
                        onClick={() => {
                           dispatch(setSelfCreated(true));
                           navigate(routes.offerManagement.add);
                        }}
                     >
                        Yeni Teklif Oluştur
                     </Button>
                  </div>
               )}
            </div>
         </div>

         {/* Mail Modal */}
         <Modal
            title="Mail Gönder"
            open={mailModal}
            onCancel={() => setMailModal(false)}
            footer={[
               <Button
                  key="cancel"
                  color="danger"
                  variant="light"
                  onClick={() => setMailModal(false)}
               >
                  İptal
               </Button>,
               <Button
                  key="submit"
                  color="primary"
                  isLoading={sendMailLoading}
                  onClick={() => handleSendMail(selectedOffer.id)}
               >
                  Gönder
               </Button>
            ]}
         >
            <div className="space-y-4">
               <p className="text-sm text-gray-600">
                  Birden fazla mail adresi eklemek için ; (noktalı virgül) kullanabilirsiniz.
                  <br />
                  Örnek: mail1@domain.com;mail2@domain.com
               </p>
               <Textarea
                  label="CC"
                  value={mailCc}
                  onChange={(e) => setMailCc(e.target.value)}
                  placeholder="Mail adreslerini giriniz"
                  rows={4}
               />
               <div>
                  <label className="block text-sm mb-2">Mail İçeriği</label>
                  <ReactQuill
                     theme="snow"
                     value={mailContent}
                     onChange={setMailContent}
                     style={{ height: 200, marginBottom: 50 }}
                  />
               </div>
            </div>
         </Modal>
      </>
   )
}

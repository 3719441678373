import Header from 'components/app/Header'
import Sidebar from 'components/app/Sidebar'
import EcommerceDashboard from 'pages/admin/home/Dashboard'

import React, { Suspense, useEffect } from 'react'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'

import GlobalDrawer from 'components/shared/drawer-views/container'
import TradingViewWidget from './TradingViewWidget'
import axios from 'axios'
import UsersPage from 'pages/admin/users/Route'
import { useDispatch, useSelector } from 'react-redux'
import type { RootState } from 'store'
import { setShouldUpdateProfile, setUser } from 'features/user/user'
import { routes } from 'config/routes'
import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, useDisclosure } from '@nextui-org/react'
import {
   Divider,
   Form,
   message,
   Input,
   Spin,
   ConfigProvider,
   List,
   Avatar,
   Modal as ModalAntd,
   notification
} from 'antd'
import pusherJs from 'pusher-js'
import { useTranslation } from 'react-i18next'
import useFetch from 'hooks/use-fetch'
import { USER_UPDATE } from 'api/user'
import OffersManagementPage from 'pages/admin/offers-management/page'
import CustomerManagementPage from 'pages/admin/customer-management/page'
import SupplierManagementPage from 'pages/admin/supplier-management/page'
import { Drawer, EmptyProductBoxIcon, Loader, Text, Title } from 'rizzui'
import { motion } from 'framer-motion'
import OrdersPage from 'pages/admin/orders/Page'
import RequestsPage from 'pages/admin/requests/Page'
import Reports from 'pages/admin/home/Reports'
import locale from 'antd/locale/tr_TR'
import 'dayjs/locale/tr'
import dayjs from 'dayjs'
import Products from 'pages/admin/Products'
import { removeCart } from 'features/user/basket'
import { IconInbox, IconQuestionMark } from '@tabler/icons-react'
import { setProduct } from 'features/user/offer'
import MailBox from 'pages/admin/mailbox'
import ListMail from 'pages/admin/requests/ListMail'
import MailList from 'pages/admin/mailbox/List'
import MyRequest from 'pages/admin/requests/MyRequest'
import CustomerOffer from 'pages/admin/customer-management/CustomerOffer'
import OfferCustomerList from 'pages/admin/offers-management/CustomerList'
import CustomerProfile from 'pages/admin/customer-management/CustomerProfile'
import RequestList from 'pages/admin/requests/List'
dayjs.locale('tr')

const AppLayout = () => {
   const [messageApi, contextHolder] = message.useMessage()

   const carts = useSelector((state: RootState) => state.basket.carts)
   const offer = useSelector((state: RootState) => state.offer)
   const navigate = useNavigate()
   const key = 'updatable'
   const { t } = useTranslation()
   const user = useSelector((state: RootState) => state.user)
   const app = useSelector((state: RootState) => state.app)
   const [row, setRow] = React.useState<any>({}) // row selection
   const [updateProfile, { data, loading, error }] = useFetch<any>()

   const dispatch = useDispatch()
   useEffect(() => {
      if (!localStorage.getItem('token')) {
         window.location.href = routes.auth.login
      } else {
         dispatch(setUser(JSON.parse(localStorage.getItem('user') as any)))
      }
   }, [])

   const mp3Ref = React.createRef<any>()

   useEffect(() => {
      const pusher = new pusherJs('a0060fff4ba8970b81b5', {
         cluster: 'eu'
      })
      pusher.connection.bind('connected', function () { })
      pusher.connection.bind('error', function (err: any) {
         if (err.data.code === 4004) {
            console.log('Over limit!')
         }
      })

      const channel = pusher.subscribe('notification-event')

      channel.bind('notification-event', function (data: any) {
         console.log(data)
         if (data?.message?.user_id && data?.message?.user_id == JSON.parse(localStorage.getItem('user') as any).id) {
            notification.info({
               message: data?.message?.title,
               description: data?.message?.description
            })

            const audio = new Audio(require('assets/mp3/notification.mp3'))
            audio.play()
         }
      })

      channel.bind('stock-event', function (data: any) {
         notification.info({
            message: data?.message?.title,
            description: data?.message?.description
         })

         const audio = new Audio(require('assets/mp3/notification.mp3'))
         audio.play()
      })
   }, [])

   const [openSidebar, setOpenSidebar] = React.useState(false)
   const [openDrawer, setOpenDrawer] = React.useState(false)

   const { isOpen, onOpen, onClose } = useDisclosure()

   useEffect(() => {
      axios.interceptors.response.use(
         (response: any) => {
            switch (response.data.status) {
               case 400:
                  onOpen()
                  return

               default:
            }

            return response
         },
         function (error) {
            if (error.response.status === 400) {
               if (error.response.data.action == 'profile') {
                  onOpen()
               }
            }
            return Promise.reject(error)
         }
      )
   }, [])

   return (
      <ConfigProvider locale={locale}>
         <audio ref={mp3Ref} src={'/mp3/notification.mp3'} />

         <main className="flex min-h-screen flex-grow">
            {app.loading && (
               <div className="fixed w-full h-full bg-white/50 top-0 left-0 z-[19999] flex items-center justify-center">
                  <Loader size="xl" />
               </div>
            )}
            <Sidebar className={`fixed ${openSidebar ? 'block' : 'hidden'} dark:bg-gray-50 xl:block`} />

            <div
               className="flex w-full flex-col xl:ms-[270px] xl:w-[calc(100%-270px)] 2xl:ms-72 2xl:w-[calc(100%-288px)]"
               onClick={() => setOpenSidebar(false)}
            >
               <Header />
               <div className="flex flex-grow flex-col px-4 pb-6 pt-2 @container md:px-5 lg:px-6 lg:pb-8 3xl:px-8 3xl:pt-4 4xl:px-10 4xl:pb-9">
                  <Suspense fallback={<div>Loading...</div>}>
                     <Routes>
                        <Route path="/index" element={<EcommerceDashboard />} />
                        <Route path="/my-requests" element={<RequestList />} />
                        <Route path="/customer-management/:id/offers" element={<OfferCustomerList />} />
                        <Route path="/my-mail-list" element={<MailList />} />
                        <Route path="/mailbox" element={<MailBox />} />
                        <Route path="/mailbox/me" element={<ListMail />} />
                        <Route path="/reports" element={<Reports />} />
                        <Route path={`/orders`} element={<OrdersPage />} />
                        <Route path={`/offer-management/*`} element={<OffersManagementPage />} />
                        <Route path={`/requests/*`} element={<RequestsPage />} />

                        <Route path={`/customer-management/*`} element={<CustomerManagementPage />} />
                        <Route path={`/supplier-management/*`} element={<SupplierManagementPage />} />
                        <Route path={`/products`} element={<Products />} />
                        <Route path={`/product-management/*`} element={<OffersManagementPage />} />
                        <Route path={`/service-management/*`} element={<OffersManagementPage />} />
                        <Route path="/users/*" element={<UsersPage />} />
                        <Route path="/customer-management/profile/:id" element={<CustomerProfile />} />

                        {/* <Route path="*" element={<Navigate to="/dashboard/index" />} /> */}

                        {/* Redirecting unknown url to 404 page */}
                        {/* <Route path="*" element={<Page404 />} /> */}
                     </Routes>
                  </Suspense>
               </div>
            </div>
            <GlobalDrawer />
         </main>
      </ConfigProvider>
   )
}

export default AppLayout

'use client'

import { Button } from '@nextui-org/react';
import { IconEye, IconEyeOff } from '@tabler/icons-react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Input, Password } from 'rizzui';
import { login } from 'features/user/auth';

interface SignInFormProps {
   mail?: string;
   logo?: string;
}

export default function SignInForm({ mail, logo }: SignInFormProps) {
   const dispatch = useDispatch();
   const [loading, setLoading] = useState(false);
   const [isVisible, setIsVisible] = useState(false);
   const [formData, setFormData] = useState({
      email: '',
      password: ''
   });

   const handleSubmit = async (e: React.FormEvent) => {
      e.preventDefault();
      setLoading(true);
      try {
         //@ts-ignore
         await dispatch(login(formData));
      } finally {
         setLoading(false);
      }
   };

   return (
      <form onSubmit={handleSubmit} className="space-y-6">
         <Input
            type="email"
            label="E-posta"
            placeholder="E-posta adresinizi girin"
            value={formData.email}
            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
            size="lg"
         />

         <Password

            label="Şifre"
            placeholder="Şifrenizi girin"
            value={formData.password}
            onChange={(e) => setFormData({ ...formData, password: e.target.value })}
            size="lg"

         />

         <Button
            type="submit"
            className="w-full bg-gradient-to-r from-primary to-primary/90 text-white h-12 text-base"
            isLoading={loading}
         >
            {loading ? 'Giriş yapılıyor...' : 'Giriş Yap'}
         </Button>
      </form>
   );
}

import { Button, Card, CardBody, CardHeader, Divider, Tooltip, Chip, Avatar } from '@nextui-org/react'
import { IconCheck, IconHistory, IconReload, IconAlertCircle, IconBell, IconTruck, IconCalendarTime, IconMail, IconShoppingCart, IconFileImport, IconUserCircle } from '@tabler/icons-react'
import { Drawer, Form, Modal, Select, message, Timeline } from 'antd'
import React, { useEffect, useState } from 'react'
import { Alert, Input, Switch, Textarea } from 'rizzui'
import { orderStatus } from 'utils/get-order-status'
import useFetch from 'hooks/use-fetch'
import { USER_LIST } from 'api/user'
import { OFFER_MANAGEMENT_SHOW, OFFER_MANAGEMENT_STORE } from 'api/offer-management'
import moment from 'moment'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface Props {
    offer_id: string
    offer?: any
}

function OrderStatusModalButton({ offer_id, offer }: Props) {
    // States
    const [openFormDrawer, setOpenFormDrawer] = useState(false)
    const [dateDrawer, setDateDrawer] = useState(false)
    const [historyDrawer, setHistoryDrawer] = useState(false)
    const [selectUserModal, setSelectUserModal] = useState(false)
    const [notifyCustomer, setNotifyCustomer] = useState(false)
    const [products, setProducts] = useState<any>(offer?.products || [])
    const [selectedUser, setSelectedUser] = useState<any>(null)
    const [orderStatusSelect, setOrderSelectStatus] = useState<string>(
        orderStatus.find((item) => item.key === offer?.offer_status)?.key || ''
    )

    // Forms
    const [form] = Form.useForm()
    const [formSaveDeliveredDate] = Form.useForm()

    // API Hooks
    const [usersQuery, { data: usersData, loading: usersLoading }] = useFetch<any>()
    const [updateStatusMutation, { data: updateStatusData, loading: updateStatusLoading }] = useFetch<any>()
    const [updateDeliveredDateMutation, { data: updateDeliveredDateData, loading: updateDeliveredDateLoading }] = useFetch<any>()
    const [historyQuery, { data: historyData, loading: historyLoading }] = useFetch<any>()

    const windowWidth = window.innerWidth

    // Effects
    useEffect(() => {
        //@ts-ignore
        usersQuery(USER_LIST, { endPoint: "/users-all" })
    }, [])

    useEffect(() => {
        if (updateStatusData?.status) {
            message.success('Sipariş durumu başarıyla güncellendi')
            setOpenFormDrawer(false)
        }
    }, [updateStatusData])

    useEffect(() => {
        if (updateDeliveredDateData?.status) {
            message.success('Teslim edildi olarak işaretlendi')
            setDateDrawer(false)
        }
    }, [updateDeliveredDateData])

    useEffect(() => {
        formSaveDeliveredDate.setFieldsValue({ product_update: products })
    }, [products])

    useEffect(() => {
        if (historyDrawer) {
            //@ts-ignore
            historyQuery(OFFER_MANAGEMENT_SHOW, {
                endPoint: `/offers/${offer_id}/history`
            })
        }
    }, [historyDrawer])

    // Handlers
    const handleUpdateDeliveredDate = (product_id: string, type: string, value: string) => {
        setProducts((prev: any) => prev.map((item: any) =>
            item.id === product_id ? { ...item, [type]: value } : item
        ))
    }

    const handleUpdateStatus = (values: any) => {
        const payload = {
            status: values.status,
            status_text: values.status_text,
            email_text: values.email_text,
            notify: notifyCustomer,
            offer_id: offer_id,
            ...(values.status === "PRODUCTION_STAGE" && selectedUser?.id && { logistics_id: selectedUser.id })
        }

        if (values.status === "PRODUCTION_STAGE" && !selectedUser?.id) {
            return message.error('Lütfen bir kullanıcı seçin')
        }
        //@ts-ignore
        updateStatusMutation(OFFER_MANAGEMENT_STORE, {
            endPoint: `/offers/handleUpdateOrderStatus`,
            body: payload
        })
    }

    const handleSaveDeliveredDate = () => {
        const formData = new FormData()

        products.forEach((item: any) => {
            formData.append(`products[${item.id}][id]`, item.id)
            formData.append(`products[${item.id}][delivered_date]`, item.delivered_date)
            formData.append(`products[${item.id}][is_delivered]`, item.is_delivered)
        })

        formData.append('offer_id', offer_id)

        //@ts-ignore
        updateDeliveredDateMutation(OFFER_MANAGEMENT_STORE, {
            endPoint: `/offers/updateDelivered`,
            body: formData
        })
    }

    const getIcon = (action: string) => {
        switch (action) {
            case 'Teklif maili gönderildi':
                return <IconMail size={16} className="text-blue-500" />
            case 'Siparişe dönüştürüldü':
                return <IconShoppingCart size={16} className="text-green-500" />
            case 'Talep teklife dönüştürüldü':
                return <IconFileImport size={16} className="text-orange-500" />
            default:
                return <IconCalendarTime size={16} className="text-primary" />
        }
    }

    return (
        <div className="space-y-4">
            {/* Status Update Drawer */}
            <div className="space-y-6">
                {/* History Button */}
                <Button
                    onClick={() => setHistoryDrawer(true)}
                    color="secondary"
                    startContent={<IconHistory />}
                    className="w-full"
                >
                    Sipariş Aktiviteleri Görüntüle
                </Button>

                {/* Status Update Form */}
                <Card className="p-4">
                    <Form layout="vertical" form={form} onFinish={handleUpdateStatus}>
                        <Form.Item
                            label="Sipariş Durumu"
                            name="status"
                            required
                            initialValue={orderStatusSelect}
                        >
                            <Select
                                onChange={(value) => {
                                    setOrderSelectStatus(value)
                                    if (value === "PRODUCTION_STAGE") {
                                        setSelectUserModal(true)
                                    }
                                }}
                                options={orderStatus.map((item) => ({
                                    label: item.label,
                                    value: item.key
                                }))}
                            />
                        </Form.Item>

                        {selectedUser && (
                            <Alert color="info" className="mb-4">
                                <div className="flex justify-between items-center">
                                    <div className="flex items-center gap-2">
                                        <IconTruck size={18} />
                                        <span>Lojistik Sorumlusu:</span>
                                        <span className="font-semibold">{selectedUser.name}</span>
                                    </div>
                                    <Button
                                        size="sm"
                                        color="secondary"
                                        onClick={() => setSelectUserModal(true)}
                                    >
                                        Değiştir
                                    </Button>
                                </div>
                            </Alert>
                        )}

                        <Form.Item
                            name="status_text"
                            label="Durum Güncellemesi"
                        >
                            <Textarea placeholder="Durum güncellemesi için açıklama girin..." />
                        </Form.Item>

                        <Form.Item
                            style={{
                                display: orderStatusSelect === orderStatus.find(s => s.key === 'SHIPPING')?.key ||
                                    orderStatusSelect === orderStatus.find(s => s.key === 'CANCELLED')?.key
                                    ? 'block'
                                    : 'none'
                            }}
                        >
                            <Switch
                                size="lg"
                                checked={notifyCustomer}
                                onChange={(e) => setNotifyCustomer(e.target.checked)}
                                label={
                                    <div className="flex items-center gap-2">
                                        <IconBell size={18} />
                                        <span>
                                            {orderStatusSelect === orderStatus.find(s => s.key === 'SHIPPING')?.key
                                                ? 'Teslim Edildi Bildirimi Gönder'
                                                : orderStatusSelect === orderStatus.find(s => s.key === 'CANCELLED')?.key
                                                    ? 'İptal Bildirimi Gönder'
                                                    : 'Müşteriye Bildirim Gönder'
                                            }
                                        </span>
                                    </div>
                                }
                            />
                        </Form.Item>

                        <Form.Item
                            style={{
                                marginBottom: '12px',
                                display: notifyCustomer &&
                                    (orderStatusSelect === orderStatus.find(s => s.key === 'SHIPPING')?.key ||
                                        orderStatusSelect === orderStatus.find(s => s.key === 'CANCELLED')?.key)
                                    ? 'block'
                                    : 'none'
                            }}
                            name="email_text"
                            label="Açıklama"
                        >
                            <ReactQuill
                                theme="snow"
                                placeholder="Müşteriye gönderilecek bildirim mesajını girin..."
                                modules={{
                                    toolbar: [
                                        ['bold', 'italic', 'underline'],
                                        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                        ['clean']
                                    ]
                                }}
                                formats={['bold', 'italic', 'underline', 'list', 'bullet']}
                                className="h-[200px] mb-12"
                            />
                        </Form.Item>

                        <div className="flex items-center justify-between mt-4">
                            <Button
                                type="submit"
                                color="primary"
                                isLoading={updateStatusLoading}
                                startContent={<IconCheck />}
                            >
                                Güncelle
                            </Button>
                        </div>
                    </Form>
                </Card>
            </div>

            {/* Main Action Buttons */}
            <div className="flex gap-2">
                {offer?.is_delivered === 0 && (
                    <Tooltip content="Sipariş Durumunu Güncelle">
                        <Button
                            onClick={() => setOpenFormDrawer(true)}
                            color="secondary"
                            startContent={<IconReload />}
                        >
                            Durum Güncelle
                        </Button>
                    </Tooltip>
                )}


            </div>

            {/* User Selection Modal */}
            <Modal
                title="Lojistik Sorumlusu Seç"
                open={selectUserModal}
                onCancel={() => setSelectUserModal(false)}
                onOk={() => {
                    if (selectedUser) {
                        setSelectUserModal(false)
                    } else {
                        message.error('Lütfen bir kullanıcı seçin')
                    }
                }}
                okText="Seç ve Devam Et"
                cancelText="İptal"
            >
                <div className="max-h-[300px] overflow-y-auto">
                    {usersData?.result
                        ?.filter((user: any) => user.role === 'logistics')
                        ?.map((user: any) => (
                            <div
                                key={user.id}
                                onClick={() => setSelectedUser(user)}
                                className={`
                                    flex items-center gap-3 p-3 border-b cursor-pointer
                                    hover:bg-gray-50 transition-all duration-200
                                    ${selectedUser?.id === user.id ? 'bg-primary/5 border-primary/20' : ''}
                                `}
                            >
                                {user.avatar ? (
                                    <Avatar src={user.avatar} size="sm" />
                                ) : (
                                    <div className="w-8 h-8 rounded-full bg-primary/10 flex items-center justify-center">
                                        <IconUserCircle size={16} className="text-primary" />
                                    </div>
                                )}
                                <div className="flex-1">
                                    <span className="font-medium text-gray-900">{user.name}</span>
                                    <p className="text-xs text-gray-500">{user.email}</p>
                                </div>
                                {selectedUser?.id === user.id && (
                                    <IconCheck size={18} className="text-primary" />
                                )}
                            </div>
                        ))}

                    {/* Boş Durum */}
                    {(!usersData?.result || usersData.result.filter((user: any) => user.role === 'logistics').length === 0) && (
                        <div className="text-center py-8">
                            <IconTruck size={40} className="text-gray-400 mx-auto mb-2" />
                            <p className="text-gray-500">Lojistik personeli bulunamadı</p>
                        </div>
                    )}
                </div>
            </Modal>

            {/* History Drawer */}
            <Drawer
                size="large"
                title={
                    <div className="flex items-center gap-2">
                        <IconHistory className="text-primary" size={20} />
                        <span className="font-medium">Sipariş Aktiviteleri</span>
                    </div>
                }
                placement="right"
                width={windowWidth > 768 ? 500 : windowWidth - 32}
                open={historyDrawer}
                onClose={() => setHistoryDrawer(false)}
            >
                {historyLoading ? (
                    <div className="flex justify-center p-4">
                        <span>Yükleniyor...</span>
                    </div>
                ) : (
                    <div className="space-y-6">
                        <Timeline
                            items={historyData?.data?.map((item: any) => ({
                                dot: getIcon(item.action),
                                children: (
                                    <div className="group hover:bg-gray-50 p-3 rounded-lg transition-all">
                                        {/* Action Header */}
                                        <div className="flex items-start justify-between mb-2">
                                            <div>
                                                <span className="font-medium text-gray-900">
                                                    {item.action}
                                                </span>
                                            </div>
                                            <Chip
                                                size="sm"
                                                variant="flat"
                                                className="text-xs"
                                            >
                                                {moment(item.created_at).format('HH:mm')}
                                            </Chip>
                                        </div>

                                        {/* User Info */}
                                        <div className="flex items-center gap-2 mt-2">
                                            <div className="flex items-center gap-2">
                                                {item.user?.avatar ? (
                                                    <Avatar
                                                        src={item.user.avatar}
                                                        size="sm"
                                                        className="w-6 h-6"
                                                    />
                                                ) : (
                                                    <div className="w-6 h-6 rounded-full bg-primary/10 flex items-center justify-center">
                                                        <IconUserCircle size={14} className="text-primary" />
                                                    </div>
                                                )}
                                                <span className="text-sm text-gray-600">
                                                    {item.user?.name}
                                                </span>
                                            </div>
                                        </div>

                                        {/* Timestamp */}
                                        <div className="mt-2">
                                            <span className="text-xs text-gray-400">
                                                {moment(item.created_at).format('DD MMMM YYYY')}
                                            </span>
                                        </div>

                                        {/* Divider */}
                                        <div className="h-px w-full bg-gray-100 mt-3 group-hover:bg-gray-200 transition-colors" />
                                    </div>
                                )
                            }))}
                        />

                        {/* Empty State */}
                        {(!historyData?.data || historyData.data.length === 0) && (
                            <div className="text-center py-8">
                                <IconHistory size={40} className="text-gray-400 mx-auto mb-2" />
                                <p className="text-gray-500">Henüz aktivite bulunmuyor</p>
                            </div>
                        )}
                    </div>
                )}
            </Drawer>

            {/* Delivered Date Drawer */}
            <Drawer
                size="large"
                title={
                    <div className="flex items-center gap-2">
                        <IconCalendarTime className="text-primary" size={20} />
                        <span className="font-medium">Teslim Edildi Olarak İşaretle</span>
                    </div>
                }
                placement="right"
                width={windowWidth > 768 ? 500 : windowWidth - 32}
                open={dateDrawer}
                onClose={() => setDateDrawer(false)}
            >
                <Form layout="vertical" form={formSaveDeliveredDate}>
                    <div className="space-y-4">
                        {products.map((product: any) => (
                            <Card key={product.id} className="p-4">
                                <div className="space-y-4">
                                    <div className="font-medium">{product.name}</div>
                                    <Input
                                        type="date"
                                        label="Teslim Tarihi"
                                        value={product.delivered_date}
                                        onChange={(e) => handleUpdateDeliveredDate(product.id, 'delivered_date', e.target.value)}
                                    />
                                    <Switch
                                        size="lg"
                                        checked={product.is_delivered === 1}
                                        onChange={(e) => handleUpdateDeliveredDate(product.id, 'is_delivered', e.target.checked ? '1' : '0')}
                                        label="Teslim Edildi"
                                    />
                                </div>
                            </Card>
                        ))}
                    </div>

                    <div className="mt-4">
                        <Button
                            onClick={handleSaveDeliveredDate}
                            color="primary"
                            className="w-full"
                            isLoading={updateDeliveredDateLoading}
                            startContent={<IconCheck />}
                        >
                            Kaydet
                        </Button>
                    </div>
                </Form>
            </Drawer>
        </div>
    )
}

export default OrderStatusModalButton

import React, { useEffect } from 'react'
import { Card, Drawer, Form, message, Popconfirm } from 'antd'
import { Button } from '@nextui-org/react'
import { IconTrash } from '@tabler/icons-react'
import { useNavigate, useParams } from 'react-router-dom'

import UserForm from './Form'
import useFetch from 'hooks/use-fetch'
import { USER_SHOW, USER_UPDATE } from 'api/user'
import { useTranslation } from 'react-i18next'

export const EditUser = () => {
   const { id } = useParams<any>()
   const { t } = useTranslation()
   const [open, setOpen] = React.useState(true)
   const navigate = useNavigate()
   const onDelete = () => { }

   const [form] = Form.useForm()

   const [updateUserMutation, { data: updateUserData }] = useFetch<any>()

   const [userQuery, { data: userData }] = useFetch<any>()
   const [deleteUserMutation, { data: deleteUserData, loading: deleteWorkerLoading }] = useFetch<any>()

   useEffect(() => {
      //@ts-ignore
      userQuery(USER_SHOW, {
         endPoint: '/users/' + id
      })
   }, [])

   const onFinish = (values: any) => {
      //@ts-ignore
      updateUserMutation(USER_UPDATE, {
         endPoint: '/users/' + id,
         body: values
      })
      // id: userData?.getUser?.id,
      // name: values.name,
      // email: values.email,
      // balance: values.balance,
      // password: values.password,
   }

   useEffect(() => {
      if (updateUserData) {
         message.success({
            content: t('messages.update_success')
         })
         setOpen(false)
         navigate(-1)
      }
   }, [updateUserData])

   useEffect(() => {
      if (userData) {
         form.setFieldsValue({
            name: userData?.user?.name,
            email: userData?.user?.email,
            balance: userData?.user?.balance,
            role: userData?.user?.role
         })
      }
   }, [userData])

   useEffect(() => {
      if (deleteUserData) {
         message.success({
            content: t('messages.delete_success')
         })
         setOpen(false)
         navigate(-1)
      }
   }, [deleteUserData])

   useEffect(() => {
      if (deleteWorkerLoading) {
         message.loading({
            content: t('messages.delete_loading')
         })
      }
   }, [])

   return (
      <>
         <Drawer
            extra={
               <div className="flex gap-2">
                  <Button
                     onClick={() => {
                        setOpen(false)
                        navigate(-1)
                     }}
                     size="lg"
                     variant="faded"
                     className="w-20"
                     color="danger"
                  >
                     {t('buttons.cancel')}
                  </Button>
                  {/* <Popconfirm
                     title={t('messages.delete_confirm')}
                     okText="Evet"
                     okButtonProps={{ danger: true }}
                     cancelText={t('buttons.cancel')}
                     onConfirm={onDelete}
                  >
                     <Button size="lg" variant="faded" className="w-20" color="danger" isLoading={deleteWorkerLoading}>
                        <IconTrash size={20} />
                     </Button>
                  </Popconfirm> */}
                  <Button
                     onPress={() => form.submit()}
                     size="lg"
                     variant="shadow"
                     className="w-20"
                     color="primary"
                     isLoading={false}
                  >
                     {t('buttons.save')}
                  </Button>
               </div>
            }
            footer={null}
            closable
            onClose={() => {
               setOpen(false)
               navigate(-1)
            }}
            placement="bottom"
            height="100%"
            open={open}
            title={t('buttons.edit')}
            classNames={{
               body: '!bg-white'
            }}
         >
            <Card>
               <Form form={form} layout="vertical" initialValues={userData?.getUser} onFinish={onFinish}>
                  <UserForm form={form} initialValues={userData?.user} onFinish={onFinish} />
               </Form>
            </Card>
         </Drawer>
      </>
   )
}

export default EditUser

import { motion, AnimatePresence } from 'framer-motion';
import { IconCheck, IconX } from '@tabler/icons-react';

interface SuccessModalProps {
    isOpen: boolean;
    onClose: () => void;
    message: string;
    description?: string;
}

const SuccessModal = ({ isOpen, onClose, message, description }: SuccessModalProps) => {
    return (
        <AnimatePresence>
            {isOpen && (
                <>
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        className="fixed inset-0 backdrop-blur-sm bg-black/30 z-[1000]"
                        onClick={onClose}
                    />
                    <motion.div
                        initial={{ opacity: 0, scale: 0.75, y: 20 }}
                        animate={{ opacity: 1, scale: 1, y: 0 }}
                        exit={{ opacity: 0, scale: 0.75, y: 20 }}
                        transition={{ type: "spring", duration: 0.5 }}
                        className="fixed top-[40%] left-1/2 -translate-x-1/2 -translate-y-1/2 z-[1001] w-full max-w-[400px] mx-auto px-4"
                    >
                        <div className="bg-white rounded-2xl shadow-2xl overflow-hidden">
                            <div className="relative pt-12">
                                <motion.div
                                    initial={{ scale: 0, rotate: -180 }}
                                    animate={{ scale: 1, rotate: 0 }}
                                    transition={{
                                        type: "spring",
                                        delay: 0.2,
                                        duration: 0.7,
                                        bounce: 0.5
                                    }}
                                    className="absolute -top-10 left-1/2 -translate-x-1/2 w-20 h-20 rounded-full bg-gradient-to-r from-green-400 to-green-500 flex items-center justify-center"
                                >
                                    <IconCheck className="w-10 h-10 text-white" />
                                </motion.div>

                                <button
                                    onClick={onClose}
                                    className="absolute top-4 right-4 text-gray-400 hover:text-gray-600 transition-colors"
                                >
                                    <IconX size={20} />
                                </button>

                                <div className="px-6 pb-6 text-center">
                                    <motion.h3
                                        initial={{ opacity: 0, y: 10 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        transition={{ delay: 0.3 }}
                                        className="text-xl font-semibold text-gray-800 mt-6 mb-2"
                                    >
                                        {message}
                                    </motion.h3>
                                    {description && (
                                        <motion.p
                                            initial={{ opacity: 0, y: 10 }}
                                            animate={{ opacity: 1, y: 0 }}
                                            transition={{ delay: 0.4 }}
                                            className="text-gray-500"
                                        >
                                            {description}
                                        </motion.p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </motion.div>
                </>
            )}
        </AnimatePresence>
    );
};

export default SuccessModal; 
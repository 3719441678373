import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { LOGIN, LOGIN_URL } from 'api/auth';
import axios from 'axios';
import { routes } from 'config/routes';
import { message } from 'antd';

export const login = createAsyncThunk(
    'auth/login',
    async (credentials: { email: string; password: string }) => {
        try {
            const response = await axios.post(LOGIN_URL, credentials);
            if (response.data.status) {
                localStorage.setItem('token', response.data.access_token);
                localStorage.setItem('user', JSON.stringify(response.data.user));
                message.success('Giriş başarılı');
                window.location.href = routes.index;
            }
            return response.data;
        } catch (error) {
            message.error('E-posta veya şifre hatalı');
            throw error;
        }
    }
);

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        user: null,
        loading: false,
        error: null as string | null | undefined
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(login.pending, (state) => {
                state.loading = true;
            })
            .addCase(login.fulfilled, (state, action) => {
                state.loading = false;
                state.user = action.payload;
            })
            .addCase(login.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    }
});

export default authSlice.reducer; 
import { routes } from "config/routes";
import { List, TableColumnProps, Modal, Drawer, message } from "antd";
import { useTranslation } from "react-i18next";
import PageHeader from "components/page-header";
import { Button, CardBody, CardHeader, Chip, Card, Checkbox, Select, SelectItem, Input, Popover, Tooltip } from "@nextui-org/react";
import { Link, useNavigate } from "react-router-dom";
import useFetch from "hooks/use-fetch";
import { useEffect, useState } from "react";
import ActionButton from "./ActionButton";
import moment from "moment";
import { OFFER_MANAGEMENT_LIST, OFFER_MANAGEMENT_LIST_URL, OFFER_MANAGEMENT_SHOW, OFFER_MANAGEMENT_SHOW_URL, OFFER_MANAGEMENT_STORE } from "api/offer-management";
import SearchInput from "components/Input";
import TableComponents from "components/Table";
import { IconEdit, IconEye, IconFileInvoice, IconFilePlus, IconPdf, IconPlus } from "@tabler/icons-react";
import { useDispatch, useSelector } from "react-redux";
import offer, { setContactId, setMail, setMailId, setOfferNumber, setProduct } from "features/user/offer";
import { RootState } from "store";
import { setLoading } from "features/user/app";
import SuccessModal from "components/Success";
import { USER_SHOW } from "api/user";
import { Alert } from "rizzui";

export default function ListMail() {
    const { t } = useTranslation();
    const pageHeader = {
        title: "Mail Talep Yönetimi",
        breadcrumb: [
            {
                href: routes.dashboard,
                name: t("aside.dashboard"),
            },
            {
                href: routes.dashboard,
                name: "Mail Talep Yönetimi",
            },

        ],
    };

    const app = useSelector((state: RootState) => state.app)
    const carts = useSelector((state: RootState) => state.basket.carts)
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [offerManagementQuery, { data, loading }] = useFetch<any>()
    const [getOfferLastIdQuery, { data: lastIdData, loading: lastIdLoading }] = useFetch<any>()
    const [getOffer, { data: offerData, loading: offerLoading }] = useFetch<any>()
    const [getOffersFromOfferGroupQuery, { data: offersFromOfferGroupData, loading: offersFromOfferGroupLoading }] = useFetch<any>()
    const [convertOfferToOrderMutation, { data: convertOfferToOrderData, loading: convertOfferToOrderLoading }] = useFetch<any>();
    const [usersQuery, { data: usersData, loading: usersLoading }] = useFetch<any>()

    const [page, setPage] = useState<number>(1)
    const [search, setSearch] = useState<string>('')
    const [offerStatus, setOfferStatus] = useState<any>(null)
    const [offerType, setOfferType] = useState<any>(null)
    const [offerListModal, setOfferListModal] = useState<boolean>(false)
    const [previewItem, setPreviewItem] = useState<any>({})
    const [previewDrawer, setPreviewDrawer] = useState<boolean>(false)
    const [successModal, setSuccessModal] = useState<boolean>(false)



    const onFilter = () => {


        let object: any = {
            page,
            search,
            offerStatus,
            offerType
        }

        const result = '?' + new URLSearchParams(object).toString();

        //url = '/offers?page=' + page + '&search=' + search + '&offerStatus=' + offerStatus


        let url = 'offers/requests' + result



        //@ts-ignore
        offerManagementQuery(OFFER_MANAGEMENT_LIST, {
            endPoint: 'mails/assignsMe'
        })
    }

    const handleCreateOffer = async (id: string) => {

        if (carts.length > 0) {
            dispatch(setMailId(id));
            Modal.confirm({
                title: 'Sepetinizde ürünler bulunmaktadır.',
                content: 'Ürünlerinizi teklife eklemek istiyor musunuz?',
                onOk() {
                    dispatch(setMail(true));
                    dispatch(setProduct(carts));
                    navigate(routes.offerManagement.add + "?mail_id=" + id);
                },
                onCancel() {
                    dispatch(setMail(true));
                    navigate(routes.offerManagement.add + "?mail_id=" + id);
                }
            });
        }
        else {
            dispatch(setMailId(id));
            dispatch(setMail(true));
            dispatch(setProduct([]));
            navigate(routes.offerManagement.add + "?mail_id=" + id);
        }
    }

    const setHide = (id: number) => {
        //@ts-ignore
        offerManagementQuery(OFFER_MANAGEMENT_LIST, {
            endPoint: '/offers/requests'
        })
    }


    const columns: TableColumnProps<any>[] = [





        {
            title: "Talep Tarihi",
            dataIndex: 'id',
            key: 'id',
            render: (id: any, record: any) => {
                return (
                    <div className="flex items-center gap-3">
                        {/* {moment(record.created_at).format('LL')} */}
                        {moment(record?.created_at).format('LL')}
                    </div>
                )
            }
        },

        {
            title: t('common.actions'),
            dataIndex: 'id',
            key: 'id',
            render: (id: any, record: any) => {
                return (
                    <div className="flex items-center gap-3">
                        <Button variant="bordered" onClick={() => {
                            Modal.info({
                                type: "info",
                                content: <div dangerouslySetInnerHTML={{ __html: record.mail_body, }}></div>,
                                okText: "Tamam",
                                width: 1000,
                                closable: true,
                            })
                        }}>
                            Mail İçeriği
                        </Button>
                        <Button variant="bordered" onClick={() => {
                            Modal.info({
                                type: "info",
                                closable: true,
                                content: <div>
                                    {record.sub_description ? record.sub_description : (
                                        <Alert variant="flat" color="info">
                                            Yönetici her hangi bir not girmedi
                                        </Alert>
                                    )}
                                </div>,
                                okText: "Tamam",
                                width: 1000
                            })
                        }}>
                            Yönetici Notu
                        </Button>
                        <Button startContent={<IconPlus />} color="primary" size="sm" onClick={() => handleCreateOffer(record.id)}>
                            Teklif Oluştur
                        </Button>
                    </div>
                )
            }
        }
    ]



    useEffect(() => {
        //@ts-ignore
        onFilter()
    }, [page])

    const handleGetlastId = () => {
        //@ts-ignore
        getOfferLastIdQuery(OFFER_MANAGEMENT_LIST, {
            endPoint: '/offers/getLastOfferId'
        })
    }

    const handleGetOffer = (id: number) => {
        //@ts-ignore
        getOffer(OFFER_MANAGEMENT_SHOW, {
            endPoint: OFFER_MANAGEMENT_SHOW_URL(id)
        })
    }

    const handlePreviewOffer = (item: any) => {
        setPreviewItem(item)
        setPreviewDrawer(true)
    }

    const handleGetOffersFromOfferGroup = (id: number) => {
        //@ts-ignore
        getOffersFromOfferGroupQuery(OFFER_MANAGEMENT_LIST, {
            endPoint: '/offers/' + id + '/edit'
        })
    }

    useEffect(() => {
        if (lastIdData) {
            dispatch(setOfferNumber(lastIdData.data))
            navigate(routes.offerManagement.add)
        }
    }, [lastIdData])

    useEffect(() => {
        dispatch(setLoading(offerLoading as boolean || offersFromOfferGroupLoading as boolean || convertOfferToOrderLoading as boolean))
    }, [offerLoading, offersFromOfferGroupLoading, convertOfferToOrderLoading])

    useEffect(() => {
        if (offerData) {
            const { data } = offerData;
            dispatch(setOfferNumber(data?.no));
            dispatch(setContactId(data?.customer_contact_id));



            const products = data?.products.map((product: any, index: number) => {
                const description = JSON.parse(product.product_json);
                return {
                    id: product.product_id,
                    name: description.name,
                    price: description.price,
                    stock: product.product_qty,
                    product_qty: description.stock,
                    delivery_date: product.delivery_date,
                    commission: product.product_markup,
                    commission_type: product.product_markup_type,
                    description: product.product_description,
                    services: product.services.map((service: any) => {
                        return {
                            ...service.service,
                            commission: service.service_markup,
                            commission_type: service.service_markup_type,
                            description: service.service_description,
                            included_in_total: service.included_in_total
                        }
                    }),
                }
            })
            // console.log(products)
            dispatch(setProduct(products));
            navigate(routes.offerManagement.view(data?.offer_group_id))
        }
    }, [offerData])

    useEffect(() => {
        if (offersFromOfferGroupData) {
            setOfferListModal(true)
        }
    }, [offersFromOfferGroupData])

    useEffect(() => {
        if (convertOfferToOrderData) {
            message.success('Siparişe dönüştürüldü!');
            setOfferListModal(false)
            onFilter();
            setSuccessModal(true)
        }
    }, [convertOfferToOrderData])

    useEffect(() => {
        //@ts-ignore
        usersQuery(USER_SHOW, {
            endPoint: '/users-all'
        })
    }, [])

    return (
        <>


            <PageHeader title={pageHeader.title} breadcrumb={pageHeader.breadcrumb}>
                <div className="mt-4 flex items-center gap-3 @lg:mt-0">
                    <Button isLoading={lastIdLoading} onClick={() => handleGetlastId()} startContent={<IconFilePlus />} color="primary">

                    </Button>
                </div>
            </PageHeader>

            <div className="@container">

                <Card title={"Teklif Yönetimi"}>

                    <TableComponents
                        columns={columns}
                        data={data?.data || []}
                        pagination={false}
                        loading={loading}
                    />
                </Card>
            </div>
        </>
    );
}

import { Fragment, useState, useEffect } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { motion } from 'framer-motion';
import { IconChevronDown, IconCheck } from '@tabler/icons-react';

interface Option {
    value: string | number;
    label: string;
    group?: string;
    extra?: string;
}

interface CustomSelectProps {
    options: Option[];
    value?: string | number;
    onChange: (value: string | number) => void;
    placeholder?: string;
    error?: string;
}

export default function CustomSelect({ options, value, onChange, placeholder = "Seçiniz", error }: CustomSelectProps) {
    const [selected, setSelected] = useState<Option | null>(null);
    const [query, setQuery] = useState('');

    // Filtreleme fonksiyonu
    const filteredOptions = options.filter((option) =>
        option.label.toLowerCase().includes(query.toLowerCase()) ||
        (option.group?.toLowerCase() ?? '').includes(query.toLowerCase())
    );

    useEffect(() => {
        if (value) {
            const option = options.find(opt => opt.value.toString() === value.toString());
            if (option) setSelected(option);
        }
    }, [value, options]);

    return (
        <div className="relative">
            <Listbox value={selected} onChange={(option: Option | null) => {
                if (option) {
                    setSelected(option);
                    onChange(option.value);
                }
            }}>
                {({ open }) => (
                    <>
                        <Listbox.Button className="relative w-full cursor-pointer rounded-lg bg-white py-2.5 pl-3 pr-10 text-left border focus:outline-none focus-visible:border-primary focus-visible:ring-2 focus-visible:ring-primary/20">
                            {selected ? (
                                <span className="block truncate">
                                    {selected.group && <span className="text-gray-500">{selected.group} / </span>}
                                    {selected.label}
                                    {selected.extra && <span className="ml-2 text-primary">({selected.extra})</span>}
                                </span>
                            ) : (
                                <span className="block truncate text-gray-400">{placeholder}</span>
                            )}
                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <IconChevronDown className="h-4 w-4 text-gray-400" />
                            </span>
                        </Listbox.Button>

                        <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options className="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-lg bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <div className="sticky top-0 z-10 bg-white px-3 py-2">
                                    <input
                                        type="text"
                                        className="w-full rounded-md border border-gray-200 py-1.5 px-3 text-sm focus:outline-none focus:ring-2 focus:ring-primary/20"
                                        placeholder="Ara..."
                                        value={query}
                                        onChange={e => setQuery(e.target.value)}
                                        onClick={e => e.stopPropagation()}
                                    />
                                </div>

                                {filteredOptions.length === 0 ? (
                                    <div className="py-2 px-3 text-sm text-gray-500">
                                        Sonuç bulunamadı
                                    </div>
                                ) : (
                                    filteredOptions.map((option, index) => (
                                        <Listbox.Option
                                            key={index}
                                            value={option}
                                            className={({ active }) => `relative cursor-pointer select-none py-2 pl-10 pr-4 ${active ? 'bg-primary/5 text-primary' : 'text-gray-900'
                                                }`}
                                        >
                                            {({ selected, active }) => (
                                                <motion.div
                                                    initial={{ opacity: 0, y: 5 }}
                                                    animate={{ opacity: 1, y: 0 }}
                                                    transition={{ delay: index * 0.03 }}
                                                    className="flex items-center justify-between"
                                                >
                                                    <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                                                        {option.group && <span className="text-gray-500">{option.group} / </span>}
                                                        {option.label}
                                                    </span>
                                                    {option.extra && (
                                                        <span className="text-primary font-medium">({option.extra})</span>
                                                    )}
                                                    {selected && (
                                                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-primary">
                                                            <IconCheck className="h-4 w-4" />
                                                        </span>
                                                    )}
                                                </motion.div>
                                            )}
                                        </Listbox.Option>
                                    ))
                                )}
                            </Listbox.Options>
                        </Transition>
                    </>
                )}
            </Listbox>
            {error && <p className="mt-1 text-sm text-red-500">{error}</p>}
        </div>
    );
} 
import { IconMail, IconCalendar, IconUser, IconBuildingCommunity, IconUsers, IconFileDescription, IconShoppingCart, IconX, IconCheck, IconTrendingUp, IconChartBar, IconCurrencyLira, IconChartPie } from '@tabler/icons-react';
import { Card } from 'antd';
import moment from 'moment';
import { AiOutlineSafety } from "react-icons/ai";
import { Avatar } from 'rizzui';
import { motion } from 'framer-motion';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { MoneyFormatTR } from 'hooks/currency';

const gradientColors = [
    { start: '#FF6B6B', end: '#FF8E8E' },
    { start: '#4ECDC4', end: '#6CE5DB' },
    { start: '#45B7D1', end: '#6BC6D9' },
    { start: '#96C93D', end: '#AAD85C' },
    { start: '#9B59B6', end: '#B07CC6' },
];

export default function ProfileHeader({ user }: { user: any }) {
    // Mock conversion data
    const conversionStats = {
        rate: 78.5,
        trend: 'up',
        lastWeek: 65.2,
        efficiency: 84.3
    };

    const userF = useSelector((state: RootState) => state.user)

    return (
        <div>
            <div className='flex gap-10 items-center justify-between'>
                <div className=''>
                    <div className=' flex items-center gap-2'>
                        <div className='logo w-16 h-16 rounded-full bg-gray-100 flex items-center justify-center'>

                            <div className='w-10 h-10 rounded-full bg-gray-100 flex items-center justify-center'>
                                <IconUser size={20} className='text-gray-500' />
                            </div>

                        </div>
                        <div className='flex flex-col'>
                            <span className='text-black text-2xl font-semibold'>
                                {user?.user?.name}
                            </span>
                            <span className='text-gray-500 '>
                                {/* Key Account Manager */}
                                {user?.user?.role === 'user' ? 'Key Account Manager' : 'Yönetici'}
                            </span>
                        </div>
                    </div>
                </div>
                <div className='flex items-center gap-10'>
                    <div className="grid grid-cols-1 md:grid-cols-5 gap-6 w-full">
                        <div className='flex items-center gap-2'>
                            <motion.div
                                initial={{ scale: 0 }}
                                animate={{ scale: 1 }}
                                transition={{ type: "spring", bounce: 0.5 }}
                                className="w-12 h-12 rounded-full bg-primary/10 flex items-center justify-center"
                            >
                                <IconUsers className="w-6 h-6 text-primary" />
                            </motion.div>
                            <motion.div
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ delay: 0.2 }}
                                whileHover={{ scale: 1.1 }}
                                className="flex items-center gap-2"
                            >
                                {user?.user?.is_active == 1 ? (
                                    <>
                                        <motion.div
                                            initial={{ rotate: -180 }}
                                            animate={{ rotate: 0 }}
                                            transition={{ type: "spring", duration: 0.5 }}
                                        >
                                            <IconCheck className="w-6 h-6 text-success" />
                                        </motion.div>
                                        <motion.span
                                            initial={{ opacity: 0, x: -20 }}
                                            animate={{ opacity: 1, x: 0 }}
                                            transition={{ delay: 0.3 }}
                                            className="text-success font-medium"
                                        >
                                            Aktif
                                        </motion.span>
                                    </>
                                ) : (
                                    <>
                                        <motion.div
                                            initial={{ rotate: 180 }}
                                            animate={{ rotate: 0 }}
                                            transition={{ type: "spring", duration: 0.5 }}
                                        >
                                            <IconX className="w-6 h-6 text-danger" />
                                        </motion.div>
                                        <motion.span
                                            initial={{ opacity: 0, x: -20 }}
                                            animate={{ opacity: 1, x: 0 }}
                                            transition={{ delay: 0.3 }}
                                            className="text-danger font-medium"
                                        >
                                            Pasif
                                        </motion.span>
                                    </>
                                )}
                            </motion.div>
                        </div>
                        {/* Toplam Müşteri */}
                        <Card className="p-4">
                            <div className="flex items-center gap-4">
                                <div className="w-12 h-12 rounded-full bg-primary/10 flex items-center justify-center">
                                    <IconUsers className="w-6 h-6 text-primary" />
                                </div>
                                <div>
                                    <p className="text-sm text-gray-500">Toplam Müşteri</p>
                                    <div className="flex items-center gap-2">
                                        <h3 className="text-2xl font-semibold">{user?.user_customers?.length || 0}</h3>
                                    </div>
                                </div>
                            </div>
                        </Card>

                        {/* Toplam Talep */}
                        <Card className="p-4">
                            <div className="flex items-center gap-4">
                                <div className="w-12 h-12 rounded-full bg-warning-50 flex items-center justify-center">
                                    <IconFileDescription className="w-6 h-6 text-warning-600" />
                                </div>
                                <div>
                                    <p className="text-sm text-gray-500">Toplam Talep</p>
                                    <div className="flex items-center gap-2">
                                        <h3 className="text-2xl font-semibold">{userF?.totalRequests || 0}</h3>
                                    </div>
                                </div>
                            </div>
                        </Card>

                        {/* Toplam Sipariş */}
                        <Card className="p-4">
                            <div className="flex items-center gap-4">
                                <div className="w-12 h-12 rounded-full bg-success-50 flex items-center justify-center">
                                    <IconShoppingCart className="w-6 h-6 text-success-600" />
                                </div>
                                <div>
                                    <p className="text-sm text-gray-500">Toplam Sipariş</p>
                                    <div className="flex items-center gap-2">
                                        <h3 className="text-2xl font-semibold">{userF?.totalOrders || 0}</h3>
                                    </div>
                                </div>
                            </div>
                        </Card>

                        {/* Dönüşüm Oranı Card'ı */}
                        <Card className="p-4 bg-gradient-to-br from-violet-50 to-purple-50">
                            <div className="flex items-center gap-4">
                                <div className="w-12 h-12 rounded-full bg-violet-100 flex items-center justify-center">
                                    <IconChartBar className="w-6 h-6 text-violet-600" />
                                </div>
                                <div>
                                    <p className="text-sm text-gray-500">Dönüşüm Oranı</p>
                                    <div className="flex items-center gap-2">
                                        <h3 className="text-2xl font-semibold text-violet-600">{userF?.conversionRate?.toFixed(1)}%</h3>
                                        <div className="flex items-center gap-1 bg-green-100 px-2 py-0.5 rounded-full">
                                            {/* <IconTrendingUp className="w-3 h-3 text-green-600" />
                                            <span className="text-xs text-green-600">
                                                +{userF?.conversionRate?.toFixed(1)}%
                                            </span> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Card>

                        {/* Toplam Ciro Card'ı */}

                    </div>

                </div>

            </div>
            <Card className="p-4 bg-gradient-to-br from-emerald-50 to-green-50">
                <div className="flex items-center gap-4">
                    <div className="w-12 h-12 rounded-full bg-emerald-100 flex items-center justify-center">
                        <IconCurrencyLira className="w-6 h-6 text-emerald-600" />
                    </div>
                    <div>
                        <p className="text-sm text-gray-500">Toplam Ciro</p>
                        <div className="flex items-center gap-2">
                            <h3 className="text-2xl font-semibold text-emerald-600">
                                {MoneyFormatTR(userF?.totalRevenue || 0)} ₺
                            </h3>
                            <div className="flex items-center gap-1 bg-emerald-100 px-2 py-0.5 rounded-full">
                                <IconTrendingUp className="w-3 h-3 text-emerald-600" />
                                <span className="text-xs text-emerald-600">
                                    +{((userF?.totalRevenue || 0) / 100).toFixed(1)}%
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </Card>

            {/* Toplam Kar Card'ı */}
            <Card className="p-4 bg-gradient-to-br from-blue-50 to-cyan-50">
                <div className="flex items-center gap-4">
                    <div className="w-12 h-12 rounded-full bg-blue-100 flex items-center justify-center">
                        <IconChartPie className="w-6 h-6 text-blue-600" />
                    </div>
                    <div>
                        <p className="text-sm text-gray-500">Toplam Kar</p>
                        <div className="flex items-center gap-2">
                            <h3 className="text-2xl font-semibold text-blue-600">
                                {MoneyFormatTR(userF?.totalProfit || 0)} ₺
                            </h3>
                            <div className="flex items-center gap-1 bg-blue-100 px-2 py-0.5 rounded-full">
                                <IconTrendingUp className="w-3 h-3 text-blue-600" />
                                <span className="text-xs text-blue-600">
                                    +{((userF?.totalProfit || 0) / 100).toFixed(1)}%
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </Card>
        </div>
    );
}

function CustomerInfoItem({ icon, label, value }: { icon: React.ReactNode, label: string, value: string }) {
    return (
        <div className='flex items-center gap-2'>
            <div className='text-gray-500'>{icon}</div>
            <div className='flex flex-col'>
                <span className='text-gray-500'>{label}</span>
                <span className='text-black text-lg font-semibold'>{value}</span>
            </div>
        </div>
    );
}

function AvatarGroup({ contacts }: { contacts: any[] }) {
    return (
        <div className='relative flex items-center'>
            {new Array(5).fill(0).map((_, index) => (
                <div
                    key={index}
                    className={`w-10 h-10 rounded-full flex items-center justify-center border-2 border-white ${index === 0 ? '' : '-ml-5'}`}
                    style={{
                        background: `linear-gradient(135deg, ${gradientColors[index % gradientColors.length].start}, ${gradientColors[index % gradientColors.length].end})`
                    }}
                >
                    <IconUser size={20} className='text-white' />
                </div>
            ))}
        </div>
    );
} 
import { Button } from '@nextui-org/react';
import { IconUser, IconMail, IconPhone, IconBriefcase, IconDeviceFloppy, IconX } from '@tabler/icons-react';
import { useState, useEffect } from 'react';
import { Input } from 'rizzui';

interface ContactFormProps {
  initialValues?: any;
  onFinish: (values: any) => void;
  onClose?: () => void;
  loading?: boolean;
}

const ContactForm = ({ initialValues, onFinish, onClose, loading }: ContactFormProps) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    title: ''
  });

  useEffect(() => {
    if (initialValues) {
      setFormData(initialValues);
    }
  }, [initialValues]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onFinish(formData);
  };

  const handleChange = (field: string, value: string) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  return (
    <form onSubmit={handleSubmit} className="h-full flex flex-col">
      <div className={`flex-grow overflow-auto p-6 ${loading ? 'opacity-50 pointer-events-none' : ''}`}>
        <div className="bg-gray-50/50 rounded-2xl p-6">
          <div className="flex items-center gap-4 mb-8">
            <div className="w-12 h-12 rounded-full bg-gradient-to-br from-primary/10 to-primary/20 flex items-center justify-center">
              <IconUser className="w-6 h-6 text-primary" />
            </div>
            <div>
              <h3 className="text-lg font-semibold text-gray-800">Kişisel Bilgiler</h3>
              <p className="text-sm text-gray-500">Temel bilgileri düzenleyin</p>
            </div>
          </div>

          <div className="space-y-6">
            <Input
              value={formData.name}
              label="İsim Soyisim"
              placeholder="İsim Soyisim giriniz"
              prefix={<IconUser className="w-5 h-5" />}
              onChange={(e) => handleChange('name', e.target.value)}
              required
            />
            <Input
              value={formData.email}
              label="E-posta"
              type="email"
              placeholder="E-posta giriniz"
              prefix={<IconMail className="w-5 h-5" />}
              onChange={(e) => handleChange('email', e.target.value)}
              required
            />
            <Input
              value={formData.phone}
              label="Telefon"
              placeholder="Telefon giriniz"
              prefix={<IconPhone className="w-5 h-5" />}
              onChange={(e) => handleChange('phone', e.target.value)}

            />
          </div>
        </div>
      </div>

      <div className="border-t border-gray-200 bg-white p-4 sticky bottom-0">
        <div className="flex justify-end gap-3 max-w-[1200px] mx-auto">
          <Button
            className="min-w-[100px]"
            color="danger"
            variant="light"
            startContent={<IconX className="w-4 h-4" />}
            onClick={onClose}
            type="button"
            isDisabled={loading}
          >
            İptal
          </Button>
          <Button
            className="min-w-[100px]"
            color="primary"
            type="submit"
            startContent={loading ? null : <IconDeviceFloppy className="w-4 h-4" />}
            isLoading={loading}
            isDisabled={loading}
          >
            {loading ? 'Kaydediliyor...' : 'Kaydet'}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default ContactForm;

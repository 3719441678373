import { useState, useEffect } from 'react';
import { Card, Button, Modal } from '@nextui-org/react';
import { IconUserCircle, IconMail, IconPhone, IconBuilding, IconUserPlus, IconEdit, IconTrash, IconCheck, IconUserOff, IconUserCheck } from '@tabler/icons-react';
import PageHeader from 'components/page-header';
import { useTranslation } from 'react-i18next';
import { routes } from 'config/routes';
import TableComponents from 'components/Table';
import moment from 'moment';
import { Modal as AntModal, notification } from 'antd';
import { Drawer } from 'rizzui';
import { USER_OFFER_MANAGEMENT_LIST_URL } from 'api/offer-management';
import useFetch from 'hooks/use-fetch';
import { USER_LIST, USER_LIST_URL } from 'api/user';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { apiUrl } from 'api';
import { createRoot } from 'react-dom/client';

export default function UsersPage() {
   const { t } = useTranslation();
   const user = JSON.parse(localStorage.getItem('user') || '{}');
   const navigate = useNavigate();
   const [selectedUser, setSelectedUser] = useState<any>(null);
   const [profileDrawer, setProfileDrawer] = useState(false);
   const [editModal, setEditModal] = useState(false);
   const [addModal, setAddModal] = useState(false);
   const [page, setPage] = useState(1);
   const [tableData, setTableData] = useState<any>({ data: [] });
   const [isLoading, setIsLoading] = useState(false);
   const [isProcessing, setIsProcessing] = useState(false);
   const [confirmModal, setConfirmModal] = useState<{ show: boolean; user?: any }>({ show: false });


   const [userManagementQuery, { data, loading, error }] = useFetch<any>();

   const fetchUsers = () => {
      //@ts-ignore
      userManagementQuery(USER_LIST, {
         endPoint: USER_LIST_URL.replace(':page', page as any)
      });
   };

   useEffect(() => {
      fetchUsers();
   }, [page]);

   useEffect(() => {
      if (data) {
         setTableData(data?.data?.data);
      }
   }, [data]);

   const columns = [
      {
         title: 'Personel',
         key: 'user',
         render: (text: any, record: any) => (
            <div className="flex items-center gap-3">
               <div className="w-10 h-10 rounded-full bg-primary/10 flex items-center justify-center">
                  {record.avatar ? (
                     <img src={record.avatar} alt={record.name} className="w-10 h-10 rounded-full object-cover" />
                  ) : (
                     <span className="text-primary font-medium">{record.name.charAt(0)}</span>
                  )}
               </div>
               <div className="flex flex-col">
                  <span className="font-medium">{record.name}</span>
                  <span className="text-sm text-gray-500">{record.role === 'admin' ? 'Admin' : 'Personel'}</span>
               </div>
            </div>
         )
      },
      {
         title: 'İletişim',
         key: 'contact',
         render: (text: any, record: any) => (
            <div className="flex flex-col gap-1">
               <div className="flex items-center gap-2">
                  <IconMail className="w-4 h-4 text-gray-400" />
                  <span className="text-sm">{record.email}</span>
               </div>
               {record.phone && (
                  <div className="flex items-center gap-2">
                     <IconPhone className="w-4 h-4 text-gray-400" />
                     <span className="text-sm">{record.phone}</span>
                  </div>
               )}
            </div>
         )
      },

      {
         title: 'Katılım Tarihi',
         key: 'created_at',
         render: (text: any, record: any) => (
            <div className="flex flex-col">
               <span className="font-medium">
                  {moment(record.created_at).format('DD MMM')}
               </span>
               <span className="text-sm text-gray-500">
                  {moment(record.created_at).format('YYYY')}
               </span>
            </div>
         )
      },

      {
         title: 'İşlemler',
         key: 'actions',
         render: (text: any, record: any) => (
            <div className="flex items-center gap-2" style={{
               display: user?.role === 'admin' ? 'flex' : 'none'
            }}>
               <Button
                  size="sm"
                  variant="flat"
                  color="primary"
                  onClick={() => {
                     navigate(routes.users.view(record.id))
                  }}
                  className="rounded-full"
                  startContent={<IconUserCircle className="w-4 h-4" />}
               >
                  Profil
               </Button>
               <Button
                  size="sm"
                  variant="light"
                  color="warning"
                  onClick={() => {
                     navigate(routes.users.edit(record.id))
                  }}
                  className="rounded-full"
                  startContent={<IconEdit className="w-4 h-4" />}
               >
                  Düzenle
               </Button>
               <Button
                  size="sm"
                  variant="light"
                  color={record.is_active == 1 ? 'danger' : 'success'}
                  onClick={() => handleStatusClick(record)}
                  className={`rounded-full transition-all duration-300 ${record.is_active
                     ? 'hover:bg-red-100 hover:text-red-600'
                     : 'hover:bg-green-100 hover:text-green-600'
                     }`}
                  startContent={
                     record.is_active ? (
                        <IconUserOff className="w-4 h-4" />
                     ) : (
                        <IconUserCheck className="w-4 h-4" />
                     )
                  }
               >
                  <span className="relative">
                     {record.is_active ? 'Pasife Al' : 'Aktife Al'}
                     <motion.span
                        className={`absolute bottom-0 left-0 w-full h-0.5 ${record.is_active ? 'bg-red-400' : 'bg-green-400'
                           }`}
                        initial={{ scaleX: 0 }}
                        animate={{ scaleX: 1 }}
                        transition={{ duration: 0.3 }}
                     />
                  </span>
               </Button>
            </div>
         )
      }
   ];

   const handleStatusClick = (user: any) => {
      setConfirmModal({
         show: true,
         user: {
            ...user,
            is_active: user.is_active ?? true
         }
      });
   };

   const handleStatusChange = async (user: any) => {
      try {
         setIsProcessing(true);
         const response = await fetch(apiUrl + `/api/users/${user.id}/set-active`, {
            method: 'POST',
            headers: {
               'Content-Type': 'application/json',
               'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
               is_active: !user.is_active
            })
         });

         const data = await response.json();
         if (data.status) {
            // Success animasyonu
            setIsProcessing(false);
            setConfirmModal({ show: false });

            // Fullscreen success animasyonu
            const successElement = document.createElement('div');
            document.body.appendChild(successElement);

            const root = createRoot(successElement);
            root.render(
               <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="fixed inset-0 z-[99999] flex items-center justify-center bg-white"
               >
                  <motion.div
                     className="flex flex-col items-center gap-6"
                     initial={{ scale: 0.8, opacity: 0 }}
                     animate={{ scale: 1, opacity: 1 }}
                     exit={{ scale: 0.8, opacity: 0 }}
                  >
                     <motion.div
                        className="w-24 h-24 rounded-full border-4 border-green-500 flex items-center justify-center"
                        initial={{ borderWidth: 4 }}
                        animate={{
                           borderWidth: [4, 8, 4],
                           scale: [1, 1.1, 1],
                           rotate: [0, 360]
                        }}
                        transition={{
                           duration: 1.5,
                           ease: "easeInOut"
                        }}
                     >
                        <motion.div
                           initial={{ scale: 0 }}
                           animate={{ scale: 1 }}
                           transition={{ delay: 0.2 }}
                        >
                           <IconCheck className="w-12 h-12 text-green-500" />
                        </motion.div>
                     </motion.div>

                     <motion.div
                        className="text-center"
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.3 }}
                     >
                        <h2 className="text-2xl font-bold text-gray-800 mb-2">
                           İşlem Başarılı!
                        </h2>
                        <p className="text-gray-600">
                           Kullanıcı {user.is_active ? 'pasife' : 'aktife'} alındı
                        </p>
                     </motion.div>

                     <motion.div
                        className="w-full h-1 bg-green-100 rounded-full overflow-hidden"
                        initial={{ width: 0 }}
                        animate={{ width: "100%" }}
                     >
                        <motion.div
                           className="h-full bg-green-500"
                           initial={{ width: "100%" }}
                           animate={{ x: ["0%", "100%"] }}
                           transition={{
                              duration: 1,
                              ease: "easeInOut"
                           }}
                        />
                     </motion.div>
                  </motion.div>
               </motion.div>
            );

            // 2 saniye sonra success ekranını kaldır
            setTimeout(() => {
               document.body.removeChild(successElement);
               fetchUsers(); // Listeyi yenile
            }, 2000);

         }
      } catch (err) {
         setIsProcessing(false);
         notification.error({
            message: 'Hata',
            description: 'İşlem sırasında bir hata oluştu'
         });
      }
   };

   return (
      <>
         <PageHeader
            title="Personeller"
            breadcrumb={[
               { name: 'Anasayfa', href: routes.home },
               { name: 'Personeller', href: routes.users.list }
            ]}
         >
            <Button
               color="primary"
               onClick={() => navigate(routes.users.add)}
               startContent={<IconUserPlus className="w-4 h-4" />}
            >
               Yeni Personel Ekle
            </Button>
         </PageHeader>

         <Card className="mt-6">
            <TableComponents
               columns={columns}
               data={data?.data?.data || []}
               loading={isLoading}
               pagination={{
                  total: tableData?.total || 0,
                  current: page,
                  pageSize: 10,
                  onChange: setPage
               }}
            />
         </Card>

         {/* Profil Drawer'ı */}
         <Drawer
            isOpen={profileDrawer}
            onClose={() => setProfileDrawer(false)}
            placement="right"
            size="lg"
         >
            <div className="p-6">
               <div className="flex items-center gap-4 mb-6">
                  <div className="w-20 h-20 rounded-full bg-primary/10 flex items-center justify-center">
                     {selectedUser?.avatar ? (
                        <img src={selectedUser.avatar} alt={selectedUser.name} className="w-20 h-20 rounded-full object-cover" />
                     ) : (
                        <span className="text-primary text-2xl font-medium">{selectedUser?.name?.charAt(0)}</span>
                     )}
                  </div>
                  <div>
                     <h3 className="text-xl font-semibold">{selectedUser?.name}</h3>
                     <p className="text-gray-500">{selectedUser?.role}</p>
                  </div>
               </div>

               <div className="space-y-6">
                  <div className="flex flex-col gap-1">
                     <span className="text-sm text-gray-500">E-posta</span>
                     <span className="font-medium">{selectedUser?.email}</span>
                  </div>
                  <div className="flex flex-col gap-1">
                     <span className="text-sm text-gray-500">Telefon</span>
                     <span className="font-medium">{selectedUser?.phone || '-'}</span>
                  </div>
                  <div className="flex flex-col gap-1">
                     <span className="text-sm text-gray-500">Departman</span>
                     <span className="font-medium">{selectedUser?.department || '-'}</span>
                  </div>
                  <div className="flex flex-col gap-1">
                     <span className="text-sm text-gray-500">Katılım Tarihi</span>
                     <span className="font-medium">{moment(selectedUser?.created_at).format('LL')}</span>
                  </div>
               </div>
            </div>
         </Drawer>

         {/* Modern Confirm Modal */}
         <AnimatePresence>
            {confirmModal.show && (
               <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="fixed inset-0 z-50 flex items-center justify-center bg-black/50 backdrop-blur-sm"
               >
                  <motion.div
                     initial={{ scale: 0.9, opacity: 0 }}
                     animate={{ scale: 1, opacity: 1 }}
                     exit={{ scale: 0.9, opacity: 0 }}
                     className="bg-white rounded-2xl p-8 max-w-md w-full mx-4 shadow-2xl"
                  >
                     <motion.div
                        initial={{ y: 10, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        className="text-center"
                     >
                        <div className="w-16 h-16 mx-auto mb-4">
                           <motion.div
                              animate={{
                                 scale: [1, 1.2, 1],
                                 rotate: [0, 180, 360]
                              }}
                              transition={{
                                 duration: 2,
                                 repeat: Infinity,
                                 ease: "easeInOut"
                              }}
                              className={`w-full h-full rounded-full border-4 ${confirmModal.user?.is_active ? 'border-red-500' : 'border-green-500'}`}
                           />
                        </div>
                        <h3 className="text-xl font-semibold mb-2">Durumu Değiştir</h3>
                        <p className="text-gray-600 mb-6">
                           {confirmModal.user?.name} isimli kullanıcıyı {' '}
                           {confirmModal.user?.is_active ? 'pasife' : 'aktife'} almak istediğinize emin misiniz?
                        </p>
                        <div className="flex gap-3 justify-center">
                           <Button
                              color="default"
                              variant="flat"
                              onClick={() => setConfirmModal({ show: false })}
                           >
                              İptal
                           </Button>
                           <Button
                              color={confirmModal.user?.is_active ? 'danger' : 'success'}
                              onClick={() => handleStatusChange(confirmModal.user)}
                           >
                              {confirmModal.user?.is_active ? 'Pasife Al' : 'Aktife Al'}
                           </Button>
                        </div>
                     </motion.div>
                  </motion.div>
               </motion.div>
            )}
         </AnimatePresence>

         {/* Processing Animation */}
         <AnimatePresence>
            {isProcessing && (
               <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="fixed inset-0 z-[99999] flex items-center justify-center bg-black/50 backdrop-blur-sm"
               >
                  <motion.div
                     initial={{ scale: 0.8, opacity: 0 }}
                     animate={{ scale: 1, opacity: 1 }}
                     exit={{ scale: 0.8, opacity: 0 }}
                     className="bg-white rounded-2xl p-8 flex flex-col items-center gap-4 shadow-2xl"
                  >
                     <motion.div
                        animate={{
                           rotate: 360,
                           borderRadius: ["50% 50% 50% 50%", "40% 60% 60% 40%", "50% 50% 50% 50%"]
                        }}
                        transition={{
                           duration: 2,
                           repeat: Infinity,
                           ease: "linear"
                        }}
                        className="w-16 h-16 border-4 border-primary border-t-transparent"
                     />
                     <motion.span
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        className="text-lg font-medium text-gray-700"
                     >
                        İşlem Yapılıyor
                     </motion.span>
                     <motion.div
                        initial={{ width: 0 }}
                        animate={{ width: "100%" }}
                        transition={{ duration: 1 }}
                        className="h-1 bg-primary/20 rounded-full overflow-hidden"
                     >
                        <motion.div
                           animate={{
                              x: ["-100%", "100%"]
                           }}
                           transition={{
                              duration: 1,
                              repeat: Infinity,
                              ease: "linear"
                           }}
                           className="h-full w-1/3 bg-primary rounded-full"
                        />
                     </motion.div>
                  </motion.div>
               </motion.div>
            )}
         </AnimatePresence>
      </>
   );

}

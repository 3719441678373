import React, { useEffect, useState } from 'react';
import { Form, Modal, message, Select } from 'antd';
import { Alert, Input, Textarea, Title } from 'rizzui';
import { Button, SelectItem } from '@nextui-org/react';
import useFetch from 'hooks/use-fetch';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { closeRequestModal, setContactId, setIsAdded } from 'features/modals/requestModal';
import { USER_SHOW, USER_STORE } from 'api/user';
import { CUSTOMER_MANAGEMENT_LIST } from 'api/customer-management';
import { Switch } from '@nextui-org/react';
import CustomSelect from 'components/ui/CustomSelect';
import { IconBox } from '@tabler/icons-react';
import SelectCustomerModal from 'components/modals/SelectCustomerModal';
import { setProduct } from 'features/user/offer';

export default function CreateRequestModal() {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const { isOpen, contactId, productsFromCart } = useSelector((state: RootState) => state.requestModal);
    const carts = useSelector((state: RootState) => state.basket.carts);
    const [sendWithProduct, setSendWithProduct] = React.useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchTimeout, setSearchTimeout] = useState<NodeJS.Timeout>();
    const [showCustomerModal, setShowCustomerModal] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState<any>(null);

    // Queries
    const [usersQuery, { data: usersData, loading }] = useFetch<any>();
    const [contactsQuery, { data: contactsData }] = useFetch<any>();
    const [offerRequestMutation, { data: offerRequestData }] = useFetch<any>();

    useEffect(() => {
        if (isOpen) {
            //@ts-ignore
            usersQuery(USER_SHOW, {
                endPoint: '/users-with-offer-current-status'
            });

            //@ts-ignore
            contactsQuery(CUSTOMER_MANAGEMENT_LIST, {
                endPoint: '/customer-with-contacts-select'
            });
        }
    }, [isOpen]);

    // Sepetten gelen ürünler varsa switch'i otomatik aç
    useEffect(() => {
        if (productsFromCart) {
            setSendWithProduct(true);
            form.setFieldValue('send_with_product', true);
        }
    }, [productsFromCart]);

    const handleCreateRequest = (values: any) => {
        //@ts-ignore
        offerRequestMutation(USER_STORE, {
            endPoint: '/offer-requests-create',
            body: {
                ...values,
                carts: values.send_with_product ? carts : []
            }
        });
    };

    useEffect(() => {
        if (offerRequestData) {
            message.success('Talep başarıyla oluşturuldu');
            dispatch(closeRequestModal());
            dispatch(setContactId(null as any));
            dispatch(setProduct([]));
            setSelectedCustomer(null);
            dispatch(setIsAdded(true));
            form.resetFields();
        }
    }, [offerRequestData]);

    useEffect(() => {
        if (contactId && contactsData) {
            form.setFieldValue('contact_id', contactId.toString());

            // Contact bilgisini bulup Select'in görüntülemesi için label'ı ayarlayalım
            const contact = contactsData?.data?.data
                ?.flatMap((company: any) => company.contacts)
                .find((contact: any) => contact.id.toString() === contactId.toString());

            if (contact) {
                form.setFields([{
                    name: 'contact_id',
                    value: contactId.toString(),
                    touched: true
                }]);
            }
        }
    }, [contactId, contactsData]);

    // Debounced search query
    const handleSearch = (value: string) => {
        setSearchQuery(value);

        // Clear existing timeout
        if (searchTimeout) {
            clearTimeout(searchTimeout);
        }

        // Set new timeout
        const timeout = setTimeout(() => {
            //@ts-ignore
            contactsQuery(CUSTOMER_MANAGEMENT_LIST, {
                endPoint: '/customer-with-contacts',
                params: {
                    search: value
                }
            });
        }, 300); // 300ms debounce

        setSearchTimeout(timeout);
    };

    const formatPrice = (price: any, currency: string) => {
        // Fiyat kontrolü
        if (!price || isNaN(price)) {
            return `0 ${currency}`;
        }

        // Sayısal değere çevir
        const numericPrice = Number(price);

        // Para birimi formatlaması
        const formatter = new Intl.NumberFormat('tr-TR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });

        return `${formatter.format(numericPrice)} ${currency}`;
    };

    return (
        <Modal
            width={1000}
            open={isOpen}
            onCancel={() => dispatch(closeRequestModal())}
            footer={null}
        >
            <div className="p-4">
                <Title as="h5">Hızlı Talep Oluştur</Title>
                <Form
                    initialValues={{
                        contact_id: contactId
                    }}
                    layout="vertical" name="basic" form={form} onFinish={handleCreateRequest}>
                    <Alert color="info" className="mb-4">
                        <p>
                            Kullanıcıların solundaki numaralar elindeki var olan işlem bekleyen taleplerin sayısını
                            göstermektedir.
                        </p>
                    </Alert>

                    <Form.Item
                        label="Müşteri seçiniz"
                        name="contact_id"
                        rules={[{ required: true, message: 'Lütfen bir müşteri seçiniz!' }]}
                    >
                        <div>
                            <div className="flex items-center gap-2">
                                <div className="flex-1">
                                    <input type='hidden' name='contact_id' />
                                    <div className="text-sm text-gray-500 bg-gray-100 p-2 rounded-lg border border-gray-200">
                                        {selectedCustomer || 'Müşteri seçiniz'}
                                    </div>
                                    {/* <CustomSelect
                                        placeholder="Seçili müşteri"
                                        value={form.getFieldValue('contact_id')}
                                        options={contactsData?.data
                                            ?.filter((item: any) => item.contacts.length > 0)
                                            .flatMap((company: any) =>
                                                company.contacts.map((contact: any) => ({
                                                    value: contact.id,
                                                    label: contact.name,
                                                    group: company.company_name
                                                }))
                                            ) ?? []
                                        }
                                        onChange={(value) => form.setFieldValue('contact_id', value)}
                                    /> */}
                                </div>
                                <button
                                    type="button"
                                    onClick={() => setShowCustomerModal(true)}
                                    className="
                                        px-4 py-2 rounded-lg
                                        border border-gray-200 hover:border-gray-300
                                        bg-white hover:bg-gray-50
                                        text-gray-600
                                        transition-all duration-200
                                    "
                                >
                                    Müşteri Seç
                                </button>
                            </div>

                            <SelectCustomerModal
                                isOpen={showCustomerModal}
                                onClose={() => setShowCustomerModal(false)}
                                onSelect={(customer) => {
                                    console.log(customer);
                                    form.setFieldValue('contact_id', customer.id);
                                    setSelectedCustomer(`${customer.customer_name} - ${customer.contact_name}`);
                                    setShowCustomerModal(false);
                                }}
                            />
                        </div>
                    </Form.Item>

                    <Form.Item
                        label="Atanacak Kullanıcı"
                        name="user_id"
                        rules={[{ required: true, message: 'Lütfen atanacak kullanıcıyı seçiniz!' }]}
                    >
                        <CustomSelect
                            placeholder="Kullanıcı seçiniz"
                            options={usersData?.result?.map((user: any) => ({
                                value: user.id,
                                label: user.name,
                                extra: user.total.toString()
                            })) ?? []}
                            value={form.getFieldValue('user_id')}
                            onChange={(value) => form.setFieldValue('user_id', value)}
                        />
                    </Form.Item>

                    <Form.Item name="send_with_product" valuePropName="checked">
                        <div className="flex flex-col gap-4">
                            <div className="flex items-center gap-2">
                                <Switch
                                    checked={sendWithProduct}
                                    onChange={(e) => {
                                        setSendWithProduct(e.target.checked);
                                        form.setFieldValue('send_with_product', e.target.checked);
                                    }}
                                />
                                <span>Sepetteki ürünlerle gönder</span>
                            </div>

                            {sendWithProduct && carts.length > 0 && (
                                <div className="border rounded-lg p-4 space-y-4">
                                    <div className="text-sm font-medium text-gray-500">
                                        Seçili Ürünler ({carts.length})
                                    </div>
                                    {carts.map((item: any) => (
                                        <div key={item.id} className="flex items-center gap-4 p-2 border-b last:border-0">
                                            <div className="w-12 h-12 rounded-lg bg-gray-100 flex items-center justify-center">
                                                {item.image ? (
                                                    <img
                                                        src={item.image}
                                                        alt={item.name}
                                                        className="w-10 h-10 object-contain"
                                                    />
                                                ) : (
                                                    <IconBox className="w-6 h-6 text-gray-400" />
                                                )}
                                            </div>
                                            <div className="flex-1">
                                                <div className="font-medium">{item.name}</div>
                                                <div className="text-sm text-gray-500">
                                                    {item.model && <span>Model: {item.model}</span>}
                                                </div>
                                            </div>
                                            <div className="text-right">
                                                <span className="font-medium text-gray-900">
                                                    {formatPrice(item.price, item.currency || 'TRY')}
                                                </span>
                                                <div className="text-sm text-gray-500">
                                                    Stok: {item.stock}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}

                            {sendWithProduct && carts.length === 0 && (
                                <Alert color="warning">
                                    Sepetinizde ürün bulunmamaktadır.
                                </Alert>
                            )}
                        </div>
                    </Form.Item>
                    <Form.Item
                        label="Talep Kaynağı"
                        name="offer_source"
                    >
                        <CustomSelect
                            placeholder="Talep kaynağı seçiniz"
                            options={[
                                {
                                    value: 'web',
                                    label: 'Web'
                                },
                                {
                                    value: 'phone',
                                    label: 'Telefon'
                                },
                                {
                                    value: 'e-posta',
                                    label: 'E-Posta'
                                },
                                {
                                    value: 'whatsapp',
                                    label: 'Whatsapp'
                                },

                            ]}
                            value={form.getFieldValue('offer_source')}
                            onChange={(value) => form.setFieldValue('offer_source', value)}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Not"
                        name="note"
                    >
                        <Textarea
                            placeholder="Talep hakkında not ekleyin"
                            className="w-full"
                        />
                    </Form.Item>

                    <div className="flex justify-end gap-2 mt-4">
                        <Button
                            color="danger"
                            variant="flat"
                            onClick={() => dispatch(closeRequestModal())}
                        >
                            İptal
                        </Button>
                        <Button
                            color="primary"
                            type="submit"
                            isLoading={loading}
                        >
                            Talep Oluştur
                        </Button>
                    </div>
                </Form>
            </div>
        </Modal>
    );
} 
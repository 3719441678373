import { Button, Chip, Input, SelectItem } from '@nextui-org/react'
import { IconConePlus, IconCubePlus, IconPlus, IconSearch, IconTrash, IconInfoCircle, IconChevronRight } from '@tabler/icons-react'
import { Card, Divider, Drawer, DrawerProps, Empty, Form, Image, List, Modal, Select, Upload, message } from 'antd'
import { PRODUCTS } from 'api/product'
import { addProduct, setOtherSuppliers, setProduct } from 'features/user/offer'
import useFetch from 'hooks/use-fetch'
import React, { useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loader, Input as InputRiz, Textarea, Alert, Switch } from 'rizzui'
import { Select as SelectUi } from '@nextui-org/react'
import { RootState } from 'store'
import _, { isDate } from 'lodash'
import { useDebounce } from 'use-debounce'
import { FetchProps } from 'api/interface'
import axios from 'axios'
import { MoneyFormat, MoneyFormat2, MoneyFormat3 } from 'hooks/currency'
import RenderSearch from './RenderSearch'
import { Button as ButtonUi } from 'antd'
import { apiUrl } from '../../../../api'
import { getDescription } from 'utils/filter-data'
import Products from 'pages/admin/Products'
import { motion } from 'framer-motion'
import ReactQuill from 'react-quill'
import moment from 'moment'

interface Props {
   drawerProps: DrawerProps
   handleNext: (products: any) => void
   handlePrev: () => void
   currentProducts?: any[]
}

interface Service {
   name: string;
   price: number;
   included_in_total?: boolean;
}

const CustomDrawer = ({ isOpen, onClose, title, children, extra, ...props }: any) => {
   return (
      <motion.div
         initial={{ y: "100%" }}
         animate={{ y: isOpen ? "0%" : "100%" }}
         transition={{ type: "spring", damping: 30, stiffness: 300 }}
         className="fixed inset-0 z-50"
      >
         {/* Backdrop */}
         <div
            className="absolute inset-0 bg-black/20 backdrop-blur-sm"
            onClick={onClose}
         />

         {/* Drawer Content */}
         <div className="
            absolute bottom-0 left-0 right-0
            h-screen w-full
            bg-white
            rounded-t-2xl
            shadow-2xl
            flex flex-col
         ">
            {/* Header */}
            <div className="
               flex items-center justify-between
               px-6 py-4
               border-b border-gray-100
            ">
               <h2 className="text-lg font-medium text-gray-800">
                  {title}
               </h2>
               {extra}
            </div>

            {/* Body */}
            <div className="flex-1 overflow-y-auto">
               {children}
            </div>
         </div>
      </motion.div>
   );
};

export default function Step2(props: Props) {
   const { drawerProps, handleNext, handlePrev } = props
   const [productImage, setProductImage] = useState<any>(null)
   const [addProductModal, setAddProductModal] = useState(false)
   const [addProductForm] = Form.useForm()
   const [products, setProducts] = useState<any[]>(props.currentProducts || [])
   const [open, setOpen] = useState(false)
   const [searchQuery, { data, loading }] = useFetch<any>()
   const [serviceQuery, { data: serviceData, loading: serviceLoading }] = useFetch<any>()
   const [search, setSearch] = useState('')
   const offer = useSelector((state: RootState) => state.offer)
   const dispatch = useDispatch()
   const bodyRef = React.useRef<HTMLDivElement>(null)
   const drawerFormRef = React.useRef<any>(null)
   const [productForm] = Form.useForm()
   const [selectedIndex, setSelectedIndex] = useState<any>(null)
   const [servicesDrawer, setServicesDrawer] = useState(false)
   const [serviceSearchModal, setServiceSearchModal] = useState(false)
   const [errors, setErrors] = useState<any>([])
   const [searchs, setSearchs] = useState([])

   const serviceButtonRef = React.useRef<any>(null)

   const [supplierModal, setSupplierModal] = useState(false)
   const [supplierForm] = Form.useForm()
   const [suppliers, setSuppliers] = useState<any[]>([])

   const [currencies, setCurrencies] = useState<any[]>([])

   const [reserveCheckModal, setReserveCheckModal] = useState(false);
   const [reserveUserName, setReserveUserName] = useState('');
   const [checkingProduct, setCheckingProduct] = useState<any>(null);
   const [checkReserveMutation] = useFetch();

   const getSuppliers = () => {
      const response = fetch(apiUrl + '/api/supplier-list', {
         headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
         }
      })
         .then(res => res.json())
         .then(data => {

            let suppliers = data.data.map((item: any) => {
               return {
                  supplier: item.supplier
               }
            })


            setSuppliers(suppliers)
         })
   }

   const searchProduct = async () => {
      const response = await fetch(apiUrl + '/api/search-product?search=' + search, {
         headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
         }
      })

      const data = await response.json()

      setSearchs(data.data.data)
   }

   useEffect(() => {
      if (search.length >= 3) {
         const url = 'https://test.gimel.com.tr/index.php?route=api/crm/searchProduct&search=' + search
         searchProduct()
      }
   }, [search])

   useEffect(() => {
      getSuppliers()

      const fetchCurrencies = async () => {
         const response = await axios.get('/get-currency');
         setCurrencies(response.data);
      }

      fetchCurrencies();
   }, [])

   const addProductToOffer = (product: any) => {
      const findProduct = products.find(item => item.id === product.product_id)
      if (findProduct) {

         message.error('Bu ürün zaten ekli')
         return
      }
      let price = product.price.split(' ')[0]
      price = price.replace(',', '.')
      price = parseFloat(price).toFixed(2)
      let productArray = {
         id: product.id,
         name: product.name,
         model: product.model,
         price: price,
         stock: product.quantity,
         product_qty: product.quantity,
         delivery_date: '',
         image: product.image,
         commission: 0,
         commission_type: '3',
         description: getDescription(product),
         services: [],
         isReserve: false,
         reserveEndDate: '',
         supplier: product.supplier,
         currency: product.currency
      }



      setProducts([...products, productArray])
   }

   const handleAddServiceToProduct = (item: any) => {
      let newProducts = _.cloneDeep(products)
      const findService = newProducts[selectedIndex].services.find((service: any) => service.id === item.id)

      if (findService) {
         message.error('Bu servis zaten ekli')
         return
      }

      newProducts[selectedIndex].services.push({
         ...item,
         commission: 0,
         commission_type: '0',
         description: '',
         included_in_total: true
      })

      newProducts[selectedIndex].services.map((item: any, index: number) => {
         const product_id = newProducts[selectedIndex].id
         serviceForm.setFieldsValue({
            [`name_${product_id}_${index}`]: item.name,
            [`price_${product_id}_${index}`]: item.price,
            [`commission_${product_id}_${index}`]: item.commission,
            [`commission_type_${product_id}_${index}`]: item.commission_type,
            [`description_${product_id}_${index}`]: item.description,
            [`included_in_total_${product_id}_${index}`]: true
         })
      })

      setProducts(newProducts)
      setServiceSearchModal(false)
      dispatch(setProduct(products))
   }

   const onChangeProductInput = (productIndex: any, field: any, value: any) => {
      // let newProducts = [...products];
      // newProducts[productIndex][field] = value;
      // setProducts(newProducts);
      // dispatch(addProduct(products))

      const newProducts = _.cloneDeep(products)

      if (field == 'isReserve') {
         newProducts[productIndex][field] = value == 'on' ? true : false
         if (value == 'on') {
            newProducts[productIndex].reserveEndDate = moment().add(4, 'days').format('YYYY-MM-DD')
         }
      } else {
         newProducts[productIndex][field] = value
      }
      setProducts(newProducts)

      // dispatch(setProduct(newProducts))
   }

   const handleCheckProductValidation = () => {
      const newErrors: any[] = [];

      products.forEach((item, index) => {
         const productErrors: any = {};
         let validated = true;

         if (!item.price || item.price === 0) {
            productErrors.price = 'Lütfen bir fiyat giriniz';
            validated = false;
         }

         if (!item.stock || item.stock === 0) {
            productErrors.stock = 'Lütfen bir stok miktarı giriniz';
            validated = false;
         }

         if (!item.delivery_date) {
            productErrors.delivery_date = 'Lütfen bir teslim iş gününü giriniz';
            validated = false;
         }



         if (item.commission_type === '3' || item.commission_type === null) {
            productErrors.commission_type = 'Lütfen bir komisyon tipi seçiniz';
            validated = false;
         }

         if (!validated) {
            newErrors[index] = productErrors;
         }
      });

      setErrors(newErrors);
      return newErrors.length === 0;
   };

   const RenderValidatioError = (productIndex: any, field: any) => {
      if (errors[productIndex]?.[field]) {
         return <span className="text-red-500 text-sm">{errors[productIndex]?.[field]}</span>
      }
      return null
   }

   const [serviceForm] = Form.useForm()

   const handleScroll = (e: any) => {
      //fixed service ref button on scroll

      if (drawerFormRef.current) {
         if (e.target.scrollTop > drawerFormRef.current.offsetTop) {
            serviceButtonRef.current?.classList.add('fixed', 'bottom-0', 'left-0', 'right-0', 'z-50')
         } else {
            serviceButtonRef.current?.classList.remove('fixed', 'bottom-0', 'left-0', 'right-0', 'z-50')
         }
      } else {
      }
   }

   const handleServiceSave = (e: any) => {
      const productServices = products[selectedIndex].services.map((item: any, index: number) => {
         const product_id = products[selectedIndex].id
         return {
            ...item,
            name: e[`name_${product_id}_${index}`],
            price: e[`price_${product_id}_${index}`],
            commission: e[`commission_${product_id}_${index}`],
            commission_type: e[`commission_type_${product_id}_${index}`],
            description: e[`description_${product_id}_${index}`],
            included_in_total: e[`included_in_total_${product_id}_${index}`] || false
         }
      })

      let newProducts = _.cloneDeep(products)
      newProducts[selectedIndex].services = productServices
      setProducts(newProducts)
      setServicesDrawer(false)
      message.success('Hizmetler kaydedildi')
   }

   const findIndexProductId = (id: any) => {
      const selectedIndex = products.findIndex(item => item.id === id)
      setSelectedIndex(selectedIndex)
      if (products[selectedIndex].services.length > 0) {
         const product_id = products[selectedIndex].id

         products[selectedIndex].services.map((item: any, index: number) => {

            serviceForm.setFieldsValue({
               [`name_${product_id}_${index}`]: item.name,
               [`price_${product_id}_${index}`]: item.price,
               [`commission_${product_id}_${index}`]: item.commission,
               [`commission_type_${product_id}_${index}`]: item.commission_type,
               [`description_${product_id}_${index}`]: item.description,
               [`included_in_total_${product_id}_${index}`]: item.included_in_total
            })
         })


      }
   }

   const CalculateComission = (item: any) => {
      let price = 0
      console.log('item.commission_type', item.commission_type)
      if (item.commission_type == 2) {
         // Kâr sabit fiyat + komisyon
         price = (parseFloat(item.price) + parseFloat(item.commission)) * item.stock;
         console.log('price', price)
      } else if (item.commission_type == 1) {
         // Kâr yüzde olarak fiyat * komisyon / 100 + komisyon
         price = (item.price * item.stock)

         price = (price * parseFloat(item.commission)) / 100 + price
      }

      return MoneyFormat(price, item.currency)
   }

   const CalculateCommisionExplain = (item: any) => {
      let calculateDescription
      // if (item.commission_type == 0) {
      //    // Kâr tipi 0 ise, komisyon = Ürün Fiyatı * Ürün Miktarı + Kâr
      //    calculateDescription = `Birim Fiyat (${item.price}) * Ürün Miktarı (${item.stock}) + Kâr (${item.commission
      //       }) = ${CalculateComission(item)}`
      // } else {
      //    // Diğer durumda, komisyon = (Ürün Fiyatı * Kâr / 100) * Ürün Miktarı + Ürün Fiyatı
      //    calculateDescription = `Birim Fiyat (${item.price}) * Stok (${item.stock}) * Kâr (${item.commission
      //       }) / 100 + Ürün Fiyatı (${item.price}) = ${CalculateComission(item)}`
      // }

      if (item.commission_type == 2) {
         calculateDescription = `Birim Fiyat (${item.price}) + Komisyon (${item.commission}) * Stok (${item.stock}) = ${CalculateComission(item)}`
      }
      else if (item.commission_type == 1) {
         calculateDescription = `Birim Fiyat (${item.price}) * Ürün Miktarı (${item.stock}) * Kâr (${item.commission
            }) / 100 + Ürün Fiyatı (${item.price}) = ${CalculateComission(item)}`
      }

      //kur çeviriyi hesapla
      // [{"currency":"USD-EUR","rate":"0.8957"},{"currency":"EUR-USD","rate":"1.1165"},{"currency":"USD-GBP","rate":"0.7473"},{"currency":"GBP-USD","rate":"1.3382"},{"currency":"USD-TRY","rate":"34.1817"},{"currency":"TRY-USD","rate":"0.0293"},{"currency":"EUR-TRY","rate":"38.1628"},{"currency":"TRY-EUR","rate":"0.0262"},{"currency":"EUR-GBP","rate":"0.8343"},{"currency":"GBP-EUR","rate":"1.1985"},{"currency":"GBP-TRY","rate":"45.7408"},{"currency":"EUR-EUR","rate":1},{"currency":"USD-USD","rate":1},{"currency":"GBP-GBP","rate":1},{"currency":"TRY-TRY","rate":1},{"currency":"TRY-GBP","rate":"0.0219"}]


      let merge = item.currency + '-' + item.converted_currency
      let currency = currencies.find((item: any) => item.currency == merge)

      let price = 0
      if (item.commission_type == 2) {
         // Birim Fiyat + Komisyon * Stok    

         price = (item.price * item.stock) + parseFloat(item.commission);

      } else {
         // Kâr yüzde olarak fiyat * komisyon / 100 + komisyon
         price = parseFloat(item.price) * parseInt(item.stock)

         price = (price * parseFloat(item.commission)) / 100 + price
      }

      let price2 = 0

      if (currency) {

         price2 = price * currency.rate
      }

      if (item.converted_currency !== item.currency) {

         return calculateDescription + " - Kur çevirisi = " + MoneyFormat(price2, item.converted_currency ?? 'TRY')
      }

      return calculateDescription
   }

   const CalculateServiceComission = (index: any) => {
      const price = serviceForm.getFieldValue(`price_${products[selectedIndex].id}_${index}`)
      const commission = serviceForm.getFieldValue(`commission_${products[selectedIndex].id}_${index}`)
      const commission_type = serviceForm.getFieldValue(`commission_type_${products[selectedIndex].id}_${index}`)

      let calculateDescription

      if (commission_type == 0) {
         calculateDescription = `Hizmet Fiyatı (${price}) + Kâr (${commission}) = ${parseFloat(price) + parseFloat(commission)
            }`
      } else {
         calculateDescription = `Hizmet Fiyatı (${price}) * Kâr (${commission}) / 100 + Hizmet Fiyatı (${price}) = ${(parseFloat(price) * parseFloat(commission)) / 100 + parseFloat(price)
            }`
      }

      return calculateDescription
   }

   const groupByXmlFirm = () => {
      //only supplier return
      setSuppliers([])
      dispatch(setOtherSuppliers({}))
      const xmlFirms = products.map(item => item.supplier)
      const uniqueXmlFirms = [...(new Set(xmlFirms) as any)]


      const groupedProducts = uniqueXmlFirms.map(xmlFirm => {
         return {
            supplier: xmlFirm,
            products: products.filter(item => item.supplier === xmlFirm)
         }
      })

      //groupedProducts listele tedarikçi 250k dan fazla olanları listele toplam tutarları



      const totalPrices = groupedProducts.map(item => {
         return item.products.reduce((acc, product) => {
            const price = acc + parseFloat(product.price) * product.stock

            if (price > 250000) {
               return {
                  total: price,
                  supplier: item.supplier
               }
            }
         }, 0)
      })
      let offerSuppliers = offer.otherSuppliers


      if (totalPrices.length == 0) {
         dispatch(setProduct(products))
         handleNext(products)
      } else {
         // if (totalPrices.length > 0) {
         //    if (totalPrices[0] !== undefined) {
         //       setSuppliers(totalPrices)
         //       setSupplierModal(true)
         //    } else {
         //       dispatch(setProduct(products))
         //       handleNext(products)
         //    }
         // } else {

         // }
         dispatch(setProduct(products))
         handleNext(products)
      }
   }



   const toggleServiceInTotal = (serviceIndex: number) => {
      const updatedServices = products[selectedIndex].services.map((service: any, idx: any) => {
         if (idx === serviceIndex) {
            return {
               ...service,
               included_in_total: !service.included_in_total
            };
         }
         return service;
      });
      const newProducts = _.cloneDeep(products);
      newProducts[selectedIndex].services = updatedServices;
      setProducts(newProducts);
   };

   // HTML'den plain text çıkaran yardımcı fonksiyon
   const getPlainTextFromHtml = (html: string) => {
      const temp = document.createElement('div');
      temp.innerHTML = html;
      return temp.textContent || temp.innerText || '';
   };

   // Rezervasyon kontrolü
   const checkReserve = async (productModel: string, newStock: number, index: number, product: any) => {
      try {
         const datas = await fetch(`${apiUrl}/api/offers/check-reserve`, {
            method: 'POST',
            body: JSON.stringify({ product_model: productModel })
         })

         const response = await datas.json()

         if (response.status) {
            setReserveUserName(response.user);
            setCheckingProduct({
               ...product,
               newStock,
               index,
               reserveDate: response.reserve_date,
               reservedQty: response.product_qty
            });
            setReserveCheckModal(true);
         } else {
            onChangeProductInput(index, 'stock', newStock);
         }
      } catch (error) {
         console.error('Rezervasyon kontrolü sırasında hata:', error);
         message.error('Rezervasyon kontrolü yapılırken bir hata oluştu');
      }
   };

   // Stok input değişikliği
   const handleStockChange = (e: any, index: number, product: any) => {
      const newStock = e.target.value;

      if (product.supplier?.toLowerCase() === 'gimel') {
         checkReserve(product.model, newStock, index, product);
      } else {
         onChangeProductInput(index, 'stock', newStock);
      }
   };

   return (
      <CustomDrawer
         isOpen={drawerProps.open}
         onClose={drawerProps.onClose}
         title="Yeni teklif oluştur - Adım 2"
         extra={
            <div className="flex gap-2">
               <Button
                  color="primary"
                  onClick={() => handlePrev()}
               >
                  Geri
               </Button>
               <Button
                  color="primary"
                  onClick={() => {
                     if (products.length === 0) {
                        message.error('Lütfen en az bir ürün ekleyiniz')
                        return
                     }
                     if (!handleCheckProductValidation()) {
                        return
                     }
                     groupByXmlFirm()
                  }}
               >
                  İleri
               </Button>
            </div>
         }
         {...drawerProps}
      >
         <Modal
            width={1000}
            footer={null}
            title="Birim Maliyet Tekli Kontrolü"
            open={supplierModal}
            onCancel={() => setSupplierModal(false)}
         >
            <Form
               layout="vertical"
               form={supplierForm}
               onFinish={(values: any) => {
                  const supplierForms = suppliers.map(item => {
                     const supplier = {
                        supplier: item.supplier,
                        total: item.total,
                        values: [
                           {
                              offer_price_1: values[`offer_price_1_${item.supplier}`],
                              offer_supplier_1: values[`offer_supplier_1_${item.supplier}`],
                              offer_price_2: values[`offer_price_2_${item.supplier}`],
                              offer_supplier_2: values[`offer_supplier_2_${item.supplier}`],
                              offer_price_3: values[`offer_price_3_${item.supplier}`],
                              offer_supplier_3: values[`offer_supplier_3_${item.supplier}`]
                           }
                        ]
                     }

                     return supplier
                  })

                  dispatch(setOtherSuppliers(supplierForms))
                  setSupplierModal(false)
                  message.success('Tedarikçi teklifleri kaydedildi')
                  dispatch(setProduct(products))
                  handleNext(products)
               }}
            >
               {suppliers?.map((item, index) => {
                  if (item?.supplier) {
                     return (
                        <div>
                           <Alert color="info" className="flex flex-col mb-5">
                              <span>{item?.supplier} </span>
                              <span>{MoneyFormat3(item?.total)} ₺</span>
                           </Alert>
                           <div className="grid grid-cols-2 gap-2 my-2">
                              {/* {JSON.stringify(item)} */}

                              <Form.Item label="2.Birim Maliyet Teklif" name={`offer_price_2_${item.supplier}`}>
                                 <InputRiz />
                              </Form.Item>
                              <Form.Item label="2.Tedarikçi Bilgisi" name={`offer_supplier_2_${item.supplier}`}>
                                 <InputRiz />
                              </Form.Item>
                              <Form.Item label="3.Birim Maliyet Teklif" name={`offer_price_3_${item.supplier}`}>
                                 <InputRiz />
                              </Form.Item>
                              <Form.Item label="3.Tedarikçi Bilgisi" name={`offer_supplier_3_${item.supplier}`}>
                                 <InputRiz />
                              </Form.Item>
                           </div>
                           <Divider />
                        </div>
                     )
                  }
               })}
               <Button color="primary" type="submit">
                  Kaydet
               </Button>
            </Form>
         </Modal>
         <>
            <Form onFinish={handleServiceSave} className="w-full" layout="vertical">
               <Drawer
                  height="100%"
                  placement="bottom"
                  open={servicesDrawer}
                  onClose={() => {
                     setSelectedIndex(null)
                     serviceForm.resetFields()
                     setServicesDrawer(false)
                  }}
               >
                  <Modal
                     footer={null}
                     title="Hizmet Ekle"
                     open={serviceSearchModal}
                     closable
                     onCancel={() => setServiceSearchModal(false)}
                  >
                     <Alert color="info" className="mb-5">
                        Hizmet adını yazın ve enter tuşuna basın
                     </Alert>
                     <Input
                        startContent={!serviceLoading ? <IconSearch /> : <Loader />}
                        variant="bordered"
                        radius="sm"
                        placeholder="Hizmet adını yazın ve enter tuşuna basın"
                        size="lg"
                        classNames={{
                           input: 'ring-0 outline-none focus:ring-0 focus:outline-none  border-0'
                        }}
                        onKeyDown={(e: any) => {

                           if (e.keyCode === 13) {

                              handleAddServiceToProduct({
                                 id: Math.random(),
                                 name: e.target.value,
                                 price: 0,
                                 stock: 0,
                                 description: '',
                                 included_in_total: true
                              })
                           }
                           setSearch(e.target.value)
                        }}
                     />
                     {/* <div className="max-h-96 overflow-y-auto">
                        {serviceData?.data?.map((item: any) => {
                           return (
                              <div
                                 key={item.id}
                                 className="flex gap-2 items-center justify-between w-full cursor-pointer h-20 border-b border-gray-100"
                              >
                                 <span className="font-bold">{item.name}</span>
                                 <Button
                                    onClick={() => handleAddServiceToProduct(item)}
                                    color="primary"
                                    startContent={<IconPlus />}
                                    variant="shadow"
                                 >
                                    Ekle
                                 </Button>
                              </div>
                           )
                        })}
                     </div> */}
                  </Modal>
                  <div className="mt-5" ref={drawerFormRef} onScroll={handleScroll}>
                     <Button
                        className="w-full"
                        onClick={() => setServiceSearchModal(true)}
                        color="primary"
                        variant="shadow"
                     >
                        Hizmet Ekle
                     </Button>
                     <div className="mt-5">
                        {products[selectedIndex]?.services.length === 0 && <Empty description="Hizmet bulunamadı" />}

                        <Form form={serviceForm} onFinish={handleServiceSave} className="w-full" layout="vertical">
                           {products[selectedIndex]?.services.length > 0 && (
                              <Button
                                 startContent={<IconConePlus />}
                                 size="lg"
                                 className="w-full"
                                 type="submit"
                                 color="secondary"
                                 variant="ghost"
                              >
                                 Kaydet
                              </Button>
                           )}
                           {products[selectedIndex]?.services?.map((item: any, index: number) => (
                              <Card
                                 key={index}
                                 className="mt-2"
                                 extra={
                                    <div className="flex gap-2">
                                       <Button
                                          onClick={() => {
                                             let newProducts = _.cloneDeep(products)
                                             newProducts[selectedIndex].services = newProducts[
                                                selectedIndex
                                             ].services.filter((service: any, serviceIndex: number) => {
                                                return serviceIndex !== index
                                             })
                                             setProducts(newProducts)
                                          }}
                                          color="danger"
                                          variant="bordered"
                                       >
                                          Sil
                                       </Button>
                                    </div>
                                 }
                              >
                                 <Alert color="info" className="flex flex-col mb-5">
                                    <Form.Item name={`name_${products[selectedIndex].id}_${index}`} label="Hizmet adı">
                                       <InputRiz />
                                    </Form.Item>
                                    <div className="grid grid-cols-3 gap-2 my-2">
                                       <Form.Item label="Birim fiyat" name={`price_${products[selectedIndex].id}_${index}`}>
                                          <InputRiz type="number" />
                                       </Form.Item>
                                       <Form.Item label="Kâr" name={`commission_${products[selectedIndex].id}_${index}`}>
                                          <InputRiz type="number" />
                                       </Form.Item>
                                       <Form.Item label="Kâr Tipi" name={`commission_type_${products[selectedIndex].id}_${index}`}>
                                          <select className="w-full p-2 border border-gray-300 rounded-md">
                                             <option value="3">Seçim Yapın</option>
                                             <option value="1">Sabit</option>
                                             <option value="2">Yüzde</option>
                                          </select>
                                       </Form.Item>
                                    </div>
                                    <Form.Item
                                       label="Birim Maliyete Dahil Et"
                                       name={`included_in_total_${products[selectedIndex].id}_${index}`}
                                       valuePropName="checked"
                                    >

                                       <Switch
                                          size="sm"
                                          checked={item.included_in_total}
                                          onChange={(e: any) => {
                                             let newProducts = _.cloneDeep(products)
                                             newProducts[selectedIndex].services[index].included_in_total = e
                                             setProducts(newProducts)
                                          }}
                                       />
                                    </Form.Item>
                                    <Form.Item name={`description_${products[selectedIndex].id}_${index}`} label="Açıklama">
                                       <ReactQuill
                                          style={{ height: 300 }}
                                          value={item.description}
                                       />
                                    </Form.Item>
                                 </Alert>
                              </Card>
                           ))}
                        </Form>
                     </div>
                  </div>
               </Drawer>
            </Form>
            <div>
               <Drawer
                  placement="bottom"
                  height={window.innerHeight - 100}
                  footer={null}
                  title="Yeni Ürün Ekle"
                  open={open}
                  closable
                  onClose={() => setOpen(false)}
               >
                  <Products
                     currentProducts={products}
                     setOfferProduct={setProducts}
                     isFromOffer={true}
                     handleClose={setOpen}
                  />
               </Drawer>
            </div>
            <Drawer
               open={addProductModal}
               onClose={() => setAddProductModal(false)}
               placement="bottom"
               height="100%"
               title="Yeni Ürün Ekle"
               extra={
                  <Button
                     onClick={() => {
                        addProductForm.submit()
                     }}
                     color="primary"
                  >
                     Kaydet
                  </Button>
               }
            >
               <Card>
                  <Form
                     layout="vertical"
                     form={addProductForm}
                     onFinish={(values: any) => {
                        const findProduct = products.find(item => item.id === values.id)
                        if (findProduct) {
                           message.error('Bu ürün zaten ekli')
                           return
                        }

                        let productArray = {
                           id: values.model,
                           name: values.name,
                           price: parseFloat(values.price).toFixed(2),
                           stock: values.stock,
                           product_qty: values.stock,
                           delivery_date: '',
                           commission: 0,
                           commission_type: '3',
                           description: values.description,
                           services: []
                        }

                        setProducts([...products, productArray])
                        setOpen(false)
                        setAddProductModal(false)
                     }}
                  >
                     <Form.Item
                        label="Ürün Adı"
                        name="name"
                        rules={[{ required: true, message: 'Lütfen bir ürün adı giriniz' }]}
                     >
                        <InputRiz />
                     </Form.Item>
                     <Form.Item
                        label="Ürün Stok Kodu"
                        name="model"
                        rules={[{ required: true, message: 'Lütfen  stok kodu giriniz' }]}
                     >
                        <InputRiz />
                     </Form.Item>
                     <Form.Item
                        label="Ürün Fiyatı"
                        name="price"
                        rules={[{ required: true, message: 'Lütfen bir ürün fiyatı giriniz' }]}
                     >
                        <InputRiz />
                     </Form.Item>
                     <Form.Item
                        label="Stok"
                        name="stock"
                        rules={[{ required: true, message: 'Lütfen bir stok miktarı giriniz' }]}
                     >
                        <InputRiz />
                     </Form.Item>
                     <Form.Item label="Açıklama" name="description">
                        {/* <Textarea /> */}
                        <ReactQuill
                           style={{ height: 300 }}
                           value={''}
                           onChange={(value: any) => {

                           }}
                        />
                     </Form.Item>
                     <Form.Item label="Görsel" name="image">
                        <Upload
                           onChange={e => {
                              setProductImage(e.file)
                           }}
                           accept="image/*"
                           name="image"
                           listType="picture"
                           className="upload-list-inline"
                        >
                           <Button>Görsel Yükle</Button>
                        </Upload>
                     </Form.Item>
                  </Form>
               </Card>
            </Drawer>
            <div className="@container">
               <Card
                  title="Teklif Detayları"
                  extra={
                     <div className="gap-2">
                        <Button
                           onClick={() => {
                              setOpen(true)
                           }}
                           color="primary"
                           variant="shadow"
                        >
                           Ürün Ekle
                        </Button>
                     </div>
                  }
               ></Card>
               <Form form={productForm} layout="vertical" className="">
                  <div className="mt-5 space-y-2" ref={bodyRef}>
                     {products.map((item: any, index: number) => {
                        return (
                           <Card
                              title={
                                 <div className="flex gap-2 items-center">
                                    <Image
                                       width={50}
                                       src={item.image}
                                       alt="product"
                                       className="w-10 h-10 object-cover rounded-full"
                                    />
                                    <div className="flex flex-col">
                                       <span>
                                          Ürün Adı <span className="font-bold"> {item.name}</span>
                                       </span>
                                       <span>
                                          Stok Kodu <span className="font-bold"> {item.model}</span>
                                       </span>
                                    </div>
                                 </div>
                              }
                              key={index}
                              extra={
                                 <div className="gap-2 flex">
                                    <Button
                                       onClick={() => {
                                          findIndexProductId(item.id)
                                          setServicesDrawer(true)
                                       }}
                                       color="secondary"
                                       startContent={<IconCubePlus />}
                                       variant="bordered"
                                    >
                                       Hizmet Ekle / Düzenle
                                    </Button>
                                    <Button
                                       onClick={() => {
                                          setProducts(products.filter((_, i) => i !== index))
                                          dispatch(setProduct(products.filter((_, i) => i !== index)))
                                       }}
                                       color="danger"
                                       startContent={<IconTrash />}
                                       variant="bordered"
                                    >
                                       Sil
                                    </Button>
                                 </div>
                              }
                           >
                              <div>
                                 <Alert color="info" className="flex flex-col mb-5">
                                    {CalculateCommisionExplain(item)}
                                 </Alert>
                              </div>
                              <div className="grid grid-cols-6 gap-5">
                                 <div>
                                    <InputRiz
                                       label={`Maliyet Birim Fiyat (${item.currency})`}
                                       onChange={e => {
                                          onChangeProductInput(index, 'price', e.target.value)
                                       }}
                                       value={item.price}
                                       type="number"
                                    />
                                    {RenderValidatioError(index, 'price')}
                                 </div>
                                 <div>
                                    <label className="block text-sm font-medium text-gray-700">
                                       Çevirmek istediğiniz para birimi
                                    </label>
                                    <select
                                       className="w-full p-2 border border-gray-300 rounded-md"
                                       onChange={e => {
                                          onChangeProductInput(index, 'converted_currency', e.target.value)
                                       }}

                                       id=""
                                    >
                                       <option
                                          selected={item.converted_currency == 'TRY'}
                                          value={"TRY"}>TRY</option>
                                       <option
                                          selected={item.converted_currency == 'USD'}
                                          value={"USD"}>USD</option>
                                       <option
                                          selected={item.converted_currency == 'EUR'}
                                          value={"EUR"}>EUR</option>
                                       <option
                                          selected={item.converted_currency == 'GBP'}
                                          value={"GBP"}>GBP</option>
                                    </select>
                                 </div>
                                 <div>
                                    {/* <Alert variant="flat" color="danger" className="text-center">
                                                        Bu ürünün stoğu {item.stock} {item.product_qty} <b>{item.supplier}</b> firmasına aittir Değişiklik yaparken bilgi alınız
                                                    </Alert> */}
                                    {item.supplier == 'gimel' && parseInt(item.stock) > parseInt(item.product_qty) && (
                                       <Alert size="sm" variant="flat" color="danger" className="text-center">
                                          Bu ürünün stoğu <b>{item.supplier}</b> firmasına aittir Değişiklik yaparken
                                          bilgi alınız
                                       </Alert>
                                    )}
                                    <InputRiz
                                       label="Adet"
                                       onChange={e => handleStockChange(e, index, item)}
                                       onBlur={(e: any) => {
                                          handleStockChange(e, index, item);
                                       }}
                                       value={item.stock}
                                       type="number"
                                    />
                                    {RenderValidatioError(index, 'stock')}
                                 </div>
                                 <div>
                                    <InputRiz
                                       label="Maximum Teslim Günü"
                                       onChange={e => {
                                          onChangeProductInput(index, 'delivery_date', e.target.value)
                                       }}
                                       placeholder='Gün Sayısı Giriniz'
                                       value={item.delivery_date}
                                       type="number"
                                    />
                                    {RenderValidatioError(index, 'delivery_date')}
                                 </div>
                                 <div>
                                    <InputRiz
                                       label="Kâr"
                                       type="number"
                                       onChange={e => {
                                          onChangeProductInput(index, 'commission', e.target.value)
                                       }}
                                       value={item.commission}
                                    />
                                    {RenderValidatioError(index, 'commission')}
                                 </div>
                                 <div>
                                    <label className="block text-sm font-medium text-gray-700">Kâr Tipi</label>


                                    <select
                                       className="w-full p-2 border border-gray-300 rounded-md"
                                       name="commission_type"
                                       id=""
                                       onChange={e => {
                                          onChangeProductInput(index, 'commission_type', e.target.value)
                                       }}
                                    >
                                       <option selected={item.commission_type == 3} value="3">
                                          Seçim Yapın
                                       </option>
                                       <option selected={item.commission_type == 1} value="1">
                                          Yüzde
                                       </option>
                                       <option selected={item.commission_type == 2} value="2">
                                          Sabit
                                       </option>
                                    </select>

                                    {RenderValidatioError(index, 'commission_type')}
                                 </div>
                              </div>
                              <div className="grid grid-cols-1">
                                 <Form.Item label="Açıklama">
                                    <ReactQuill
                                       style={{ height: 300 }}
                                       value={item.description}
                                       onChange={(value: any) => {
                                          const plainText = getPlainTextFromHtml(value);
                                          if (plainText.length > 250) {
                                             message.error('Açıklama en fazla 250 karakter olabilir');
                                             return;
                                          }
                                          onChangeProductInput(index, 'description', value);
                                       }}
                                    />
                                 </Form.Item>
                              </div>

                              <div className="mt-2">
                                 {item.services.length > 0 && (
                                    <div>
                                       <Alert color="info">
                                          Hizmet Toplamı: {item.services.length > 0 ? item.services.length : 0}
                                       </Alert>

                                       <table className="w-full">
                                          <thead>
                                             <tr>
                                                <th className="text-left p-2">Hizmet Adı</th>
                                                <th className="text-left p-2">Fiyat</th>
                                                <th className="text-left p-2">Kâr</th>
                                                <th className="text-left p-2">Kâr Tipi</th>
                                                <th className="text-left p-2">Açıklama</th>
                                                <th className="text-left p-2">Birim Fiyat Dahil mi ?</th>

                                                <th className="text-left p-2">Düzenle</th>
                                             </tr>
                                          </thead>
                                          <tbody>
                                             {item.services.map((service: any, serviceIndex: number) => {
                                                return (
                                                   <tr key={serviceIndex}>
                                                      <td className="p-2">{service.name}</td>
                                                      <td className="p-2">
                                                         {service.price} {item.currency}
                                                      </td>
                                                      <td className="p-2">{service.commission}</td>
                                                      <td className="p-2">
                                                         {service.commission_type == 0 ? 'Sabit Kâr' : 'Yüzde Kâr'}
                                                      </td>
                                                      <td className="p-2">{service.description}</td>
                                                      <td className="p-2">
                                                         {service.included_in_total ? 'Evet' : 'Hayır'}
                                                      </td>
                                                      <td className="p-2">
                                                         <Button
                                                            color="primary"
                                                            onClick={() => {
                                                               findIndexProductId(item.id)
                                                               setServicesDrawer(true)
                                                            }}
                                                         >
                                                            Düzenle
                                                         </Button>
                                                      </td>
                                                   </tr>
                                                )
                                             })}
                                          </tbody>
                                       </table>
                                    </div>
                                 )}
                              </div>

                              {(item.supplier == 'gimel' || item.supplier == 'Gimel') && (
                                 <div className="grid grid-cols-1">
                                    <Form.Item
                                       label="Stoğu Rezerve Et"
                                       valuePropName="checked"
                                       name={`isReserve_${item.id}`}
                                    >
                                       <Switch
                                          label="Stoğu Rezerve Et"
                                          onChange={e => {
                                             onChangeProductInput(index, 'isReserve', e.target.value)
                                          }}
                                       />
                                    </Form.Item>
                                    {productForm.getFieldValue(`isReserve_${item.id}`) && (
                                       <Alert color="info">
                                          Bu ürün şu an {moment(item.reserveEndDate).format('DD.MM.YYYY')} ({moment(item.reserveEndDate).diff(moment(), 'days')} gün kaldı)
                                       </Alert>
                                    )}
                                 </div>
                              )}
                           </Card>
                        )
                     })}
                  </div>
               </Form>
            </div>
         </>
         <Modal
            open={reserveCheckModal}
            onCancel={() => {
               setReserveCheckModal(false);
               setCheckingProduct(null);
            }}
            title={
               <div className="flex items-center gap-2 text-warning">
                  <IconInfoCircle size={20} />
                  <span>Rezervasyon Uyarısı</span>
               </div>
            }
            footer={[
               <Button
                  key="cancel"
                  variant="light"
                  onClick={() => {
                     setReserveCheckModal(false);
                     setCheckingProduct(null);
                  }}
               >
                  İptal
               </Button>,
               <Button
                  key="continue"
                  color="primary"
                  onClick={() => {
                     if (checkingProduct) {
                        onChangeProductInput(checkingProduct.index, 'stock', checkingProduct.newStock);
                     }
                     setReserveCheckModal(false);
                     setCheckingProduct(null);
                  }}
               >
                  Yine de Değiştir
               </Button>
            ]}
         >
            <div className="p-4">
               <Alert
                  color="warning"
                  className="mb-4"
               >
                  <div className="space-y-2">
                     <p className="font-medium">Bu ürün rezerve edilmiş!</p>
                     <p>
                        Bu ürün şu an <span className="font-semibold text-warning">{reserveUserName}</span> tarafından rezerve edilmiş durumda.
                        Stok değişikliği yapmadan önce lütfen ilgili kişiyle iletişime geçin.
                     </p>
                  </div>
               </Alert>

               {checkingProduct && (
                  <div className="space-y-4">
                     {/* Ürün Detayları */}
                     <div className="p-4 bg-gray-50 rounded-lg">
                        <div className="text-sm text-gray-600 mb-2">Ürün Bilgileri</div>
                        <div className="font-medium">{checkingProduct.name}</div>
                        <div className="text-sm text-gray-500">Stok Kodu: {checkingProduct.model}</div>
                     </div>

                     {/* Rezervasyon Detayları */}
                     <div className="p-4 bg-blue-50 rounded-lg">
                        <div className="text-sm text-blue-600 mb-2">Rezervasyon Detayları</div>
                        <div className="space-y-2">
                           <div className="flex items-center justify-between text-sm">
                              <span className="text-gray-600">Rezervasyon Tarihi:</span>
                              <span className="font-medium">
                                 {moment(checkingProduct.reserveDate).format('DD.MM.YYYY')}
                              </span>
                           </div>
                           <div className="flex items-center justify-between text-sm">
                              <span className="text-gray-600">Rezerve Edilen Miktar:</span>
                              <span className="font-medium">{checkingProduct.reservedQty} Adet</span>
                           </div>
                        </div>
                     </div>

                     {/* Stok Değişikliği */}
                     <div className="p-4 bg-green-50 rounded-lg">
                        <div className="text-sm text-green-600 mb-2">Talep Edilen Değişiklik</div>
                        <div className="flex items-center gap-3">
                           <div className="flex-1 text-center p-2 bg-white rounded border border-gray-200">
                              <div className="text-xs text-gray-500 mb-1">Mevcut Stok</div>
                              <div className="font-medium">{checkingProduct.stock}</div>
                           </div>
                           <IconChevronRight size={16} className="text-gray-400" />
                           <div className="flex-1 text-center p-2 bg-white rounded border border-gray-200">
                              <div className="text-xs text-gray-500 mb-1">Yeni Stok</div>
                              <div className="font-medium">{checkingProduct.newStock}</div>
                           </div>
                        </div>
                     </div>
                  </div>
               )}
            </div>
         </Modal>
      </CustomDrawer>
   )
}

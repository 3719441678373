import { motion, AnimatePresence } from 'framer-motion';
import { IconFileImport, IconUserPlus, IconX } from '@tabler/icons-react';

interface AddCustomerChoiceModalProps {
    isOpen: boolean;
    onClose: () => void;
    onManualAdd: () => void;
    onExcelAdd: () => void;
}

export default function AddCustomerChoiceModal({
    isOpen,
    onClose,
    onManualAdd,
    onExcelAdd
}: AddCustomerChoiceModalProps) {
    return (
        <AnimatePresence>
            {isOpen && (
                <div className="fixed inset-0 z-50 overflow-y-auto">
                    <div className="min-h-screen px-4 text-center">
                        {/* Backdrop */}
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            className="fixed inset-0 bg-black/50 backdrop-blur-sm"
                            onClick={onClose}
                        />

                        {/* Modal positioning trick */}
                        <span className="inline-block h-screen align-middle" aria-hidden="true">
                            &#8203;
                        </span>

                        {/* Modal */}
                        <motion.div
                            initial={{ opacity: 0, scale: 0.95, y: 20 }}
                            animate={{ opacity: 1, scale: 1, y: 0 }}
                            exit={{ opacity: 0, scale: 0.95, y: 20 }}
                            transition={{ type: "spring", duration: 0.5 }}
                            className="inline-block w-full max-w-2xl my-8 text-left align-middle transition-all transform"
                        >
                            <div className="relative bg-white rounded-2xl shadow-2xl">
                                {/* Close button */}
                                <button
                                    onClick={onClose}
                                    className="absolute right-4 top-4 p-2 hover:bg-gray-100 rounded-full transition-colors"
                                >
                                    <IconX className="w-5 h-5 text-gray-500" />
                                </button>

                                <div className="p-8">
                                    {/* Header */}
                                    <div className="text-center mb-8">
                                        <h2 className="text-2xl font-bold text-gray-800">
                                            Müşteri Ekleme Yöntemi
                                        </h2>
                                        <p className="mt-2 text-gray-600">
                                            Tercih ettiğiniz müşteri ekleme yöntemini seçin
                                        </p>
                                    </div>

                                    {/* Options */}
                                    <div className="grid grid-cols-2 gap-6">
                                        <motion.button
                                            whileHover={{ y: -4 }}
                                            whileTap={{ scale: 0.98 }}
                                            transition={{ type: "spring", stiffness: 400 }}
                                            className="relative group"
                                            onClick={onManualAdd}
                                        >
                                            <div className="absolute inset-0 rounded-2xl bg-gradient-to-r from-primary to-blue-600 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                                            <div className="relative p-6 bg-white rounded-2xl border-2 border-primary/20 group-hover:border-transparent transition-colors duration-300">
                                                <div className="flex flex-col items-center gap-4">
                                                    <div className="w-20 h-20 rounded-2xl bg-primary/5 flex items-center justify-center group-hover:bg-white/10 transition-colors duration-300">
                                                        <IconUserPlus className="w-10 h-10 text-primary group-hover:text-white transition-colors duration-300" />
                                                    </div>
                                                    <div className="text-center group-hover:text-white transition-colors duration-300">
                                                        <h3 className="font-semibold text-xl mb-2">Manuel Ekle</h3>
                                                        <p className="text-sm text-gray-500 group-hover:text-white/80">
                                                            Tek tek müşteri bilgilerini girerek ekleyin
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </motion.button>

                                        <motion.button
                                            whileHover={{ y: -4 }}
                                            whileTap={{ scale: 0.98 }}
                                            transition={{ type: "spring", stiffness: 400 }}
                                            className="relative group"
                                            onClick={onExcelAdd}
                                        >
                                            <div className="absolute inset-0 rounded-2xl bg-gradient-to-r from-green-500 to-green-600 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                                            <div className="relative p-6 bg-white rounded-2xl border-2 border-green-500/20 group-hover:border-transparent transition-colors duration-300">
                                                <div className="flex flex-col items-center gap-4">
                                                    <div className="w-20 h-20 rounded-2xl bg-green-500/5 flex items-center justify-center group-hover:bg-white/10 transition-colors duration-300">
                                                        <IconFileImport className="w-10 h-10 text-green-500 group-hover:text-white transition-colors duration-300" />
                                                    </div>
                                                    <div className="text-center group-hover:text-white transition-colors duration-300">
                                                        <h3 className="font-semibold text-xl mb-2">Excel İle Ekle</h3>
                                                        <p className="text-sm text-gray-500 group-hover:text-white/80">
                                                            Excel dosyası ile toplu müşteri ekleyin
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </motion.button>
                                    </div>
                                </div>
                            </div>
                        </motion.div>
                    </div>
                </div>
            )}
        </AnimatePresence>
    );
} 
import { routes } from 'config/routes'
import { List, TableColumnProps, Modal, Drawer, message, Form, Select, Spin, Empty } from 'antd'
import { useTranslation } from 'react-i18next'
import PageHeader from 'components/page-header'
import {
   Button,
   CardBody,
   CardHeader,
   Chip,
   Card,
   Checkbox,
   SelectItem,
   Input,
   Popover,
   PopoverTrigger,
   PopoverContent,
   Tooltip
} from '@nextui-org/react'
import { Link, useNavigate } from 'react-router-dom'
import useFetch from 'hooks/use-fetch'
import { useEffect, useState } from 'react'
import ActionButton from './ActionButton'
import moment from 'moment'
import {
   OFFER_MANAGEMENT_LIST,
   OFFER_MANAGEMENT_LIST_URL,
   OFFER_MANAGEMENT_SHOW,
   OFFER_MANAGEMENT_SHOW_URL,
   OFFER_MANAGEMENT_STORE
} from 'api/offer-management'
import SearchInput from 'components/Input'
import TableComponents from 'components/Table'
import {
   IconBox,
   IconBrandWhatsapp,
   IconDots,
   IconEdit,
   IconFileInvoice,
   IconFilePlus,
   IconMailBolt,
   IconPdf,
   IconPhone,
   IconWebhook,
   IconInbox,
   IconFilter,
   IconSearch,
   IconEye,
   IconUser,
   IconDotsVertical,
   IconRefresh,
   IconX
} from '@tabler/icons-react'
import { useDispatch, useSelector } from 'react-redux'
import offer, { setContactId, setOfferNumber, setProduct } from 'features/user/offer'
import { RootState } from 'store'
import { setLoading } from 'features/user/app'
import SuccessModal from 'components/Success'
import { USER_SHOW } from 'api/user'
import { offerAdminStatus } from 'utils/offer'
import UserFilter from 'components/FilterContainer/User'
import Preview from '../offers-management/Preview'
import { Alert } from 'rizzui'
import Pagination from 'components/ui/pagination'
import CardComponents from 'components/CardData'
import { motion, AnimatePresence } from 'framer-motion'
import { setTotalRequests } from 'features/user/user'

const PreviewModal = ({ isOpen, onClose, item }: { isOpen: boolean; onClose: () => void; item: any }) => {
   return (
      <div className={`
           fixed inset-0 z-50 
           flex items-center justify-center
           ${isOpen ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'}
           transition-opacity duration-300
       `}>
         {/* Backdrop */}
         <div
            className="absolute inset-0 bg-black/30 backdrop-blur-sm"
            onClick={onClose}
         />

         {/* Modal Content */}
         <motion.div
            initial={{ opacity: 0, scale: 0.95, y: 20 }}
            animate={{ opacity: 1, scale: 1, y: 0 }}
            exit={{ opacity: 0, scale: 0.95, y: 20 }}
            className={`
                   relative w-[75vw] h-[95vh]
                   bg-white rounded-2xl
                   overflow-hidden
                   shadow-2xl
                   flex flex-col
               `}
         >
            {/* Header */}
            <div className="
                   flex items-center justify-between
                   px-6 py-4
                   border-b border-gray-100
               ">
               <div className="flex items-center gap-3">
                  <div className="p-2 rounded-xl bg-primary/10">
                     <IconBox className="w-5 h-5 text-primary" />
                  </div>
                  <h2 className="text-xl font-semibold text-gray-800">
                     Ürün Önizleme
                  </h2>
               </div>

               <button
                  onClick={onClose}
                  className="
                           p-2 rounded-full
                           hover:bg-gray-100
                           transition-colors
                       "
               >
                  <IconX className="w-5 h-5 text-gray-500" />
               </button>
            </div>

            {/* Body */}
            <div className="flex-1 overflow-y-auto p-6">
               <div className="max-w-full mx-auto">
                  {item && <Preview item={item} />}
               </div>
            </div>
         </motion.div>
      </div>
   );
};

export default function RequestList({
   customer_id,
   user_id
}: {
   customer_id?: string | number
   user_id?: string | number
}) {
   const { t } = useTranslation()
   const pageHeader = {
      title: 'Talepler Yönetimi',
      breadcrumb: [
         {
            href: routes.dashboard,
            name: t('aside.dashboard')
         },
         {
            href: routes.dashboard,
            name: 'Talep Yönetimi'
         }
      ]
   }

   const app = useSelector((state: RootState) => state.app)
   const requestModal = useSelector((state: RootState) => state.requestModal)
   const user = JSON.parse(localStorage.getItem('user') as any)
   const [form] = Form.useForm()
   const navigate = useNavigate()
   const dispatch = useDispatch()
   const [offerManagementQuery, { data, loading }] = useFetch<any>()
   const [getOfferLastIdQuery, { data: lastIdData, loading: lastIdLoading }] = useFetch<any>()
   const [getOffer, { data: offerData, loading: offerLoading }] = useFetch<any>()
   const [getOffersFromOfferGroupQuery, { data: offersFromOfferGroupData, loading: offersFromOfferGroupLoading }] =
      useFetch<any>()
   const [convertOfferToOrderMutation, { data: convertOfferToOrderData, loading: convertOfferToOrderLoading }] =
      useFetch<any>()

   const [usersQuery, { data: usersData, loading: usersLoading }] = useFetch<any>()
   const [groupByAssignedFrom, { data: groupByAssignedFromData, loading: groupByAssignedFromLoading }] = useFetch<any>()

   const [page, setPage] = useState<number>(1)
   const [search, setSearch] = useState<string>('')
   const [offerStatus, setOfferStatus] = useState<any>(null)
   const [offerType, setOfferType] = useState<any>(null)
   const [offerListModal, setOfferListModal] = useState<boolean>(false)
   const [previewItem, setPreviewItem] = useState<any>({})
   const [previewDrawer, setPreviewDrawer] = useState<boolean>(false)
   const [successModal, setSuccessModal] = useState<boolean>(false)
   const [isMobile, setIsMobile] = useState<boolean>(false)

   const onFilter = (values: any) => {
      let object: any = {}

      // page,
      // search,
      // offerStatus,
      // offerType
      object.page = page
      object.customer_id = customer_id ?? null
      object.user_id = user_id ?? null
      object.who_assigned = values?.who_assigned ?? null




      const result = '?' + new URLSearchParams(object).toString()



      //url = '/offers?page=' + page + '&search=' + search + '&offerStatus=' + offerStatus

      let url = 'offers/requests/admin' + result

      //@ts-ignore
      offerManagementQuery(OFFER_MANAGEMENT_STORE, {
         endPoint: url,
         body: {
            ...values,
            ...(user?.role === 'user' ? { user_ids: [user?.id] } : {}),
            ...(user_id ? { user_ids: [user_id] } : {})
         }
      })
   }

   const setHide = (id: number) => {
      //@ts-ignore
      offerManagementQuery(OFFER_MANAGEMENT_LIST, {
         endPoint: '/offers/requests/' + user?.role
      })
   }

   const columns: TableColumnProps<any>[] = [
      {
         title: 'Teklif Grup No',
         dataIndex: 'no',
         key: 'no',
         render: (no: any, record) => {
            return <div className="flex items-center gap-3">{record.id}</div>
         }
      },
      {
         title: 'Müşteri',
         dataIndex: 'id',
         key: 'id',
         render: (id: any, record: any) => {
            const parse = JSON.parse(record?.offer?.customer_json ?? '{}')

            let name: any = ''

            if (parse?.name) {
               name = parse?.name
            } else if (record?.offers?.contact?.name) {
               name = record?.offers?.contact?.name
            } else if (record?.offer?.contact?.name) {
               name = record?.offer?.contact?.name
            }

            return <div className="flex items-center gap-3">{name ?? 'Bilinmiyor'}</div>
         }
      },
      {
         title: 'E-posta Adresi',
         dataIndex: 'id',
         key: 'id',
         render: (id: any, record: any) => {
            const parse = JSON.parse(record?.offer?.customer_json ?? '{}')

            let email: any = ''

            if (parse?.email) {
               email = parse?.email
            } else if (record?.offers?.contact?.email) {
               email = record?.offers?.contact?.email
            } else if (record?.offer?.contact?.email) {
               email = record?.offer?.contact?.email
            }

            return <div className="flex items-center gap-3">{email ?? 'Bilinmiyor'}</div>
         }
      },
      {
         title: 'Durumu',
         dataIndex: 'id',
         key: 'id',
         render: (id: any, record: any) => {
            const currentStatus = offerAdminStatus(record)
            return (
               <div className="items-center gap-3">
                  {/* <Chip color={record.status === 1 ? 'primary' : 'danger'}>{record.status === 1 ? 'Aktif' : 'Pasif'}</Chip> */}
                  <Chip color={currentStatus.color}>{record.durum}</Chip>
               </div>
            )
         }
      },
      {
         title: 'Talep Kaynağı',
         dataIndex: 'id',
         key: 'id',
         render: (id: any, record: any) => {
            const color = record.is_ordered === 1 ? 'text-green-500' : 'text-red-500'
            const background = record.is_ordered === 1 ? 'bg-green-500' : 'bg-red-100'
            return (
               <div className="items-center gap-3">
                  {/* <Chip color={record.status === 1 ? 'primary' : 'danger'}>{record.status === 1 ? 'Aktif' : 'Pasif'}</Chip> */}
                  {record?.offer_source === 'Web' ? (
                     <div className="flex gap-5 items-center" color="primary">
                        <IconWebhook />
                        Web
                     </div>
                  ) : record?.offer_source === 'Email' ? (
                     <div className="flex gap-5 items-center" color="secondary">
                        <IconMailBolt />
                        E-Posta
                     </div>
                  ) : record?.offer_source === 'Phone' ? (
                     <div className="flex gap-5 items-center" color="warning">
                        <IconPhone />
                        Telefon
                     </div>
                  ) : record?.offer_source === 'Whatsapp' ? (
                     <div className="flex gap-5 items-center" color="success">
                        <IconBrandWhatsapp />
                        Whatsapp
                     </div>
                  ) : (
                     <div className="flex gap-5 items-center" color="secondary">
                        <IconDots />
                        Diğer
                     </div>
                  )}
               </div>
            )
         }
      },
      {
         title: 'Atanan Kullanıcı',
         dataIndex: 'id',
         key: 'id',
         render: (id: any, record: any) => {
            const color = record.is_ordered === 1 ? 'text-green-500' : 'text-red-500'
            const background = record.is_ordered === 1 ? 'bg-green-500' : 'bg-red-100'
            return (
               <div className="items-center gap-3">
                  {/* <Chip color={record.status === 1 ? 'primary' : 'danger'}>{record.status === 1 ? 'Aktif' : 'Pasif'}</Chip> */}
                  <Chip color={record?.is_assigned ? 'success' : 'danger'}>
                     {record?.is_assigned ? record?.user : 'Atanmamış'}
                  </Chip>
               </div>
            )
         }
      },

      {
         title: 'Talep Tarihi',
         dataIndex: 'id',
         key: 'id',
         render: (id: any, record: any) => {
            return (
               <div className="flex items-center gap-3">
                  {/* {moment(record.created_at).format('LL')} */}
                  {moment(record?.created_at).format('LLLL')}
               </div>
            )
         }
      },

      {
         title: t('common.actions'),
         dataIndex: 'id',
         key: 'id',
         render: (id: any, record: any) => {
            return (
               <div className="flex items-center gap-3">
                  <ActionButton
                     setHide={setHide}
                     users={usersData}
                     row={record?.offer}
                     isAssigned={record?.is_assigned}
                     editButtonClick={() => {
                        handleGetOffer(record.id)
                     }}
                     viewButtonClick={() => {
                        handleGetOffersFromOfferGroup(record.id)
                     }}
                     deleteButtonClick={() => { }}
                  />

                  <Button
                     color="secondary"
                     variant="shadow"
                     onClick={() => handlePreviewOffer(record?.offer)}
                     startContent={<IconBox />}
                  >
                     Ürünler
                  </Button>
               </div>
            )
         }
      }
   ]

   useEffect(() => {
      //@ts-ignore
      onFilter()
   }, [page])

   useEffect(() => {
      console.log(data?.data?.total)
      dispatch(setTotalRequests(data?.data?.total ?? 0))
   }, [data])

   const handleGetlastId = () => {
      //@ts-ignore
      getOfferLastIdQuery(OFFER_MANAGEMENT_LIST, {
         endPoint: '/offers/getLastOfferId'
      })
   }

   const handleGetOffer = (id: number) => {
      //@ts-ignore
      getOffer(OFFER_MANAGEMENT_SHOW, {
         endPoint: OFFER_MANAGEMENT_SHOW_URL(id)
      })
   }

   const handlePreviewOffer = (item: any) => {
      setPreviewItem(item)
      setPreviewDrawer(true)
   }

   const handleGetOffersFromOfferGroup = (id: number) => {
      //@ts-ignore
      getOffersFromOfferGroupQuery(OFFER_MANAGEMENT_LIST, {
         endPoint: '/offers/' + id + '/edit'
      })
   }

   useEffect(() => {
      if (lastIdData) {
         dispatch(setOfferNumber(lastIdData.data))
         navigate(routes.offerManagement.add)
      }
   }, [lastIdData])

   useEffect(() => {
      dispatch(
         setLoading(
            (offerLoading as boolean) ||
            (offersFromOfferGroupLoading as boolean) ||
            (convertOfferToOrderLoading as boolean)
         )
      )
   }, [offerLoading, offersFromOfferGroupLoading, convertOfferToOrderLoading])

   useEffect(() => {
      if (window.innerWidth < 1024) {
         setIsMobile(true)
      }
   }, [window.innerWidth])

   useEffect(() => {
      if (offerData) {
         const { data } = offerData
         dispatch(setOfferNumber(data?.no))
         dispatch(setContactId(data?.customer_contact_id))

         const products = data?.products.map((product: any, index: number) => {
            const description = JSON.parse(product.product_json)
            return {
               id: product.product_id,
               name: description.name,
               price: description.price,
               stock: product.product_qty,
               product_qty: description.stock,
               delivery_date: product.delivery_date,
               commission: product.product_markup,
               commission_type: product.product_markup_type,
               description: product.product_description,
               services: product.services.map((service: any) => {
                  return {
                     ...service.service,
                     commission: service.service_markup,
                     commission_type: service.service_markup_type,
                     description: service.service_description,
                     included_in_total: service.included_in_total
                  }
               })
            }
         })
         // console.log(products)
         dispatch(setProduct(products))
         navigate(routes.offerManagement.view(data?.offer_group_id))
      }
   }, [offerData])

   useEffect(() => {
      if (offersFromOfferGroupData) {
         setOfferListModal(true)
      }
   }, [offersFromOfferGroupData])

   useEffect(() => {
      if (convertOfferToOrderData) {
         message.success('Siparişe dönüştürüldü!')
         setOfferListModal(false)
         onFilter(form.getFieldsValue())
         setSuccessModal(true)
      }
   }, [convertOfferToOrderData])

   useEffect(() => {
      if (requestModal.isAdded) {
         onFilter(form.getFieldsValue())
      }
   }, [requestModal.isAdded])

   useEffect(() => {
      //@ts-ignore
      usersQuery(USER_SHOW, {
         endPoint: '/users-all'
      })
      //@ts-ignore
      groupByAssignedFrom(USER_SHOW, {
         endPoint: '/groupByAssignedFrom'
      })
   }, [])



   const FilterSection = () => {
      return (
         <Card className="mb-6 border-none shadow-sm">
            <CardBody className="p-6">
               <div className="flex items-center gap-3 mb-6">
                  <div className="w-10 h-10 rounded-lg bg-primary/10 flex items-center justify-center">
                     <IconFilter className="w-5 h-5 text-primary" />
                  </div>
                  <div>
                     <h3 className="text-lg font-semibold text-gray-800">Talepleri Filtrele</h3>
                     <p className="text-sm text-gray-500">Talepleri çeşitli kriterlere göre filtreleyebilirsiniz</p>
                  </div>
               </div>

               <Form
                  layout="vertical"
                  form={form}
                  onFinish={onFilter}
                  initialValues={{
                     user_ids: user_id ? [user_id] : user?.role === 'user' ? [user?.id] : []
                  }}
                  className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 items-center"
               >
                  <Form.Item label="Satışçılar" name="user_ids" style={{ display: user_id ? 'none' : 'block' }}>
                     <Select
                        placeholder="Satışçı seçin"
                        mode="multiple"
                        showSearch
                        optionFilterProp="children"
                        className="w-full"
                        filterOption={(input, option: any) =>
                           option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                     >
                        {usersData?.result?.map((user: any) => (
                           <Select.Option key={user?.id} value={user?.id}>
                              {user?.name}
                           </Select.Option>
                        ))}
                     </Select>
                  </Form.Item>

                  <Form.Item label="Talep Kaynağı" name="offer_source">
                     <Select placeholder="Kaynak seçin">
                        <Select.Option value="email">
                           <div className="flex items-center gap-2">
                              <IconMailBolt className="w-4 h-4" />
                              <span>E-Posta</span>
                           </div>
                        </Select.Option>
                        <Select.Option value="web">
                           <div className="flex items-center gap-2">
                              <IconWebhook className="w-4 h-4" />
                              <span>Web</span>
                           </div>
                        </Select.Option>
                        <Select.Option value="phone">
                           <div className="flex items-center gap-2">
                              <IconPhone className="w-4 h-4" />
                              <span>Telefon</span>
                           </div>
                        </Select.Option>
                        <Select.Option value="whatsapp">
                           <div className="flex items-center gap-2">
                              <IconBrandWhatsapp className="w-4 h-4" />
                              <span>WhatsApp</span>
                           </div>
                        </Select.Option>
                     </Select>
                  </Form.Item>

                  <Form.Item label="Talep Durumu" name="offer_status">
                     <Select placeholder="Durum seçin">
                        <Select.Option value="1">Atanmış - Teklif Bekleyen</Select.Option>
                        <Select.Option value="2">Atanmış - Teklif Gönderilen</Select.Option>
                        <Select.Option value="3">Atanmamış Talepler</Select.Option>
                     </Select>
                  </Form.Item>

                  <div className="flex items-end gap-2">
                     <Button
                        type="submit"
                        color="primary"
                        className="w-full h-[40px]"
                        startContent={<IconSearch className="w-4 h-4" />}
                        isLoading={loading}
                     >
                        Filtrele
                     </Button>
                     <Button
                        color="danger"
                        onClick={() => {
                           form.resetFields()
                           onFilter({})
                        }}
                        variant='flat'
                        className="w-full h-[40px]"
                        startContent={<IconRefresh className="w-4 h-4" />}
                        isLoading={loading}
                     >
                        Temizle
                     </Button>
                  </div>
               </Form>
            </CardBody>
         </Card>
      );
   };

   const RequestCard = ({ request }: { request: any }) => {
      const statusColor = offerAdminStatus(request).color;
      const sourceIcon: { [key: string]: JSX.Element } = {
         Web: <IconWebhook className="w-4 h-4" />,
         Email: <IconMailBolt className="w-4 h-4" />,
         Phone: <IconPhone className="w-4 h-4" />,
         Whatsapp: <IconBrandWhatsapp className="w-4 h-4" />,
         default: <IconDots className="w-4 h-4" />
      };

      return (
         <Card className="border-none shadow-sm hover:shadow-md transition-all duration-200">
            <CardBody className="p-6">
               <div className="flex flex-col lg:flex-row justify-between gap-6">
                  {/* Sol Kısım */}
                  <div className="flex items-start gap-4 flex-1">
                     <div className="w-12 h-12 rounded-xl bg-gradient-to-br from-primary/10 to-primary/20 flex items-center justify-center">
                        <span className="text-lg font-semibold text-primary">
                           {request.no?.charAt(0)}
                        </span>
                     </div>
                     <div className="space-y-3">
                        <div className="flex flex-wrap items-center gap-2">
                           <h3 className="text-lg font-semibold text-gray-800">
                              Talep #{request.no}
                           </h3>
                           <Chip color={statusColor} size="sm">
                              {request.durum}
                           </Chip>
                        </div>

                        <div className="flex flex-wrap items-center gap-3 text-sm text-gray-600">
                           <div className="flex items-center gap-1.5">
                              {sourceIcon[request?.offer_source] || sourceIcon.default}
                              <span>{request?.offer_source || 'Diğer'}</span>
                           </div>
                           <span className="w-1 h-1 rounded-full bg-gray-300" />
                           <span>{moment(request?.created_at).format('LLLL')}</span>
                        </div>

                        <div className="flex items-center gap-2">
                           <Chip
                              size="sm"
                              color={request?.is_assigned ? 'success' : 'danger'}
                              variant="flat"
                              startContent={request?.is_assigned ? <IconUser className="w-3 h-3" /> : null}
                           >
                              {request?.is_assigned ? request?.user : 'Atanmamış'}
                           </Chip>
                        </div>
                     </div>
                  </div>

                  {/* Sağ Kısım */}
                  <div className="flex flex-col lg:items-end gap-4">
                     <div className="flex flex-wrap gap-2">
                        <Button
                           size="sm"
                           color="primary"
                           variant="flat"
                           startContent={<IconEye size={18} />}
                           onClick={() => handlePreviewOffer(request?.offer)}
                        >
                           Önizle
                        </Button>
                        <Button
                           size="sm"
                           color="secondary"
                           variant="flat"
                           startContent={<IconEdit size={18} />}
                           onClick={() => handleGetOffer(request.id)}
                        >
                           Düzenle
                        </Button>
                        <Button
                           size="sm"
                           color="success"
                           variant="flat"
                           startContent={<IconBox size={18} />}
                           onClick={() => handlePreviewOffer(request?.offer)}
                        >
                           Ürünler
                        </Button>
                     </div>
                  </div>
               </div>
            </CardBody>
         </Card>
      );
   };

   // Modern tablo komponentini oluşturalım
   const ModernTable = () => {
      return (
         <Card className="border-none shadow-sm">
            <div className="overflow-x-auto">
               <table className="w-full">
                  <thead>
                     <tr className="border-b border-gray-100">
                        <th className="whitespace-nowrap px-4 py-3 text-left text-sm font-medium text-gray-700">
                           Talep No
                        </th>
                        <th className="whitespace-nowrap px-4 py-3 text-left text-sm font-medium text-gray-700">
                           Müşteri
                        </th>
                        <th className="whitespace-nowrap px-4 py-3 text-left text-sm font-medium text-gray-700">
                           Atayan
                        </th>
                        <th className="whitespace-nowrap px-4 py-3 text-left text-sm font-medium text-gray-700">
                           Atanan
                        </th>
                        <th className="whitespace-nowrap px-4 py-3 text-left text-sm font-medium text-gray-700">
                           Durum
                        </th>
                        <th className="whitespace-nowrap px-4 py-3 text-left text-sm font-medium text-gray-700">
                           Tarih
                        </th>
                        <th className="whitespace-nowrap px-4 py-3 text-right text-sm font-medium text-gray-700">
                           İşlemler
                        </th>
                     </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-100">
                     {data?.data?.data?.map((request: any) => (
                        <tr key={request.id} className="group hover:bg-gray-50/50">
                           <td className="whitespace-nowrap px-4 py-4 text-sm">
                              <div className="flex items-center gap-3">
                                 <div className="h-10 w-10 rounded-lg bg-primary/10 flex items-center justify-center">
                                    <span className="text-primary font-medium">
                                       {request.offer.no.charAt(0)}
                                    </span>
                                 </div>
                                 #{request.offer.no}
                              </div>
                           </td>
                           <td className="whitespace-nowrap px-4 py-4 text-sm">
                              <div
                                 onClick={() => navigate(`/dashboard/customer-management/profile/${request.offer.contact.customer_id}`)}
                                 className="group/link cursor-pointer"
                              >
                                 <div className="font-medium group-hover/link:text-blue-600 transition-colors">
                                    {request?.offer?.contact?.customer?.company_name}
                                 </div>
                                 <div className="text-gray-500 text-xs">
                                    {request?.offer?.contact?.name}
                                 </div>
                              </div>
                           </td>
                           <td className="whitespace-nowrap px-4 py-4 text-sm">
                              {request.atayan}
                           </td>
                           <td className="whitespace-nowrap px-4 py-4 text-sm">
                              <Chip
                                 size="sm"
                                 color={request.is_assigned ? "success" : "danger"}
                                 variant="flat"
                              >
                                 {request.is_assigned ? request.user : 'Atanmamış'}
                              </Chip>
                           </td>
                           <td className="whitespace-nowrap px-4 py-4 text-sm">
                              <Chip
                                 size="sm"
                                 color={request.is_sent_offer_mail ? "success" : "warning"}
                                 variant="flat"
                              >
                                 {request.durum}
                              </Chip>
                           </td>
                           <td className="whitespace-nowrap px-4 py-4 text-sm text-gray-500">
                              {moment(request.created_at).format('DD MMM YYYY')}
                           </td>
                           <td className="whitespace-nowrap px-4 py-4 text-right">
                              <div className="flex items-center justify-end gap-2  group-hover:opacity-100">
                                 {/* <Button
                                    size="sm"
                                    variant="flat"
                                    color="primary"
                                    onClick={() => handlePreviewOffer(request.offer)}
                                    startContent={<IconEye size={16} />}
                                 >
                                    Önizle
                                 </Button>
                                 <Button
                                    size="sm"
                                    variant="flat"
                                    color="secondary"
                                    onClick={() => handleGetOffer(request.id)}
                                    startContent={<IconEdit size={16} />}
                                 >
                                    Düzenle
                                 </Button> */}
                                 <ActionButton
                                    setHide={setHide}
                                    users={usersData}
                                    row={request?.offer}
                                    isAssigned={request?.is_assigned}
                                    editButtonClick={() => handleGetOffer(request.id)}
                                    viewButtonClick={() => handleGetOffersFromOfferGroup(request.id)}
                                    deleteButtonClick={() => { }}
                                 />
                              </div>
                           </td>
                        </tr>
                     ))}
                  </tbody>
               </table>

               {/* Empty State */}
               {!loading && data?.data?.data?.length === 0 && (
                  <div className="text-center py-12">
                     <IconInbox className="w-12 h-12 mx-auto text-gray-400 mb-3" />
                     <h3 className="text-lg font-medium text-gray-800">
                        Talep Bulunamadı
                     </h3>
                     <p className="text-sm text-gray-500">
                        Henüz hiç talep oluşturulmamış.
                     </p>
                  </div>
               )}

               {/* Loading State */}
               {loading && (
                  <div className="flex justify-center items-center py-12">
                     <Spin size="large" />
                  </div>
               )}
            </div>
         </Card>
      );
   };

   return (
      <>
         <div style={{
            display: (customer_id || user_id) ? 'none' : 'block'
         }}>
            <PageHeader title={pageHeader.title} breadcrumb={pageHeader.breadcrumb}>
               {user?.role === 'admin' && (
                  <div className="mt-4 flex items-center gap-3 @lg:mt-0">

                  </div>
               )}
            </PageHeader>
         </div>

         <Card className="border-none shadow-sm">
            <FilterSection />
            <CardBody>
               <div className="overflow-x-auto">
                  <table className="w-full">
                     <thead>
                        <tr className="border-b border-gray-100">
                           <th className="whitespace-nowrap px-4 py-3 text-left text-sm font-medium text-gray-700">
                              Talep No
                           </th>
                           <th className="whitespace-nowrap px-4 py-3 text-left text-sm font-medium text-gray-700">
                              Müşteri
                           </th>
                           <th className="whitespace-nowrap px-4 py-3 text-left text-sm font-medium text-gray-700">
                              Atayan
                           </th>
                           <th className="whitespace-nowrap px-4 py-3 text-left text-sm font-medium text-gray-700">
                              Atanan
                           </th>
                           <th className="whitespace-nowrap px-4 py-3 text-left text-sm font-medium text-gray-700">
                              Talep Kaynağı
                           </th>
                           <th className="whitespace-nowrap px-4 py-3 text-left text-sm font-medium text-gray-700">
                              Durum
                           </th>
                           <th className="whitespace-nowrap px-4 py-3 text-left text-sm font-medium text-gray-700">
                              Tarih
                           </th>
                           <th className="whitespace-nowrap px-4 py-3 text-right text-sm font-medium text-gray-700">
                              İşlemler
                           </th>
                        </tr>
                     </thead>
                     <tbody className="divide-y divide-gray-100">
                        {data?.data?.data?.map((request: any) => (
                           <tr key={request.id} className="group hover:bg-gray-50/50">
                              <td className="whitespace-nowrap px-4 py-4 text-sm">
                                 <div className="flex items-center gap-3">
                                    <div className="h-10 w-10 rounded-lg bg-primary/10 flex items-center justify-center">
                                       <span className="text-primary font-medium">
                                          {request.offer.no.charAt(0)}
                                       </span>
                                    </div>
                                    #{request.offer.no}
                                 </div>
                              </td>
                              <td className="whitespace-nowrap px-4 py-4 text-sm">
                                 <div
                                    onClick={() => navigate(`/dashboard/customer-management/profile/${request.offer.contact.customer_id}`)}
                                    className="group/link cursor-pointer"
                                 >
                                    <div className="font-medium group-hover/link:text-blue-600 transition-colors">
                                       {request?.offer?.contact?.customer?.company_name}
                                    </div>
                                    <div className="text-gray-500 text-xs">
                                       {request?.offer?.contact?.name}
                                    </div>
                                 </div>
                              </td>
                              <td className="whitespace-nowrap px-4 py-4 text-sm">
                                 {request.atayan}
                              </td>
                              <td className="whitespace-nowrap px-4 py-4 text-sm">
                                 <div className='flex gap-2 items-center'>
                                    <Chip
                                       size="sm"
                                       color={request.is_assigned ? "success" : "danger"}
                                       variant="flat"
                                    >
                                       {request.is_assigned ? request.user : 'Atanmamış'}
                                    </Chip>

                                 </div>
                              </td>
                              <td>
                                 <div className="items-center gap-3">
                                    {/* <Chip color={record.status === 1 ? 'primary' : 'danger'}>{record.status === 1 ? 'Aktif' : 'Pasif'}</Chip> */}
                                    {request?.offer_source === 'Web' ? (
                                       <div className="flex gap-5 items-center" color="primary">
                                          <IconWebhook />
                                          Web
                                       </div>
                                    ) : request?.offer_source === 'Email' ? (
                                       <div className="flex gap-5 items-center" color="secondary">
                                          <IconMailBolt />
                                          E-Posta
                                       </div>
                                    ) : request?.offer_source === 'Phone' ? (
                                       <div className="flex gap-5 items-center" color="warning">
                                          <IconPhone />
                                          Telefon
                                       </div>
                                    ) : request?.offer_source === 'Whatsapp' ? (
                                       <div className="flex gap-5 items-center" color="success">
                                          <IconBrandWhatsapp />
                                          Whatsapp
                                       </div>
                                    ) : (
                                       <div className="flex gap-5 items-center" color="secondary">
                                          <IconDots />
                                          Diğer
                                       </div>
                                    )}
                                 </div>
                              </td>
                              <td className="whitespace-nowrap px-4 py-4 text-sm">
                                 <Chip
                                    size="sm"
                                    color={request.is_sent_offer_mail ? "success" : "warning"}
                                    variant="flat"
                                 >
                                    {request.durum}
                                 </Chip>
                              </td>
                              <td className="whitespace-nowrap px-4 py-4 text-sm text-gray-500">
                                 {moment(request.created_at).format('DD MMM YYYY HH:mm')}
                              </td>
                              <td className="whitespace-nowrap px-4 py-4 text-right">
                                 <div className="flex items-center justify-end gap-2  transition-opacity">
                                    {/* <Button
                                       size="sm"
                                       variant="flat"
                                       color="primary"
                                       onClick={() => handlePreviewOffer(request.offer)}
                                       startContent={<IconEye size={16} />}
                                    >
                                       Önizle
                                    </Button>
                                    <Button
                                       size="sm"
                                       variant="flat"
                                       color="secondary"
                                       onClick={() => handleGetOffer(request.id)}
                                       startContent={<IconEdit size={16} />}
                                    >
                                       Düzenle
                                    </Button> */}
                                    <ActionButton
                                       setHide={setHide}
                                       users={usersData}
                                       row={request?.offer}
                                       isAssigned={request?.is_assigned}
                                       editButtonClick={() => handleGetOffer(request.id)}
                                       viewButtonClick={() => handleGetOffersFromOfferGroup(request.id)}
                                       deleteButtonClick={() => { }}
                                    />

                                    <Button
                                       size="sm"
                                       color="primary"
                                       variant="flat"
                                       startContent={<IconBox />}
                                       onClick={() => {
                                          if (request?.offer?.products?.length > 0) {
                                             setPreviewItem(request.offer);
                                             setPreviewDrawer(true);
                                          }
                                       }}
                                    >
                                       Ürünleri Gör ({request?.offer?.products?.length || 0})
                                    </Button>
                                 </div>
                              </td>
                           </tr>
                        ))}
                     </tbody>
                  </table>

                  {/* Empty State */}
                  {!loading && data?.data?.data?.length === 0 && (
                     <div className="text-center py-12">
                        <IconInbox className="w-12 h-12 mx-auto text-gray-400 mb-3" />
                        <h3 className="text-lg font-medium text-gray-800">
                           Talep Bulunamadı
                        </h3>
                        <p className="text-sm text-gray-500">
                           Henüz hiç talep oluşturulmamış.
                        </p>
                     </div>
                  )}

                  {/* Loading State */}
                  {loading && (
                     <div className="flex justify-center items-center py-12">
                        <Spin size="large" />
                     </div>
                  )}
               </div>
            </CardBody>
         </Card>

         {data?.data?.total > 0 && (
            <div className="mt-6 flex justify-center">
               <Pagination
                  total={data?.data?.total}
                  current={page}
                  pageSize={20}
                  onChange={setPage}
               />
            </div>
         )}
         <AnimatePresence>
            {previewDrawer && (
               <PreviewModal
                  isOpen={previewDrawer}
                  onClose={() => setPreviewDrawer(false)}
                  item={previewItem}
               />
            )}
         </AnimatePresence>
      </>
   )
}

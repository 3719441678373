import { Form, Select } from 'antd'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { NumericFormat } from 'react-number-format'
import { Input } from 'rizzui'

interface Props {
   onFinish: any
   initialValues?: any
   isLoading?: boolean
   form: any
}

const UserForm = (props: Props) => {
   const { t } = useTranslation()

   // useEffect(() => {
   //    if (props.initialValues?.name !== '') {
   //       const user = props.initialValues
   //       props.form.setFieldsValue({
   //          name: user?.name,
   //          email: user?.email,
   //          role: user?.role,
   //       })
   //    }
   // }, [props.initialValues])

   return (
      <div>
         <Form.Item
            rules={[
               {
                  required: true,
                  message: "Lütfen kullanıcı adını giriniz."
               }
            ]}
            label={t('common.name')}
            name="name"
         >
            <Input className="ant-input rizzui-input" />
         </Form.Item>


         <Form.Item
            rules={[
               {
                  required: true,
                  message: "Lütfen e-posta adresini giriniz."
               },
               {
                  type: 'email',
                  message: "Lütfen geçerli bir e-posta adresi giriniz."
               }
            ]}
            label={t('common.email')}
            name="email"
         >
            <Input className="ant-input rizzui-input" type="text" />
         </Form.Item>

         <Form.Item
            rules={[
               {
                  required: props.initialValues?.name ? false : true,
                  message: "Lütfen şifreyi giriniz."
               }
            ]}
            label={t('common.password')}
            name="password"
         >
            <Input className="ant-input rizzui-input" type="text" />
         </Form.Item>

         <Form.Item
            label="Kullanıcı Tipi"
            name="role"
            rules={[
               {
                  required: true,
                  message: "Lütfen kullanıcı tipini seçiniz."
               }
            ]}
         >
            <Select
               placeholder="Kullanıcı Tipi"
               defaultValue={props.initialValues?.user_type}
               className="w-full"
            // defaultValue={props.initialValues?.user_type}
            // onChange={(value) => {
            //   form.setFieldsValue({ user_type: value });
            // }}
            >
               <Select.Option value="admin">Yönetici</Select.Option>
               <Select.Option value="user">Personel</Select.Option>
            </Select>
         </Form.Item>
      </div>
   )
}

export default UserForm

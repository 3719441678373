import { Form, message } from 'antd';
import React, { useEffect } from 'react'
import CustomerForm from './Form';
import { Button, Card } from '@nextui-org/react';
import useFetch from 'hooks/use-fetch';
import { CUSTOMER_MANAGEMENT_STORE, CUSTOMER_MANAGEMENT_STORE_URL } from 'api/customer-management';

interface AddCustomerProps {
    onSuccess?: () => void;
    onCancel?: () => void;
}

export default function AddCustomer({ onSuccess, onCancel }: AddCustomerProps) {
    const [form] = Form.useForm();
    const [addCustomerMutation, { data: addCustomerData, loading, error }] = useFetch<any>();

    const handleSubmit = (values: any) => {
        //@ts-ignore
        addCustomerMutation(CUSTOMER_MANAGEMENT_STORE, {
            endPoint: CUSTOMER_MANAGEMENT_STORE_URL,
            body: {
                ...values,
                status: values.status ?? false
            },
        });
    };

    useEffect(() => {
        if (addCustomerData?.status) {
            message.success("Müşteri başarıyla eklendi");
            form.resetFields();
            onSuccess?.();
        }
    }, [addCustomerData]);

    useEffect(() => {
        if (error) {
            message.error(error?.response?.data?.message);
        }
    }, [error]);

    return (
        <div className="max-w-3xl mx-auto">
            <Form
                layout="vertical"
                form={form}
                onFinish={handleSubmit}
                className="space-y-6"
            >
                <CustomerForm />

                <div className="flex justify-end gap-2 mt-6">
                    <Button
                        size="sm"
                        variant="flat"
                        color="danger"
                        onClick={onCancel}
                    >
                        İptal
                    </Button>
                    <Button
                        size="sm"
                        color="primary"
                        isLoading={loading}
                        onClick={() => form.submit()}
                    >
                        Kaydet
                    </Button>
                </div>
            </Form>
        </div>
    );
}

import { Button, Card, CardBody, CardHeader, Chip } from '@nextui-org/react'
import { IconDownload, IconFilePlus, IconUpload } from '@tabler/icons-react'
import { message, Modal, TableColumnProps, UploadFile, UploadProps } from 'antd'
import PageHeader from 'components/page-header'
import { routes } from 'config/routes'
import moment from 'moment'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion'

import useFetch from 'hooks/use-fetch'
import { SUPPLIER_MANAGEMENT_LIST, SUPPLIER_MANAGEMENT_LIST_URL } from 'api/supplier-management'
import TableComponents from 'components/Table'
import SearchInput from 'components/Input'
import axios from 'axios'
import Dragger from 'antd/es/upload/Dragger'
import { RiFileExcel2Line } from 'react-icons/ri'
import { apiUrl } from 'api'
import * as XLSX from 'xlsx'

function MailList() {
   const navigate = useNavigate()
   const { t } = useTranslation()

   const [page, setPage] = React.useState(1) // [1,2,3,4,5]
   const [search, setSearch] = React.useState('') // [1,2,3,4,5
   const [excelModal, setExcelModal] = React.useState(false)
   const [previewData, setPreviewData] = useState<any[]>([])

   const [suppliers, { data, loading, error }] = useFetch<any>()
   const [fileList, setFileList] = useState<UploadFile[]>([])
   const [isValidFormat, setIsValidFormat] = useState(false)

   React.useEffect(() => {
      //@ts-ignore
      suppliers(SUPPLIER_MANAGEMENT_LIST, {
         endPoint: 'mail-list?page=' + page + '&search=' + search
      })
   }, [page])

   const onFilter = () => {
      //@ts-ignore
      suppliers(SUPPLIER_MANAGEMENT_LIST, {
         endPoint: 'mail-list?page=' + page + '&search=' + search
      })
   }

   const pageHeader = {
      title: 'Mail Listesi',
      breadcrumb: [
         {
            href: routes.dashboard,
            name: t('aside.dashboard')
         },
         {
            href: routes.myMail,
            name: 'Mail Listesi'
         }
      ]
   }

   //  <th>Firma Adı</th>
   //                              <th>İrtibatlı</th>
   //                              <th>İrtibat Email</th>
   //                              <th>İrtibat Telefon</th>
   //                              <th>Eklenen Tarih</th>
   //                              <th>Son Güncelleme</th>
   //                              <th>Durumu</th>
   //                              <th>İşlemler</th>

   const columns: TableColumnProps<any>[] = [
      {
         title: 'Firma Adı',
         dataIndex: 'company_name',
         key: 'company_name',
         sorter: (a, b) => a.company_name.localeCompare(b.company_name),
         render: (text, record) => (
            <>
               <span>{record.company_name}</span>
            </>
         )
      },
      {
         title: 'Mail',
         dataIndex: 'mail',
         key: 'mail',
         sorter: (a, b) => a.name.localeCompare(b.name),
         render: (text, record) => (
            <>
               <span>{record.mail}</span>
            </>
         )
      },

      {
         title: 'Eklenen Tarih',
         dataIndex: 'created_at',
         key: 'created_at',
         sorter: (a, b) => a.created_at.localeCompare(b.created_at),
         render: (text, record) => (
            <>
               <span>{moment(record.created_at).format('DD.MM.YYYY')}</span>
            </>
         )
      }
   ]

   const handleUpload = async () => {
      try {
         const formData = new FormData()
         if (fileList[0]) {
            formData.append('file', fileList[0] as any)
         }

         formData.append('type', 'mail')

         const response = await axios.post('/uploadFileTask', formData, {
            headers: {
               'Content-Type': 'multipart/form-data',
               Authorization: 'Bearer ' + localStorage.getItem('token') || ''
            }
         })

         Modal.success({
            title: 'Başarılı',
            content: 'İşlem tamamlanıca mail adresinize bilgilendirme yapılacaktır',
            onCancel: () => {
               setExcelModal(false)
            },
            onOk: () => {
               setExcelModal(false)
            },
            okText: 'Tamam',
            cancelText: 'Kapat'
         })
      } catch (error) {
         message.error('Dosya yüklenirken bir hata oluştu')
      }
   }

   const validateExcelFormat = (data: any[]) => {
      // Excel formatı kontrolü
      if (!data || data.length < 2) return false; // En az header ve bir satır olmalı

      const headers = data[0];
      const requiredColumns = ['Firma Adı', 'E-posta Adresi'];

      // Gerekli kolonların varlığını kontrol et
      return requiredColumns.every(col => headers.includes(col));
   };

   const props: UploadProps = {
      onRemove: () => {
         setFileList([]);
         setIsValidFormat(false);
         setPreviewData([]);
      },
      maxCount: 1,
      accept: '.xlsx, .xls',
      beforeUpload: file => {
         // Dosya formatı kontrolü
         const isExcel = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
            file.type === 'application/vnd.ms-excel';

         if (!isExcel) {
            message.error('Lütfen sadece Excel dosyası (.xlsx, .xls) yükleyin!')
            return false
         }

         const reader = new FileReader();
         reader.onload = (e) => {
            const data = e.target?.result;
            const workbook = XLSX.read(data, { type: 'array' });
            const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
            const jsonData = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });

            // Format kontrolü
            const isValid = validateExcelFormat(jsonData);
            setIsValidFormat(isValid);

            if (!isValid) {
               message.error('Excel formatı uygun değil! Lütfen örnek dosyayı indirip kullanın.')
               setFileList([]);
               return;
            }

            setPreviewData(jsonData.slice(0, 5));
         };
         reader.readAsArrayBuffer(file);
         setFileList([file]);
         return false;
      },
      fileList
   }

   const downloadExampleExcel = () => {
      const exampleData = [
         ['Firma Adı', 'E-posta Adresi'],
         ['Örnek Firma A', 'info@ornekfirma.com'],
         ['Örnek Firma B', 'contact@ornekfirmab.com']
      ];

      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.aoa_to_sheet(exampleData);

      // Sütun genişliklerini ayarla
      ws['!cols'] = [
         { wch: 30 }, // Firma Adı
         { wch: 40 }  // E-posta Adresi
      ];

      // Başlık satırını formatla
      const headerStyle = {
         font: { bold: true },
         fill: { fgColor: { rgb: "CCCCCC" } }
      };

      // İlk satırı formatla
      const range = XLSX.utils.decode_range(ws['!ref'] || 'A1');
      for (let C = range.s.c; C <= range.e.c; ++C) {
         const address = XLSX.utils.encode_cell({ r: 0, c: C });
         if (!ws[address]) continue;
         ws[address].s = headerStyle;
      }

      XLSX.utils.book_append_sheet(wb, ws, 'Mail Listesi');
      XLSX.writeFile(wb, 'mail-listesi-ornegi.xlsx');
   };

   return (
      <div>
         <PageHeader title={pageHeader.title} breadcrumb={pageHeader.breadcrumb}>
            <div className="flex items-center gap-2">
               <Button startContent={<IconFilePlus />} onClick={() => setExcelModal(true)} color="primary">
                  Yeni Mail Listesi Yükle
               </Button>
               <Button
                  startContent={<IconDownload />}
                  onClick={() => {
                     window.open(apiUrl + '/api/mail-list/export?token=' + localStorage.getItem('token'))
                  }}
                  color="primary"
               >
                  Mail Listesi İndir
               </Button>
            </div>
         </PageHeader>
         <Modal footer={null} title="Excel İle Yükle" open={excelModal} closable onCancel={() => setExcelModal(false)}>
            <Dragger {...props} multiple={false} accept=".xlsx, .xls">
               <p className="ant-upload-drag-icon text-center w-full flex items-center justify-center">
                  <RiFileExcel2Line size={30} />
               </p>
               <p className="ant-upload-text">
                  Dosyayı sürükleyip bırakın ya da tıklayın. Örnek dosyayı indirmek için{' '}
                  <Button
                     onClick={(e) => {
                        e.stopPropagation();
                        downloadExampleExcel();
                     }}
                     color="primary"
                     variant="light"
                     size="sm"
                     className="px-2 py-0 h-6 min-w-0"
                  >
                     buraya tıklayın
                  </Button>
               </p>
               <p className="ant-upload-hint">Dosyayı yüklemek için buraya sürükleyin</p>
            </Dragger>

            {previewData.length > 0 && (
               <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  className="mt-4 border rounded-lg overflow-hidden"
               >
                  <div className="bg-gray-50 p-2 border-b">
                     <h3 className="text-sm font-medium text-gray-700">Dosya Önizleme (İlk 5 Satır)</h3>
                  </div>
                  <div className="max-h-[200px] overflow-auto">
                     <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50 sticky top-0">
                           <tr>
                              {previewData[0]?.map((header: string, index: number) => (
                                 <th
                                    key={index}
                                    className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                 >
                                    {header}
                                 </th>
                              ))}
                           </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                           {previewData.slice(1).map((row, rowIndex) => (
                              <tr key={rowIndex}>
                                 {row.map((cell: any, cellIndex: number) => (
                                    <td
                                       key={cellIndex}
                                       className="px-3 py-2 whitespace-nowrap text-sm text-gray-500"
                                    >
                                       {cell}
                                    </td>
                                 ))}
                              </tr>
                           ))}
                        </tbody>
                     </table>
                  </div>
               </motion.div>
            )}

            <Button
               onClick={handleUpload}
               color="primary"
               variant="solid"
               className="mt-5 w-full"
               startContent={<IconUpload className="w-4 h-4" />}
               isDisabled={!fileList.length || !isValidFormat}
            >
               {!fileList.length ? 'Dosya Seçilmedi' : !isValidFormat ? 'Geçersiz Format' : 'Yükle'}
            </Button>
         </Modal>
         <div className="@container">
            <Card title="Filter" className="mb-5">
               <CardHeader>
                  <h3 className="text-lg font-semibold">{t('common.filterTable')}</h3>
               </CardHeader>
               <CardBody className="grid grid-cols-4 gap-2 items-center">
                  <SearchInput onChange={(e: any) => setSearch(e.target.value)} value={search} />

                  <Button color="primary" variant="solid" onClick={() => onFilter()} className="mt-5 w-20">
                     {t('common.filter')}
                  </Button>
               </CardBody>
            </Card>
            <Card title={'Mail Listesi'}>
               <TableComponents
                  columns={columns}
                  data={data?.data || []}
                  pagination={{
                     total: data?.data?.total,
                     current: page,
                     pageSize: 10,
                     onChange: (page: any) => {
                        setPage(page)
                     }
                  }}
                  loading={loading}
               />
            </Card>
         </div>
      </div>
   )
}

export default MailList

import { IconSearch } from '@tabler/icons-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Input } from 'rizzui'

interface Props {
   placeholder?: string;
   onChange?: (e: any) => void;
   value?: string;
   className?: string;
}

function SearchInput(props: Props) {
   const { t } = useTranslation()
   return (
      <div>
         <span className="font-semibold">{t('common.keyword')}</span>
         <Input className="outline-0" placeholder={t('common.keyword')} onChange={props.onChange} value={props.value} />
      </div>
   )
}

export default SearchInput

import React, { useEffect } from 'react'
import { Button, Card, Chip, Select, SelectItem } from '@nextui-org/react';
import Sidebar from './Sidebar'
import HamburgerButton from './HamerburgerButton'
import { useIsMounted } from 'hooks/use-is-mounted'
import useWindowScroll from 'react-use/lib/useWindowScroll'
import cn from 'utils/class-names'
import { Link, useNavigate } from 'react-router-dom'

import ProfileMenu from './profile-menu'
import { useDispatch, useSelector } from 'react-redux'
import { MoneyFormat } from 'hooks/currency';
import MessagesDropdown from './messages-dropdown'
import { FaCircleQuestion } from 'react-icons/fa6'

import {
   IconBrandWhatsapp,
   IconChevronDown,
   IconDots,
   IconMail,
   IconPhone,
   IconQuestionMark,
   IconShoppingBag,
   IconWorld,
} from '@tabler/icons-react';
import { PiPlusCircle } from 'react-icons/pi'
import { Avatar, Drawer, Empty, Form, Image, Input, List, Modal, message, notification } from 'antd'
import { Alert, Badge, EmptyProductBoxIcon, Switch, Title } from 'rizzui';
import useFetch from 'hooks/use-fetch'
import { USER_SHOW, USER_STORE } from 'api/user';
import { Select as SelectBox } from 'antd'
import { setContactDetail, setProduct } from 'features/user/offer'
import { CUSTOMER_MANAGEMENT_LIST, CUSTOMER_MANAGEMENT_STORE } from 'api/customer-management'
import { RootState } from 'store'
import { removeCart, setCart } from 'features/user/basket'
import { routes } from 'config/routes'
import _ from 'lodash'
import { Popover, PopoverTrigger, PopoverContent } from '@nextui-org/popover'
import CreateRequestModal from 'components/modals/CreateRequestModal';
import { openRequestModal } from 'features/modals/requestModal';
import { IconFileDescription, IconFileInvoice } from '@tabler/icons-react';

interface Props {
   toggleSidebar: any
}

function HeaderMenuRight() {
   const carts = useSelector((state: RootState) => state.basket.carts)
   const navigate = useNavigate()
   const [cartDrawer, setCartDrawer] = React.useState(false)
   const user = JSON.parse(localStorage.getItem('user') as string)
   const dispatch = useDispatch()
   const [open, setOpen] = React.useState(false)
   const [openDrawer, setOpenDrawer] = React.useState(false)
   const [form] = Form.useForm()
   const [contactForm] = Form.useForm()
   const [contactModal, setContactModal] = React.useState(false)
   const [contactsQuery, { data: contactsData, loading: contactsLoading, error: contactsError }] = useFetch<any>()
   const [usersQuery, { data: usersData, loading, error }] = useFetch<any>()
   const [offerRequestMutation, { data: offerRequestData, loading: offerRequestLoading, error: offerRequestError }] =
      useFetch<any>()
   const [contactMutation, { data: contactData, loading: contactLoading, error: contactError }] = useFetch<any>()
   const [contact_id, setContact_id] = React.useState<any>(null)
   const [sendWithProduct, setSendWithProduct] = React.useState(false)

   const [isMobile, setIsMobile] = React.useState(false)

   useEffect(() => {
      if (window.innerWidth < 1024) {
         setIsMobile(true)
      } else {
         setIsMobile(false)
      }
   }, [window.innerWidth])

   const [gimel, { data: gimelData }] = useFetch<any>()

   useEffect(() => {
      //@ts-ignore
      gimel(USER_SHOW, {
         endPoint: '/total-gimel-stock'
      })
   }, [])

   useEffect(() => {
      //@ts-ignore
      usersQuery(USER_SHOW, {
         endPoint: '/users-with-offer-current-status'
      })
   }, [])

   const handleCreateRequest = () => {
      dispatch(openRequestModal(contact_id));
   };

   useEffect(() => {
      if (offerRequestData) {
         notification.success({
            message: 'Başarılı',
            description: 'Talep oluşturuldu.'
         })
         setOpen(false)
      }
   }, [offerRequestData])

   useEffect(() => {
      if (contactData) {
         message.success('Müşteri başarıyla eklendi!')

         setContactModal(false)
         setContact_id(contactData.contact_id)
         //@ts-ignore
         contactsQuery(CUSTOMER_MANAGEMENT_LIST, {
            endPoint: '/customer-with-contacts'
         })
      }
   }, [contactData])

   useEffect(() => {
      //@ts-ignore
      contactsQuery(CUSTOMER_MANAGEMENT_LIST, {
         endPoint: '/customer-with-contacts'
      })
   }, [])

   const handleSubmitContact = (values: any) => {
      console.log('values', values)
      //@ts-ignore
      contactMutation(CUSTOMER_MANAGEMENT_STORE, {
         endPoint: 'customers/addContactOffer',
         body: {
            ...values
         }
      })
   }

   const handleCartAction = () => {
      const products = carts.map((product: any) => {
         let price = product.price.split(' ')[0];
         price = price.replace(',', '.');
         price = parseFloat(price).toFixed(2);
         return {
            id: product.product_id,
            name: product.name,
            image: product.image,
            model: product.model,
            price: price,
            stock: product.stock,
            product_qty: product.stock,
            delivery_date: '',
            commission: 0,
            commission_type: '3',
            description: product.description,
            services: [],
            isReserve: false,
            reserveEndDate: '',
            supplier: product.supplier,
            currency: product.currency
         };
      });

      if (user?.role === 'admin') {
         setCartDrawer(false);
         dispatch(openRequestModal({ contactId: null, productsFromCart: true }));
      } else {
         dispatch(setProduct(products));
         navigate(routes.offerManagement.add);
      }
   };

   const HeaderAction = ({ className }: any) => (
      <div className={className}>
         <Popover backdrop="opaque" placement="bottom" showArrow={true}>
            <PopoverTrigger>
               <Button isIconOnly color="warning" variant="ghost">
                  <FaCircleQuestion size={20} />
               </Button>
            </PopoverTrigger>
            <PopoverContent>
               <Title as="h4">Uyarılar</Title>
               <div className="w-full w-[260px] border-small px-1 py-2 rounded-small  ">
                  <div>
                     {/* <ListboxItem key="new">New file</ListboxItem>
               <ListboxItem key="copy">Copy link</ListboxItem>
               <ListboxItem key="edit">Edit file</ListboxItem>
               <ListboxItem key="delete" className="text-danger" color="danger">
                  Delete file
               </ListboxItem> */}
                     <div className="p-2 flex items-center gap-2">
                        <div className="w-5 h-5 rounded-full bg-[#c0392b]"></div>
                        <span className="font-medium text-md">Kırmızı - İptal</span>
                     </div>
                     <div className="p-2 flex items-center gap-2 mt-2">
                        <div className="w-5 h-5 rounded-full bg-[#ff9800]"></div>
                        <span className="font-medium text-md">Turuncu - Revize</span>
                     </div>
                     <div className="p-2 flex items-center gap-2 mt-2">
                        <div className="w-5 h-5 rounded-full bg-[#3cb371]"></div>
                        <span className="font-medium text-md">Yeşil - Tamamlanmış</span>
                     </div>
                  </div>
               </div>
            </PopoverContent>
         </Popover>

         <Button

            color="danger"
            variant="shadow"
            onClick={() => {
               if (JSON.parse(localStorage.getItem('user') as string)?.role === 'admin') {
                  dispatch(openRequestModal({ contactId: null, productsFromCart: false }));
               } else {
                  navigate(routes.offerManagement.add);
               }
            }}
         >
            {JSON.parse(localStorage.getItem('user') as string)?.role === 'admin' ? (
               <div className="flex gap-2 items-center">
                  <IconFileDescription className="w-4 h-4" />
                  Talep Oluştur
               </div>
            ) : (
               <div className="flex gap-2 items-center"  >
                  <IconFileInvoice className="w-4 h-4" />
                  Teklif Oluştur
               </div>
            )}
         </Button>
         {/* <Button color="default" variant="bordered">
            Toplam Gimel Stoğu <Badge color="success">{gimelData?.data?.stock}</Badge>
         </Button>
         <Button color="default" variant="bordered">
            Toplam Gimel Stok Fiyatı <Badge color="success">{MoneyFormatTR(gimelData?.data?.price)}</Badge>
         </Button> */}
         <Button
            color="default"
            startContent={<IconShoppingBag size={20} />}
            variant="bordered"
            endContent={<Badge color="success">{carts.length}</Badge>}
            onClick={() => {
               setOpenDrawer(false)
               setCartDrawer(true)
            }}
         >
            Sepet
         </Button>
         <MessagesDropdown />
      </div>
   )

   return (
      <div className="ms-auto flex shrink-0 items-center gap-2 text-gray-700 xs:gap-3 xl:gap-4">
         <Drawer className="" open={cartDrawer} onClose={() => setCartDrawer(false)}>
            {carts.length === 0 && (
               <div className="grid h-full place-content-center">
                  <EmptyProductBoxIcon className="mx-auto h-auto w-52 text-gray-400" />
                  <Title as="h5" className="mt-6 text-center">
                     Sepet Boş!
                  </Title>
               </div>
            )}

            <List
               itemLayout="horizontal"
               dataSource={carts}
               className="p-5"
               renderItem={(item: any, index: number) => (
                  <List.Item
                     actions={[
                        <Button
                           onClick={() => {
                              Modal.confirm({
                                 className: 'z-[10000]',
                                 title: 'Ürünü silmek istediğinize emin misiniz?',
                                 icon: <IconQuestionMark />,
                                 content: 'Bu işlem geri alınamaz',
                                 okText: 'Evet',
                                 okType: 'danger',
                                 cancelText: 'Hayır',
                                 closable: true,
                                 onOk() {
                                    dispatch(removeCart(index))
                                 }
                              })
                           }}
                           color="danger"
                        >
                           Sil
                        </Button>
                     ]}
                  >
                     <List.Item.Meta
                        avatar={<Image width={50} src={item.image} />}
                        title={item.name}
                        description={item.model}
                     />
                  </List.Item>
               )}
            />

            <div className="p-5">
               <Button
                  onClick={handleCartAction}
                  color="primary"
                  className="w-full"
               >
                  {user?.role === 'admin' ? (
                     <>
                        <IconFileDescription className="w-4 h-4" />
                        Sepetteki ürünlerle talep oluştur
                     </>
                  ) : (
                     <>
                        <IconFileInvoice className="w-4 h-4" />
                        Sepetteki ürünlerle teklif oluştur
                     </>
                  )}
               </Button>
            </div>
         </Drawer>
         <Modal open={contactModal} footer={null} onCancel={() => setContactModal(false)} title="Yeni Müşteri Ekle">
            <Form form={contactForm} layout="vertical" onFinish={handleSubmitContact}>
               <Form.Item
                  label="Firma Adı"
                  name="company_name"
                  rules={[{ required: true, message: 'Lütfen firma adı giriniz!' }]}
               >
                  <Input placeholder="Firma adı giriniz" />
               </Form.Item>
               <Form.Item
                  label="İlgili müşteri"
                  name="contact_name"
                  rules={[{ required: true, message: 'Lütfen ilgili müşteri adı giriniz!' }]}
               >
                  <Input placeholder="İlgili müşteri adı giriniz" />
               </Form.Item>
               <Form.Item
                  label="Telefon"
                  name="phone"
                  rules={[{ required: false, message: 'Lütfen telefon numarası giriniz!' }]}
               >
                  <Input placeholder="Telefon numarası giriniz" />
               </Form.Item>
               <Form.Item
                  label="Email"
                  name="email"
                  rules={[{ required: true, message: 'Lütfen email adresi giriniz!' }]}
               >
                  <Input placeholder="Email adresi giriniz" />
               </Form.Item>
               <Button isLoading={contactLoading} type="submit" color="primary">
                  Kaydet
               </Button>
            </Form>
         </Modal>
         <CreateRequestModal />
         {!isMobile && <HeaderAction className="hidden lg:flex xl:flex md:hidden sm:hidden gap-2 items-center" />}
         {isMobile && (
            <div>
               <Drawer
                  open={openDrawer}
                  onClose={() => {
                     setOpenDrawer(false)
                  }}
                  placement="bottom"
               >
                  <HeaderAction className="flex flex-col gap-2" />
               </Drawer>
               <Button onClick={() => setOpenDrawer(true)} color="primary" variant="shadow">
                  <PiPlusCircle size={20} /> Diğer İşlemler
               </Button>
            </div>
         )}
         <ProfileMenu
            buttonClassName="w-auto sm:w-auto p-1 border border-gray-300"
            avatarClassName="!w-7 !h-7 sm:!h-8 sm:!w-8"
         />
      </div>
   )
}

export default function Header() {
   const isMounted = useIsMounted()
   const windowScroll = useWindowScroll()
   const [open, setOpen] = React.useState(false)
   const [openDrawer, setOpenDrawer] = React.useState(false)
   const [foreigns, setForeigns] = React.useState<any>([])

   return (
      <header
         className={cn(
            'sticky top-0 z-50 flex items-center justify-between bg-gray-0/80 px-4 py-4 backdrop-blur-xl dark:bg-gray-50/50 md:px-5 lg:px-6 2xl:py-5 2xl:pl-6 3xl:px-8',
            ((isMounted && windowScroll.y) as number) > 2 ? 'card-shadow' : ''
         )}
      >
         <div className="flex w-full max-w-2xl items-center xl:w-auto">
            <HamburgerButton view={<Sidebar className="static w-full 2xl:w-full" />} />
            <Link aria-label="Site Logo" to={'/'} className="me-4 w-9 shrink-0 text-gray-900 lg:me-5 xl:hidden">
               {/* <Logo iconOnly={true} /> */}
            </Link>
            {/* <SearchWidget
              icon={<PiMagnifyingGlassDuotone className="h-[20px] w-[20px]" />}
              className={cn(
                'text-gray-700 hover:text-gray-900 focus-visible:outline-0 active:translate-y-0 xl:border-0 xl:p-0 xl:shadow-none xl:backdrop-blur-none xl:hover:border-0 xl:hover:outline-0 xl:focus:outline-0 xl:focus-visible:outline-0 [&_.magnifying-glass]:me-0 [&_.placeholder-text]:hidden [&_.search-command]:ms-2 [&_.search-command]:hidden [&_.search-command]:lg:text-gray-0'
              )}
            /> */}
         </div>

         <div className="hidden items-center gap-3 xl:flex">
            <Link
               aria-label="Site Logo"
               to={'/'}
               className="me-4 hidden w-[155px] shrink-0 text-gray-900 lg:me-5 xl:block"
            >
               {/* <Logo className="max-w-[155px]" /> */}
            </Link>
            {/* <HeaderMenusLeft /> */}
         </div>
         <div className="flex w-full items-center gap-5 xl:w-auto 3xl:gap-6">
            <HeaderMenuRight />
         </div>
      </header>
   )
}

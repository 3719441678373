import { Button } from '@nextui-org/react';
import { Badge } from 'rizzui';
import { FaChartLine, FaUpload } from "react-icons/fa6";
import { motion } from 'framer-motion';
import { useState } from 'react';
import { message } from 'antd';
import { IconUpload, IconZoomIn, IconTrash } from '@tabler/icons-react';
import { Modal, Upload } from 'antd';
import { apiUrl } from 'api';
import axios from 'axios';
import { RootState } from 'store';
import { useSelector } from 'react-redux';


export default function CustomerTabs({ id, activeTab, onTabChange, customer }: { id: string, activeTab: string, onTabChange: (tab: string) => void, customer: any }) {
    const [designModal, setDesignModal] = useState(false);
    const [selectedDesign, setSelectedDesign] = useState<string[]>([]);
    const [uploadVisible, setUploadVisible] = useState(false);

    const user = useSelector((state: RootState) => state.user)

    const tabItems = [
        { slug: 'toplam-talepler', name: 'Toplam Talepler', total: user?.totalRequests },
        { slug: 'toplam-teklifler', name: 'Toplam Teklifler', total: user?.totalOffers },
        { slug: 'toplam-siparisler', name: 'Toplam Siparişler', total: user?.totalOrders }
    ];

    // Tasarıma tıklama fonksiyonu
    const handleDesignClick = () => {
        try {
            // JSON string'i parse et ve array'e çevir
            const photoArray = customer?.data?.photos ?
                JSON.parse(customer.data.photos)
                    .filter((photo: string) => photo.trim() !== '')
                    .map((photo: string) => apiUrl + `/uploads/${photo}`) // URL'i oluştur
                : [];

            setSelectedDesign(photoArray);
            setDesignModal(true);
        } catch (error) {
            console.error('Photo parse error:', error);
            setSelectedDesign([]);
            setDesignModal(true);
        }
    };

    // Upload işlemi için
    const handleUploadChange = (info: any) => {
        if (info.file.status === 'done') {
            const response = info.file.response;
            if (response.status && response.data) {
                message.success(response.message || 'Dosya başarıyla yüklendi');
                // Yeni dosya adlarını array'e ekle
                const newFiles = response.data.map((filename: string) => apiUrl + `/uploads/${filename}`);
                setSelectedDesign(newFiles);
            }
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} yüklenemedi.`);
        }
    };

    // Tasarım silme fonksiyonu
    const handleDeleteDesign = async (filename: string, index: number) => {
        try {
            // Dosya adını URL'den ayıkla
            const fileNameOnly = filename.split('/').pop();

            // API isteği
            await axios.post(apiUrl + '/delete-design/' + id, {
                customer_id: id,
                filename: fileNameOnly,
                photos: customer?.data?.photos // mevcut photos array'i
            });

            // UI'dan kaldır
            setSelectedDesign(prev => prev.filter((_, i) => i !== index));
            message.success('Tasarım başarıyla silindi');
        } catch (error) {
            console.error('Delete error:', error);
            message.error('Tasarım silinirken bir hata oluştu');
        }
    };

    return (
        <div className='flex items-center justify-between'>
            <motion.div
                className='flex items-center gap-2'
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
            >
                {tabItems.map((item, index) => (
                    <motion.div
                        key={item.slug}
                        initial={{ opacity: 0, x: -20 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ delay: index * 0.1 }}
                    >
                        <Button
                            size="lg"
                            className={`relative ${activeTab === item.slug ? 'bg-primary text-white' : 'text-gray-500 hover:bg-gray-100'}`}
                            onClick={() => onTabChange(item.slug)}
                        >
                            {activeTab === item.slug && (
                                <motion.div
                                    layoutId="activeTab"
                                    className="absolute inset-0 bg-primary rounded-lg"
                                    initial={false}
                                    transition={{ type: "spring", duration: 0.5 }}
                                />
                            )}
                            <span className="relative z-10 flex items-center gap-2">
                                {item.name}
                                <Badge
                                    color='primary'
                                    variant='solid'
                                    className={`${activeTab === item.slug ? 'bg-white text-primary' : 'text-gray-500 bg-gray-100'}`}
                                >
                                    {item.total}
                                </Badge>
                            </span>
                        </Button>
                    </motion.div>
                ))}
            </motion.div>

            <motion.div
                className='flex items-center gap-2'
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: 0.3 }}
            >
                <Button
                    size="lg"
                    className='bg-amber-500 opacity-70 rounded-xl hover:opacity-100 transition-opacity'
                    onClick={handleDesignClick}
                    startContent={
                        <motion.div
                            whileHover={{ rotate: 360 }}
                            transition={{ duration: 0.5 }}
                        >
                            <FaChartLine size={20} className='text-white' />
                        </motion.div>
                    }
                >
                    <span className='text-white'>Tasarımlar</span>
                </Button>
                {/* <Button
                    size="lg"
                    className='bg-amber-500 rounded-l-none hover:opacity-90 transition-opacity'
                    onClick={() => setUploadVisible(true)}
                    startContent={
                        <motion.div
                            whileHover={{ scale: 1.2 }}
                            transition={{ type: "spring", stiffness: 300 }}
                        >
                            <FaUpload size={20} className='text-white' />
                        </motion.div>
                    }
                >
                    <span className='text-white'>Tasarım Yükle</span>
                </Button> */}
            </motion.div>

            {/* Modal */}
            <Modal
                title={
                    <div className="flex items-center justify-between">
                        <span>Tasarımlar</span>

                    </div>
                }
                open={designModal}
                onCancel={() => {
                    setDesignModal(false);
                    setUploadVisible(false);
                }}
                footer={(

                    <div className='my-2'>
                        <Button
                            color="primary"
                            size="sm"
                            onClick={() => setUploadVisible(!uploadVisible)}
                            startContent={<IconUpload className="w-4 h-4" />}
                        >
                            Tasarım Yükle
                        </Button>
                    </div>
                )}
                width={800}
            >
                {uploadVisible && (
                    <div className="mb-6 border rounded-lg p-4">
                        <Upload.Dragger
                            multiple
                            action={apiUrl + `/upload/${id}`}
                            onChange={handleUploadChange}
                        >
                            <p className="ant-upload-drag-icon">
                                <IconUpload className="w-8 h-8 mx-auto text-gray-400" />
                            </p>
                            <p className="ant-upload-text">Tasarım yüklemek için tıklayın veya sürükleyin</p>
                        </Upload.Dragger>
                    </div>
                )}

                <div className="grid grid-cols-2 gap-4">
                    {selectedDesign.map((photo, index) => (
                        <div key={index} className="relative group">
                            <img
                                src={photo}
                                alt={`Tasarım ${index + 1}`}
                                className="w-full h-full object-contain aspect-square rounded-lg  bg-gray-300"
                            />
                            <div className="absolute bottom-0 p-2 w-full">
                                <div className="flex  mt-2 justify-between gap-2 items-center">
                                    <Button
                                        size="sm"
                                        color="primary"

                                        onClick={() => window.open(photo, '_blank')}
                                        startContent={<IconZoomIn className="w-4 h-4" />}
                                    >
                                        Büyüt
                                    </Button>
                                    <Button
                                        size="sm"
                                        color="danger"
                                        variant="solid"
                                        onClick={() => handleDeleteDesign(photo, index)}
                                        startContent={<IconTrash className="w-4 h-4" />}
                                    >
                                        Sil
                                    </Button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                {selectedDesign.length === 0 && !uploadVisible && (
                    <div className="text-center py-12 text-gray-500">
                        Henüz tasarım yüklenmemiş
                    </div>
                )}
            </Modal>
        </div>
    );
} 
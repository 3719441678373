import React, { useEffect } from 'react'
import SummaryWidget from './summary-widget'
import TotalAppointment from './total-appointment'
import RecentActivities from './Times'
import useFetch from 'hooks/use-fetch'
import { OFFER_MANAGEMENT_LIST, OFFER_MANAGEMENT_LIST_URL, OFFER_MANAGEMENT_SHOW } from 'api/offer-management'
import { Badge, Title } from 'rizzui'
import { TableColumnProps } from 'antd'
import moment from 'moment'
import TableComponents from 'components/Table'
import { Button, Card, CardBody, CardHeader, Divider, PopoverContent, PopoverTrigger, Tooltip, Popover, Chip } from '@nextui-org/react'
import Lottie from 'react-lottie'
import { getOrderStatusWithClass, getOrderWithAnimation, orderStatus, orderStatusColor, orderStatusText } from 'utils/get-order-status'
import cn from 'utils/class-names'
import { MoneyFormatTR } from 'hooks/currency'
import PersonalDashboard from './PersonalDashboard'
import { useNavigate } from 'react-router-dom'
import { routes } from 'config/routes'
import { offerAdminStatus } from 'utils/offer'
import { Link } from 'react-router-dom'
import { IconCalculator, IconChevronDown, IconChevronRight, IconPackage, IconInfoCircle, IconUser, IconChartBar } from '@tabler/icons-react'

const Dashboard = () => {
   // ... existing imports ...

   // Yeni bileşen tanımı
   const OrderCardNew = ({ order }: { order: any }) => {
      const offer = order.offer;
      const total = parseFloat(offer?.total || '0');
      const profit = parseFloat(offer?.profit || '0');

      // Kar oranını product_markup'tan hesapla
      const profitMargin = offer?.products?.reduce((total: number, product: any) => {
         const markup = parseFloat(product.product_markup || '0');
         // Eğer markup type 2 ise sabit tutardır, yüzde hesaplanmalı
         if (product.product_markup_type === '2') {
            const productJson = JSON.parse(product.product_json);
            const basePrice = parseFloat(productJson.price || '0');
            return total + ((markup / basePrice) * 100);
         }
         // Markup type 1 ise direkt yüzde değeridir
         return total + markup;
      }, 0) || 0;
      return (
         <div className="p-4 bg-white rounded-lg border hover:border-primary/30 transition-colors">
            {/* Üst Kısım - Sipariş No ve Tarih */}
            <div className="flex items-center justify-between mb-3">
               <div className="flex items-center gap-2">
                  <span className="px-2 py-0.5 text-xs bg-primary/10 text-primary rounded">
                     #{offer.no}
                  </span>
                  <span className="text-sm text-gray-500">
                     {moment(offer.created_at).format('DD MMM YYYY')}
                  </span>
               </div>
               <Chip
                  color={offer.profit > 0 ? "success" : "danger"}
                  size="sm"
                  variant="flat"
               >
                  {MoneyFormatTR(offer.total)} ₺
               </Chip>
            </div>

            {/* Orta Kısım - Müşteri Bilgileri */}
            <div className="space-y-3">
               <div className="flex items-center justify-between">
                  <span className="text-sm text-gray-500">Toplam Tutar:</span>
                  <Popover placement="left">
                     <PopoverTrigger>
                        <span className="font-semibold text-primary cursor-pointer hover:opacity-70 transition-opacity flex items-center gap-1">
                           {MoneyFormatTR(offer.total)} {offer?.currency || '₺'}
                           <IconInfoCircle size={16} className="text-gray-400" />
                        </span>
                     </PopoverTrigger>
                     <PopoverContent className="w-96">
                        <div className="p-4">
                           <div className="flex items-center gap-2 mb-4">
                              <div className="p-2 rounded-lg bg-primary/10">
                                 <IconCalculator size={20} className="text-primary" />
                              </div>
                              <div className="font-medium">Fiyat Analizi</div>
                           </div>

                           <div className="space-y-4">
                              {offer?.products?.map((product: any, index: number) => {
                                 const productJson = JSON.parse(product.product_json);
                                 const basePrice = parseFloat(productJson.price || '0');
                                 const quantity = parseInt(product.product_qty || '0');
                                 const totalServices = product.services?.reduce((sum: number, service: any) =>
                                    sum + (service.included_in_total ? parseFloat(service.price || '0') : 0), 0
                                 ) || 0;

                                 return (
                                    <div key={index} className="p-3 bg-gray-50 rounded-lg">
                                       <div className="flex items-start justify-between mb-2">
                                          <div className="flex items-center gap-2">
                                             <div className="w-8 h-8 rounded bg-white flex items-center justify-center">
                                                <IconPackage size={16} className="text-gray-500" />
                                             </div>
                                             <div className="font-medium">{productJson.name}</div>
                                          </div>
                                          <Chip color="primary" variant="flat" size="sm">
                                             {quantity} Adet
                                          </Chip>
                                       </div>

                                       <div className="space-y-2 text-sm">
                                          <div className="flex justify-between p-2 bg-white rounded">
                                             <span>Birim Fiyat</span>
                                             <span className="font-medium">{MoneyFormatTR(basePrice)} {offer?.currency}</span>
                                          </div>

                                          {product.services?.length > 0 && (
                                             <div className="p-2 bg-white rounded">
                                                <div className="flex justify-between mb-2">
                                                   <span>Hizmetler</span>
                                                   <span className="font-medium">{MoneyFormatTR(totalServices)} {offer?.currency}</span>
                                                </div>
                                                <div className="space-y-1 ml-3 text-xs">
                                                   {product.services.map((service: any, sIndex: number) => (
                                                      <div key={sIndex} className="flex justify-between items-center">
                                                         <div className="flex items-center gap-1">
                                                            <span>{service.name}</span>
                                                            {service.included_in_total ? (
                                                               <Chip size="sm" variant="flat" color="success">Dahil</Chip>
                                                            ) : (
                                                               <Chip size="sm" variant="flat" color="warning">Hariç</Chip>
                                                            )}
                                                         </div>
                                                         <span>{MoneyFormatTR(service.price)} {offer?.currency}</span>
                                                      </div>
                                                   ))}
                                                </div>
                                             </div>
                                          )}

                                          <div className="flex justify-between p-2 bg-primary/5 rounded font-medium">
                                             <span>Ürün Toplamı</span>
                                             <span>{MoneyFormatTR((basePrice + totalServices) * quantity)} {offer?.currency}</span>
                                          </div>
                                       </div>
                                    </div>
                                 );
                              })}

                              <div className="p-3 bg-primary/5 rounded-lg">
                                 <div className="grid grid-cols-3 gap-4">
                                    <div>
                                       <div className="text-sm text-gray-500">Toplam Tutar</div>
                                       <div className="font-medium text-primary">
                                          {MoneyFormatTR(total)} {offer?.currency}
                                       </div>
                                    </div>
                                    <div>
                                       <div className="text-sm text-gray-500">Toplam Ürün</div>
                                       <div className="font-medium">
                                          {offer?.products?.length || 0} Kalem
                                       </div>
                                    </div>
                                    <div>
                                       <div className="text-sm text-gray-500">Toplam Adet</div>
                                       <div className="font-medium">
                                          {offer?.products?.reduce((sum: number, p: any) => sum + parseInt(p.product_qty || '0'), 0)} Adet
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </PopoverContent>
                  </Popover>
               </div>

               <div className="flex items-center justify-between">
                  <span className="text-sm text-gray-500">Toplam Kar:</span>
                  <Popover placement="left">
                     <PopoverTrigger>
                        <span className="font-semibold text-green-600 cursor-pointer hover:opacity-70 transition-opacity flex items-center gap-1">
                           {MoneyFormatTR(profit)} {offer?.currency || '₺'}
                           <IconInfoCircle size={16} className="text-gray-400" />
                        </span>
                     </PopoverTrigger>
                     <PopoverContent className="w-96">
                        <div className="p-4">
                           <div className="flex items-center gap-2 mb-4">
                              <div className="p-2 rounded-lg bg-green-50">
                                 <IconChartBar size={20} className="text-green-600" />
                              </div>
                              <div className="font-medium">Kar Analizi</div>
                           </div>

                           <div className="space-y-4">
                              {offer?.products?.map((product: any, index: number) => {
                                 const productJson = JSON.parse(product.product_json);
                                 const basePrice = parseFloat(productJson.price || '0');
                                 const quantity = parseInt(product.product_qty || '0');
                                 const markup = parseFloat(product.product_markup || '0');

                                 // Kar tutarı hesaplama
                                 const markupAmount = product.product_markup_type === '2'
                                    ? markup * quantity  // Sabit tutar
                                    : basePrice * (markup / 100) * quantity;  // Yüzde bazlı

                                 // Kar oranı hesaplama
                                 const markupPercentage = product.product_markup_type === '2'
                                    ? ((markup / basePrice) * 100).toFixed(2)  // Sabit tutarın yüzdelik karşılığı
                                    : markup.toFixed(2);  // Direkt yüzde değeri

                                 return (
                                    <div key={index} className="p-3 bg-gray-50 rounded-lg">
                                       <div className="flex items-center justify-between mb-2">
                                          <span className="font-medium">{productJson.name}</span>
                                          <Chip
                                             color={markupAmount > 0 ? "success" : "danger"}
                                             variant="flat"
                                          >
                                             {MoneyFormatTR(markupAmount)} {offer?.currency}
                                          </Chip>
                                       </div>

                                       <div className="grid grid-cols-2 gap-2 text-sm">
                                          <div className="p-2 bg-white rounded">
                                             <div className="text-gray-500">Kar Tipi</div>
                                             <div className="font-medium">
                                                {product.product_markup_type === '2' ? 'Sabit Tutar' : 'Yüzde Bazlı'}
                                             </div>
                                          </div>
                                          <div className="p-2 bg-white rounded">
                                             <div className="text-gray-500">Kar Oranı</div>
                                             <div className="font-medium">%{markupPercentage}</div>
                                          </div>
                                       </div>
                                    </div>
                                 );
                              })}

                              <div className="p-3 bg-green-50 rounded-lg">
                                 <div className="grid grid-cols-3 gap-4">
                                    <div>
                                       <div className="text-sm text-gray-500">Toplam Kar</div>
                                       <div className="font-medium text-green-600">
                                          {MoneyFormatTR(profit)} {offer?.currency}
                                       </div>
                                    </div>
                                    <div>
                                       <div className="text-sm text-gray-500">Ortalama Kar Oranı</div>
                                       <div className="font-medium text-green-600">
                                          %{(profitMargin / (offer?.products?.length || 1)).toFixed(2)}
                                       </div>
                                    </div>
                                    <div>
                                       <div className="text-sm text-gray-500">Toplam Satış</div>
                                       <div className="font-medium">{MoneyFormatTR(total)} {offer?.currency}</div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </PopoverContent>
                  </Popover>
               </div>

               <div className="flex items-center justify-between">
                  <span className="text-sm text-gray-500">Ortalama Kar Oranı:</span>
                  <span className={`font-semibold ${profit > 0 ? 'text-green-600' : 'text-red-600'}`}>
                     %{(profitMargin / (offer?.products?.length || 1)).toFixed(2)}
                  </span>
               </div>
            </div>

            {/* Alt Kısım - Sorumlu ve Detay Butonu */}
            <div className="flex items-center justify-between text-sm">
               <div className="flex items-center gap-2">
                  <IconUser size={16} className="text-gray-400" />
                  <span className="text-gray-600">{offer.user?.name}</span>
               </div>

            </div>
         </div>
      );
   };

   // Kullanım yerini güncelle

   const [fetchDashboard, { data: dashboardData, loading }] = useFetch<any>()
   const [offerManagementQuery, { data, loading: offerManagementLoading }] = useFetch<any>()

   React.useEffect(() => {
      //@ts-ignore
      fetchDashboard(OFFER_MANAGEMENT_SHOW, {
         endPoint: 'dashboard'
      })
   }, [])

   useEffect(() => {
      //@ts-ignore
      offerManagementQuery(OFFER_MANAGEMENT_LIST, {
         endPoint: OFFER_MANAGEMENT_LIST_URL(1, '') + '&offerType=1&is_ordered=1'
      })
   }, [])

   const columns: TableColumnProps<any>[] = [
      {
         title: 'Teklif Grup No',
         dataIndex: 'no',
         key: 'no',
         render: (no: any, record) => {
            return <div className="flex items-center gap-3">{record.no}</div>
         }
      },
      {
         title: 'Müşteri',
         dataIndex: 'id',
         key: 'id',
         render: (id: any, record: any) => {
            const parse = JSON.parse(record?.customer_json)
            if (!parse && record?.contact)
               return (
                  <div className="flex items-center gap-3">
                     {record?.contact?.name} ({record.contact?.customer?.company_name})
                  </div>
               )
            return <div className="flex items-center gap-3">{parse?.name}</div>
         }
      },
      {
         title: 'Durumu',
         dataIndex: 'id',
         key: 'id',
         render: (id: any, record: any) => {
            const color = record.is_ordered === 1 ? 'text-green-500' : 'text-red-500'
            const background = record.is_ordered === 1 ? 'bg-green-500' : 'bg-red-100'
            return (
               <div className="items-center gap-3">
                  {/* <Chip color={record.status === 1 ? 'primary' : 'danger'}>{record.status === 1 ? 'Aktif' : 'Pasif'}</Chip> */}
                  <div
                     className={`flex items-center gap-2 rounded-md px-2 py-1 ${background} ${color}`}
                     style={{
                        background: record.is_ordered === 1 ? '#e6ffed' : '#fff5f5'
                     }}
                  >
                     <span>{record.is_ordered === 1 ? 'Siparişe Dönüştürüldü' : 'Bekleniyor'}</span>
                  </div>
               </div>
            )
         }
      },

      {
         title: 'Eklenme Tarihi',
         dataIndex: 'id',
         key: 'id',
         render: (id: any, record: any) => {
            return (
               <div className="flex items-center gap-3">
                  {/* {moment(record.created_at).format('LL')} */}
                  {moment(record?.created_at).format('LL')}
               </div>
            )
         }
      },

      {
         title: 'Ürünler',
         dataIndex: 'id',
         key: 'id',
         render: (id: any, record: any) => {
            return (
               <div className="flex items-center gap-3">
                  {/* {moment(record.updated_at).format('LL')} */}
                  <Tooltip
                     content={
                        <div className="px-1 py-2">
                           {record?.products.map((product: any, index: any) => {
                              var parse = JSON.parse(product.product_json || '{}')
                              return (
                                 <div key={index} className="flex justify-between gap-10 w-full">
                                    <div className="text-left">{parse?.name}</div>
                                    <div className="text-right">{parse?.price}₺</div>
                                 </div>
                              )
                           })}
                        </div>
                     }
                  >
                     <Button variant="bordered">Ürünler</Button>
                  </Tooltip>
               </div>
            )
         }
      }
   ]

   const ordersColumns: TableColumnProps<any>[] = [
      {
         title: 'Teklif Grup No',
         dataIndex: 'no',
         key: 'no',
         render: (no: any, record) => {
            return <div className="flex items-center gap-3">{record.offer.no}</div>
         }
      },

      {
         title: 'Müşteri seçiniz (Firma)',
         dataIndex: 'id',
         key: 'id',
         render: (id: any, record: any) => {
            return (
               <div className="flex items-center gap-3">
                  {/* {record.contact.name} ({record.contact.customer.company_name}) */}
                  {record?.offer.contact?.name}
               </div>
            )
         }
      },
      {
         title: 'Durumu',
         dataIndex: 'id',
         key: 'id',
         render: (id: any, record: any) => {
            const color = record.is_ordered === 1 ? 'text-green-500' : 'text-red-500'
            const background = record.is_ordered === 1 ? 'bg-green-500' : 'bg-red-100'

            return (
               <div
                  className={cn(
                     getOrderStatusWithClass(record.offer.offer_status),
                     'flex justify-between items-center gap-2'
                  )}
               >
                  <Lottie
                     options={{
                        loop: true,
                        autoplay: true,
                        animationData: getOrderWithAnimation(record.offer.offer_status)
                     }}
                     height={30}
                     width={30}
                  />
                  {orderStatusText[record.offer.offer_status]}
               </div>
            )
         }
      },
      {
         title: 'Sipariş Tarihi',
         dataIndex: 'id',
         key: 'id',
         render: (id: any, record: any) => {
            const color = record.is_ordered === 1 ? 'text-green-500' : 'text-red-500'
            const background = record.is_ordered === 1 ? 'bg-green-500' : 'bg-red-100'

            return (
               <div className="flex-col items-center gap-3">
                  {/* <Chip color={record.status === 1 ? 'primary' : 'danger'}>{record.status === 1 ? 'Aktif' : 'Pasif'}</Chip> */}
                  <div
                     className={`flex items-center gap-2 rounded-md px-2 py-1 ${background} ${color}`}
                     style={{
                        background: record.is_ordered === 1 ? '#e6ffed' : '#fff5f5'
                     }}
                  >
                     {record.is_ordered === 1 && <div>{moment(record.ordered_at).format('LL')}</div>}
                  </div>
               </div>
            )
         }
      },
      {
         title: 'Eklenme Tarihi',
         dataIndex: 'id',
         key: 'id',
         render: (id: any, record: any) => {
            return (
               <div className="flex items-center gap-3">
                  {/* {moment(record.created_at).format('LL')} */}
                  {moment(record.offer.created_at).format('LL')}
               </div>
            )
         }
      },
      {
         title: 'Toplam Tutar',
         dataIndex: 'id',
         key: 'id',
         render: (id: any, record: any) => {
            return (
               <div className="flex items-center gap-3">
                  {/* {moment(record.updated_at).format('LL')} */}
                  {MoneyFormatTR(record.offer.total)}
               </div>
            )
         }
      },
      {
         title: 'Toplam Kâr',
         dataIndex: 'id',
         key: 'id',
         render: (id: any, record: any) => {
            return (
               <div className="flex items-center gap-3">
                  {/* {moment(record.updated_at).format('LL')} */}
                  {MoneyFormatTR(record.offer.profit)}
               </div>
            )
         }
      },
      {
         title: 'Key Account Manager',
         dataIndex: 'id',
         key: 'id',
         render: (id: any, record: any) => {
            return (
               <div className="flex items-center gap-3">
                  {/* {record.contact.name} ({record.contact.customer.company_name}) */}
                  {record.offer.user?.name}
               </div>
            )
         }
      }
   ]

   // Yeni istatistik kartları için veri hazırlama

   const navigate = useNavigate()

   const user = JSON.parse(localStorage.getItem('user') || '{}')


   // Yeni bir bileşen ekleyelim
   const OrderCard = ({ order }: { order: any }) => {

      const FinancialSummary = ({ products }: { products: any[] }) => {
         const calculatePrices = (product: any) => {
            try {
               const productJson = typeof product.product_json === 'string'
                  ? JSON.parse(product.product_json)
                  : product.product_json;

               const quantity = parseInt(product.product_qty || 0);

               // 1. Temel satış fiyatı (komisyonsuz)
               const basePrice = parseFloat(productJson.price || 0);

               // 2. Komisyon hesaplama
               let commission = 0;
               if (product.product_markup_type === '2') {
                  commission = parseFloat(product.product_markup || 0);
               } else {
                  commission = basePrice * (parseFloat(product.product_markup || 0) / 100);
               }

               // 3. Hizmet maliyetleri ve komisyonları
               const serviceTotals = (product.services || []).reduce((total: number, service: any) => {
                  if (service.included_in_total === 1) {
                     const servicePrice = parseFloat(service.price || 0);
                     let serviceCommission = 0;

                     if (service.service_markup_type === '2') {
                        serviceCommission = parseFloat(service.service_markup || 0);
                     } else {
                        serviceCommission = servicePrice * (parseFloat(service.service_markup || 0) / 100);
                     }

                     return total + servicePrice + serviceCommission;
                  }
                  return total;
               }, 0);

               // 4. Hesaplamalar
               const totalUnitPrice = basePrice + serviceTotals; // Komisyonsuz toplam
               const totalWithMarkup = totalUnitPrice + commission; // Komisyonlu toplam

               return {
                  revenue: totalUnitPrice * quantity, // Komisyonsuz toplam tutar
                  profit: commission * quantity, // Sadece komisyon (kar)
                  quantity: quantity
               };
            } catch (error) {
               console.error('Fiyat hesaplama hatası:', error);
               return { revenue: 0, profit: 0, quantity: 0 };
            }
         };

         const totals = products.reduce((sum, product) => {
            const prices = calculatePrices(product);
            return {
               revenue: sum.revenue + prices.revenue,
               profit: sum.profit + prices.profit,
               quantity: sum.quantity + prices.quantity
            };
         }, { revenue: 0, profit: 0, quantity: 0 });

         const profitMargin = totals.revenue > 0 ? (totals.profit / totals.revenue) * 100 : 0;

         return (
            <div className="space-y-2">
               <div className="flex items-center justify-between">
                  <span className="text-sm text-gray-500">Toplam Tutar:</span>
                  <span className="font-semibold text-primary">
                     {MoneyFormatTR(totals.revenue)} {products?.[0]?.currency || '₺'}
                  </span>
               </div>
               <div className="flex items-center justify-between">
                  <span className="text-sm text-gray-500">Toplam Kar:</span>
                  <span className="font-semibold text-green-600">
                     {MoneyFormatTR(totals.profit)} {products?.[0]?.currency || '₺'}
                  </span>
               </div>
               <div className="flex items-center justify-between">
                  <span className="text-sm text-gray-500">Kar Oranı:</span>
                  <span className={`font-semibold ${totals.profit > 0 ? 'text-green-600' : 'text-red-600'}`}>
                     %{profitMargin.toFixed(2)}
                  </span>
               </div>
               <div className="flex items-center justify-between">
                  <span className="text-sm text-gray-500">Toplam Ürün Adedi:</span>
                  <span className="font-medium">{totals.quantity}</span>
               </div>
            </div>
         );
      };

      const ProductDetails = ({ product }: { product: any }) => {
         // 1. Ürün birim fiyatı
         const productJson = JSON.parse(product.product_json);
         let basePrice = parseFloat(productJson.price || '0');

         // 2. Ürün kar hesabı
         const productMarkup = product.product_markup_type === 1
            ? basePrice * (parseFloat(product.product_markup || '0') / 100)
            : parseFloat(product.product_markup || '0');

         let unitPrice = basePrice + productMarkup;

         // 3. Dahil olan hizmetleri ve karlarını ekle
         product.services?.forEach((service: any) => {
            if (service.included_in_total) {
               // Hizmet fiyatı
               const servicePrice = parseFloat(service.price || '0');

               // Hizmet kar hesabı
               const serviceMarkup = service.service_markup_type === 1
                  ? servicePrice * (parseFloat(service.service_markup || '0') / 100)
                  : parseFloat(service.service_markup || '0');

               // Sadece included_in_total true ise birim fiyata ekle
               unitPrice += servicePrice + serviceMarkup;
            }
         });

         const currency = product.currency || 'TRY';
         const currencySymbol = currency === 'TRY' ? '₺' : currency === 'USD' ? '$' : '€';
         const quantity = product.stock || product.product_qty;

         return (
            <div>
               <div className="ozellik">
                  Miktar: {quantity} Adet
               </div>
               <div className="ozellik">
                  Birim Fiyat: {MoneyFormatTR(unitPrice)} {currencySymbol} + KDV
               </div>

               {product.services?.length > 0 && (
                  <div className="urun-text mt-5">
                     <h5 className="baslik2">Hizmetler</h5>
                     {product.services.map((service: any, index: number) => (
                        <div key={index} className="mb-4 p-3 bg-gray-50 rounded-lg">
                           <div className="flex justify-between items-center">
                              <strong className="flex-1 mr-5">{service.name}</strong>
                              {service.price > 0 && (
                                 <div className="flex items-center gap-2">
                                    {!service.included_in_total && (
                                       <span className="whitespace-nowrap">
                                          {MoneyFormatTR(service.price)} {currencySymbol} + KDV
                                       </span>
                                    )}
                                    <span className={cn(
                                       "px-2 py-1 rounded text-xs font-medium",
                                       service.included_in_total == true
                                          ? "bg-green-500 text-white"
                                          : "bg-red-500 text-white"
                                    )}>
                                       {service.included_in_total == true
                                          ? "Birim Fiyata Dahildir"
                                          : "Birim Fiyata Dahil Değildir"
                                       }
                                    </span>
                                 </div>
                              )}
                           </div>
                           {/* {service.service_description && (
                              <div className="mt-2 text-gray-600">
                                 {service.service_description}
                              </div>
                           )} */}
                        </div>
                     ))}
                  </div>
               )}
            </div>
         );
      };

      const offer = order.offer
      const calculateProductTotal = (products: any[]) => {
         return products?.reduce((total: number, product: any) => {
            const productJson = JSON.parse(product.product_json);
            return total + (product.product_qty * parseFloat(productJson.price));
         }, 0) || 0;
      };

      const calculateServiceTotal = (products: any[]) => {
         return products?.reduce((total: number, product: any) => {
            return total + product.services?.reduce((serviceTotal: number, service: any) => {
               return serviceTotal + (parseFloat(service.price) * product.product_qty);
            }, 0) || 0;
         }, 0) || 0;
      };

      const calculateMarkupTotal = (products: any[]) => {
         return products?.reduce((total: number, product: any) => {
            // Ürün kar hesabı
            const productJson = JSON.parse(product.product_json);
            const baseAmount = product.product_qty * parseFloat(productJson.price);
            const productMarkup = product.product_markup_type === 1
               ? baseAmount * (parseFloat(product.product_markup) / 100)
               : parseFloat(product.product_markup);

            // Hizmet kar hesabı
            const serviceMarkup = product.services?.reduce((sTotal: number, service: any) => {
               const serviceAmount = parseFloat(service.price) * product.product_qty;
               return sTotal + (service.service_markup_type === 1
                  ? serviceAmount * (parseFloat(service.service_markup) / 100)
                  : parseFloat(service.service_markup));
            }, 0) || 0;

            return total + productMarkup + serviceMarkup;
         }, 0) || 0;
      };
      return (
         <div className="bg-white rounded-lg p-4 hover:shadow-lg transition-all duration-200 border border-gray-100">
            <div className="flex justify-between items-start mb-4">
               <div>
                  <div className="text-sm text-gray-500">Teklif No: {order.offer.no}</div>
                  <div className="font-semibold text-lg mt-1">{order.offer.contact?.name}</div>
               </div>
               <div
                  className={cn(
                     getOrderStatusWithClass(order.offer.offer_status),
                     'flex items-center gap-2 px-3 py-1 rounded-full text-sm'
                  )}
               >
                  <Lottie
                     options={{
                        loop: true,
                        autoplay: true,
                        animationData: getOrderWithAnimation(order.offer.offer_status),
                     }}
                     height={20}
                     width={20}
                  />
                  <span>{orderStatusText[order.offer.offer_status]}</span>
               </div>
            </div>

            <div className="grid grid-cols-2 gap-4 mb-4">
               <div>
                  <div className="text-xs text-gray-500">Sipariş Tarihi</div>
                  <div className="text-sm font-medium">
                     {moment(order.ordered_at).format('LL')}
                  </div>
               </div>
               <div>
                  <div className="text-xs text-gray-500">Eklenme Tarihi</div>
                  <div className="text-sm font-medium">
                     {moment(order.offer.created_at).format('LL')}
                  </div>
               </div>
            </div>

            <div className="flex justify-between items-end">
               <div>
                  <div className="text-xs text-gray-500">Key Account Manager</div>
                  <div className="text-sm font-medium">{order.offer.user?.name}</div>
               </div>
               <div className="mb-3">
                  <Popover placement="bottom">
                     <PopoverTrigger>
                        <div className="group cursor-pointer">
                           <div className="font-medium group-hover:text-primary transition-colors flex items-center gap-1">
                              {order.contact?.name}
                              <IconChevronDown size={16} className="text-gray-400" />
                           </div>
                           <div className="text-sm text-gray-500">
                              {order.contact?.customer?.company_name || '-'}
                           </div>
                        </div>
                     </PopoverTrigger>
                     <PopoverContent className="w-72">
                        <div className="p-3">
                           <div className="space-y-2">
                              <div>
                                 <div className="text-xs text-gray-500">E-posta</div>
                                 <div className="text-sm">{order.contact?.email}</div>
                              </div>
                              <div>
                                 <div className="text-xs text-gray-500">Ürün Sayısı</div>
                                 <div className="text-sm">{order.products?.length || 0} Kalem</div>
                              </div>
                              <div>
                                 <div className="text-xs text-gray-500">Toplam Kar</div>
                                 <div className="text-sm font-medium text-green-600">
                                    {MoneyFormatTR(order.profit)} ₺
                                 </div>
                              </div>
                           </div>
                        </div>
                     </PopoverContent>
                  </Popover>
               </div>
            </div>
         </div>
      );
   };

   const RequestCard = ({ request }: { request: any }) => {
      const parse = JSON.parse(request?.customer_json || '{}')
      const customerName = parse?.name || (request?.contact?.customer?.company_name ?
         `${request?.contact?.name} (${request.contact?.customer?.company_name})` :
         request?.contact?.name)

      return (
         <div className="group relative bg-white rounded-xl p-5 transition-all duration-300 hover:shadow-lg border border-gray-100 hover:border-primary/30">
            {/* Yeni tasarım için gradient overlay */}
            <div className="absolute inset-0 bg-gradient-to-r from-primary/5 to-transparent rounded-xl opacity-0 group-hover:opacity-100 transition-opacity" />

            {/* Status Indicator - Daha sofistike animasyon */}
            <div className="absolute -top-2 -right-2">
               <div className="relative">
                  <div className="w-4 h-4 rounded-full bg-yellow-400 animate-ping absolute" />
                  <div className="w-4 h-4 rounded-full bg-yellow-400 relative" />
               </div>
            </div>

            {/* Header Section */}
            <div className="flex justify-between items-start mb-4">
               <div className="flex items-center gap-2">
                  <span className="text-lg font-semibold text-primary font-mono">#{request.no}</span>
                  <Badge className="bg-gradient-to-r from-yellow-500 to-yellow-600 text-white border-none px-3 py-1">
                     Bekliyor
                  </Badge>
               </div>
               <div className="text-sm text-gray-500 flex items-center gap-2">
                  <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                     <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                  {moment(request.created_at).fromNow()}
               </div>
            </div>

            {/* Customer Info with Icon */}
            <div className="mb-4 flex items-start gap-3">
               <div className="w-10 h-10 rounded-full bg-primary/10 flex items-center justify-center flex-shrink-0">
                  <svg className="w-5 h-5 text-primary" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                     <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
                  </svg>
               </div>
               <div>
                  <h4 className="font-medium text-gray-900">{customerName}</h4>
                  <p className="text-sm text-gray-500">Müşteri ID: {request.contact?.id}</p>
               </div>
            </div>

            {/* Products Preview with new design */}
            <div className="space-y-2 bg-gray-50 rounded-lg p-3">
               {request?.products?.slice(0, 2).map((product: any, index: number) => {
                  const productData = JSON.parse(product.product_json || '{}')
                  return (
                     <div key={index} className="flex items-center justify-between text-sm p-2 bg-white rounded-md">
                        <div className="flex items-center gap-2">
                           <div className="w-2 h-2 rounded-full bg-primary/60" />
                           <span className="text-gray-700">{productData?.name}</span>
                        </div>
                        <span className="font-medium text-primary">{MoneyFormatTR(productData?.price)}</span>
                     </div>
                  )
               })}
               {request?.products?.length > 2 && (
                  <button className="w-full text-sm text-primary hover:text-primary-dark text-center pt-2 flex items-center justify-center gap-1">
                     <span>+{request.products.length - 2} daha fazla ürün</span>
                     <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                     </svg>
                  </button>
               )}
            </div>

            {/* Action Button with new hover effect */}

         </div>
      )
   }

   // Skeleton loading component ekleyelim
   const RequestCardSkeleton = () => (
      <div className="bg-white rounded-xl p-5 border border-gray-100">
         <div className="animate-pulse">
            <div className="flex justify-between items-start mb-4">
               <div className="flex items-center gap-2">
                  <div className="h-6 w-16 bg-gray-200 rounded" />
                  <div className="h-6 w-20 bg-gray-200 rounded" />
               </div>
               <div className="h-4 w-24 bg-gray-200 rounded" />
            </div>

            <div className="flex items-start gap-3 mb-4">
               <div className="w-10 h-10 rounded-full bg-gray-200" />
               <div className="flex-1">
                  <div className="h-5 w-3/4 bg-gray-200 rounded mb-2" />
                  <div className="h-4 w-1/2 bg-gray-200 rounded" />
               </div>
            </div>

            <div className="space-y-2 bg-gray-50 rounded-lg p-3">
               <div className="h-10 bg-gray-200 rounded" />
               <div className="h-10 bg-gray-200 rounded" />
            </div>

            <div className="mt-4">
               <div className="h-10 bg-gray-200 rounded" />
            </div>
         </div>
      </div>
   )

   const PaymentDueCard = ({ order }: { order: any }) => {
      const dueDate = moment(order.created_at).add(order.term_time || 30, 'days');
      const daysLeft = dueDate.diff(moment(), 'days');
      const isUrgent = daysLeft <= 3;

      // Get main offer data
      const offerData = order.offer || order;
      const totalAmount = parseFloat(offerData.total);
      const profit = parseFloat(offerData.profit);

      // Parse customer info
      const contact = offerData.contact;
      const customerName = contact?.customer?.company_name || contact?.name || 'İsimsiz Müşteri';

      // Get status info
      const statusText = orderStatus.find(status => status.key === offerData.offer_status)?.label;
      const statusColor = orderStatusColor[offerData.offer_status];

      // Gradient badge style helper
      const getGradientByColor = (color: string) => {
         const colorMap: { [key: string]: string } = {
            'green': 'from-green-50 to-green-100 text-green-700 border-green-200',
            'yellow': 'from-yellow-50 to-yellow-100 text-yellow-700 border-yellow-200',
            'red': 'from-red-50 to-red-100 text-red-700 border-red-200',
            'blue': 'from-blue-50 to-blue-100 text-blue-700 border-blue-200',
            'purple': 'from-purple-50 to-purple-100 text-purple-700 border-purple-200',
            'gray': 'from-gray-50 to-gray-100 text-gray-700 border-gray-200'
         };
         return colorMap[color] || colorMap.gray;
      };

      return (
         <div className="bg-white rounded-lg p-4 border-l-4 hover:shadow-md transition-shadow relative group"
            style={{ borderLeftColor: isUrgent ? '#ef4444' : '#eab308' }}>
            {/* Payment Due Badge */}
            <div className={`absolute -right-2 -top-2 px-3 py-1 rounded-full text-white text-xs font-medium ${isUrgent ? 'bg-red-500' : 'bg-yellow-500'
               }`}>
               {daysLeft <= 0 ? 'Bugün Son Gün!' : `${daysLeft} gün kaldı`}
            </div>

            {/* Order Info */}
            <div className="flex items-start justify-between mb-3">
               <div>
                  <div className="flex items-center gap-2">
                     <h4 className="font-medium text-gray-900">
                        Teklif #{offerData.no || order.offer_group?.no}
                     </h4>
                     <span className={`
                        text-xs px-3 py-1 rounded-full
                        bg-gradient-to-r border
                        ${getGradientByColor(statusColor)}
                        transition-all duration-200
                        hover:shadow-sm
                     `}>
                        {statusText || 'Beklemede'}
                     </span>
                  </div>
                  <p className="text-sm text-gray-500 mt-1">{customerName}</p>
               </div>
            </div>

            {/* Payment Details */}
            <div className="grid grid-cols-2 gap-4 mb-3">
               <div>
                  <p className="text-xs text-gray-500">Teklif Tutarı</p>
                  <p className="font-medium">{MoneyFormatTR(totalAmount)}</p>
               </div>
               <div>
                  <p className="text-xs text-gray-500">Kâr Marjı</p>
                  <p className="font-medium text-green-600">{MoneyFormatTR(profit)}</p>
               </div>
            </div>

            {/* Assigned User Info */}
            {order.assigned_user && (
               <Link
                  to={routes.users.view(order.assigned_user.id)}
                  className="flex items-center gap-2 mb-3 p-2 rounded-lg
                     bg-gradient-to-r from-blue-50 to-indigo-50
                     hover:from-blue-100 hover:to-indigo-100
                     border border-blue-100 hover:border-blue-200
                     transition-all duration-300 group/user
                     relative overflow-hidden"
               >
                  {/* Hover Effect Background */}
                  <div className="absolute inset-0 bg-gradient-to-r from-blue-500/5 to-indigo-500/5 
                     opacity-0 group-hover/user:opacity-100 transition-opacity" />

                  {/* Avatar */}
                  <div className="relative w-8 h-8 rounded-full bg-gradient-to-r from-blue-100 to-indigo-100 
                     flex items-center justify-center border border-blue-200 
                     group-hover/user:shadow-md transition-shadow">
                     <span className="text-blue-600 text-sm font-medium">
                        {order.assigned_user.name.charAt(0)}
                     </span>
                  </div>

                  {/* User Info */}
                  <div className="relative">
                     <p className="text-xs text-blue-600 font-medium">Sorumlu</p>
                     <p className="text-sm font-medium text-gray-700 group-hover/user:text-blue-700 
                        transition-colors flex items-center gap-1">
                        {order.assigned_user.name}
                        <svg className="w-3.5 h-3.5 opacity-0 -translate-x-2 group-hover/user:opacity-100 
                           group-hover/user:translate-x-0 transition-all duration-300"
                           fill="none" stroke="currentColor" viewBox="0 0 24 24">
                           <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                              d="M9 5l7 7-7 7" />
                        </svg>
                     </p>
                  </div>

                  {/* Hover Indicator */}
                  <div className="absolute bottom-0 left-0 w-full h-0.5 bg-gradient-to-r 
                     from-blue-500 to-indigo-500 transform scale-x-0 group-hover/user:scale-x-100 
                     transition-transform origin-left" />
               </Link>
            )}

            {/* Due Date Info */}
            <div className="flex items-center justify-between text-sm">
               <div className="flex items-center gap-2 text-gray-500">
                  <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                     <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                  </svg>
                  <span>Oluşturulma: {moment(order.created_at).format('DD MMM YYYY')}</span>
               </div>
            </div>

            {/* Action Button */}
            {/* <div className="mt-4 opacity-0 group-hover:opacity-100 transition-opacity">
               <Button
                  className="w-full"
                  color={isUrgent ? "danger" : "warning"}
                  variant="flat"
                  size="sm"
                  onClick={() => navigate(`${routes.orders}/${offerData.id}`)}
               >
                  <span className="flex items-center justify-center gap-2">
                     Teklif Detayları
                     <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                     </svg>
                  </span>
               </Button>
            </div> */}
         </div>
      );
   };

   return (
      <div className="space-y-6">
         {/* İstatistik Kartları */}

         {/* Grafik Bölümü */}
         <div className="grid lg:grid-cols-2 gap-6">
            <Card className="border-none shadow-md">
               <CardHeader>
                  <Title as="h3">Satış Performansı</Title>
               </CardHeader>
               <CardBody>
                  <TotalAppointment />
               </CardBody>
            </Card>

            <Card className="border-none shadow-md">
               <CardBody>
                  <SummaryWidget />
               </CardBody>
            </Card>
         </div>

         {/* Tablolar */}
         {user?.role == "admin" && (
            <div className="grid lg:grid-cols-2 gap-6">
               <Card className="border-none shadow-md">
                  <CardHeader className="flex justify-between items-center">
                     <div>
                        <Title as="h3">Atanmayı Bekleyen Talepler</Title>
                        <p className="text-sm text-gray-500 mt-1">
                           Hızlı aksiyon gerektiren talepler
                        </p>
                     </div>
                     <Button
                        onClick={() => navigate(routes.requests.list)}
                        size="sm"
                        color="primary"
                     >
                        Tümünü Gör
                     </Button>
                  </CardHeader>
                  <Divider />
                  <CardBody>
                     {loading ? (
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                           {[...Array(4)].map((_, index) => (
                              <RequestCardSkeleton key={index} />
                           ))}
                        </div>
                     ) : (
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                           {(dashboardData?.waiting_requests || []).slice(0, 4).map((request: any, index: number) => (
                              <RequestCard key={index} request={request} />
                           ))}
                        </div>
                     )}
                     {!loading && (!dashboardData?.waiting_requests || dashboardData.waiting_requests.length === 0) && (
                        <div className="text-center py-10">
                           <div className="text-4xl mb-4">🎉</div>
                           <h3 className="text-lg font-semibold">Bekleyen Talep Yok</h3>
                           <p className="text-gray-500">Tüm talepler atanmış durumda</p>
                        </div>
                     )}
                  </CardBody>
               </Card>

               <Card className="border-none shadow-md">
                  <CardHeader className="flex justify-between items-center">
                     <div>
                        <Title as="h3">Vadesi Yaklaşan Ödemeler</Title>
                        <p className="text-sm text-gray-500 mt-1">
                           Yakın zamanda tahsil edilmesi gereken ödemeler
                        </p>
                     </div>
                     <Button
                        onClick={() => navigate(routes.orders)}
                        size="sm"
                        color="warning"
                     >
                        Tümünü Gör
                     </Button>
                  </CardHeader>
                  <Divider />
                  <CardBody>
                     {loading ? (
                        <div className="space-y-4">
                           {[...Array(3)].map((_, index) => (
                              <div key={index} className="animate-pulse">
                                 <div className="h-32 bg-gray-100 rounded-lg" />
                              </div>
                           ))}
                        </div>
                     ) : dashboardData?.close_term_offers?.length ? (
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                           {dashboardData.close_term_offers.slice(0, 5).map((order: any, index: number) => (
                              <PaymentDueCard key={index} order={order} />
                           ))}
                        </div>
                     ) : (
                        <div className="text-center py-8">
                           <div className="text-4xl mb-3">✨</div>
                           <h3 className="text-lg font-medium text-gray-900">Yaklaşan Ödeme Yok</h3>
                           <p className="text-gray-500">Tüm ödemeler zamanında yapılmış</p>
                        </div>
                     )}
                  </CardBody>
               </Card>
            </div>
         )}

         {/* Son Siparişler */}
         <Card className="border-none shadow-md">
            <CardHeader className="flex justify-between items-center">
               <div>
                  <Title as="h3">Son Siparişler</Title>
                  <p className="text-sm text-gray-500 mt-1">
                     Son 30 günün sipariş aktiviteleri
                  </p>
               </div>
               <Button size="sm" color="primary" onClick={() => navigate(routes.orders)}>
                  Tümünü Gör
               </Button>
            </CardHeader>
            <Divider />
            <CardBody>
               {loading ? (
                  <div className="flex justify-center items-center h-64">
                     <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
                  </div>
               ) : (
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                     {(data?.data?.data || []).map((order: any, index: number) => (
                        <OrderCardNew key={index} order={order} />
                     ))}
                  </div>
               )}
               {!loading && data?.data?.data?.length === 0 && (
                  <div className="text-center py-10">
                     <div className="text-4xl mb-4">📦</div>
                     <h3 className="text-lg font-semibold">Henüz sipariş bulunmuyor</h3>
                     <p className="text-gray-500">Yeni siparişler burada görüntülenecek</p>
                  </div>
               )}
            </CardBody>
         </Card>
      </div>
   )
}

export default Dashboard

export const orderStatus = [
   {
      key: 'ORDER_RECEIVED',
      id: 1,
      label: 'Sipariş Alındı',
      animation: require('assets/animation/step_3.json')
   },
   {
      key: 'ORDER_PREPARING',
      id: 2,
      label: 'Sipariş Hazırlanıyor',
      animation: require('assets/animation/step_1.json')
   },

   {
      key: 'PRODUCTION_STAGE',
      id: 4,
      label: 'Kargoya Hazırlandı',
      animation: require('assets/animation/step_4.json')
   },
   {
      key: 'SHIPPING',
      id: 5,
      label: 'Teslim Edildi',
      animation: require('assets/animation/success.json')
   },
   {
      key: 'DELIVERED',
      id: 6,
      label: 'Faturalandı',
      animation: require('assets/animation/step_5.json')
   },
   {
      key: 'CANCELLED',
      id: 7,
      label: 'İptal Edildi',
      animation: require('assets/animation/step_5.json')
   }
]

export const orderStatusColor: any = {
   ORDER_RECEIVED: 'blue',
   ORDER_PREPARING: 'secondary',
   PRODUCTION_STAGE: 'primary',
   SHIPPING: 'orange',
   DELIVERED: 'green',
   CANCELLED: 'red'
}

export const orderStatusText: any = {
   ORDER_RECEIVED: 'Sipariş Alındı',
   ORDER_PREPARING: 'Hazırlanıyor',
   PRODUCTION_STAGE: 'Kargoya verildi',
   SHIPPING: 'Teslim Edildi',
   DELIVERED: 'Tamamlandı',
   CANCELLED: 'İptal Edildi'
}

export const getOrderStatusWithClass = (status: string) => {
   return `border-2 rounded-md p-2 border-${orderStatusColor[status]} text-${orderStatusColor[status]}`
}

export const getOrderWithAnimation = (status: string) => {
   return orderStatus.find(item => item.key === status)?.animation
}

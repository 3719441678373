import React from 'react';

const ProductSkeleton = () => {
    return (
        <div className="bg-white border border-gray-300 rounded-lg p-5 animate-pulse">
            <div className="relative">
                <div className="relative mx-auto aspect-[6/5] w-full overflow-hidden rounded-lg bg-gray-200" />
            </div>
            <div className="pt-4 space-y-3">
                <div className="h-4 bg-gray-200 rounded w-3/4" />
                <div className="h-4 bg-gray-200 rounded w-1/2" />
                <div className="h-4 bg-gray-200 rounded w-2/3" />
                <div className="h-4 bg-gray-200 rounded w-1/2" />
            </div>
        </div>
    );
};

export default ProductSkeleton; 
import { Card, Chip, Select, SelectItem } from '@nextui-org/react'
import {
   IconBrandWhatsapp,
   IconCheck,
   IconChevronDown,
   IconCircleCheck,
   IconDots,
   IconInfoCircle,
   IconMail,
   IconPhone,
   IconWorld,
   IconRefresh,
   IconSearch
} from '@tabler/icons-react'
import { Avatar, Drawer, Empty, Form, List, Modal, Select as SelectBox, notification } from 'antd'
import { CUSTOMER_MANAGEMENT_LIST, CUSTOMER_MANAGEMENT_STORE } from 'api/customer-management'
import { USER_SHOW, USER_STORE } from 'api/user'
import { USER_STOCK_STORE } from 'api/userStock'
import axios from 'axios'
import { routes } from 'config/routes'
import { setCart } from 'features/user/basket'
import { setContactDetail, setContactId, setMail, setMailId, setProduct } from 'features/user/offer'
import { MoneyFormat } from 'hooks/currency'
import useFetch from 'hooks/use-fetch'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Alert, Badge, Button, Switch, Textarea, Title, Input, AdvancedRadio, Text, RadioGroup, Checkbox } from 'rizzui'
import SimpleBar from 'simplebar-react'
import { RootState } from 'store'
import _, { set } from 'lodash'
import { DotLoader } from 'react-spinners'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { send } from 'process'
import { motion, AnimatePresence } from 'framer-motion'
import { Checkbox as AntCheckbox } from 'antd'
import { useInView } from 'react-intersection-observer';
import { apiUrl } from 'api'

type Props = {
   data: any
   id: any
   users?: any
}

function Mail(props: Props) {
   const [isRefreshing, setIsRefreshing] = React.useState(false);

   const handleRefresh = async () => {
      window.location.reload();
   };


   const dispatch = useDispatch()
   const navigate = useNavigate()
   const [isReplied, setIsReplied] = useState(false);
   const carts = useSelector((state: RootState) => state.basket.carts)
   const user = JSON.parse(localStorage.getItem('user') as any)
   const { data, id } = props
   const message = data.find((item: any) => item.id == id)

   const [usersQuery, { data: usersData, loading: usersLoading }] = useFetch<any>()
   const [assignUserMutation, { data: assignUserData, loading: assignUserLoading }] = useFetch<any>()
   const [setAsReadMutation, { data: setAsReadData, loading: setAsReadLoading }] = useFetch<any>()
   const [setAsUnreadMutation, { data: setAsUnreadData, loading: setAsUnreadLoading }] = useFetch<any>()
   const [mailAssignLoading, setMailAssignLoading] = React.useState(false)
   const [checkGraphTokenQuery, { data: checkGraphTokenData, loading: checkGraphTokenLoading }] = useFetch<any>()
   const [getGraphTokenDeviceQuery, { data: getGraphTokenDeviceData, loading: getGraphTokenDeviceLoading }] =
      useFetch<any>()
   const [checkDeviceTokenMutation, { data: checkDeviceTokenData, loading: checkDeviceTokenLoading }] = useFetch<any>()
   const [updateUserGraphTokenMutation, { data: updateUserGraphTokenData, loading: updateUserGraphTokenLoading }] =
      useFetch<any>()
   const [getMailQuery, { data: getMailData, loading: getMailLoading }] = useFetch<any>()
   const [deviceTokenInfoModal, setDeviceTokenInfoModal] = React.useState(false)
   const [access_token, setAccess_token] = React.useState('')
   const [mailDetailOpen, setMailDetailOpen] = React.useState(false)
   const [setMailDetail, setMailDetailData] = React.useState<any>(null)

   const [cartDrawer, setCartDrawer] = React.useState(false)
   const [open, setOpen] = React.useState(false)
   const [requestForm] = Form.useForm()
   const [contactForm] = Form.useForm()
   const [contactModal, setContactModal] = React.useState(false)
   const [contactsQuery, { data: contactsData, loading: contactsLoading, error: contactsError }] = useFetch<any>()
   const [offerRequestMutation, { data: offerRequestData, loading: offerRequestLoading, error: offerRequestError }] =
      useFetch<any>()
   const [contactMutation, { data: contactData, loading: contactLoading, error: contactError }] = useFetch<any>()
   const [contact_id, setContact_id] = React.useState<any>(null)
   const [sendWithProduct, setSendWithProduct] = React.useState(false)
   const [checkSenderContactMail, { data: checkSenderContactMailData, loading: checkSenderContactMailLoading }] =
      useFetch<any>()
   const [hasContact, setHasContact] = React.useState(false)
   const [hasCustomer, setHasCustomer] = React.useState(false)
   const [customerDetail, setCustomerDetail] = React.useState<any>(null)
   const [hasAddedCustomer, setHasAddedCustomer] = React.useState(false)


   const [sendMail, { data: sendMailData, loading: sendMailLoading }] = useFetch<any>()

   const [sendMailOpen, setSendMailOpen] = React.useState(false)

   const [selectTypeModal, setSelectTypeModal] = React.useState(false)
   const [selectedType, setSelectedType] = React.useState<any>(null)

   const [sendMailForm] = Form.useForm()

   const [welcomeMailValue, setWelcomeMailValue] = React.useState<any>(`
      <p>Selamlar,</p>
    
    <p>Şirketimizin tanıtım dosyamızı ekte bulabilirsiniz.</p>
    
    <p>
        Her türlü promosyon ürünü, teknolojik – tekstil ürün, markalı ürün tedariğini Bayer, Borusan, Mercedes, Volvo, Reckitt, TEB, 
        Novartis, Siemens, Nestle, Eczacıbaşı, Loreal, Henkel ve daha birçok kurumsal firmaya 
        <strong>34 yılı aşkındır temin ediyoruz.</strong>
    </p>
    
    <ul>
        <li><em>Unilever, Booking.com ve Sandoz firmalarının tek tedarikçisiyiz.</em></li>
        <li><strong style="background-color: lime;">Stanley, Moleskine, Hugo Boss, Aladdin, Mugo, Net Zero Cup ve Pigra markalarının Türkiye distribütörüyüz.</strong></li>
        <li><em>Algida, Oyak, Socar, Opel, Citroen, Kerry, Peugeot ve Mercedes gibi firmalara webshop hizmeti sunuyoruz.</em></li>
    </ul>

    <p>
        Gimel hakkında detaylar için yandaki linke bağlanmanızı rica ederiz: 
        <a href="https://gimel.com.tr" target="_blank">Hakkımızda (gimel.com.tr)</a>
    </p>
    
    <p>
        Gimel'in hizmetleri için linke bağlanmanızı rica ederiz: 
        <a href="https://www.gimel.com.tr/hizmetlerimiz" target="_blank">https://www.gimel.com.tr/hizmetlerimiz</a>
    </p>
    
    <p>Haberlerinizi ve taleplerinizi heyecanla bekliyoruz.</p>
    
    <p>Sevgiler,</p>
      `)
   const [subject, setSubject] = React.useState<any>('Bizi Daha Yakından Tanımak İster Misiniz ? / Gimel')

   const [isSendWelcomeMail, setIsSendWelcomeMail] = React.useState(false)
   const [isMobile, setIsMobile] = React.useState(false)

   const [filterType, setFilterType] = React.useState('all');
   const [selectedMails, setSelectedMails] = React.useState<string[]>([]);

   const [showContactAlert, setShowContactAlert] = useState(true);

   const { ref: loadMoreRef, inView } = useInView();
   const [page, setPage] = useState(1);
   const [allMails, setAllMails] = useState<any[]>([]);
   const [hasMore, setHasMore] = useState(true);

   const [companyNameModal, setCompanyNameModal] = useState(false);
   const [companyName, setCompanyName] = useState('');

   const [isSaving, setIsSaving] = useState(false);

   const [searchTerm, setSearchTerm] = useState('');

   const [currentPage, setCurrentPage] = useState(1);

   useEffect(() => {
      if (inView && hasMore && !getMailLoading) {
         setPage(prev => prev + 1);
      }
   }, [inView, hasMore]);

   useEffect(() => {
      if (getMailData?.value) {
         if (page === 1) {
            setAllMails(getMailData.value);
         } else {
            setAllMails(prev => [...prev, ...getMailData.value]);
         }
         setHasMore(getMailData.value.length > 0);
      }
   }, [getMailData]);

   useEffect(() => {
      //@ts-ignore
      getMailQuery(USER_SHOW, {
         endPoint: `/get-mails?page=${page}`
      });
   }, [page]);

   useEffect(() => {
      // Sayfa değiştiğinde veya filtre değiştiğinde yeni sorgu yap
      if (checkGraphTokenData?.response !== false) {
         setPage(1); // Filtre değiştiğinde sayfa 1'e dön
         //@ts-ignore
         getMailQuery(USER_SHOW, {
            endPoint: `/get-mails?page=1&isRead=${filterType}`
         });
      }
   }, [filterType]); // filterType değiştiğinde tetikle

   useEffect(() => {
      if (inView && hasMore && !getMailLoading) {
         //@ts-ignore
         getMailQuery(USER_SHOW, {
            endPoint: `/get-mails?page=${page}&filter=${filterType}`
         });
      }
   }, [inView, hasMore, page, filterType]);

   const getFilteredMails = () => {
      if (!allMails) return [];

      let filtered = [...allMails];

      // Arama filtresi
      if (searchTerm) {
         filtered = filtered.filter((mail: any) => {
            const searchFields = [
               mail.subject,
               mail.sender?.emailAddress?.name,
               mail.sender?.emailAddress?.address,
               mail.body?.content
            ];

            return searchFields.some(field =>
               field?.toLowerCase().includes(searchTerm.toLowerCase())
            );
         });
      }

      return filtered;
   };

   const handleSearchChange = (value: string) => {
      setSearchTerm(value);
   };

   useEffect(() => {
      //@ts-ignore
      usersQuery(USER_SHOW, {
         endPoint: '/users-with-offer-current-status'
      })
   }, [])

   const checkMailContact = (item: any) => {
      //@ts-ignore
      checkSenderContactMail(USER_STORE, {
         endPoint: '/check-mail-contact',
         body: {
            email: item.sender?.emailAddress?.address
         }
      })
   }

   const sendMailDetail = (values: any) => {
      //@ts-ignore
      sendMail(USER_STORE, {
         endPoint: '/send-mail',
         body: {
            ...values,
            mail: setMailDetail?.from?.emailAddress?.address
         }
      })
   }

   const handleCreateRequest = (values: any) => {
      console.log('values', values)
      //@ts-ignore8
      offerRequestMutation(USER_STORE, {
         endPoint: '/offer-requests-create',

         body: {
            ...values,
            carts: values.send_with_product ? carts : [],
            mailContent: setMailDetail
         }
      })
   }

   useEffect(() => {
      if (offerRequestData) {
         notification.success({
            message: 'Başarılı',
            description: 'Talep oluşturuldu.'
         })
         setOpen(false)
         navigate(routes.requests.list)
      }
   }, [offerRequestData])

   useEffect(() => {
      if (contactData) {
         notification.success({
            message: 'Başarılı',
            description: 'Müşteri başarıyla eklendi.'
         });
         setHasAddedCustomer(true)

         dispatch(setContactId(contactData.contact_id));
         setContactModal(false);
         setContact_id(contactData.contact_id);
         setShowContactAlert(false);
         setHasContact(true);

         // Hızlı talep oluştur modalını aç ve müşteriyi seç

         form.setFieldValue('contact_id', contactData.contact_id);

         //@ts-ignore
         contactsQuery(CUSTOMER_MANAGEMENT_LIST, {
            endPoint: '/customer-with-contacts'
         });
      }
   }, [contactData]);

   useEffect(() => {
      //@ts-ignore
      contactsQuery(CUSTOMER_MANAGEMENT_LIST, {
         endPoint: '/customer-with-contacts'
      })
   }, [])



   const handleSubmitContact = (values: any) => {
      console.log('values', values)
      //@ts-ignore
      contactMutation(CUSTOMER_MANAGEMENT_STORE, {
         endPoint: 'customers/addContactOffer',
         body: {
            ...values,
            sendWelcomeMail: isSendWelcomeMail,
            welcomeMail: welcomeMailValue,
            subject: subject,
            ...(hasCustomer ? { customer_id: customerDetail?.id } : {})
         }
      })
   }

   const handleSaveMailList = async () => {
      try {
         setIsSaving(true);
         const response = await fetch(apiUrl + '/api/save-mail-list', {
            method: 'POST',
            headers: {
               'Content-Type': 'application/json',
               'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
               email: setMailDetail?.sender?.emailAddress?.address,
               company_name: companyName,
               type: 'promotion'
            })
         });

         const data = await response.json();

         if (data.status === 'success') {
            notification.success({
               message: 'Başarılı',
               description: 'Mail listesine eklendi'
            });
            setCompanyNameModal(false);
            setCompanyName('');
            setMailDetailOpen(false); // Mail detay drawer'ını kapat
            setSelectTypeModal(false); // Tip seçim modalını kapat
         } else {
            notification.error({
               message: 'Hata',
               description: data.message || 'Bir hata oluştu'
            });
         }
      } catch (err: any) {
         console.log('err', err);
         notification.error({
            message: 'Hata',
            description: err.message || 'Bir hata oluştu'
         });
      } finally {
         setIsSaving(false);
      }
   };

   useEffect(() => {
      //@ts-ignore
      checkGraphTokenQuery(USER_SHOW, {
         endPoint: '/check-graph-token'
      })
   }, [])

   useEffect(() => {
      if (checkGraphTokenData?.response === false) {
         //@ts-ignore
         getGraphTokenDeviceQuery(USER_SHOW, {
            endPoint: '/get-graph-device-code'
         })
      } else if (checkGraphTokenData?.status == false) {
         //@ts-ignore
         getGraphTokenDeviceQuery(USER_SHOW, {
            endPoint: '/get-graph-device-code'
         })
      } else {
         //@ts-ignore
         getMailQuery(USER_SHOW, {
            endPoint: '/get-mails'
         })
      }
   }, [checkGraphTokenData])

   useEffect(() => {
      if (getGraphTokenDeviceData) {
         setDeviceTokenInfoModal(true)
      }
   }, [getGraphTokenDeviceData])

   const [modal, setModal] = React.useState(false)
   const [form] = Form.useForm()
   const onFinish = (values: any) => {
      //@ts-ignore
      assignUserMutation(USER_STOCK_STORE, {
         endPoint: `/mails/assignUser`,
         method: 'POST',
         body: {
            user_id: values.user,
            description: values.description,
            mail: setMailDetail
         }
      })
   }

   useEffect(() => {
      if (assignUserData) {
         notification.success({
            message: 'Atama Başarılı',
            description: 'Mail başarıyla atandı'
         })

      }
   }, [assignUserData])

   const handleCreateOffer = async () => {
      if (carts.length > 0) {
         dispatch(setMailId(id))
         Modal.confirm({
            title: 'Sepetinizde ürünler bulunmaktadır.',
            content: 'Ürünlerinizi teklife eklemek istiyor musunuz?',
            onOk() {
               dispatch(setMail(true))
               dispatch(setProduct(carts))
               navigate(routes.offerManagement.add + '?mail_id=' + id)
            },
            onCancel() {
               dispatch(setMail(true))
               navigate(routes.offerManagement.add + '?mail_id=' + id)
            }
         })
      } else {
         dispatch(setMailId(id))
         dispatch(setMail(true))
         dispatch(setProduct([]))
         navigate(routes.offerManagement.add + '?mail_id=' + id)
      }
   }

   const checkDeviceToken = () => {
      //@ts-ignore
      checkDeviceTokenMutation(USER_STORE, {
         endPoint: '/check-device-token',
         body: {
            device_code: getGraphTokenDeviceData?.device_code
         }
      })
   }

   useEffect(() => {
      if (checkDeviceTokenData) {
         if (checkDeviceTokenData?.error) {
            if (checkDeviceTokenData?.error == 'authorization_pending') {
               setTimeout(() => {
                  checkDeviceToken()
               }, 5000)
            }
         } else {
            // setAccess_token(checkDeviceTokenData?.access_token)
            // localStorage.setItem('access_token', checkDeviceTokenData?.access_token)

            //@ts-ignore

            updateUserGraphTokenMutation(USER_STORE, {
               endPoint: '/update-user-graph-token',
               body: {
                  access_token: checkDeviceTokenData?.access_token,
                  device_code: getGraphTokenDeviceData?.device_code,
                  refresh_token: checkDeviceTokenData?.refresh_token
               }
            })

            // @ts-ignore

            getMailQuery(USER_SHOW, {
               endPoint: '/get-mails'
            })
         }
      }
   }, [checkDeviceTokenData])

   useEffect(() => {
      if (updateUserGraphTokenData) {
         setDeviceTokenInfoModal(false)
         Modal.success({
            title: 'Başarılı',
            content: 'Mail oturumunuz başarıyla oluşturuldu.',
            onOk() { },
            closeIcon: true,
            closable: true,
            okText: 'Tamam'
         })
      }
   }, [updateUserGraphTokenData])

   useEffect(() => {
      if (checkSenderContactMailData) {
         console.log('checkSenderContactMailData', checkSenderContactMailData)

         setMailDetailOpen(true)
         if (checkSenderContactMailData?.customer?.company_name) {
            setHasCustomer(true)
            setCustomerDetail(checkSenderContactMailData?.customer)
            contactForm.setFieldValue('email', setMailDetail?.sender?.emailAddress?.address)
         } else {
            setHasCustomer(false)
            setCustomerDetail(null)
         }
      }
   }, [checkSenderContactMailData])

   useEffect(() => {
      if (sendMailData) {
         notification.success({
            message: 'Başarılı',
            description: 'Mail başarıyla gönderildi.'
         })
         setIsReplied(true);
      }
   }, [sendMailData])

   useEffect(() => {
      if (window.innerWidth < 768) {
         setIsMobile(true)
      }
   }, [window.innerWidth])

   useEffect(() => {
      if (setAsUnreadData) {
         notification.success({
            message: 'Başarılı',
            description: 'Mail başarıyla okunmadı olarak işaretlendi.'
         })

         //@ts-ignore
         getMailQuery(USER_SHOW, {
            endPoint: '/get-mails'
         })
      }
   }, [setAsUnreadData])

   const MailItem = React.memo(({ item, isSelected, onSelect }: {
      item: any;
      isSelected: boolean;
      onSelect: (checked: boolean) => void;
   }) => {
      const hasKeywords = checkMailKeywords(item.body.content);

      return (
         <div className={`group relative flex items-center gap-4 p-4 hover:bg-blue-50 cursor-pointer transition-all duration-200
            ${item.isRead ? 'bg-white' : 'has-read'}`}
         >


            <div className="flex-1 min-w-0">
               <div className="flex items-center gap-2 mb-1">
                  <span className={`font-semibold truncate ${item.isRead ? 'text-gray-700' : 'text-gray-900'}`}>
                     {item.sender?.emailAddress?.name || item.sender?.emailAddress?.address}
                  </span>
                  {hasKeywords && (
                     <Badge color="danger" variant="flat" size="sm">
                        Teklif Potansiyeli
                     </Badge>
                  )}
               </div>

               <h4 className={`text-sm mb-1 ${item.isRead ? 'text-gray-600' : 'text-gray-800 font-medium'}`}>
                  {item.subject}
               </h4>

               <p className="text-sm text-gray-500 truncate">
                  {item.body.content.replace(/<[^>]*>/g, '').slice(0, 100)}...
               </p>
            </div>

            <div className="flex flex-col items-end gap-2">
               <span className="text-sm text-gray-500">
                  {moment(item.createdDateTime).format('DD.MM.YYYY HH:mm')}
               </span>

               <div className="flex items-center gap-2  transition-opacity">

                  {item.isRead && (<Chip
                     color="success"
                     variant="flat">
                     Okundu
                  </Chip>)}

                  {!item.isRead && (<Chip
                     color="danger"
                     variant="flat">
                     Okunmamış
                  </Chip>)}

                  <Button
                     size="sm"
                     variant="flat"
                     className="text-gray-600 hover:bg-blue-100"
                     onClick={(e) => {
                        e.stopPropagation();
                        setMailDetailData(item);
                        checkMailContact(item);
                        setIsReplied(false)
                        setHasAddedCustomer(false)

                        //@ts-ignore
                        setAsReadMutation(USER_STORE, {
                           endPoint: '/set-as-read',
                           body: {
                              id: item.id
                           }
                        })
                     }}
                  >
                     Görüntüle
                  </Button>
               </div>
            </div>
         </div>
      );
   }, (prevProps, nextProps) => {
      return prevProps.isSelected === nextProps.isSelected &&
         prevProps.item.id === nextProps.item.id;
   });

   const FilterBanner = React.memo(({ type, onClear }: {
      type: string;
      onClear: () => void;
   }) => {
      return (
         <div className="absolute top-0 left-0 right-0 bg-blue-50 p-2 text-center text-sm text-blue-600 border-b border-blue-100">
            {type === 'read' && "Okunmuş mailler gösteriliyor"}
            {type === 'unread' && "Okunmamış mailler gösteriliyor"}
            {type === 'none' && "Filtre temizlendi"}
            <Button
               size="sm"
               variant="flat"
               className="ml-2 text-blue-600"
               onClick={onClear}
            >
               Filtreyi Temizle
            </Button>
         </div>
      );
   });

   const MailLoadingState = ({ text }: any) => {
      return (
         <div className="min-h-[70vh] bg-white rounded-xl shadow-sm overflow-hidden">
            <div className="border-b border-gray-200 p-4 bg-gray-50 animate-pulse">
               <div className="h-8 w-96 bg-gray-200 rounded-md" />
            </div>

            <div className="p-4 space-y-4">
               {[1, 2, 3, 4, 5].map((i) => (
                  <motion.div
                     key={i}
                     initial={{ opacity: 0, y: 20 }}
                     animate={{ opacity: 1, y: 0 }}
                     transition={{
                        duration: 0.5,
                        delay: i * 0.1,
                        ease: [0.23, 1, 0.32, 1]
                     }}
                     className="flex items-center gap-4 p-4 border border-transparent"
                  >
                     <div className="w-5 h-5 rounded bg-gray-200 animate-pulse" />

                     <div className="flex-1 space-y-3">
                        <div className="flex items-center gap-2">
                           <div className="h-4 w-40 bg-gray-200 rounded animate-pulse" />
                           <div className="h-4 w-16 bg-blue-100 rounded animate-pulse" />
                        </div>
                        <div className="h-4 w-3/4 bg-gray-100 rounded animate-pulse" />
                        <div className="h-4 w-1/2 bg-gray-100 rounded animate-pulse" />
                     </div>

                     <div className="flex flex-col items-end gap-2">
                        <div className="h-4 w-16 bg-gray-200 rounded animate-pulse" />
                        <div className="h-8 w-24 bg-gray-100 rounded animate-pulse" />
                     </div>
                  </motion.div>
               ))}
            </div>

            {/* Animasyonlu loading indikatörü */}
            <div className="absolute inset-0 bg-white/50 backdrop-blur-sm flex items-center justify-center">
               <div className="relative">
                  <svg
                     className="w-16 h-16 animate-spin"
                     viewBox="0 0 24 24"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg"
                  >
                     <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                     />
                     <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                     />
                  </svg>
                  <div className="absolute inset-0 flex items-center justify-center">
                     <IconMail className="w-6 h-6 text-blue-600" />
                  </div>
               </div>
               <div className="ml-4 text-lg font-medium text-gray-900">
                  {text || 'Mailler yükleniyor...'}
               </div>
            </div>
         </div>
      );
   };




   // Mail içeriğindeki anahtar kelimeleri kontrol eden yardımcı fonksiyon
   const checkMailKeywords = (content: string) => {
      const keywords = [
         'fiyat', 'teklif', 'ödeme', 'satın alma', 'indirim', 'sipariş',
         'ürün', 'hizmet', 'müşteri', 'talep', 'anlaşma'
      ];

      return keywords.some(keyword =>
         content.toLowerCase().includes(keyword.toLowerCase())
      );
   };

   const RenderMobile = () => {
      return (
         <div className="space-y-4">
            {allMails.map((item: any) => (
               <MailItem
                  key={item.id}
                  item={item}
                  isSelected={selectedMails.includes(item.id)}
                  onSelect={(checked) => {
                     if (checked) {
                        setSelectedMails(prev => [...prev, item.id]);
                     } else {
                        setSelectedMails(prev => prev.filter(id => id !== item.id));
                     }
                  }}
               />
            ))}

            {/* Loading Indicator */}
            <div ref={loadMoreRef} className="py-4 flex justify-center">
               {getMailLoading && (
                  <div className="flex items-center gap-2">
                     <div className="animate-spin w-5 h-5 border-2 border-primary border-t-transparent rounded-full" />
                     <span className="text-sm text-gray-500">Yükleniyor...</span>
                  </div>
               )}
            </div>
         </div>
      )
   }

   const RenderAlertMesage = () => {
      const data = checkSenderContactMailData;
      if (data?.type == 'customer' && data?.has) {
         return (
            <Alert color="info" className="flex items-center gap-10">
               <span>
                  Bu mail uzantısı  {data?.customer?.company_name} Müşterisi ile eşleşmektedir.
               </span>

               <div className='flex items-center gap-2'>
                  <Button
                     size="sm"
                     color="primary"
                     className="ml-5"
                     onClick={() => {
                        setHasCustomer(true)
                        setCustomerDetail(data?.customer)
                        setContactModal(true);
                     }}
                  >
                     {data?.customer?.company_name} Firmasına Kişi Olarak Ekle
                  </Button>
                  <Button
                     size="sm"
                     color="danger"
                     variant="flat"
                     onClick={() => {
                        setHasCustomer(false)
                        setCustomerDetail(null)
                        setSelectTypeModal(true);
                     }}
                  >
                     Yeni Müşteri Olarak Ekle
                  </Button>
               </div>
            </Alert>
         )
      }
      else {
         return (
            <>
               <Alert color="info">
                  <p>
                     Bu mail müşteri listesinde bulunmamaktadır.
                  </p>
                  <Button
                     color="primary"
                     onClick={() => {
                        setSelectTypeModal(true);
                     }}
                  >
                     Müşteri Ekle
                  </Button>
               </Alert>
            </>
         )
      }
   }

   return (
      <div>
         <Modal title="Mail Gönder" open={sendMailOpen} footer={null} onCancel={() => setSendMailOpen(false)}>
            <Form
               initialValues={{
                  subject: message?.subject
               }}
               form={sendMailForm}
               layout="vertical"
               onFinish={sendMailDetail}
            >
               <Form.Item
                  label="Mail Konusu"
                  name="subject"
                  rules={[{ required: true, message: 'Lütfen mail konusu giriniz!' }]}
               >
                  <Input placeholder="Mail konusu giriniz" />
               </Form.Item>
               <Form.Item
                  label="Mail İçeriği"
                  name="content"
                  rules={[{ required: true, message: 'Lütfen mail içeriği giriniz!' }]}
               >
                  <Textarea placeholder="Mail içeriği giriniz" />
               </Form.Item>
               <Button isLoading={sendMailLoading} type="submit" color="primary">
                  Gönder
               </Button>
            </Form>
         </Modal>
         <Modal open={contactModal} footer={null} onCancel={() => setContactModal(false)} title={hasCustomer ? customerDetail?.company_name : 'Yeni Müşteri Ekle'}>
            <Form form={contactForm} layout="vertical" onFinish={handleSubmitContact}>
               {hasCustomer && (
                  <div className='my-4'>
                     <input type="hidden" name="customer_id" value={customerDetail?.id} />
                     <div className="flex items-center gap-2 border p-2 rounded-md">
                        <Text>{customerDetail?.company_name}</Text>
                     </div>
                  </div>
               )}
               {!hasCustomer && (
                  <Form.Item
                     label="Firma Adı"
                     name="company_name"
                     rules={[{ required: true, message: 'Lütfen firma adı giriniz!' }]}
                  >
                     <Input placeholder="Firma adı giriniz" />
                  </Form.Item>
               )}
               <Form.Item
                  label="İlgili müşteri adı"
                  name="contact_name"
                  rules={[{ required: true, message: 'Lütfen ilgili müşteri adı giriniz!' }]}
               >
                  <Input placeholder="İlgili müşteri adı giriniz" />
               </Form.Item>
               <Form.Item
                  label="Telefon"
                  name="phone"
                  rules={[{ required: false, message: 'Lütfen telefon numarası giriniz!' }]}
               >
                  <Input placeholder="Telefon numarası giriniz" />
               </Form.Item>
               <Form.Item
                  label="Email"
                  name="email"
                  rules={[{ required: true, message: 'Lütfen email adresi giriniz!' }]}
               >
                  <Input placeholder="Email adresi giriniz" />
               </Form.Item>
               <Button isLoading={contactLoading} type="submit" color="primary">
                  Kaydet
               </Button>
            </Form>
         </Modal>
         <Modal
            width={1000}
            open={open}
            onCancel={() => {
               setOpen(false)
            }}
            footer={null}
         >
            <Title as="h5">Hızlı Talep Oluştur</Title>
            <Form
               initialValues={{
                  offer_source: 'email'
               }}
               layout="vertical"
               name="basic"
               form={form}
               onFinish={handleCreateRequest}
            >
               <Alert color="info">
                  <p>
                     Kullanıcıların solundaki numaralar elindeki var olan işlem bekleyen taleplerin sayısını
                     göstermektedir.
                  </p>
               </Alert>
               <Form.Item
                  label="Müşteri seçiniz"
                  name="contact_id"
                  rules={[{ required: true, message: 'Lütfen bir müşteri seçiniz!' }]}
               >
                  <SelectBox
                     onChange={(value: any) => {
                        const find = contactsData?.data?.find((item: any) =>
                           item.contacts.some((contact: any) => contact.id === value)
                        )
                        if (find) {
                           const contactDetail = find.contacts.find((contact: any) => contact.id === value)
                           dispatch(setContactDetail(contactDetail))
                        }
                     }}
                     options={contactsData?.data
                        .filter((item: any) => item.contacts.length > 0)
                        .map((item: any) => {
                           return {
                              label: item.company_name,
                              options: item.contacts.map((contact: any) => {
                                 return {
                                    label: contact.name,
                                    value: contact.id
                                 }
                              })
                           }
                        })}
                     filterOption={(input: any, option: any) =>
                        option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                        option?.options?.some((item: any) => item.label.toLowerCase().indexOf(input.toLowerCase()) >= 0)
                     }
                     showSearch
                     notFoundContent={
                        <div className="flex flex-col items-center justify-center">
                           <Empty description="Müşteri seçiniz bulunamadı" />
                           <Button color="primary" onClick={() => setContactModal(true)}>
                              Yeni Müşteri Ekle
                           </Button>
                        </div>
                     }
                     placeholder="müşteri seçiniz"
                     suffixIcon={<IconChevronDown />}
                  ></SelectBox>
               </Form.Item>
               <Form.Item
                  label="Atanacak Kullanıcı"
                  name="user_id"
                  rules={[{ required: true, message: 'Lütfen atanacak kullanıcıyı seçiniz!' }]}
               >
                  <Select size="sm" label="Kullanıcı Seç" placeholder="Kullanıcı">
                     {usersData &&
                        usersData?.result
                           ?.sort((a: any, b: any) => a.total - b.total)
                           .map((item: any) => (
                              <SelectItem
                                 startContent={<Badge color="primary">{item.total}</Badge>}
                                 key={item.id}
                                 value={item.id}
                              >
                                 {item?.name}
                              </SelectItem>
                           ))}
                  </Select>
               </Form.Item>

               <Form.Item name="offer_source" label="Talep Kaynağı">
                  <Select value={'email'} size="sm" label="Talep Kaynağı Seç" placeholder="Talep Kaynağı">
                     <SelectItem startContent={<IconWorld size={20} />} key={'web'} value="web">
                        Web
                     </SelectItem>
                     <SelectItem startContent={<IconMail size={20} />} key={'email'} value="email">
                        E-posta
                     </SelectItem>
                     <SelectItem startContent={<IconBrandWhatsapp size={20} />} key={'whatsapp'} value="whatsapp">
                        Whatsapp
                     </SelectItem>
                     <SelectItem startContent={<IconPhone size={20} />} key={'phone'} value="phone">
                        Telefon
                     </SelectItem>
                     <SelectItem startContent={<IconDots size={20} />} key={'other'} value="other">
                        Diğer
                     </SelectItem>
                  </Select>
               </Form.Item>
               <Form.Item name="note" label="Not">
                  <Textarea style={{ height: 100 }} />
               </Form.Item>
               <Form.Item name="send_with_product" valuePropName="checked">
                  <Switch
                     onChange={(e: any) => {
                        setSendWithProduct(e.target.checked)
                     }}
                     size="xl"
                     label="Oluşturduğum Sepetle Gönder"
                     labelClassName="font-medium"
                  />
               </Form.Item>
               {sendWithProduct && carts.length > 0 && (
                  <Card className="p-4">
                     <Title as="h5">Sepetteki Ürünler</Title>
                     <List
                        itemLayout="horizontal"
                        dataSource={carts}
                        renderItem={(item: any) => (
                           <List.Item>
                              <List.Item.Meta
                                 avatar={<Avatar src={item.thumb} />}
                                 title={item.name}
                                 description={item.model}
                              />

                              <div className="flex items-center gap-2">
                                 <Chip color="success">{MoneyFormat(item.price, 'TRY')}</Chip>
                                 <input
                                    className="w-20 p-1 border border-gray-300 rounded-md"
                                    type="number"
                                    onChange={e => {
                                       const currentProducts = _.cloneDeep(carts)
                                       const product = currentProducts.find(
                                          (product: any) => product.model === item.model
                                       )
                                       product.stock = e.target.value
                                       dispatch(setCart(currentProducts))
                                    }}
                                    value={item.stock}
                                 />
                              </div>
                           </List.Item>
                        )}
                     />
                  </Card>
               )}
               <Form.Item className="flex gap-5 items-center">
                  <Button isLoading={offerRequestLoading as boolean} type="submit" color="primary">
                     Talep Oluştur
                  </Button>
                  <Button
                     color="secondary"
                     className="ml-5"
                     onClick={() => {
                        setOpen(false)
                     }}
                  >
                     İptal
                  </Button>
               </Form.Item>
            </Form>
         </Modal>
         <Modal
            title="Yeni Mail Oturum Kaydı"
            open={deviceTokenInfoModal}
            footer={null}
            closable
            onCancel={() => setDeviceTokenInfoModal(false)}
         >
            {getGraphTokenDeviceData && (
               <div>
                  <p className="text-sm font-medium">
                     Cihazınızın güvenliğini sağlamak için aşağıdaki kodu kullanınız.
                  </p>
                  <p className="text-sm font-medium">Kod: {getGraphTokenDeviceData?.user_code}</p>
                  <p className="text-sm font-medium">
                     {' '}
                     <a href={getGraphTokenDeviceData?.verification_uri} className="text-blue" target="_blank">
                        {getGraphTokenDeviceData?.verification_uri}
                     </a>
                  </p>
                  <Button
                     isLoading={checkDeviceTokenLoading}
                     color="primary"
                     onClick={() => {
                        checkDeviceToken()
                     }}
                  >
                     Tamam
                  </Button>
               </div>
            )}
         </Modal>
         <Modal title="Atama Yap" open={modal} footer={null} closable onCancel={() => setModal(false)}>
            <Form form={form} layout="vertical" name="form_in_modal" onFinish={onFinish}>
               <Form.Item name="user" label="Kullanıcı" rules={[{ required: true, message: 'Kullanıcı seçiniz!' }]}>
                  <SelectBox placeholder="Seçim">
                     {props.users?.result?.map((user: any) => (
                        <SelectBox.Option key={user.id} value={user.id}>
                           {user.name}
                        </SelectBox.Option>
                     ))}
                  </SelectBox>
               </Form.Item>
               <Form.Item
                  name="description"
                  label="Açıklama"
                  rules={[{ required: false, message: 'Açıklama giriniz!' }]}
               >
                  <Textarea />
               </Form.Item>
               <div className="gap-2 flex">
                  <Button type="submit" color="primary" isLoading={assignUserLoading} onClick={form.submit}>
                     Atama Yap
                  </Button>
                  <Button color="danger" onClick={() => setModal(false)}>
                     İptal
                  </Button>
               </div>
            </Form>
         </Modal>
         <Drawer
            placement="right"
            width={isMobile ? '100%' : window.innerWidth / 1.2}
            title={isMobile ? '' : 'Mail İçeriği ' + '(' + setMailDetail?.sender?.emailAddress?.address + ')'}
            open={mailDetailOpen}
            onClose={() => {
               setMailDetailOpen(false)
               setHasContact(false)
               setMailDetailData(null)
            }}
            extra={
               <div className="flex items-center gap-2">
                  <Button
                     size="DEFAULT"
                     variant="outline"
                     color="primary"
                     onClick={() => {
                        const user = JSON.parse(localStorage.getItem('user') as any);
                        if (user.role == 'admin') {
                           if (checkSenderContactMailData?.contact?.id) {
                              setOpen(true);
                              form.setFieldValue('contact_id', checkSenderContactMailData?.contact?.id);
                           } else {
                              setSelectTypeModal(true);
                           }
                        } else {
                           dispatch(setContactId(checkSenderContactMailData?.data?.id));
                           navigate(routes.offerManagement.add);
                        }
                     }}
                  >
                     {JSON.parse(localStorage.getItem('user') as any).role == 'admin'
                        ? 'Hızlı talep oluştur'
                        : 'Teklif Oluştur'}
                  </Button>
                  {isReplied ? (
                     <Button
                        size="DEFAULT"
                        variant="flat"
                        color="success"
                        className="cursor-default"

                     >
                        <motion.span
                           initial={{ opacity: 0, x: -10 }}
                           animate={{ opacity: 1, x: 0 }}
                           transition={{ duration: 0.3 }}
                        >
                           Yanıtlandı
                        </motion.span>
                     </Button>
                  ) : (
                     <Button
                        size="DEFAULT"
                        variant="outline"
                        color="primary"
                        onClick={() => setSendMailOpen(true)}

                     >
                        Maili Yanıtla
                     </Button>
                  )}

                  <Button
                     size="DEFAULT"
                     variant="outline"
                     color="primary"
                     onClick={() => {
                        //@ts-ignore
                        setAsUnreadMutation(USER_STORE, {
                           endPoint: '/set-as-unread',
                           body: {
                              id: setMailDetail?.id
                           }
                        })
                     }}
                  >
                     Okunmadı Olarak İşaretle
                  </Button>

               </div>
            }
         >
            <div className="my-2">
               {!checkSenderContactMailLoading && checkSenderContactMailData && !hasAddedCustomer && (
                  <RenderAlertMesage />
               )}
            </div>

            {mailDetailOpen && (
               <div
                  dangerouslySetInnerHTML={{
                     __html: setMailDetail?.body.content
                  }}
               ></div>
            )}
         </Drawer>
         <Drawer
            open={selectTypeModal}
            title="Müşteri Tipini Seç"
            footer={null}
            onClose={() => setSelectTypeModal(false)}
            width={isMobile ? '100%' : window.innerWidth / 2.5}
         >
            <div className="bg-white p-2">
               <RadioGroup
                  value={selectedType}
                  setValue={setSelectedType}
                  className="grid grid-cols-1 sm:grid-cols-3 max-w-2xl  gap-4"
               >
                  <AdvancedRadio
                     key={'promosyon'}
                     name="payment"
                     value={'promosyon'}
                     className={`border-1 border-${selectedType == 'promosyon' ? 'black' : 'gray-500'
                        } p-5 rounded-md cursor-pointer hover:border-black`}
                     inputClassName="border-1 border-black p-5 [&:checked~span_.icon]:block"
                  >
                     <span className="flex justify-between">
                        <Text as="b">{`Promosyon`}</Text>
                        <IconCircleCheck className="icon hidden h-5 w-5 text-primary" />
                     </span>
                  </AdvancedRadio>
                  <AdvancedRadio
                     key={'customer'}
                     name="payment"
                     value={'customer'}
                     className={`border-1 border-${selectedType == 'customer' ? 'black' : 'gray-500'
                        } p-5 rounded-md cursor-pointer hover:border-black`}
                     inputClassName="[&:checked~span_.icon]:block"
                  >
                     <span className="flex justify-between">
                        <Text as="b">{`Müşteri`}</Text>
                        <IconCircleCheck className="icon hidden h-5 w-5 text-black" />
                     </span>
                  </AdvancedRadio>
               </RadioGroup>
               {selectedType == 'customer' && (
                  <div className="">
                     <Input label="Konu" onChange={e => setSubject(e.target.value)} value={subject} />
                     <ReactQuill
                        theme="snow"
                        className="mt-2"
                        value={welcomeMailValue}
                        onChange={setWelcomeMailValue}
                        placeholder="Müşteriye gönderilecek mail içeriğini buraya yazabilirsiniz."
                     />
                     <Switch
                        label="Hoşgeldin maili gönder"
                        size="xl"
                        checked={isSendWelcomeMail}
                        onChange={e => setIsSendWelcomeMail(e.target.checked)}
                     />
                     <Button
                        onClick={() => {
                           setSelectTypeModal(false)
                           contactForm.setFieldValue('email', setMailDetail?.sender?.emailAddress?.address)

                           setContactModal(true)
                        }}
                        color="primary"
                        className="mt-2"
                     >
                        Kaydet
                     </Button>
                  </div>
               )}

               {selectedType == 'promosyon' && (
                  <Button
                     onClick={() => setCompanyNameModal(true)}
                     color="primary"
                     className="mt-2"
                  >
                     Mail listesine kaydet
                  </Button>
               )}
            </div>
         </Drawer>
         <Modal
            title="Firma Adı"
            open={companyNameModal}
            onCancel={() => setCompanyNameModal(false)}
            footer={null}
         >
            <div className="space-y-4">
               <Input
                  placeholder="Firma adı giriniz"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
               />
               <Button
                  color="primary"
                  className="w-full"
                  onClick={handleSaveMailList}
               >
                  Kaydet
               </Button>
            </div>
         </Modal>
         {!getMailLoading && allMails.length === 0 ? (
            <motion.div
               className="min-h-[70vh] flex flex-col items-center justify-center bg-gradient-to-br from-blue-50 via-white to-purple-50"
               initial={{ opacity: 0 }}
               animate={{ opacity: 1 }}
               transition={{ duration: 0.3 }}
            >
               <motion.div
                  className="relative bg-white p-12 rounded-3xl shadow-xl max-w-lg text-center border border-blue-100"
                  initial={{ scale: 0.9, y: 20 }}
                  animate={{ scale: 1, y: 0 }}
                  transition={{
                     type: "spring",
                     stiffness: 100,
                     damping: 15
                  }}
               >
                  <motion.div
                     className="absolute -top-20 left-1/2 transform -translate-x-1/2"
                     initial={{ y: 20, opacity: 0 }}
                     animate={{ y: 0, opacity: 1 }}
                     transition={{ delay: 0.2 }}
                  >
                     <div className="w-32 h-32 bg-gradient-to-br from-blue-400 to-purple-500 rounded-full flex items-center justify-center shadow-lg">
                        <IconMail className="w-16 h-16 text-white" />
                     </div>
                  </motion.div>

                  <div className="mt-16">
                     <motion.h2
                        className="text-2xl font-bold text-gray-800 mb-4"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 0.3 }}
                     >
                        Posta Kutunuz Boş
                     </motion.h2>

                     <motion.p
                        className="text-gray-600 mb-8"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 0.4 }}
                     >
                        Henüz hiç mail bulunmamaktadır. Mailleri görüntülemek için Microsoft hesabınızı bağlayın.
                     </motion.p>

                     <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 0.5 }}
                     >
                        <Button
                           color="primary"
                           size="lg"
                           className="bg-gradient-to-r from-blue-500 to-purple-500 text-white font-medium px-8 py-3 rounded-xl shadow-md hover:shadow-lg transition-all duration-200"
                           onClick={() => {
                              //@ts-ignore
                              getGraphTokenDeviceQuery(USER_SHOW, {
                                 endPoint: '/get-graph-device-code'
                              })
                           }}
                        >
                           <span className="flex items-center gap-2">
                              <IconMail className="w-5 h-5" />
                              Microsoft Hesabını Bağla
                           </span>
                        </Button>
                     </motion.div>
                  </div>
               </motion.div>
            </motion.div>
         ) : (
            // Mevcut mail listesi
            <div>
               <div className="bg-white rounded-xl shadow-sm overflow-hidden">
                  {/* Mail Toolbar */}
                  <div className="border-b border-gray-200 p-4 bg-gray-50 flex items-center justify-between">
                     <div className="flex items-center gap-4">

                        <Button
                           variant="flat"
                           className={`text-gray-600 transition-all duration-300 ${isRefreshing ? 'bg-blue-50' : 'hover:bg-gray-100'}`}
                           size="sm"
                           isLoading={isRefreshing}
                           onClick={handleRefresh}
                        >
                           <IconRefresh
                              size={18}
                              className={`${isRefreshing ? 'animate-spin' : ''}`}
                           />
                        </Button>
                        <div className="h-6 w-[1px] bg-gray-300" />

                        {/* Filtre Seçenekleri */}
                        <select
                           className="w-36"
                           value={filterType}
                           onChange={(e) => setFilterType(e.target.value)}
                        >
                           <option value="all">Tümü</option>
                           <option value="1">Okunmuş</option>
                           <option value="0">Okunmamış</option>
                        </select>

                        {/* Arama Input'u */}
                        <div className="flex items-center gap-4">
                           <Input
                              className="w-64"
                              size="sm"
                              placeholder="Mail ara..."
                              value={searchTerm}
                              onChange={(e) => handleSearchChange(e.target.value)}
                              prefix={<IconSearch size={16} className="text-gray-400" />}
                              clearable
                           />

                           <div className="flex items-center gap-2">
                              <Button
                                 variant="flat"
                                 size="sm"
                                 disabled={currentPage === 1}
                                 onClick={() => {
                                    setCurrentPage(prev => prev - 1);
                                    //@ts-ignore
                                    getMailQuery(USER_SHOW, {
                                       endPoint: `/get-mails?page=${currentPage - 1}&filter=${filterType}`
                                    });
                                 }}
                              >
                                 Önceki
                              </Button>

                              <span className="text-sm font-medium text-gray-600">
                                 Sayfa {currentPage}
                              </span>

                              <Button
                                 variant="flat"
                                 size="sm"
                                 disabled={!hasMore}
                                 onClick={() => {
                                    setCurrentPage(prev => prev + 1);
                                    //@ts-ignore
                                    getMailQuery(USER_SHOW, {
                                       endPoint: `/get-mails?page=${currentPage + 1}&filter=${filterType}`
                                    });
                                 }}
                              >
                                 Sonraki
                              </Button>
                           </div>
                        </div>
                     </div>

                     {selectedMails.length > 0 && (
                        <motion.div
                           initial={{ opacity: 0, x: 20 }}
                           animate={{ opacity: 1, x: 0 }}
                           exit={{ opacity: 0, x: 20 }}
                           className="flex items-center gap-2"
                        >
                           <span className="text-sm font-medium text-blue-600 bg-blue-50 px-3 py-1 rounded-full">
                              {selectedMails.length} mail seçildi
                           </span>
                           <Button
                              size="sm"
                              color="danger"
                              variant="flat"
                              onClick={() => setSelectedMails([])}
                              className="hover:bg-red-50"
                           >
                              Seçimi Temizle
                           </Button>
                        </motion.div>
                     )}
                  </div>

                  {/* Mail List with Filter Indicator */}
                  <div className="relative">
                     {filterType !== 'all' && (
                        <FilterBanner type={filterType} onClear={() => setFilterType('all')} />
                     )}

                     {getFilteredMails().length === 0 && (
                        <div className="flex items-center justify-center h-48">
                           <Text>Hiç mail bulunamadı.</Text>
                        </div>
                     )}
                     {getMailLoading && (
                        <div className="flex items-center justify-center h-48">
                           <Text>Mailler yükleniyor...</Text>
                        </div>
                     )}

                     <div className={`divide-y divide-gray-100 ${filterType !== 'all' ? 'pt-12' : ''}`}>
                        {getFilteredMails().map((item: any) => (
                           <MailItem
                              key={item.id}
                              item={item}
                              isSelected={selectedMails.includes(item.id)}
                              onSelect={(checked) => {
                                 if (checked) {
                                    setSelectedMails(prev => [...prev, item.id]);
                                 } else {
                                    setSelectedMails(prev => prev.filter(id => id !== item.id));
                                 }
                              }}
                           />
                        ))}
                     </div>
                  </div>
               </div>
            </div>
         )}
         <AnimatePresence>
            {isSaving && (
               <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="fixed inset-0 z-[99999] flex items-center justify-center bg-black/50 backdrop-blur-sm"
               >
                  <motion.div
                     initial={{ scale: 0.8, opacity: 0 }}
                     animate={{ scale: 1, opacity: 1 }}
                     exit={{ scale: 0.8, opacity: 0 }}
                     className="bg-white rounded-2xl p-8 flex flex-col items-center gap-4 shadow-2xl"
                  >
                     <motion.div
                        animate={{
                           rotate: 360,
                           borderRadius: ["50% 50% 50% 50%", "40% 60% 60% 40%", "50% 50% 50% 50%"]
                        }}
                        transition={{
                           duration: 2,
                           repeat: Infinity,
                           ease: "linear"
                        }}
                        className="w-16 h-16 border-4 border-primary border-t-transparent"
                     />
                     <motion.span
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        className="text-lg font-medium text-gray-700"
                     >
                        Mail Listesine Ekleniyor
                     </motion.span>
                     <motion.div
                        initial={{ width: 0 }}
                        animate={{ width: "100%" }}
                        transition={{ duration: 1 }}
                        className="h-1 bg-primary/20 rounded-full overflow-hidden"
                     >
                        <motion.div
                           animate={{
                              x: ["-100%", "100%"]
                           }}
                           transition={{
                              duration: 1,
                              repeat: Infinity,
                              ease: "linear"
                           }}
                           className="h-full w-1/3 bg-primary rounded-full"
                        />
                     </motion.div>
                  </motion.div>
               </motion.div>
            )}
         </AnimatePresence>
      </div>
   )
}

export default Mail

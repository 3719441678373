import { Card, CardHeader, Skeleton, CardBody, Pagination, Button, Badge } from '@nextui-org/react';
import { CUSTOMER_MANAGEMENT_LIST } from 'api/customer-management';
import { routes } from 'config/routes';
import useFetch from 'hooks/use-fetch';
import { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ProfileHeader from './ProfileHeader';
import ProfileTab from './ProfileTab';
import { Avatar } from 'antd';
import PageHeader from 'components/page-header';
import { USER_LIST } from 'api/user';
import RequestsTable from '../customer-management/components/RequestsTable';
import OffersTable from '../customer-management/components/OffersTable';
import { motion } from 'framer-motion';
import { IconSearch, IconUserSearch, IconBuilding, IconArrowRight, IconUsers, IconX } from '@tabler/icons-react';
import { Input } from '@nextui-org/react';
import OrdersPage from '../orders/Page';
import RequestList from '../requests/List';
import OfferList from '../offers-management/List';


// Soft renk paleti
const colorPalettes = [
    { from: 'from-blue-400/80', to: 'to-indigo-400/80', text: 'text-blue-600' },
    { from: 'from-purple-400/80', to: 'to-pink-400/80', text: 'text-purple-600' },
    { from: 'from-emerald-400/80', to: 'to-teal-400/80', text: 'text-emerald-600' },
    { from: 'from-orange-400/80', to: 'to-amber-400/80', text: 'text-orange-600' },
    { from: 'from-rose-400/80', to: 'to-red-400/80', text: 'text-rose-600' },
];

// Şirket ismine göre renk seçimi
const getColorByName = (name: string) => {
    const gradients = ['blue', 'purple', 'emerald', 'orange', 'rose'];
    const index = name.length % gradients.length;
    return `gradient-${gradients[index]}`;
};

export default function UserProfile() {
    const { id } = useParams();
    const [fetchUser, { data: user, loading }] = useFetch<any>();
    const [tabs, setTabs] = useState<string>('toplam-talepler');
    const navigate = useNavigate();

    useEffect(() => {
        //@ts-ignore
        fetchUser(USER_LIST, {
            endPoint: `/users/${id}`
        });
    }, [id]);

    if (loading) {
        return <LoadingSkeleton />;
    }

    return (
        <div>
            <PageHeader
                title={user?.user?.name || 'Personel Profili'}
                breadcrumb={[
                    { href: routes.users.list, name: 'Personel Listesi' },
                    { name: 'Personel Profili' }
                ]}
            />

            <ProfileHeader user={user} />

            <div className="mt-10">
                <ProfileTab
                    customer={user}
                    activeTab={tabs} onTabChange={setTabs} />
            </div>

            <div className='mt-10'>
                <Card shadow="lg" className='p-4' radius="lg">
                    <div style={{
                        display: tabs === 'toplam-talepler' ? 'block' : 'none',
                    }}>
                        <RequestList
                            user_id={user?.user?.id}
                        />
                    </div>
                    <div style={{
                        display: tabs === 'toplam-teklifler' ? 'block' : 'none',
                    }}>
                        <OfferList

                            user_id={id} />
                    </div>

                    <div style={{
                        display: tabs === 'toplam-siparisler' ? 'block' : 'none',
                    }}>
                        <OrdersPage user_id={id as any} />
                    </div>
                    {/* 
                    {tabs === 'toplam-talepler' && <RequestsTable customer_id={id} />}
                    {tabs === 'toplam-teklifler' && <OffersTable customer_id={id} />}
                    
                    {tabs === 'toplam-siparisler' && <OrdersPage customer_id={id as any} />} */}
                </Card>
            </div>
            <CustomerPortfolio user={user} />
        </div>
    );
}

function LoadingSkeleton() {
    return (
        <div className="space-y-4">
            <Skeleton className="h-12 w-1/3" />
            <Card className="p-6">
                <div className="flex items-center gap-4 mb-6">
                    <Skeleton className="w-16 h-16 rounded-full" />
                    <div className="space-y-3">
                        <Skeleton className="h-6 w-48" />
                        <Skeleton className="h-4 w-32" />
                    </div>
                </div>
                <div className="grid grid-cols-3 gap-4">
                    <Skeleton className="h-24 rounded-lg" />
                    <Skeleton className="h-24 rounded-lg" />
                    <Skeleton className="h-24 rounded-lg" />
                </div>
            </Card>
        </div>
    );
}

const SearchInput = ({ value, onChange }: {
    value: string;
    onChange: (value: string) => void
}) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [isFocused, setIsFocused] = useState(false);

    return (
        <div className="relative w-72">
            <div
                className={`
               relative flex items-center gap-2 w-full
               bg-white rounded-xl
               border transition-all duration-200
               ${isFocused
                        ? 'border-blue-500 shadow-sm shadow-blue-100'
                        : 'border-gray-200 hover:border-gray-300'
                    }
            `}
                onClick={() => inputRef.current?.focus()}
            >
                {/* Arama İkonu */}
                <div className="pl-3">
                    <IconSearch
                        size={18}
                        className={`transition-colors ${isFocused ? 'text-blue-500' : 'text-gray-400'
                            }`}
                    />
                </div>

                {/* Input */}
                <input
                    ref={inputRef}
                    type="text"
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    placeholder="Müşteri ara..."
                    className="
                  w-full py-2 pr-3 
                  bg-transparent
                  text-sm text-gray-700 placeholder-gray-400
                  focus:outline-none
               "
                />

                {/* Temizleme Butonu */}
                {value && (
                    <button
                        onClick={(e) => {
                            e.stopPropagation();
                            onChange('');
                            inputRef.current?.focus();
                        }}
                        className="
                     absolute right-2
                     p-1 rounded-full
                     hover:bg-gray-100
                     text-gray-400 hover:text-gray-600
                     transition-colors
                  "
                    >
                        <IconX size={14} />
                    </button>
                )}
            </div>

            {/* Arama Animasyonu */}
            {isFocused && (
                <div className="absolute -bottom-1 left-1/2 -translate-x-1/2">
                    <div className="
                  w-12 h-0.5 
                  bg-gradient-to-r from-transparent via-blue-500 to-transparent
                  animate-searchPulse
               " />
                </div>
            )}
        </div>
    );
};

const CustomerPortfolio = ({ user }: { user: any }) => {
    const navigate = useNavigate();
    const [search, setSearch] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const filteredCustomers = user?.user_customers?.filter((contact: any) =>
        contact.company_name?.toLowerCase().includes(search.toLowerCase()) ||
        contact.email?.toLowerCase().includes(search.toLowerCase())
    );

    const paginatedCustomers = filteredCustomers?.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const totalPages = Math.ceil((filteredCustomers?.length || 0) / itemsPerPage);

    return (
        <div className='mt-10 bg-white rounded-xl shadow-sm'>
            {/* Header */}
            <div className="p-6 border-b border-gray-100">
                <div className="flex items-center justify-between">
                    <h2 className='text-xl font-semibold text-gray-800 flex items-center gap-2'>
                        <IconUsers size={24} className="text-blue-500" />
                        Müşteri Portföyü

                    </h2>

                    <SearchInput
                        value={search}
                        onChange={(value) => {
                            setSearch(value);
                            setCurrentPage(1);
                        }}
                    />
                </div>
            </div>

            {/* Grid */}
            <div className="p-6">
                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4'>
                    {paginatedCustomers?.map((contact: any, index: number) => (
                        <Card
                            key={contact.id}
                            isPressable
                            className="hover:shadow-lg transition-all duration-300 group"
                            as="a"
                            href={`/dashboard/customer-management/profile/${contact.id}`}
                        >
                            <CardBody className="p-4">
                                <div className="flex items-center gap-4">
                                    <div className="relative shrink-0">
                                        {/* Avatar container */}
                                        <div className={`
                                            w-16 h-16 rounded-xl
                                            flex items-center justify-center
                                            bg-gray-100
                                            shadow-sm
                                            backdrop-blur-sm
                                            group-hover:scale-105 transition-transform duration-300
                                            gradient-pattern
                                            bg-gradient-to-br ${getColorByName(contact.company_name)}
                                        `}>
                                            <span className="relative z-10 text-xl font-bold text-white drop-shadow-sm">
                                                {contact?.company_name?.charAt(0)}
                                            </span>
                                        </div>

                                        {/* Status indicator */}
                                        <div className="absolute -bottom-1 -right-1 
                                            w-4 h-4 rounded-full 
                                            border-2 border-white 
                                            bg-gradient-to-br from-emerald-400 to-emerald-500
                                            shadow-sm
                                        " />
                                    </div>

                                    <div className="flex-1 min-w-0">
                                        <h3 className={`
                                            font-semibold mb-1 line-clamp-1
                                            
                                            group-hover:opacity-80 transition-opacity
                                        `}>
                                            {contact.company_name}
                                        </h3>
                                        <p className="text-sm text-gray-500 line-clamp-1 mb-2">
                                            {contact.email}
                                        </p>
                                        <Button
                                            size="sm"
                                            variant="flat"
                                            className={`
                                                w-full
                                                text-black
                                                opacity-90 hover:opacity-100
                                                transition-all duration-300
                                            `}
                                            endContent={
                                                <div className="group-hover:translate-x-1 transition-transform duration-300">
                                                    <IconArrowRight size={16} />
                                                </div>
                                            }
                                        >
                                            Profile Git
                                        </Button>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    ))}
                </div>

                {/* Pagination */}
                {totalPages > 1 && (
                    <div className="mt-6 flex justify-center">
                        <Pagination
                            total={totalPages}
                            page={currentPage}
                            onChange={setCurrentPage}
                            showControls
                            color="primary"
                            className="gap-2"
                        />
                    </div>
                )}

                {/* Empty State */}
                {filteredCustomers?.length === 0 && (
                    <div className="text-center py-12">
                        <div className="text-gray-400 mb-3">
                            <IconUserSearch size={48} className="mx-auto" />
                        </div>
                        <p className="text-gray-600">
                            Aradığınız kriterlere uygun müşteri bulunamadı
                        </p>
                    </div>
                )}
            </div>
        </div>
    );
};

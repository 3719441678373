import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface RequestModalState {
    isOpen: boolean;
    contactId: number | null;
    productsFromCart: boolean;
    isAdded: boolean;
}

const initialState: RequestModalState = {
    isOpen: false,
    contactId: null,
    productsFromCart: false,
    isAdded: false
};

export const requestModalSlice = createSlice({
    name: 'requestModal',
    initialState,
    reducers: {
        openRequestModal: (state, action: PayloadAction<{ contactId: number | null, productsFromCart: boolean }>) => {
            state.isOpen = true;
            state.contactId = action.payload.contactId;
            state.productsFromCart = action.payload.productsFromCart;
        },
        setIsAdded: (state, action: PayloadAction<boolean>) => {
            state.isAdded = action.payload;
        },
        closeRequestModal: (state) => {
            state.isOpen = false;
            state.contactId = null;
        },
        setContactId: (state, action: PayloadAction<number>) => {
            state.contactId = action.payload;
        }
    }
});

export const { openRequestModal, closeRequestModal, setContactId, setIsAdded } = requestModalSlice.actions;
export default requestModalSlice.reducer; 
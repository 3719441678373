import { routes } from 'config/routes'
import { Divider, Form, Modal, TableColumnProps, UploadProps, message, notification } from 'antd'
import { useTranslation } from 'react-i18next'
import PageHeader from 'components/page-header'
import { Button, CardBody, CardHeader, Chip, Card, select } from '@nextui-org/react'
import { Link, useNavigate } from 'react-router-dom'
import useFetch from 'hooks/use-fetch'
import { useEffect, useState } from 'react'
import ActionButton from './ActionButton'
import moment from 'moment'
import {
   ADD_CONTACT,
   ADD_CONTACT_URL,
   CUSTOMER_MANAGEMENT_LIST,
   CUSTOMER_MANAGEMENT_LIST_URL,
   EDIT_CONTACT,
   EDIT_CONTACT_URL
} from 'api/customer-management'
import SearchInput from 'components/Input'
import TableComponents from 'components/Table'
import { IconChecklist, IconFilePlus, IconCalendar, IconUsers, IconUser, IconSearch, IconMail, IconFilter, IconFileImport, IconInfoCircle, IconUserPlus, IconFileDownload } from '@tabler/icons-react'
import ContactCard from './ContactCard'
import ContactForm from './ContactForm'
import Empty from 'components/Empty'
import { Drawer, Input, Title } from 'rizzui'
import Dragger from 'antd/es/upload/Dragger'
import { RiFileExcel2Line } from 'react-icons/ri'
import { apiUrl } from 'api'
import axios from 'axios'
import CustomerOffer from './CustomerOffer'
import CustomerProfile from './CustomerProfile'
import Pagination from 'components/ui/pagination'
import SuccessModal from 'components/modals/SuccessModal'
import { motion } from 'framer-motion'
import ExcelUploadModal from './components/ExcelUploadModal'
import AddCustomer from './AddCustomer'
import AddCustomerChoiceModal from './components/AddCustomerChoiceModal'
import * as XLSX from 'xlsx'


interface Contact {
   id: number;
   name?: string;
   email?: string;
   phone?: string;
   title?: string;
}

interface PreviewData {
   'Email': string;
   'isim_soyisim': string;
   'telefon': string;
   'sirket_adi': string;
   'sirket_eposta': string;
}

export default function CustomerManagementList({ customer_id }: { customer_id?: number }) {
   const { t } = useTranslation()
   const pageHeader = {
      title: 'Müşteri Yönetimi',
      breadcrumb: [
         {
            href: routes.dashboard,
            name: t('aside.dashboard')
         },
         {
            href: routes.dashboard,
            name: 'Müşteri Yönetimi'
         }
      ]
   }

   const navigate = useNavigate()

   const [offerManagementQuery, { data, loading }] = useFetch<any>()
   const [addContactMutation, { data: addContactData, loading: addContactLoading, error: addContactError }] =
      useFetch<any>()
   const [editContactMutation, { data: editContactData, loading: editContactLoading }] = useFetch<any>()
   const [seeAllOfferDrawer, setSeeAllOfferDrawer] = useState<boolean>(false)
   const [selectedContactId, setSelectedContactId] = useState<number | null>(null)
   const [selectedCustomerId, setSelectedCustomerId] = useState<number | null>(null)

   const [page, setPage] = useState<number>(1)
   const [search, setSearch] = useState<string>('')
   const [selectedRow, setSelectedRow] = useState<any>(null)
   const [selectedContact, setSelectedContact] = useState<any>(null)
   const [openContactsDrawer, setOpenContactsDrawer] = useState<boolean>(false)
   const [addContactDrawer, setAddContactDrawer] = useState<boolean>(false)
   const [editContactDrawer, setEditContactDrawer] = useState<boolean>(false)
   const [addContactForm] = Form.useForm()
   const [editContactForm] = Form.useForm()
   const [excelModal, setExcelModal] = useState<boolean>(false)
   const [customerProfileDrawer, setCustomerProfileDrawer] = useState<boolean>(false)
   const [editingContact, setEditingContact] = useState<Contact | null>(null)
   const [successModal, setSuccessModal] = useState({
      isOpen: false,
      message: '',
      description: ''
   });
   const [emailSearch, setEmailSearch] = useState('');
   const [addCustomerModal, setAddCustomerModal] = useState<boolean>(false);
   const [showChoiceModal, setShowChoiceModal] = useState(false);
   const [showPreview, setShowPreview] = useState(false);
   const [previewData, setPreviewData] = useState<any[]>([]);
   const [uploadedFile, setUploadedFile] = useState<File | null>(null);

   const onFilter = () => {
      //@ts-ignore
      offerManagementQuery(CUSTOMER_MANAGEMENT_LIST, {
         endPoint: CUSTOMER_MANAGEMENT_LIST_URL(page, search, emailSearch)
      })
   }

   const addNewCustomer = () => {
      setShowChoiceModal(true);
   }

   const handleAddContact = async (values: any) => {
      //@ts-ignore
      addContactMutation(ADD_CONTACT, {
         endPoint: ADD_CONTACT_URL(selectedRow.id),
         body: values
      })
   }

   const handleEditContact = (values: any) => {
      //@ts-ignore
      editContactMutation(EDIT_CONTACT, {
         endPoint: EDIT_CONTACT_URL(selectedRow.id, selectedContact.id),
         body: values
      })
   };

   const updateContact = (values: any) => {
      //@ts-ignore
      editContactMutation(EDIT_CONTACT, {
         endPoint: EDIT_CONTACT_URL(selectedContactId, selectedContact.id),
         body: values
      })
   }

   const handleDeleteContact = async (contactId: number) => {
      try {
         // Implement your delete API call here
         // After successful deletion, update the contacts list
         const updatedContacts = selectedRow.contacts.filter((c: Contact) => c.id !== contactId)
         setSelectedRow({ ...selectedRow, contacts: updatedContacts })
         message.success('Kişi başarıyla silindi')
      } catch (error) {
         message.error('Kişi silinirken bir hata oluştu')
      }
   }

   useEffect(() => {
      if (addContactData) {
         console.log(addContactData)
         if (addContactData.status) {

            setAddContactDrawer(false)
            const newContacts = [...selectedRow.contacts, addContactData.data]
            setSelectedRow({ ...selectedRow, contacts: newContacts })
            setSuccessModal({
               isOpen: true,
               message: 'Kişi Başarıyla Eklendi',
               description: 'Yeni kişi firma listesine başarıyla eklendi.'
            });

            //@ts-ignore
            offerManagementQuery(CUSTOMER_MANAGEMENT_LIST, {
               endPoint: CUSTOMER_MANAGEMENT_LIST_URL(page)
            })
         }
      }
   }, [addContactData])

   useEffect(() => {
      if (editContactData) {
         if (editContactData.status) {

            setEditContactDrawer(false)
            // const newContacts = [...selectedRow.contacts.filter((item: any) => item.id !== editContactData.data.id), editContactData.data];
            // setSelectedRow({ ...selectedRow, contacts: newContacts })

            const contacts = selectedRow.contacts.map((item: any) => {
               if (item.id === editContactData.data.id) {
                  return editContactData.data
               }
               return item
            })

            setSelectedRow({ ...selectedRow, contacts: contacts })
            setSuccessModal({
               isOpen: true,
               message: 'Kişi Başarıyla Güncellendi',
               description: 'Kişi bilgileri başarıyla güncellendi.'
            });

            //@ts-ignore
            offerManagementQuery(CUSTOMER_MANAGEMENT_LIST, {
               endPoint: CUSTOMER_MANAGEMENT_LIST_URL(page)
            })
         }
      }
   }, [editContactData])

   useEffect(() => {
      if (addContactError) {
         console.log(addContactError)

         notification.error({
            message: addContactError?.response?.data?.message || 'Bir hata oluştu'
         })
      }
   }, [addContactError])

   const columns: TableColumnProps<any>[] = [
      {
         title: 'Firma Adı',
         dataIndex: 'id',
         key: 'id',
         render: (id: any, record: any) => {
            return (
               <div
                  className="flex items-center gap-3 cursor-pointer hover:text-primary transition-colors"
                  onClick={() => navigate(`/admin/customer-management/profile/${record.id}`)}
               >
                  {record.company_name}
               </div>
            )
         }
      },
      {
         title: 'E-posta Adresi',
         dataIndex: 'id',
         key: 'id',
         render: (id: any, record: any) => {
            return (
               <div className="flex items-center gap-3">
                  {record?.contacts?.length > 0 ? record.contacts[0].email : 'E-posta adresi bulunamadı'}
               </div>
            )
         }
      },

      {
         title: 'Firmaya ait Kişiler',
         dataIndex: 'id',
         key: 'id',
         render: (id: any, record: any) => {
            return (
               <div className="flex items-center gap-3">
                  <Button
                     color="primary"
                     variant="ghost"
                     onClick={() => {
                        setSelectedRow(record)
                        setOpenContactsDrawer(true)
                     }}
                  >
                     Görüntüle
                  </Button>
               </div>
            )
         }
      },
      {
         title: 'Eklenme Tarihi',
         dataIndex: 'id',
         key: 'id',
         render: (id: any, record: any) => {
            return <div className="flex items-center gap-3">{moment(record.created_at).format('LL')}</div>
         }
      },

      {
         title: 'Durum',
         dataIndex: 'id',
         key: 'id',
         render: (id: any, record: any) => {
            return (
               <div className="flex items-center gap-3">
                  <Chip color={record.status ? 'success' : 'danger'} size="sm">
                     {record.status ? 'Aktif' : 'Pasif'}
                  </Chip>
               </div>
            )
         }
      },

      {
         title: t('common.actions'),
         dataIndex: 'id',
         key: 'id',
         render: (id: any, record: any) => {
            return (
               <div className="flex items-center gap-3">
                  <ActionButton
                     editButtonClick={() => {
                        navigate(routes.customerManagement.view(record.id))
                     }}
                     deleteButtonClick={() => {
                        //@ts-ignore
                        // Modal.confirm({
                        //    title: t('messages.delete_confirm'),
                        //    okText: t('buttons.delete'),
                        //    cancelText: t('buttons.cancel'),
                        //    onOk: () => {
                        //       //@ts-ignore
                        //       deleteReferralsMutation(REFERRALS_DELETE, {
                        //          endPoint: REFERRALS_UPDATE_URL + '/' + id
                        //       })
                        //    }
                        // })
                     }}
                  />
               </div>
            )
         }
      },
      {
         title: t('common.actions'),
         dataIndex: 'id',
         key: 'id',
         render: (id: any, record: any) => {
            return (
               <div className="flex items-center gap-3">
                  <Button
                     onClick={() => {
                        setSelectedContactId(record.id)
                        setCustomerProfileDrawer(true)
                     }}
                     color="primary"
                     variant="ghost"
                     startContent={<IconChecklist size={20} />}
                  >
                     Müşteri Profili
                  </Button>
               </div>
            )
         }
      }
   ]

   useEffect(() => {
      //@ts-ignore
      offerManagementQuery(CUSTOMER_MANAGEMENT_LIST, {
         endPoint: CUSTOMER_MANAGEMENT_LIST_URL(page, search, emailSearch, customer_id)
      })
   }, [page])

   const handleUpload = async (options: any) => {
      const { file } = options;
      setUploadedFile(file);

      try {
         const reader = new FileReader();
         reader.onload = (e: ProgressEvent<FileReader>) => {
            if (e.target?.result) {
               const data = new Uint8Array(e.target.result as ArrayBuffer);
               const workbook = XLSX.read(data, { type: 'array' });
               const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
               const jsonData = XLSX.utils.sheet_to_json(firstSheet) as PreviewData[];
               setPreviewData(jsonData.slice(0, 10));
               setShowPreview(true);
            }
         };
         reader.readAsArrayBuffer(file);
      } catch (error) {
         Modal.error({
            title: 'Yükleme Hatası',
            content: 'Excel dosyası yüklenirken bir hata oluştu.'
         });
      }
   };

   const handleExcelSuccess = () => {
      setSuccessModal({
         isOpen: true,
         message: 'Excel Yükleme Başarılı',
         description: 'Müşteri verileri başarıyla içe aktarıldı. işlem tamamlanınca listeye yansıyacaktır.'
      });
      // Listeyi yenile
      onFilter();
   };

   // Add uploadProps configuration
   const uploadProps: UploadProps = {
      name: 'file',
      multiple: false,
      customRequest: handleUpload,
      accept: '.xlsx, .xls',
      showUploadList: false,
   };

   const downloadExcelTemplate = () => {
      const data = [
         ['email', 'isim_soyisim', 'telefon', 'sirket_adi', 'sirket_eposta'],
         ['isim@myfcyazilim.com', 'Fırat Kayar', '05312089801', 'Myfc Yazılım', 'info@myfcyazilim.com'],

      ];

      const ws = XLSX.utils.aoa_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Müşteriler');
      XLSX.writeFile(wb, 'musteri_listesi_sablonu.xlsx');
   };

   // Add this new function for actual file upload
   const handleConfirmUpload = async (file: File) => {
      try {
         const formData = new FormData();
         formData.append('file', file);
         formData.append('type', 'customer');

         const response = await axios.post(`${apiUrl}/api/uploadFileTask`, formData, {
            headers: {
               'Content-Type': 'multipart/form-data',
               'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
         });

         if (response.data.success) {
            setExcelModal(false);
            setShowPreview(false);
            setPreviewData([]);
            handleExcelSuccess(); // This will show success modal and refresh the list
         }
      } catch (error: any) {
         Modal.error({
            title: 'Yükleme Hatası',
            content: error?.response?.data?.message || 'Excel dosyası yüklenirken bir hata oluştu.'
         });
      }
   };

   return (
      <div className="space-y-6 p-6 bg-gray-50 min-h-screen">
         {/* Header Section */}
         <div className="flex flex-col lg:flex-row justify-between items-start lg:items-center gap-4 bg-white p-6 rounded-xl shadow-sm">
            <div>
               <h1 className="text-2xl font-bold text-gray-800">Müşteri Yönetimi</h1>
               <p className="text-gray-500 mt-1">Tüm müşterilerinizi buradan yönetebilirsiniz</p>
            </div>
            <div className="flex gap-3">
               <Button
                  color="primary"
                  className="relative overflow-hidden group  hover:from-blue-700 hover:to-blue-900 text-white shadow-lg hover:shadow-blue-500/30 transition-all duration-300 transform hover:scale-[1.02]"
                  startContent={
                     <>
                        <IconFilePlus className="h-5 w-5 transition-transform group-hover:rotate-12" />
                        <div className="absolute inset-0 bg-white/20 group-hover:bg-transparent transition-colors duration-300" />
                     </>
                  }
                  onClick={addNewCustomer}
                  size="lg"
               >
                  <span className="relative z-10 font-medium">Yeni Müşteri Ekle</span>
               </Button>
               <Button
                  color="primary"
                  variant="flat"
                  startContent={<IconFileImport className="w-5 h-5" />}
                  onClick={() => {
                     const user = JSON.parse(localStorage.getItem('user') || '{}')
                     if (user.role === 'admin') {
                        const url = apiUrl.replace('api/', '') + '/downloadCustomer'
                        window.open(url, '_blank')
                     } else {
                        const url = apiUrl.replace('api/', '') + '/downloadCustomer?user_id=' + user.id
                        window.open(url, '_blank')
                     }
                  }}
               >
                  Müşterileri Dışa Aktar
               </Button>
            </div>
         </div>

         {/* Search and Filter Section */}
         <Card className="border-none shadow-sm">
            <CardBody className="p-6">
               <form
                  onSubmit={(e) => {
                     e.preventDefault();
                     onFilter();
                  }}
                  className="flex flex-col lg:flex-row gap-6"
               >
                  <div className="flex-1">
                     <div className="space-y-4">
                        <h3 className="text-base font-semibold text-gray-800">Arama & Filtreleme</h3>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                           <div>
                              <label className="text-sm font-medium text-gray-700 mb-1.5 block">
                                 Firma Adı
                              </label>
                              <Input
                                 placeholder="Firma adı ile arayın..."
                                 onChange={(e: any) => setSearch(e.target.value)}
                                 value={search}
                                 prefix={<IconSearch className="text-gray-400" />}
                                 onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                       e.preventDefault();
                                       onFilter();
                                    }
                                 }}
                              />
                           </div>
                           <div>
                              <label className="text-sm font-medium text-gray-700 mb-1.5 block">
                                 E-posta
                              </label>
                              <Input
                                 placeholder="E-posta ile arayın..."
                                 onChange={(e: any) => setEmailSearch(e.target.value)}
                                 value={emailSearch}
                                 prefix={<IconMail className="text-gray-400" />}
                                 onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                       e.preventDefault();
                                       onFilter();
                                    }
                                 }}
                              />
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="flex items-end">
                     <Button
                        type="submit"
                        className={`min-w-[140px] h-[42px] ${loading ? 'opacity-80' : 'hover:scale-105'}`}
                        style={{
                           background: 'linear-gradient(to right, #3b82f6, #2563eb)',
                        }}
                        isDisabled={loading}
                        isLoading={loading}
                     >
                        <div className="flex items-center gap-2 text-white">
                           <IconFilter className="w-4 h-4" />
                           <span>{loading ? 'Filtreleniyor...' : 'Filtrele'}</span>
                        </div>
                     </Button>
                  </div>
               </form>
            </CardBody>
         </Card>

         {/* Customer List */}
         <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
            {(data?.data?.data || []).map((customer: any) => (
               <Card
                  key={customer.id}
                  className="border-none shadow-sm hover:shadow-md transition-shadow duration-200"
               >
                  <CardBody className="p-6">
                     <div className="flex justify-between items-start mb-4">
                        <div>
                           <h3 className="text-lg font-semibold text-gray-800">
                              {customer.company_name}
                           </h3>
                           <p className="text-gray-500 text-sm mt-1">
                              {
                                 customer.email
                              }
                           </p>
                        </div>
                     </div>

                     <div className="space-y-3">
                        <div className="flex items-center gap-2 text-gray-600">
                           <IconCalendar className="h-4 w-4" />
                           <span className="text-sm">
                              {moment(customer.created_at).format('LL')}
                           </span>
                        </div>

                        <div className="flex items-center gap-2 text-gray-600">
                           <IconUsers className="h-4 w-4" />
                           <span className="text-sm">
                              {customer.contacts?.length || 0} Kişi
                           </span>
                        </div>
                     </div>

                     <Divider className="my-4" />

                     <div className="flex gap-2 justify-between">
                        <Button
                           color="primary"
                           variant="flat"
                           size="sm"
                           onClick={() => {
                              setSelectedRow(customer);
                              setOpenContactsDrawer(true);
                           }}
                        >
                           Kişileri Görüntüle
                        </Button>
                        <Link
                           to={routes.customerManagement.profile(customer.id)}
                           className="inline-flex"
                        >
                           <Button
                              color="primary"
                              variant="light"
                              size="sm"
                              startContent={<IconUser className="w-4 h-4" />}
                           >
                              Müşteri Profili
                           </Button>
                        </Link>
                        <Button
                           color="primary"
                           variant="light"
                           size="sm"
                           onClick={() => navigate(routes.customerManagement.view(customer.id))}
                        >
                           Düzenle
                        </Button>
                     </div>
                  </CardBody>
               </Card>
            ))}
         </div>

         {/* Empty State */}
         {data?.data?.data?.length === 0 && (
            <div className="bg-white rounded-xl shadow-sm overflow-hidden">
               <div className="p-8 flex flex-col items-center justify-center min-h-[400px]">
                  <div className="w-20 h-20 rounded-full bg-gray-50 flex items-center justify-center mb-6">
                     <motion.div
                        initial={{ scale: 0 }}
                        animate={{ scale: 1 }}
                        transition={{
                           type: "spring",
                           stiffness: 260,
                           damping: 20
                        }}
                     >
                        <IconUsers className="w-10 h-10 text-gray-400" />
                     </motion.div>
                  </div>

                  <motion.div
                     initial={{ opacity: 0, y: 20 }}
                     animate={{ opacity: 1, y: 0 }}
                     transition={{ delay: 0.1 }}
                     className="text-center"
                  >
                     <h3 className="text-xl font-semibold text-gray-800 mb-2">
                        Henüz müşteri bulunmuyor
                     </h3>
                     <p className="text-gray-500 mb-6 max-w-md">
                        Sisteme henüz müşteri eklenmemiş. Yeni müşteri ekleyerek müşterilerinizi yönetmeye başlayabilirsiniz.
                     </p>

                     <div className="space-y-3 gap-2">
                        <Button
                           className="bg-primary min-w-[200px]"
                           startContent={<IconFilePlus className="w-5 h-5" />}
                           onClick={() => {
                              setAddCustomerModal(true)
                           }}
                        >
                           Manuel Müşteri Ekle
                        </Button>

                        <Button
                           className="min-w-[200px] ml-2"
                           color="secondary"
                           variant="flat"
                           startContent={<IconFileImport className="w-5 h-5" />}
                           onClick={() => setExcelModal(true)}
                        >
                           Excel ile İçe Aktar
                        </Button>
                     </div>
                  </motion.div>
               </div>
            </div>
         )}

         {/* Pagination */}
         <div className="flex justify-center mt-6">
            <Pagination
               total={data?.data?.total}
               current={page}
               pageSize={10}
               onChange={(page: number) => setPage(page)}
            />
         </div>

         {/* Existing Drawers */}
         {/* ... keep existing drawer components ... */}

         {/* Contacts Drawer */}
         <Drawer
            isOpen={openContactsDrawer}
            onClose={() => setOpenContactsDrawer(false)}
            size="lg"
            placement="right"
            className="max-w-[720px] w-full"
         >
            <div className="h-full flex flex-col">
               <div className="p-5 border-b border-gray-200">
                  <h3 className="text-lg font-semibold text-gray-800">
                     {selectedRow?.company_name || 'Firma'} Kişileri
                  </h3>
               </div>
               <div className="flex-1 overflow-y-auto">
                  <ContactCard
                     contacts={selectedRow?.contacts || []}
                     onClose={() => setOpenContactsDrawer(false)}
                     onEdit={(contact) => {
                        setSelectedContact(contact);
                        setEditContactDrawer(true);
                        editContactForm.setFieldsValue(contact);
                     }}
                     onDelete={handleDeleteContact}
                     onAdd={() => setAddContactDrawer(true)}
                  />
               </div>
            </div>
         </Drawer>

         {/* Edit Contact Drawer */}
         <Drawer
            isOpen={editContactDrawer}
            onClose={() => setEditContactDrawer(false)}
            size="lg"
            placement="right"
            className="max-w-[800px] w-full"
         >
            <div className="h-full flex flex-col">
               <div className="p-5 border-b border-gray-200">
                  <div className="flex items-center gap-3">
                     <div className="w-10 h-10 rounded-full bg-gradient-to-br from-primary/10 to-primary/20 flex items-center justify-center">
                        <IconUser className="w-5 h-5 text-primary" />
                     </div>
                     <div>
                        <h3 className="text-lg font-semibold text-gray-800">Kişi Düzenle</h3>
                        <p className="text-sm text-gray-500">{selectedContact?.name}</p>
                     </div>
                  </div>
               </div>
               <div className="flex-1 overflow-y-auto">
                  <ContactForm
                     initialValues={selectedContact}
                     onFinish={handleEditContact}
                     onClose={() => setEditContactDrawer(false)}
                     loading={editContactLoading}
                  />
               </div>
            </div>
         </Drawer>

         {/* Add Contact Drawer */}
         <Drawer
            isOpen={addContactDrawer}
            onClose={() => setAddContactDrawer(false)}
            size="lg"
            placement="right"

         >
            <div className="h-full flex flex-col">
               <div className="p-5 border-b border-gray-200">
                  <div className="flex items-center gap-3">
                     <div className="w-10 h-10 rounded-full bg-gradient-to-br from-primary/10 to-primary/20 flex items-center justify-center">
                        <IconUser className="w-5 h-5 text-primary" />
                     </div>
                     <div>
                        <h3 className="text-lg font-semibold text-gray-800">Yeni Kişi Ekle</h3>
                        <p className="text-sm text-gray-500">{selectedRow?.company_name}</p>
                     </div>
                  </div>
               </div>
               <div className="flex-1 overflow-y-auto">
                  <ContactForm
                     onFinish={handleAddContact}
                     onClose={() => setAddContactDrawer(false)}
                     loading={addContactLoading}
                  />
               </div>
            </div>
         </Drawer>

         <SuccessModal
            isOpen={successModal.isOpen}
            onClose={() => setSuccessModal(prev => ({ ...prev, isOpen: false }))}
            message={successModal.message}
            description={successModal.description}
         />

         <Modal
            title={
               <div className="flex items-center gap-3 mb-2">
                  <div className="w-12 h-12 rounded-xl bg-gradient-to-br from-green-400/20 to-green-500/20 flex items-center justify-center">
                     <RiFileExcel2Line className="w-6 h-6 text-green-600" />
                  </div>
                  <div>
                     <h3 className="text-lg font-semibold text-gray-800">Excel ile Müşteri Yükle</h3>
                     <p className="text-sm text-gray-500">Toplu müşteri verilerini içe aktarın</p>
                  </div>
               </div>
            }
            open={excelModal}
            onCancel={() => {
               setExcelModal(false);
               setShowPreview(false);
               setPreviewData([]);
            }}
            footer={null}
            width={800}
            className="p-0"
         >
            <div className="px-6 pb-6">
               {!showPreview ? (
                  <>
                     <Dragger {...uploadProps} className="mb-4">
                        <div className="text-center">
                           <div className="w-16 h-16 rounded-full flex items-center justify-center mx-auto mb-4">
                              <RiFileExcel2Line className="w-8 h-8 text-green-600" />
                           </div>
                           <p className="text-lg font-medium text-gray-800 mb-2">
                              Excel Dosyasını Sürükleyin
                           </p>
                           <p className="text-sm text-gray-500">
                              veya dosya seçmek için tıklayın
                           </p>
                           <div className="flex items-center justify-center gap-2 mt-4">
                              <span className="px-3 py-1 rounded-full text-xs font-medium bg-gray-100 text-gray-600">
                                 .xlsx
                              </span>
                              <span className="px-3 py-1 rounded-full text-xs font-medium bg-gray-100 text-gray-600">
                                 .xls
                              </span>
                           </div>
                        </div>
                     </Dragger>

                     <div className="mt-4 flex justify-between items-center">
                        <div className="flex items-center gap-2">
                           <IconInfoCircle className="w-5 h-5 text-blue-500" />
                           <span className="text-sm text-gray-600">
                              Örnek Excel şablonunu indirerek başlayabilirsiniz
                           </span>
                        </div>
                        <Button
                           onClick={downloadExcelTemplate}
                           color="primary"
                           variant="light"
                           startContent={<IconFileDownload className="w-4 h-4" />}
                        >
                           Örnek Excel İndir
                        </Button>
                     </div>
                  </>
               ) : (
                  <div className="space-y-4">
                     <div className="bg-blue-50 p-4 rounded-lg">
                        <h4 className="text-sm font-medium text-blue-700 mb-2">
                           İlk 10 Satır Önizleme
                        </h4>
                        <div className="overflow-x-auto">
                           <table className="min-w-full divide-y divide-gray-200">
                              <thead className="bg-gray-50">
                                 <tr>
                                    {Object.keys(previewData[0] || {}).map((header) => (
                                       <th
                                          key={header}
                                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                       >
                                          {header}
                                       </th>
                                    ))}
                                 </tr>
                              </thead>
                              <tbody className="bg-white divide-y divide-gray-200">
                                 {previewData.map((row, idx) => (
                                    <tr key={idx}>
                                       {Object.values(row).map((value, cellIdx) => (
                                          <td
                                             key={cellIdx}
                                             className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                                          >
                                             {value as string}
                                          </td>
                                       ))}
                                    </tr>
                                 ))}
                              </tbody>
                           </table>
                        </div>
                     </div>

                     <div className="flex justify-end gap-3">
                        <Button
                           color="danger"
                           variant="light"
                           onClick={() => {
                              setShowPreview(false);
                              setPreviewData([]);
                           }}
                        >
                           İptal
                        </Button>
                        <Button
                           color="primary"
                           onClick={() => uploadedFile && handleConfirmUpload(uploadedFile)}
                        >
                           Yüklemeyi Onayla
                        </Button>
                     </div>
                  </div>
               )}
            </div>
         </Modal>

         {/* Add Customer Modal */}
         <Modal
            title={
               <div className="flex items-center gap-2 p-2">
                  <div className="w-8 h-8 rounded-full bg-primary/10 flex items-center justify-center">
                     <IconUserPlus className="w-5 h-5 text-primary" />
                  </div>
                  <div>
                     <h3 className="text-lg font-semibold">Yeni Müşteri Ekle</h3>
                     <p className="text-sm text-gray-500">Müşteri bilgilerini doldurun</p>
                  </div>
               </div>
            }
            width={800}
            open={addCustomerModal}
            onCancel={() => setAddCustomerModal(false)}
            footer={null}
         >
            <div className="p-4">
               <AddCustomer
                  onSuccess={() => {
                     setAddCustomerModal(false);
                     onFilter(); // Listeyi yenile
                     setSuccessModal({
                        isOpen: true,
                        message: 'Müşteri Başarıyla Eklendi',
                        description: 'Yeni müşteri kaydı başarıyla oluşturuldu.'
                     });
                  }}
                  onCancel={() => setAddCustomerModal(false)}
               />
            </div>
         </Modal>

         <AddCustomerChoiceModal
            isOpen={showChoiceModal}
            onClose={() => setShowChoiceModal(false)}
            onManualAdd={() => {
               setShowChoiceModal(false);
               setAddCustomerModal(true);
            }}
            onExcelAdd={() => {
               setShowChoiceModal(false);
               setExcelModal(true);
            }}
         />


      </div>
   )
}

import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface UserState {
   user: any
   token?: string
   shouldUpdateProfile: boolean,
   totalRequests: number,
   totalOffers: number,
   totalOrders: number,
   totalProfit: number,
   totalRevenue: number,
   conversionRate: any
}

const initialState: UserState = {
   user: {},
   token: '',
   shouldUpdateProfile: false,
   totalRequests: 0,
   totalOffers: 0,
   totalOrders: 0,
   totalProfit: 0,
   totalRevenue: 0,
   conversionRate: null
}

export const userSlice = createSlice({
   name: 'user',
   initialState,
   reducers: {
      setUser: (state, action: PayloadAction<UserState>) => {
         state.user = action.payload
      },
      setToken: (state, action: PayloadAction<string>) => {
         state.token = action.payload
      },
      setShouldUpdateProfile: (state, action: PayloadAction<boolean>) => {
         state.shouldUpdateProfile = action.payload
      },
      setTotalRequests: (state, action: PayloadAction<number>) => {
         state.totalRequests = action.payload
      },
      setTotalOffers: (state, action: PayloadAction<number>) => {
         state.totalOffers = action.payload
      },
      setTotalOrders: (state, action: PayloadAction<number>) => {
         state.totalOrders = action.payload
      },
      setTotalProfit: (state, action: PayloadAction<number>) => {
         state.totalProfit = action.payload
      },
      setTotalRevenue: (state, action: PayloadAction<number>) => {
         state.totalRevenue = action.payload
      },
      setConversionRate: (state, action: PayloadAction<any>) => {
         state.conversionRate = action.payload
      }
   }
})

// Action creators are generated for each case reducer function
export const { setUser, setShouldUpdateProfile, setToken, setTotalRequests, setTotalOffers, setTotalOrders, setTotalProfit, setTotalRevenue, setConversionRate } = userSlice.actions

export default userSlice.reducer

import { Card, CardHeader, Skeleton } from '@nextui-org/react';
import { CUSTOMER_MANAGEMENT_LIST } from 'api/customer-management';
import { routes } from 'config/routes';
import useFetch from 'hooks/use-fetch';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CustomerHeader from './components/CustomerHeader';
import CustomerTabs from './components/CustomerTabs';
import RequestsTable from './components/RequestsTable';
import { Avatar } from 'rizzui';
import PageHeader from 'components/page-header';
import OffersTable from './components/OffersTable';
import OrdersPage from '../orders/Page';
import RequestList from '../requests/List';
import OfferList from '../offers-management/List';

export default function CustomerProfile() {
   const { id } = useParams();
   const [fetchCustomer, { data: customer, loading }] = useFetch<any>();
   const [tabs, setTabs] = useState<string>('toplam-talepler');

   useEffect(() => {
      //@ts-ignore
      fetchCustomer(CUSTOMER_MANAGEMENT_LIST, {
         endPoint: `/customers/${id}`
      });
   }, [id]);

   if (loading) {
      return <LoadingSkeleton />;
   }

   return (
      <div>
         <PageHeader
            title={customer?.company_name || 'Müşteri Detayı'}
            breadcrumb={[
               { href: routes.customerManagement.list, name: 'Müşteri Yönetimi' },
               { name: 'Müşteri Detayı' }
            ]}
         />

         <CustomerHeader customer={customer} />

         <div className="mt-10">
            <CustomerTabs
               id={id || ''}
               customer={customer}
               activeTab={tabs} onTabChange={setTabs} />
         </div>

         <div className='mt-10'>

            {/* {tabs === 'toplam-talepler' && <RequestList customer_id={id} />}
            {tabs === 'toplam-teklifler' && <OfferList customer_id={id} />}
            {tabs === 'toplam-siparisler' && <OrdersPage customer_id={id as any} />} */}
            <div style={{
               display: tabs === 'toplam-talepler' ? 'block' : 'none'
            }}>
               <RequestList customer_id={id} />
            </div>
            <div style={{
               display: tabs === 'toplam-teklifler' ? 'block' : 'none'
            }}>
               <OfferList customer_id={id} />
            </div>
            <div style={{
               display: tabs === 'toplam-siparisler' ? 'block' : 'none'
            }}>
               <OrdersPage customer_id={id as any} />
            </div>
         </div>
         <div className='mt-20'>
            <h2 className='text-2xl font-semibold'>
               Yetkili Kişiler
            </h2>

            <div className='grid grid-cols-5 items-center gap-2 mt-10'>
               {
                  customer?.data?.contacts?.map((contact: any) => (
                     <Card key={contact.id} className='p-6 flex flex-col items-center text-center'>
                        <Avatar
                           src={contact.avatar || ''}
                           name={contact.name}
                           size="lg"
                           className="mb-4"
                        />
                        <h3 className="text-lg font-semibold text-gray-800">
                           {contact.name}
                        </h3>
                        <p className="text-sm text-gray-500 mt-1">
                           {contact.email}
                        </p>
                        {/* <button className="mt-4 px-6 py-2 text-gray-600 border border-gray-300 rounded-full hover:bg-gray-50 transition-colors">
                           Profile Git
                        </button> */}
                     </Card>
                  ))
               }
            </div>
         </div>
      </div>
   );
}

function LoadingSkeleton() {
   return (
      <div className="space-y-4">
         <Skeleton className="h-12 w-1/3" />
         <Card className="p-6">
            <div className="flex items-center gap-4 mb-6">
               <Skeleton className="w-16 h-16 rounded-full" />
               <div className="space-y-3">
                  <Skeleton className="h-6 w-48" />
                  <Skeleton className="h-4 w-32" />
               </div>
            </div>
            <div className="grid grid-cols-3 gap-4">
               <Skeleton className="h-24 rounded-lg" />
               <Skeleton className="h-24 rounded-lg" />
               <Skeleton className="h-24 rounded-lg" />
            </div>
         </Card>
      </div>
   );
}

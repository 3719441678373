import { Button } from '@nextui-org/react';
import { Badge } from 'rizzui';
import { FaChartLine, FaUpload } from "react-icons/fa6";
import { motion } from 'framer-motion';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { useEffect } from 'react';

export default function CustomerTabs({ activeTab, onTabChange, customer }: { activeTab: string, onTabChange: (tab: string) => void, customer: any }) {

    const userF = useSelector((state: RootState) => state.user)

    const tabItems = [
        { slug: 'toplam-talepler', name: 'Toplam Talepler', total: userF?.totalRequests },
        { slug: 'toplam-teklifler', name: 'Toplam Teklifler', total: userF?.totalOffers ?? 0 },
        { slug: 'toplam-siparisler', name: 'Toplam Siparişler', total: userF?.totalOrders ?? 0 }
    ];

    useEffect(() => {
        console.log(userF)
    }, [userF?.totalRequests])

    return (
        <div className='flex items-center justify-between'>
            <motion.div
                className='flex items-center gap-2'
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
            >
                {tabItems.map((item, index) => (
                    <motion.div
                        key={item.slug}
                        initial={{ opacity: 0, x: -20 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ delay: index * 0.1 }}
                    >
                        <Button
                            size="lg"
                            className={`relative ${activeTab === item.slug ? 'bg-primary text-white' : 'text-gray-500 hover:bg-gray-100'}`}
                            onClick={() => onTabChange(item.slug)}
                        >
                            {activeTab === item.slug && (
                                <motion.div
                                    layoutId="activeTab"
                                    className="absolute inset-0 bg-primary rounded-lg"
                                    initial={false}
                                    transition={{ type: "spring", duration: 0.5 }}
                                />
                            )}
                            <span className="relative z-10 flex items-center gap-2">
                                {item.name}
                                <Badge
                                    color='primary'
                                    variant='solid'
                                    className={`${activeTab === item.slug ? 'bg-white text-primary' : 'text-gray-500 bg-gray-100'}`}
                                >
                                    {item.total}
                                </Badge>
                            </span>
                        </Button>
                    </motion.div>
                ))}
            </motion.div>

            <motion.div
                className='flex items-center gap-2'
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: 0.3 }}
            >

            </motion.div>
        </div>
    );
} 